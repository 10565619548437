import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge, Subject, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProfileService } from '@services/profile.service';

enum SocialMediaEnum {
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    SNAPCHAT = 'snapchat',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
    SPOTIFY = 'spotify',
    TIKTOK = 'tiktok',
    LINKEDIN = 'linkedin',
}

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.less'],
})
export class SignUpComponent implements OnInit, OnDestroy {
    validateForm!: FormGroup;
    subscriptions: Subscription[] = [];
    checked = true;
    submitting = false;
    isCodeChecked = false;
    socialMedialPopOverVisible = false;
    shopifyToken: string;
    platforms: SocialMediaEnum[] = [SocialMediaEnum.YOUTUBE, SocialMediaEnum.INSTAGRAM, SocialMediaEnum.TIKTOK];
    private unsubscribe: Subject<void> = new Subject();

    get invitationCode() {
        return !this.validateForm.valid || (!!this.validateForm.controls.invitation_code.value && !this.isCodeChecked);
    }

    constructor(
        private fb: FormBuilder,
        private auth: AngularFireAuth,
        private router: Router,
        private message: NzMessageService,
        private profileService: ProfileService,
        private activatedRoute: ActivatedRoute
    ) {}

    checkPasswords(group: FormGroup) {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirm_password').value;
        return !!pass && !!confirmPass && pass === confirmPass ? null : { notSame: true };
    }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            confirm_password: [null, [Validators.required]],
            terms_of_use: [false, [Validators.requiredTrue]],
            invitation_code: [null],
            platform: ['instagram', [Validators.required]],
            platform_id: [null, [Validators.required]],
        });

        merge(this.validateForm.controls.password.valueChanges, this.validateForm.controls.confirm_password.valueChanges)
            .pipe(takeUntil(this.unsubscribe), debounceTime(300))
            .subscribe(() => {
                this.validateForm.get('confirm_password').markAsTouched();
                this.validateForm.get('confirm_password').setErrors(this.checkPasswords(this.validateForm));
            });

        this.validateForm.controls.invitation_code.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(async () => {
            this.isCodeChecked = false;
            if (this.validateForm.controls.invitation_code.errors) {
                Object.keys(this.validateForm.controls.invitation_code.errors)
                    .filter(errorCode => errorCode !== 'invalidCode')
                    .forEach(errorCode => {
                        this.validateForm.controls.invitation_code.setErrors({
                            [errorCode]: this.validateForm.controls.invitation_code.errors[errorCode],
                        });
                    });
            }
            if (this.validateForm.controls.invitation_code.value) {
                try {
                    await this.profileService.checkInvitationCode({ invitation_code: this.validateForm.controls.invitation_code.value });
                    this.isCodeChecked = true;
                } catch (error) {
                    if (this.validateForm.controls.invitation_code.value) {
                        this.validateForm.controls.invitation_code.setErrors({ invalidCode: error.body.error });
                    }
                }
            }
        });

        this.shopifyToken = this.activatedRoute.snapshot.queryParamMap.get('shopifyToken');
    }

    submitForm(): void {
        if (this.submitting) {
            return;
        }
        if (this.invitationCode) {
            throw new Error('Form is not valid');
        }
        this.submitting = true;
        const signInInformation = this.validateForm.getRawValue();
        this.auth
            .createUserWithEmailAndPassword(signInInformation.email, signInInformation.password)
            .then(() => {
                const codeParams: any = {
                    invitation_code: this.validateForm.controls.invitation_code.value,
                    platform: this.validateForm.controls.platform.value,
                    platform_id: this.validateForm.controls.platform_id.value,
                };
                const utm_source = this.activatedRoute.snapshot.queryParamMap.get('utm_source');
                const utm_medium = this.activatedRoute.snapshot.queryParamMap.get('utm_medium');
                const utm_campaign = this.activatedRoute.snapshot.queryParamMap.get('utm_campaign');

                if (this.shopifyToken) {
                    codeParams.shopify_token = this.shopifyToken;
                }

                if (utm_source) {
                    codeParams.utm_source = utm_source;
                }

                if (utm_medium) {
                    codeParams.utm_medium = utm_medium;
                }

                if (utm_campaign) {
                    codeParams.utm_campaign = utm_campaign;
                }

                return this.profileService.sendInvitationCode(codeParams);
            })
            .then(res => {
                if (res.error) {
                    this.message.error(res.error, { nzDuration: 3000 });
                    this.auth.signOut();
                    return false;
                }

                const invitation_code = this.activatedRoute.snapshot.queryParamMap.get('t');

                if (invitation_code) {
                    const invitedParams = {
                        currentEmail: signInInformation.email,
                        invitationCode: invitation_code,
                    };
                    return this.profileService.receiveInvited(invitedParams);
                }
                return Promise.resolve(true);
            })
            .then(res => {
                this.profileService.getCurrentProfile().then(profile => (this.profileService.currentProfile = profile));
                this.router.navigate(['/home/dashboard']).then(() => (this.submitting = false));
            })
            .catch(error => {
                this.submitting = false;
                this.auth.signOut();
                this.message.create('error', error.message || error?.body.message);
            });
    }

    selectPlatform(platform: SocialMediaEnum) {
        this.validateForm.controls.platform.setValue(platform);
        this.socialMedialPopOverVisible = false;
    }

    getSocialIcon(platform: SocialMediaEnum) {
        return `assets/svg/platform_icons/new_${platform}.svg`;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
