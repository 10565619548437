import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';
import { Component, OnInit, Input } from '@angular/core';
import { DateTimeService } from '@services/date-time.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);
dayjs.extend(isSameOrBefore);

@Component({
    selector: 'app-start-crowdfunding-modal',
    templateUrl: './start-crowdfunding-modal.component.html',
    styleUrls: ['./start-crowdfunding-modal.component.less'],
})
export class StartCrowdfundingModalComponent implements OnInit {
    @Input() moq: any;
    @Input() endTime: any;
    @Input() quantity: number;
    @Input() isEditMode = false;

    timeOptions = {
        nzFormat: 'HH:mm',
        nzDefaultOpenValue: dayjs(dayjs().format('YYYY-MM-DD HH:mm:00')).toDate(),
    };

    submitting = false;

    get confirmDisabled() {
        return !this.endTime || this.quantity < 1;
    }

    constructor(private dateTimeService: DateTimeService, private modalRef: NzModalRef) {}

    ngOnInit(): void {}

    disabledDate = (current: Date): boolean => {
        const formattedCurrentByTz = this.dateTimeService.replaceCurrentTzWithPST(current.toString());
        return dayjs(formattedCurrentByTz).diff(dayjs().add(8, 'day'), 'day') < 0 || dayjs(formattedCurrentByTz).diff(dayjs(), 'day') > 90;
    };

    disabledDateTime: DisabledTimeFn = () => ({
        nzDisabledHours: () => [],
        nzDisabledMinutes: () => [],
        nzDisabledSeconds: () => this.range(1, 60),
    });

    ok() {
        this.modalRef.triggerOk();
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    private range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
}
