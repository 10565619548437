<section>
    <h1>Connect YouTube Video</h1>

    <input nz-input class="width-100p" placeholder="Paste YouTube video link here" [(ngModel)]="aboutYoutubeVideUrl" />

    <div class="how-it-work mt-20">
        <nz-collapse>
            <nz-collapse-panel [nzActive]="true" [nzHeader]="'How to find my video link in YouTube?'">
                <div class="d-flex justify-content-between align-items-center">
                    <img style="width: 100%" src="assets/imgs/connect-youtube.png" />
                </div>
            </nz-collapse-panel>
        </nz-collapse>
    </div>

    <div class="width-100p d-flex justify-content-center align-items-center mt-20">
        <button nz-button style="width: 200px" nzType="default" (click)="modalRef.triggerCancel()">Cancel</button>
        <button nz-button nzType="primary" style="width: 200px; margin-left: 30px" [disabled]="!aboutYoutubeVideUrl" (click)="confirm()">
            Connect
        </button>
    </div>
</section>
