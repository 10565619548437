import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-balance-history',
    templateUrl: './balance-history.component.html',
    styleUrls: ['./balance-history.component.less'],
})
export class BalanceHistoryComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
