import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { SharedModule } from '@shared/shared.module';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';

import { HomeService } from './home.service';
import { RevenueDetailPopupComponent } from './home-dashboard/revenue-detail-popup/revenue-detail-popup.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { NewOrderPaymentModalComponent } from './home-dashboard/new-order-payment-modal/new-order-payment-modal.component';
import { AddPaymentMethodComponent } from './home-dashboard/add-payment-method/add-payment-method.component';

@NgModule({
    declarations: [
        HomeComponent,
        OrderHistoryComponent,
        HomeDashboardComponent,
        RevenueDetailPopupComponent,
        OrderDetailComponent,
        NewOrderPaymentModalComponent,
        AddPaymentMethodComponent,
    ],
    imports: [CommonModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [HomeService, { provide: MaskPipe, useClass: MaskPipe }],
    exports: [AddPaymentMethodComponent],
})
export class HomeModule {}
