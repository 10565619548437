<section class="connect-payment">
    <h1>Add Payment Method</h1>

    <div class="p-20">
        <p class="fs-14">This payment method will be used for balance payout and subscription payment.</p>

        <nz-divider></nz-divider>

        <!-- Bank -->
        <div
            class="radio-item user-select-none cursor-pointer"
            [class.active]="selectedPaymentMethod === PaymentMethodEnum.BANK"
            (click)="selectPaymentMethod(PaymentMethodEnum.BANK)"
        >
            <i
                class="mr-30"
                nz-icon
                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.BANK ?'radio-checked':'radio-unchecked'}}"
                nzTheme="outline"
            ></i>
            <div>
                <div>
                    <i nz-icon nzType="bank" class="mr-10"></i>
                    <span>Bank Account (US & CA banks Only)</span>
                </div>
            </div>
        </div>

        <!-- PayPal -->
        <div
            *ngIf="shopProfile?.subscription_plan === subscribePlanNameEnum.PREMIUM"
            class="credit-radio-item radio-item user-select-none cursor-pointer align-items-center"
            [class.active]="selectedPaymentMethod === PaymentMethodEnum.PAYPAL"
            (click)="selectPaymentMethod(PaymentMethodEnum.PAYPAL)"
        >
            <i
                class="mr-30"
                nz-icon
                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.PAYPAL ?'radio-checked':'radio-unchecked'}}"
                nzTheme="outline"
            ></i>
            <div class="flex-1">
                <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png" height="28" alt="PayPal" />
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-10">
        <button nz-button class="mr-20 flex-1" (click)="cancel()">Cancel</button>
        <button nz-button nzType="primary" class="ml-20 flex-1" [nzLoading]="connectingBank" (click)="confirm()">Confirm</button>
    </div>
</section>
