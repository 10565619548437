import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.less'],
})
export class ImageViewerComponent implements OnInit {
    @Input() set imagesUrlsList(urlsList: string[]) {
        this.urlsList = urlsList;
        this.selectedImage = urlsList[0];
    }

    urlsList: string[] = [];

    selectedImage: string;

    constructor() {}

    ngOnInit(): void {}
}
