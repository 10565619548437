export interface OrderItem {
    order_id: string;
    created_at: string;
    financial_status: string;
    fulfillment_status: string;
    line_items_count: string;
    name: string;
    original_shop_id: number;
    original_shop_name: string;
    total_price: number;
    tracking_company: string;
    tracking_number: string;
    updated_at: string;
    customer_name: string;
}

export interface OrderDetail extends OrderItem {
    order_detail: any;
    original_order_detail: any;
    line_items: OrderLineItem[];
}

export interface OrderLineItem {
    id: number;
    image: string;
    product_id: string;
    product_type: ProductTypeEnum;
    sku: string;
    title: string;
    vendor: string;
    price: number;
    quantity: number;
    total_discount: number;
    has_sufficient_inventory: boolean;
    variant_json: any;
}

export enum ProductTypeEnum {
    PRODUCT = 'product',
    CUSTOM_PRODUCT = 'customize_product',
    STANDARD_PRODUCT = 'standard_product',
    DIY_PRODUCT = 'diy_product',
    POD_PRODUCT = 'pod_product',
    WRAPPER = 'wrapper',
    INSERT = 'insert',
    PACKAGE = 'package',
    STICKER = 'sticker',
}

export const paymentStatusMap = new Map([
    ['authorized', 'Authorized'],
    ['paid', 'Paid'],
    ['partially_paid', 'Partially Paid'],
    ['pending', 'Pending'],
    ['refunded', 'Refunded'],
    ['voided', 'Voided'],
]);
