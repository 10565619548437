<section>
    <div *ngIf="images.length === 1" class="img-item-1 cursor-pointer">
        <img [src]="images[0].src" />
    </div>
    <div *ngIf="images.length === 2" class="img-item-2 cursor-pointer">
        <div class="left"><img style="margin-right: 6px" [src]="images[0].src" /></div>
        <div class="left"><img [src]="images[1].src" /></div>
    </div>
    <div *ngIf="images.length === 3" class="img-item-3 cursor-pointer">
        <div class="left"><img [src]="images[0].src" /></div>
        <div class="right">
            <img [src]="images[1].src" />
            <img [src]="images[2].src" />
        </div>
    </div>
    <div *ngIf="images.length >= 4" class="img-item-4 cursor-pointer">
        <img style="margin-right: 6px; margin-bottom: 6px" [src]="images[0].src" />
        <img [src]="images[1].src" />
        <img style="margin-right: 6px" [src]="images[2].src" />
        <img [src]="images[3].src" />
    </div>
</section>
