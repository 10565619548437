<section class="d-flex width-100p flex-direction-column align-items-center justify-content-start p-30">
    <img width="150" height="150" src="assets/imgs/sourcing_request_success.png" class="mb-20 mt-20" />

    <div class="fs-20 fw-bold mb-20 mt-10" style="color: #1b222e">Crowdfunding is in progress!</div>

    <div class="d-flex justify-content-center align-items-center width-100p mt-20" style="color: rgba(27, 34, 46, 0.7)">
        <div>Min. Goal: <span class="black">{{crowdFundingDetail.minimal_quantity}}</span> Units Sold</div>
        <div>End Time: <span class="black">{{formatEndDate(crowdFundingDetail.end_date)}}</span></div>
    </div>

    <div style="width: 450px; display: flex; justify-content: space-between; align-items: center; margin-top: 30px">
        <button style="width: 200px; margin-right: 20px" nz-button nzSize="large" (click)="cancel()">Close</button>
        <button style="width: 200px" nz-button nzSize="large" nzType="primary" (click)="goEditProduct()">View Product</button>
    </div>
</section>
