import { SubscribePlanNameEnum } from './../components/subscription/subscription.data';
import { ThemeSettings } from '@src/app/modules/storefront/storefront.service';

export enum AvailablePaymentMethods {
    paypal = 'paypal',
    bank_account = 'bank_account',
}

export interface UserProfile {
    email?: string;
    uid?: string;
    name?: string; // to be deprecate
    first_name?: string;
    last_name?: string;
    address1?: string;
    address_line_1?: string;
    address2?: string;
    address_line_2?: string;
    country?: string;
    city?: string;
    province?: string;
    state?: string;
    zip?: string;
    phone_number?: string;
    instagram_id?: string;
    tiktok_id?: string;
    youtube_id?: string;
    instagram_user_id?: string;
    tiktok_user_id?: string;
    youtube_user_id?: string;
    paypal?: string;
    stripe_customer_id?: string;
    mercury_recipient_id?: string;
    selected_payment_method?: null | AvailablePaymentMethods;
    is_instagram_checked?: boolean;
    industries?: string[];
    platforms?: string[];
    content_types?: string[];
    profile_picture?: string;
    invited_by?: string;
    invited_to_lottery?: string;
    is_shop_notification_showed?: boolean;
    is_shop_exist?: boolean;
}

export interface ShopProfile {
    id: string;
    name: string;
    email: string;
    phone: string;
    shop_owner: string;
    domain: string;
    subscription_plan: SubscribePlanNameEnum;
    myshopify_domain: string;
    shop_detail: any;
    theme_settings: ThemeSettings;
    setup_complete: boolean;
    ship_to: any;
    theme_id: any;
    subscription?: SubscriptionOfShopProfile;
    service_fee_rate: number;
    user_id: string;
    available_credit_rate: number;
    shop_type: 'Shared' | 'Shopify' | string;
}

export interface SubscriptionPlanEnum {
    STARTER: 'starter';
    EXPERIENCED: 'experienced';
    ADVANCED: 'advanced';
    PRO: 'pro';
    PREMIUM: 'premium';
    NULL: null;
    EMPTY: '';
}

export interface SubscriptionOfShopProfile {
    billing_status: 'failed' | string;
    product_sourcing_quota: number;
    product_sourcing_used: number;
    order_fulfillment_quota: number;
    order_fulfillment_used: number;
    last_billing_date: string;
    next_billing_date: string;
    sub_plan_id: SubscribePlanNameEnum;
    next_sub_plan_id?: SubscribePlanNameEnum;
    prev_sub_plan_id?: SubscribePlanNameEnum;
    sub_end_date: string;
    trial_start_date: string;
    trial_end_date: string;
}

export interface IPaymentRecord {
    amount: number;
    id: number;
    invoice_image_url: string;
    operator: string;
    paid_at: string;
    payment_method: any;
    payment_method_json: string;
    payment_status: string;
    payment_type: string;
    shop_id: number;
    shop_name: string;
    pay_method: string;
    bank_number: string;
    created_at?: any;
}
