import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class ReuseService implements RouteReuseStrategy {
    static storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Used to determine whether the router needs to be stored when navigating
    from = '';
    to = '';

    // Used to determine whether to read the previously stored router when navigating
    reuseFrom = '';
    reuseTo = '';

    static removeRouteCache(url: string) {
        ReuseService.storedRouteHandles.delete(url);
    }

    shouldReuseRoute(from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot): boolean {
        if (from.routeConfig) {
            this.from = this.getPath(from);
        }
        if (to.routeConfig) {
            this.to = this.getPath(to);
        }
        return from.routeConfig === to.routeConfig;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // Judge if do store
        if (this.shouldStore()) {
            this.reuseFrom = this.to;
            this.reuseTo = this.from;
        }
        return this.shouldStore();
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        // Store router
        ReuseService.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (this.from === this.reuseFrom && this.to === this.reuseTo) {
            // Read reused router
            return ReuseService.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
        } else {
            return null;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (this.reuseFrom && this.reuseTo && this.from && this.to) {
            return this.from === this.reuseFrom && this.to === this.reuseTo && ReuseService.storedRouteHandles.has(this.getPath(route));
        }

        return false;
    }

    private shouldStore() {
        return (
            (this.from === '/home/order-history' && this.to === '/home/order-detail') ||
            (this.from === '/product-management/product-list' && this.to === '/product-management/product-detail') ||
            (this.from === '/nidavellir/product-list' && this.to === '/nidavellir/product-detail') ||
            (this.from === '/nidavellir/product-list' && this.to === '/nidavellir/order-detail') ||
            (this.from === '/nidavellir/product-list' && this.to === '/nidavellir/advanced-customization') ||
            (this.from === '/my-purchase/orders' && this.to === '/nidavellir/advanced-customization')
        );
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        let url: string = route['_routerState'].url;

        if (url.startsWith('/home/order-detail')) {
            url = '/home/order-detail';
        }
        if (url.startsWith('/product-management/product-detail')) {
            url = '/product-management/product-detail';
        }
        if (url.startsWith('/nidavellir/product-list')) {
            url = '/nidavellir/product-list';
        }
        if (url.startsWith('/nidavellir/product-detail')) {
            url = '/nidavellir/product-detail';
        }
        if (url.startsWith('/nidavellir/order-detail')) {
            url = '/nidavellir/order-detail';
        }
        if (url.startsWith('/nidavellir/advanced-customization')) {
            url = '/nidavellir/advanced-customization';
        }

        return url.split('?')[0];
    }
}
