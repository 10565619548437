<div class="login-box">
    <div class="sub-title">Welcome to Lifo Creator Hub!</div>
    <div class="title">
        Sign In
        <img class="logo" src="assets/imgs/project-logo.jpg" alt="" height="26" />
    </div>
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
        <div class="labels">Email</div>
        <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your email address">
                <input type="text" nz-input formControlName="email" placeholder="Please enter your email address" />
            </nz-form-control>
        </nz-form-item>
        <div class="labels">Password</div>
        <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your password">
                <input type="password" nz-input formControlName="password" placeholder="Please enter your password" />
            </nz-form-control>
        </nz-form-item>
        <div nz-row class="login-form-margin">
            <div nz-col [nzSpan]="12">
                <label nz-checkbox formControlName="remember">
                    <span>Remember me</span>
                </label>
            </div>
            <div nz-col class="forgot-password" [nzSpan]="9" [nzOffset]="3">
                <a [routerLink]="['/auth/restore-password']">Forgot password?</a>
            </div>
        </div>
        <button
            nz-button
            [nzLoading]="submitting"
            class="login-form-button login-form-margin"
            [nzType]="'primary'"
            [disabled]="!validateForm.valid"
        >
            Sign In
        </button>
    </form>

    <div class="sign-up"><span>Don’t have an account?</span><a [routerLink]="['/auth/sign-up']">Sign Up</a></div>
</div>
