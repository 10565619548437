<div class="address-form">
    <form [formGroup]="addressForm" nz-form>
        <div>Name <span style="color: red">*</span></div>
        <div class="d-flex justify-content-between align-items-center mb-10">
            <nz-form-control class="mr-20">
                <input
                    type="text"
                    formControlName="first_name"
                    nz-input
                    placeholder="First Name"
                    [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                />
            </nz-form-control>
            <nz-form-control>
                <input
                    formControlName="last_name"
                    type="text"
                    nz-input
                    placeholder="Last Name"
                    [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                />
            </nz-form-control>
        </div>

        <div>Address <span style="color: red">*</span></div>
        <div class="mb-10">
            <nz-form-control>
                <input
                    #addressText
                    class="width-100p mb-10"
                    type="text"
                    nz-input
                    placeholder="Address Line 1"
                    formControlName="address_line_1"
                    [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                />
            </nz-form-control>
            <nz-form-control>
                <input
                    class="width-100p mb-10"
                    type="text"
                    nz-input
                    placeholder="Address Line 2 (Optional)"
                    formControlName="address_line_2"
                />
            </nz-form-control>
            <nz-form-control>
                <input
                    formControlName="city"
                    class="width-100p mb-10"
                    type="text"
                    nz-input
                    placeholder="City"
                    [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                />
            </nz-form-control>
            <div class="d-flex justify-contnet-between align-items-center width-100p">
                <nz-form-item class="flex-1 mr-10" style="margin-bottom: 0">
                    <nz-form-control [nzValidateStatus]="saveBtnActivated && addressForm.get('country').invalid ? 'error' : ''">
                        <nz-select
                            formControlName="country"
                            nzSize="large"
                            class="width-100p"
                            [nzPlaceHolder]="'Country'"
                            [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                        >
                            <nz-option
                                *ngFor="let country of supportedUPSCountryList"
                                [nzValue]="country.name"
                                [nzLabel]="country.name"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-control class="flex-1 ml-10 mr-10">
                    <input
                        formControlName="state"
                        type="text"
                        nz-input
                        placeholder="State"
                        [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                    />
                </nz-form-control>

                <nz-form-control class="flex-1 ml-10">
                    <input
                        formControlName="zip"
                        type="text"
                        nz-input
                        placeholder="Zip Code"
                        [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                    />
                </nz-form-control>
            </div>
        </div>

        <div>Phone Number</div>
        <nz-form-control>
            <nz-input-group [nzAddOnBefore]="countryCode">
                <input
                    formControlName="phone_number"
                    class="width-100p"
                    type="text"
                    nz-input
                    placeholder="Phone Number"
                    [ngClass]="{'ng-touched': saveBtnActivated, 'ng-dirty': saveBtnActivated}"
                />
            </nz-input-group>

            <div *ngIf="addressForm.get('phone_number').invalid && addressForm.get('phone_number').dirty" style="color: red">
                <i class="mr-10" nz-icon nzType="info-circle" nzTheme="fill"></i>
                <span>{{addressForm.get('phone_number').errors.length}}</span>
            </div>
        </nz-form-control>
    </form>

    <label class="mt-20" nz-checkbox [(ngModel)]="isSetAsDefault">Save as default address</label>
</div>
