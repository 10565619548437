<section class="general-payment-container relative">
    <div class="fs-20 fw-bold mb-20">Payment</div>

    <div style="max-height: 60vh; overflow-y: auto">
        <!-- Items -->
        <div class="items">
            <div *ngFor="let item of data?.itemList" class="d-flex justify-content-between align-items-center mb-20">
                <span>{{item.name}}</span>
                <span class="fs-18" style="color: #d8b165">{{item.amount | currency}}</span>
            </div>
        </div>

        <!-- Payment Method -->
        <div class="right-box-item pt-30 pb-20">
            <div class="fw-500">Payment Method</div>

            <div
                *ngIf="!disabledPaymentMethod.has(PaymentMethodEnum.LIFO_CREDIT) && accountBalance?.lifo_credit"
                class="credit d-flex justify-content-between align-items-center mt-30"
            >
                <div>
                    <div>
                        Use my
                        <i nz-icon nzType="antd:dollar-wallet" style="margin: 0 3px"></i>
                        <span style="color: #bd8f35">{{accountBalance?.lifo_credit|currency}}</span>
                        Lifo Credit
                        <i
                            class="fs-20"
                            nz-icon
                            nzType="antd:info"
                            nz-popover
                            nzPopoverTrigger="hover"
                            [nzPopoverContent]="estDateTpl"
                            nzPopoverOverlayClassName="no-padding no-bg dark-arrow"
                            style="vertical-align: top"
                        ></i>

                        <ng-template #estDateTpl>
                            <div style="width: 300px; background-color: #554764; padding: 20px; border-radius: 12px; color: #fff">
                                Lifo credit can be used to deduct the total order cost (up to {{availableLifoCreditRate | percent}}) to help
                                you get your business scaled up faster.
                            </div>
                        </ng-template>
                    </div>
                    <div class="fs-12" style="color: #9f99a3">
                        To deduct up to {{availableLifoCreditRate * 100}}% of the total item amount
                    </div>
                </div>

                <nz-switch [(ngModel)]="viewStatusControl.useLifoCredit" (ngModelChange)="calculateAmount()"></nz-switch>
            </div>

            <div
                *ngIf="!disabledPaymentMethod.has(PaymentMethodEnum.BALANCE)"
                class="balance d-flex justify-content-between align-items-center mt-30"
                [style.opacity]="accountBalance?.account_balance <= 0 ? 0.5 : 1"
            >
                <div>
                    <ng-container *ngIf="accountBalance?.account_balance>0;else insufficientBalanceTpl">
                        Use my
                        <i nz-icon nzType="antd:balance-dollar" style="margin: 0 3px; vertical-align: middle"></i>
                        <span style="color: #573357">{{accountBalance?.account_balance|currency}}</span>
                        Balance
                    </ng-container>
                    <ng-template #insufficientBalanceTpl>
                        Insufficient balance
                        <i nz-icon nzType="antd:balance-dollar" style="margin: 0 3px; vertical-align: middle"></i>
                        <span style="color: #573357">{{accountBalance?.account_balance|currency}}</span>
                    </ng-template>
                </div>

                <nz-switch
                    [disabled]="accountBalance?.account_balance<=0"
                    [(ngModel)]="viewStatusControl.useBalancePay"
                    (ngModelChange)="calculateAmount()"
                ></nz-switch>
            </div>
        </div>

        <!-- Rest Payment -->
        <div *ngIf="amountDetail.total > 0" class="rest-payment mt-20">
            <div class="mb-20">
                Pay
                <span class="fw-500" style="color: #573357">{{amountDetail.total | currency}}</span>
                from
            </div>

            <!-- Bank -->
            <div
                class="radio-item user-select-none cursor-pointer"
                [class.active]="selectedPaymentMethod === PaymentMethodEnum.BANK"
                [style.opacity]="this.connectedBankName === undefined ? 0.5 : 1"
                (click)="selectPaymentMethod(PaymentMethodEnum.BANK)"
            >
                <i
                    class="mr-30"
                    nz-icon
                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.BANK ?'radio-checked':'radio-unchecked'}}"
                    nzTheme="outline"
                ></i>
                <div>
                    <div>Bank Account</div>
                    <div *ngIf="connectedBankName" class="mt-10 fs-12">{{connectedBankName}}</div>
                    <div
                        *ngIf="connectedBankName === null && selectedPaymentMethod === PaymentMethodEnum.BANK"
                        class="mt-10 fs-14 fw-bold d-flex justify-content-start align-items-center cursor-pointer user-select-none"
                        style="color: #f3745e"
                        [style.opacity]="viewStatusControl.connectingBank?0.5:1"
                        (click)="!viewStatusControl.connectingBank ? connectPlaid() : null"
                    >
                        <i class="fs-14 fw-bold" nz-icon nzType="plus" style="margin-right: 4px"></i>
                        <span>Connect{{viewStatusControl.connectingBank?'ing':''}}</span>
                    </div>
                    <div
                        *ngIf="selectedPaymentMethod === PaymentMethodEnum.BANK && isNoSubscriptionPaymentMethod"
                        class="mt-10"
                        style="color: #666"
                    >
                        This will be the payment method for the subscription
                    </div>
                </div>
            </div>

            <!-- Credit -->
            <div
                class="credit-radio-item radio-item user-select-none cursor-pointer align-items-start"
                [class.active]="selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD"
                (click)="selectPaymentMethod(PaymentMethodEnum.CREDIT_CARD)"
            >
                <i
                    class="mr-30"
                    nz-icon
                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD ?'radio-checked':'radio-unchecked'}}"
                    nzTheme="outline"
                ></i>
                <div class="flex-1">
                    <div>Credit Card</div>
                    <div [hidden]="selectedPaymentMethod !== PaymentMethodEnum.CREDIT_CARD" class="mt-30">
                        <div ngxStripeCardGroup [elementsOptions]="shoppingCartService.elementsOptions">
                            <div class="mb-20">
                                <div>Cardholder Name <span class="necessary">*</span></div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="credit-card-box mr-20 flex-1">
                                        <input type="text" class="input-item" placeholder="First Name" [(ngModel)]="stripeInfo.firstName" />
                                    </div>

                                    <div class="credit-card-box ml-20 flex-1">
                                        <input type="text" class="input-item" placeholder="Last Name" [(ngModel)]="stripeInfo.lastName" />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-20">
                                <div>Zip <span class="necessary">*</span></div>
                                <div class="credit-card-box">
                                    <input
                                        type="text"
                                        class="input-item"
                                        placeholder="Zip Code"
                                        [(ngModel)]="stripeInfo.zipCode"
                                        (ngModelChange)="zipCodeChange()"
                                    />
                                </div>
                            </div>

                            <div>
                                <div>Card <span class="necessary">*</span></div>

                                <ngx-stripe-card
                                    [options]="shoppingCartService.cardOptions"
                                    [elementsOptions]="shoppingCartService.elementsOptions"
                                    (change)="cardInputChange($event)"
                                ></ngx-stripe-card>
                            </div>
                        </div>

                        <div *ngIf="isNoSubscriptionPaymentMethod" class="mt-20" style="color: #1b222e">
                            <label nz-checkbox [(ngModel)]="isCreditCardSetAsSubscriptionPayment">
                                Save this payment method for subscription
                            </label>
                            <div
                                *ngIf="!isCreditCardSetAsSubscriptionPayment"
                                class="d-flex width-100p justify-content-flex-start align-items-center mt-20"
                                style="color: red"
                            >
                                <i nz-icon nzType="svg:Info" class="mr-10"></i>
                                <div>This is required when you don't have any payment method connected</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PayPal -->
            <div
                class="credit-radio-item radio-item user-select-none cursor-pointer align-items-center"
                [class.active]="selectedPaymentMethod === PaymentMethodEnum.PAYPAL"
                (click)="selectPaymentMethod(PaymentMethodEnum.PAYPAL)"
            >
                <i
                    class="mr-30"
                    nz-icon
                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.PAYPAL ?'radio-checked':'radio-unchecked'}}"
                    nzTheme="outline"
                ></i>
                <div class="flex-1">
                    <img src="assets/imgs/paypal.png" height="28" alt="PayPal" />
                </div>
            </div>
        </div>
    </div>

    <footer class="footer pt-20 d-flex justify-content-between align-items-center width-100p">
        <ng-container *ngIf="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL || amountDetail.total <= 0">
            <button nz-button style="width: 45%" (click)="modalRef.triggerCancel()">Cancel</button>
            <button
                nz-button
                nzType="primary"
                style="width: 45%"
                [nzLoading]="viewStatusControl.submitting"
                [disabled]="confirmDisabled"
                (click)="pay()"
            >
                Confirm
            </button>
        </ng-container>

        <div
            class="flex-1"
            appLoading
            [hidden]="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL || amountDetail.total <= 0"
            [loading]="viewStatusControl.submitting"
            [maskVisible]="true"
        >
            <ngx-paypal [config]="paypalConfig"></ngx-paypal>
        </div>
    </footer>
</section>
