<section class="faq-sections">
    <div class="content-text">
        <h2>FAQ</h2>
        <div class="topQuestion">
            <h3>Video Tutorials</h3>
            <div class="d-flex justify-content-center video-box">
                <div class="video-card" *ngFor="let data of videoTutorials">
                    <div class="video-mask" (click)="playVideo(data.src)"></div>
                    <iframe [src]="data.src | safeUrl" width="240" frameborder="0"></iframe>
                    <p>{{data.title}}</p>
                </div>
            </div>
        </div>
        <div class="topQuestion">
            <h3>Q&As</h3>
            <div class="btnList">
                <span (click)="selectClick(i)" *ngFor="let i of radioData" [ngClass]="i.checked ? 'selectBtn' : ''" class="list">
                    {{ i.name }}
                </span>
            </div>
            <div class="filterList" appLoading [loading]="fetchingFaqList" style="min-height: 200px">
                <nz-collapse [nzExpandIconPosition]="'right'" [nzBordered]="false">
                    <nz-collapse-panel #p *ngFor="let item of faqList" [nzHeader]="item.question" [nzExpandedIcon]="expandedIcon">
                        <div style="margin: 0" [innerHTML]="item.response"></div>
                        <ng-template #expandedIcon let-active>
                            {{ active }}
                            <i nz-icon nzType="down" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? -180 : 0"></i>
                        </ng-template>
                    </nz-collapse-panel>
                </nz-collapse>
            </div>
        </div>
    </div>
</section>

<nz-modal
    nzWrapClassName="no-padding no-bg"
    [(nzVisible)]="videoTutorialVisible"
    [nzTitle]="null"
    [nzFooter]="null"
    [nzClosable]="false"
    nzWidth="1120px"
>
    <section class="video-tutorials-content" style="padding: 10px">
        <a class="close-button" (click)="videoTutorialVisible = false; videoTutorialUrl = null"><img src="assets/svg/ic_close.svg" /></a>
        <iframe [src]="videoTutorialUrl | safeUrl" width="1100" height="620" frameborder="0" allow="autoplay; picture-in-picture"></iframe>
    </section>
</nz-modal>
