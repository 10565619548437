<section class="subscription-box">
    <!-- Subscription Main Card -->
    <section [hidden]="shownCard !== 'subscription'" class="step-box">
        <div appLoading [loading]="viewStatusControl.loadingProfile || viewStatusControl.freeTrialApplying" [maskVisible]="true">
            <section class="card-list clearfix">
                <app-subscribe-plan-cards
                    [selectedPlan]="selectedPlan"
                    [type]="type"
                    (choosePlan)="chooseCardItem($event)"
                ></app-subscribe-plan-cards>
            </section>
        </div>

        <app-plan-full-feature-table *ngIf="viewStatusControl.showFullFeatureTable"></app-plan-full-feature-table>

        <section class="skip">
            <div class="d-flex">
                <button
                    (click)="viewStatusControl.showFullFeatureTable = !viewStatusControl.showFullFeatureTable"
                    class="default-button view-full-feature"
                    nz-button
                    nzType="default"
                >
                    {{ viewStatusControl.showFullFeatureTable? 'Hide Full Features': 'View Full Features' }}
                </button>
                <div
                    class="faq-btn"
                    nz-tooltip
                    [nzTooltipTitle]="faqTooltip"
                    [nzTooltipOverlayClassName]="'common-tooltip'"
                    [nzTooltipTrigger]="'click'"
                    [nzTooltipVisible]="faqTooltipVisible"
                    [nzTooltipPlacement]="'bottom'"
                >
                    <span>FAQ</span>
                    <i
                        nz-icon
                        nzType="question"
                        nzTheme="outline"
                        style="
                            margin-left: 10px;
                            color: #554764;
                            background-color: rgba(85, 71, 100, 0.08);
                            border-radius: 50%;
                            padding: 4px;
                            font-size: 12px;
                        "
                    ></i>
                    <ng-template #faqTooltip>
                        <div class="faq-tooltip-content">
                            <h5 style="font-size: 16px; color: #fff">What is an order fulfillment?</h5>
                            <p>
                                When someone places an order from your shop, Lifo will have the product made, packaged, and shipped directly
                                to them.
                            </p>
                            <h5 style="font-size: 16px; color: #fff">What is a product recommendation?</h5>
                            <p>
                                Don’t see that thing you really want to sell in our marketplace? We’ve got you! Just make a product
                                recommendation request and our team will work to add it to your page for you to view.
                            </p>
                            <h5 style="font-size: 16px; color: #fff">What are Lifo Creator Funds?</h5>
                            <p style="margin: 0">
                                Lifo wants to support you. Apply Lifo Creator Funds through our platform and we MAY BE ABLE TO offer you the
                                advanced funds to get started, which is then deducted from your profits once you start making sales.
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>
            <button *ngIf="showSkipBtnInput && showSkipBtn" (click)="skipSubscription()" class="skip-btn" nz-button nzType="default">
                <span>Skip</span>
                <i nz-icon nzTheme="outline" nzType="double-right"></i>
            </button>
        </section>
    </section>

    <!-- Credit -->
    <section [hidden]="shownCard !== 'payment'" class="credit-card-content">
        <div>
            <div class="mb-20 back-box">
                <section (click)="shownCard = 'subscription'; shownCard$.next('subscription');" class="cursor-pointer back-btn">
                    <img alt="" src="assets/imgs/patha.png" width="15" />
                </section>
            </div>
            <div class="flex-1 d-flex">
                <div class="credit-card-left">
                    <div class="billing-content">
                        <div class="display-title">Billing Interval</div>
                        <div
                            class="radio-item user-select-none cursor-pointer"
                            [class.active]="selectedBillingInterval === BillingIntervalEnum.YEARLY"
                            (click)="changeBillingInterval(BillingIntervalEnum.YEARLY)"
                        >
                            <i
                                class="mr-30"
                                nz-icon
                                nzType="antd:{{selectedBillingInterval === BillingIntervalEnum.YEARLY ?'radio-checked':'radio-unchecked'}}"
                                nzTheme="outline"
                            ></i>
                            <div class="flex-1">
                                <div>Yearly</div>
                                <div class="mt-10 fs-12">
                                    $24 / month for 12months
                                    <span style="padding-left: 10px; color: #55c3b9">↓20% off</span>
                                </div>
                            </div>
                            <div
                                class="d-flex align-items-center"
                                style="background: rgba(243, 153, 105, 0.2); padding: 3px 10px; border-radius: 16px"
                            >
                                <i
                                    nz-icon
                                    nzType="antd:star"
                                    nzTheme="outline"
                                    style="color: #f3745e; font-size: 12px; margin-right: 8px"
                                ></i>
                                <span style="color: #f3745e; font-size: 12px">Recommended</span>
                            </div>
                        </div>

                        <div
                            class="radio-item user-select-none cursor-pointer"
                            [class.active]="selectedBillingInterval === BillingIntervalEnum.MONTHLY"
                            (click)="changeBillingInterval(BillingIntervalEnum.MONTHLY)"
                        >
                            <i
                                class="mr-30"
                                nz-icon
                                nzType="antd:{{selectedBillingInterval === BillingIntervalEnum.MONTHLY ?'radio-checked':'radio-unchecked'}}"
                                nzTheme="outline"
                            ></i>
                            <div class="flex-1">
                                <div>Monthly</div>
                                <div class="mt-10 fs-12">$29.99 / month</div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-content">
                        <div class="display-title">Payment Method</div>
                        <!-- Credit -->
                        <div
                            class="credit-radio-item radio-item user-select-none cursor-pointer align-items-start"
                            [class.active]="selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD"
                            (click)="selectedPaymentMethod = PaymentMethodEnum.CREDIT_CARD"
                        >
                            <i
                                class="mr-30"
                                nz-icon
                                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD ?'radio-checked':'radio-unchecked'}}"
                                nzTheme="outline"
                            ></i>
                            <div class="flex-1">
                                <div>Credit Card</div>
                                <div [hidden]="selectedPaymentMethod !== PaymentMethodEnum.CREDIT_CARD" class="mt-30">
                                    <div ngxStripeCardGroup [elementsOptions]="elementsOptions">
                                        <div class="mb-20">
                                            <div>Cardholder Name <span class="necessary">*</span></div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="credit-card-box mr-20 flex-1">
                                                    <input
                                                        type="text"
                                                        class="input-item"
                                                        placeholder="First Name"
                                                        [(ngModel)]="stripeInfo.firstName"
                                                    />
                                                </div>

                                                <div class="credit-card-box ml-20 flex-1">
                                                    <input
                                                        type="text"
                                                        class="input-item"
                                                        placeholder="Last Name"
                                                        [(ngModel)]="stripeInfo.lastName"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-20">
                                            <div>Zip <span class="necessary">*</span></div>
                                            <div class="credit-card-box">
                                                <input
                                                    type="text"
                                                    class="input-item"
                                                    placeholder="Zip Code"
                                                    [(ngModel)]="stripeInfo.zipCode"
                                                    (ngModelChange)="zipCodeChange()"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div>Card <span class="necessary">*</span></div>

                                            <ngx-stripe-card
                                                [options]="cardOptions"
                                                [elementsOptions]="elementsOptions"
                                                (change)="cardInputChange($event)"
                                            ></ngx-stripe-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Bank -->
                        <div
                            class="radio-item user-select-none cursor-pointer"
                            [class.active]="selectedPaymentMethod === PaymentMethodEnum.BANK"
                            (click)="selectedPaymentMethod = PaymentMethodEnum.BANK"
                        >
                            <i
                                class="mr-30"
                                nz-icon
                                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.BANK ?'radio-checked':'radio-unchecked'}}"
                                nzTheme="outline"
                            ></i>
                            <div>
                                <div>Bank Account</div>
                                <div *ngIf="bankAccount" class="mt-10 fs-12">{{bankAccount}}</div>
                                <div
                                    *ngIf="bankAccount === null"
                                    class="mt-10 fs-14 fw-bold d-flex justify-content-start align-items-center cursor-pointer user-select-none"
                                    style="color: #f3745e"
                                    [style.opacity]="viewStatusControl.connectingBank?0.5:1"
                                    (click)="!viewStatusControl.connectingBank ? connectPlaid() : null"
                                >
                                    <i class="fs-14 fw-bold" nz-icon nzType="plus" style="margin-right: 4px"></i>
                                    <span>Connect{{viewStatusControl.connectingBank?'ing':''}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="credit-card-right">
                    <div class="d-flex justify-content-between" style="margin: 20px 0 28px">
                        <div>
                            <ng-container *ngIf="selectedBillingInterval === BillingIntervalEnum.YEARLY; else monthlyBillingInterval">
                                Yearly
                                <span style="font-size: 12px; color: rgba(27, 34, 46, 0.7); margin-left: 10px">
                                    $24 / month for 12months
                                </span>
                            </ng-container>
                            <ng-template #monthlyBillingInterval>
                                Monthly
                                <span style="font-size: 12px; color: rgba(27, 34, 46, 0.7); margin-left: 10px">$29.99 / month</span>
                            </ng-template>
                        </div>
                        <b>${{subTotal | number: "1.2-2"}}</b>
                    </div>
                    <div class="coupon-content">
                        <form nz-form [formGroup]="validateCoupon" (ngSubmit)="checkCoupon()">
                            <nz-form-control [nzErrorTip]="couponCodeErrorTpl">
                                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                                    <input
                                        type="text"
                                        nz-input
                                        [(ngModel)]="couponCode"
                                        formControlName="couponCode"
                                        placeholder="Coupon code"
                                    />
                                </nz-input-group>
                                <ng-template #suffixButton>
                                    <button nz-button nzType="primary" nzSearch [nzLoading]="couponChecking" [disabled]="isApplied">
                                        {{isApplied ? 'Applied' : 'Apply'}}
                                    </button>
                                </ng-template>
                                <ng-template #couponCodeErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('invalidCode')">
                                        <span class="input-tip">{{control.getError('invalidCode')}}</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </form>
                    </div>
                    <div class="d-flex justify-content-between" style="margin-top: 25px">
                        <div>Subtotal</div>
                        <span style="color: #9f99a3">${{subTotal | number: "1.2-2"}}</span>
                    </div>
                    <div *ngIf="discount > 0" class="d-flex justify-content-between" style="margin-top: 20px">
                        <div>Coupon</div>
                        <span style="color: #55c3b9">-${{discount | number:"1.2-2"}}</span>
                    </div>
                    <div class="d-flex justify-content-between" style="margin-top: 20px">
                        <div style="font-size: 18px">Total</div>
                        <b style="color: #d8b165; font-size: 24px">${{(subTotal - discount) | number:"1.2-2"}}</b>
                    </div>
                    <button
                        (click)="subscriptionConfirm()"
                        [disabled]="checkoutDisabled"
                        [nzLoading]="viewStatusControl.payingByCredit || viewStatusControl.payingByBank"
                        class="width-100p"
                        nz-button
                        nzType="primary"
                        style="margin-top: 50px; height: 50px; font-size: 16px"
                    >
                        Confirm
                    </button>
                </div>
            </div>
            <div *ngIf="showSkipBtnInput && showSkipBtn" class="text-align-right">
                <button (click)="skipSubscription()" class="skip-btn" nz-button nzType="default">
                    <span>Skip</span>
                    <i nz-icon nzTheme="outline" nzType="double-right"></i>
                </button>
            </div>
        </div>
    </section>

    <!-- Payment Success -->
    <section [hidden]="shownCard !== 'subscribeSuccess'" class="credit-cart-success">
        <nz-result [nzIcon]="resultIcon" nzStatus="success" nzTitle="Thanks for subscribing to our service!">
            <ng-template #resultIcon>
                <img src="assets/svg/img_email.svg" width="150" />
            </ng-template>

            <div nz-result-extra>
                <button (click)="outChoseCard()" nz-button nzType="primary">Start My Journey</button>
            </div>
        </nz-result>
    </section>

    <!-- Payment Success but no store available -->
    <section [hidden]="shownCard !== 'subscribeSuccessNoStore'" class="credit-cart-success">
        <nz-result
            [nzIcon]="resultIcon"
            nzStatus="success"
            nzTitle="Thanks for subscribing to our service!"
            nzSubTitle="We are preparing your storefront, we will get back to you within 1 day."
        >
            <ng-template #resultIcon>
                <img src="assets/svg/img_email.svg" width="150" />
            </ng-template>

            <div nz-result-extra>
                <button (click)="outChoseCard()" nz-button nzType="primary">Start My Journey</button>
            </div>
        </nz-result>
    </section>
</section>
