import { Component, OnInit } from '@angular/core';
import { HomeService } from '../home/home.service';
import { FundsService } from './funds.service';

@Component({
    selector: 'app-funds',
    templateUrl: './funds.component.html',
    styleUrls: ['./funds.component.less'],
})
export class FundsComponent implements OnInit {
    lifoCredit: any;
    accountBalance: any;
    fundsTooltipVisible = false;

    constructor(private fundsService: FundsService, private homeService: HomeService) {}

    async ngOnInit() {
        this.accountBalance = await this.homeService.getAccountBalance();
        this.lifoCredit = await this.fundsService.getLifoCredit();
    }

    getBalanceBeforeDot(amount: number): string {
        if (amount) {
            return String(amount.toFixed(2).split('.')[0]);
        }
        return '0';
    }

    getBalanceAfterDot(amount: number): string {
        if (amount) {
            return String(amount.toFixed(2)).split('.')[1];
        }
        return '00';
    }
}
