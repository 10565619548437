import { IHttpErrorResponse } from '@shared/typings/general.typings';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SourcingRequestService } from '../sourcing-request.service';
import { MessageService } from '@services/message.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GeneralDialogComponent } from '@shared/components/general-dialog/general-dialog.component';
import { SourcingRequestDeckProduct, SourcingRequestDeckProductComment } from './../sourcing-request.type';
import {
    slideInRightOnEnterAnimation,
    slideOutRightOnLeaveAnimation,
    collapseOnLeaveAnimation,
    collapseAnimation,
} from 'angular-animations';

@Component({
    selector: 'app-deck-comment',
    templateUrl: './deck-comment.component.html',
    styleUrls: ['./deck-comment.component.less'],
    animations: [
        slideInRightOnEnterAnimation({ anchor: 'slideEnter', duration: 300 }),
        slideOutRightOnLeaveAnimation({ anchor: 'slideLeave', duration: 300 }),
        collapseOnLeaveAnimation({ anchor: 'collapseLeave' }),
        collapseAnimation({ anchor: 'collapse', duration: 150 }),
    ],
})
export class DeckCommentComponent implements OnInit {
    @Input() selectedProduct: SourcingRequestDeckProduct;
    @Input() productImage: SourcingRequestDeckProduct['images'][0];
    @Output() closeModal = new EventEmitter();
    @Output() moveToBottom = new EventEmitter();

    shownAddCommentsMap = new Map<SourcingRequestDeckProductComment, { comments: string }>();
    replySubmittingSet = new Set<SourcingRequestDeckProductComment>();
    commentEditingMap = new Map<SourcingRequestDeckProductComment, { comments: string }>();
    commentEditSubmittingSet = new Set<SourcingRequestDeckProductComment>();

    commentSubmitting = false;
    comments: string;

    get commentList() {
        if (this.productImage) {
            return this.productImage.comments;
        }

        return this.selectedProduct.comments;
    }

    constructor(
        private sourcingRequestService: SourcingRequestService,
        private messageService: MessageService,
        private modalService: NzModalService
    ) {}

    ngOnInit(): void {}

    addReply(commentItem: SourcingRequestDeckProductComment) {
        this.replySubmittingSet.add(commentItem);

        this.sourcingRequestService
            .replyComment({
                product_id: this.selectedProduct.product_id,
                content: this.shownAddCommentsMap.get(commentItem).comments,
                reply_to_id: commentItem.comment_id,
            })
            .then(res => {
                if (!commentItem.replies) {
                    commentItem.replies = [];
                }
                commentItem.replies.push(res);
                this.shownAddCommentsMap.delete(commentItem);
            })
            .catch((err: IHttpErrorResponse) => this.messageService.error(err.status !== 500 ? err.body.error : null))
            .finally(() => this.replySubmittingSet.delete(commentItem));
    }

    addComment() {
        if (!this.comments) return;

        this.commentSubmitting = true;
        const productImageSnapShot = this.productImage;
        const data: any = {
            product_id: this.selectedProduct.product_id,
            content: this.comments,
        };

        if (this.productImage) {
            data.image_id = this.productImage.id;
        }

        this.sourcingRequestService
            .addComment(data)
            .then(res => {
                if (productImageSnapShot) {
                    // Image Comment
                    if (!productImageSnapShot.comments) {
                        productImageSnapShot.comments = [];
                    }

                    productImageSnapShot.comments.push(res);
                } else {
                    // Product Comment
                    if (!this.selectedProduct.comments) {
                        this.selectedProduct.comments = [];
                    }

                    this.selectedProduct.comments.push(res);
                }

                this.comments = null;
                setTimeout(() => {
                    this.moveToBottom.emit();
                }, 100);
            })
            .catch(() => this.messageService.error())
            .finally(() => (this.commentSubmitting = false));
    }

    deleteComment(commentItem: SourcingRequestDeckProductComment, parentComment?: SourcingRequestDeckProductComment) {
        const productImageSnapShot = this.productImage;
        this.modalService.create({
            nzContent: GeneralDialogComponent,
            nzFooter: null,
            nzComponentParams: {
                cancelText: 'Cancel',
                confirmText: 'Remove',
                title: 'Remove this comment?',
                hasTitle: true,
            },
            nzWrapClassName: 'no-padding',
            nzOnOk: comp => {
                comp.submitting = true;
                return this.sourcingRequestService
                    .deleteComment(commentItem.comment_id)
                    .then(() => {
                        if (parentComment) {
                            const index = parentComment.replies.findIndex(item => item === commentItem);
                            parentComment.replies.splice(index, 1);
                        } else {
                            if (productImageSnapShot) {
                                // Delete image comment
                                const index = productImageSnapShot.comments.findIndex(item => item === commentItem);
                                productImageSnapShot.comments.splice(index, 1);
                            } else {
                                // Delete product comment
                                const index = this.selectedProduct.comments.findIndex(item => item === commentItem);
                                this.selectedProduct.comments.splice(index, 1);
                            }
                        }
                    })
                    .catch((err: IHttpErrorResponse) => this.messageService.error(err.status !== 500 ? err.body.error : null))
                    .finally(() => (comp.submitting = false));
            },
        });
    }

    expandComment(commentItem: SourcingRequestDeckProductComment) {
        if (this.shownAddCommentsMap.has(commentItem)) {
            this.shownAddCommentsMap.delete(commentItem);
        } else {
            this.shownAddCommentsMap.set(commentItem, { comments: '' });
        }
    }

    editComment(commentItem: SourcingRequestDeckProductComment) {
        if (this.commentEditingMap.has(commentItem)) {
            this.commentEditingMap.delete(commentItem);
        } else {
            this.commentEditingMap.set(commentItem, { comments: commentItem.content });
        }
    }

    cancelEditComment(commentItem: SourcingRequestDeckProductComment) {
        this.commentEditingMap.delete(commentItem);
    }

    saveEditComment(commentItem: SourcingRequestDeckProductComment) {
        const newContent = this.commentEditingMap.get(commentItem).comments;

        if (!newContent) {
            // If content is empty, delete comment
            this.deleteComment(commentItem);
        } else {
            this.commentEditSubmittingSet.add(commentItem);
            this.sourcingRequestService
                .editComment({
                    comment_id: commentItem.comment_id,
                    content: newContent,
                })
                .then(() => {
                    commentItem.content = newContent;
                    this.commentEditingMap.delete(commentItem);
                })
                .finally(() => this.commentEditSubmittingSet.delete(commentItem));
        }
    }

    slideEnterStart() {
        const dom = document.querySelector('.comments-modal .comments-box');
        dom.scrollTop = dom.scrollHeight;
    }

    closeCommentModal() {
        this.closeModal.emit();
    }

    shouldCommentBtnDisable(comments: string) {
        return !comments;
    }
}
