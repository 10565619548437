<div style="padding: 50px; min-height: 500px" appLoading [loading]="viewStatusControl.loadingCheckDetailData">
    <!-- Navigation -->
    <nav
        *ngIf="!isRequestOrderView"
        class="back-nav fw-500 cursor-pointer d-flex justify-content-start align-items-center width-100p"
        style="color: #141b22; margin-bottom: 40px"
    >
        <span [routerLink]="['/','nidavellir','product-list']">
            <img style="margin-right: 12px" class="fs-18" width="18" height="18" src="assets/svg/arrow-left.svg" />
            <span>Back</span>
        </span>
    </nav>

    <h1 *ngIf="isShoppingCartView" class="title">
        <span *ngIf="currentCart === 'creator'" class="fs-20 fw-bold" style="color: #1b222e">Creator's </span>
        <span class="fs-20 fw-bold" style="color: #1b222e">Shopping Cart </span>
        <span class="count fs-14 fw-bold text-align-center ml-10">{{products?.length || 0}}</span>
        <span *ngIf="isAccountManagerView">
            <button *ngIf="currentCart !== 'creator'" nz-button class="view-cart" (click)="switchCart()">Switch to Creator's Cart</button>
            <button *ngIf="currentCart !== 'user'" nz-button class="view-cart" (click)="switchCart()">Switch to Your Cart</button>
        </span>
    </h1>

    <ng-container *ngIf="isOrderDetailView;">
        <h1 class="title d-flex align-items-center">
            <span class="fs-20 fw-bold" style="color: #1b222e">#{{checkoutId}} </span>
            <span
                class="payment_status ml-10"
                [ngClass]="{unpaid: orderDetail?.payment_status === 'unpaid' || !orderDetail?.payment_status, pending: orderDetail?.payment_status === 'pending'}"
            >
                {{ paymentStatusMap.get(orderDetail?.payment_status) || 'Unpaid' }}
            </span>
            <span class="flex-1 fw-400" style="text-align: right; color: #9f99a3; font-size: 14px">
                Order Date: {{paymentRequestItem?.create_at | localtime}}
            </span>
        </h1>
    </ng-container>

    <ng-container *ngIf="isRequestOrderView">
        <div style="margin-bottom: 30px">
            <div class="fs-20 fw-bold" style="color: #1b222e">New Order Payment Request</div>
            <div>Order ID: {{checkoutId}}</div>
            <div>Order Date: {{paymentRequestItem?.create_at | date:'yyyy-MM-dd HH:mm:SS'}}</div>
        </div>
    </ng-container>

    <!-- Content -->
    <div
        *ngIf="!viewStatusControl.isFetchDataFailed && (products?.length > 0 || viewStatusControl.showAddNewProduct)"
        class="shopping-cart-content d-flex justify-content-between width-100p align-items-stretch"
    >
        <!-- Left -->
        <div class="left-products">
            <!-- Sample Products-->
            <div *ngIf="sampleProducts.length>0" class="sample-products" style="margin-bottom: 50px">
                <ng-container
                    *ngTemplateOutlet="dividerTpl;context:{icon:'magic-box',title:'ORDER SAMPLE',isCustomIcon:true}"
                ></ng-container>

                <div class="address-container" [ngClass]="{'empty-address-container': profileService.isProfileInValid(addressInfo)}">
                    <div class="relative" style="z-index: 3">
                        <div class="d-flex justify-content-start align-items-start" [ngClass]="{'mb-20': viewStatusControl.editingAddress}">
                            <i class="mr-20 vertical-align-middle" nz-icon nzType="antd:location-new" style="font-size: 30px"></i>
                            <div class="flex-1">
                                <div class="fw-500 d-flex justify-content-between align-items-center" style="height: 30px">
                                    <span class="fs-16">Shipping to My Address</span>
                                    <i
                                        *ngIf="!viewStatusControl.editingAddress && isShoppingCartView && !profileService.isProfileInValid(addressInfo)"
                                        class="cursor-pointer"
                                        nz-icon
                                        nzType="edit"
                                        nzTheme="fill"
                                        style="color: rgba(35, 11, 52, 0.5)"
                                        (click)="viewStatusControl.editingAddress = true"
                                    ></i>
                                    <button
                                        *ngIf="!viewStatusControl.editingAddress && profileService.isProfileInValid(addressInfo)"
                                        nz-button
                                        nzType="primary"
                                        nzSize="small"
                                        class="pl-20 pr-20 fs-14"
                                        (click)="viewStatusControl.editingAddress = true"
                                    >
                                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                                        <span style="margin-left: 3px">Add New Address</span>
                                    </button>
                                    <div *ngIf="viewStatusControl.editingAddress">
                                        <button
                                            nz-button
                                            nzSize="small"
                                            class="mr-20 pl-20 pr-20 fs-14"
                                            style="background: #fff; color: #554764"
                                            [disabled]="viewStatusControl.submittingAddressInfo"
                                            (click)="viewStatusControl.editingAddress = false"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            [nzLoading]="viewStatusControl.submittingAddressInfo"
                                            nz-button
                                            nzType="primary"
                                            nzSize="small"
                                            class="pl-20 pr-20 fs-14"
                                            (click)="saveNewAddress()"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div
                                    *ngIf="!viewStatusControl.editingAddress && !profileService.isProfileInValid(addressInfo)"
                                    class="d-flex justify-content-start align-items-start"
                                    style="color: rgba(27, 34, 46, 0.7)"
                                >
                                    <!-- *ngIf="!profileService.isProfileInValid(addressInfo);else addAddressTpl" -->
                                    <ng-container>
                                        <div class="mr-20">{{addressInfo?.first_name}} {{ addressInfo?.last_name}}</div>
                                        <div>
                                            <div>{{address}}</div>
                                            <div>{{addressInfo?.phone_number}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <app-address-form
                            #addressFormTpl
                            *ngIf="viewStatusControl.editingAddress"
                            [addressInfo]="addressInfo"
                        ></app-address-form>
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="productsTpl;context:{$implicit:sampleProducts}"></ng-container>
            </div>

            <!-- Normal Products -->
            <div *ngIf="otherProducts.length>0" class="other-products" style="margin-bottom: 50px">
                <ng-container
                    *ngTemplateOutlet="dividerTpl;context:{icon:'category',title:'PRODUCT STOCK UP',isCustomIcon:true}"
                ></ng-container>

                <div class="address-container d-flex justify-content-start align-items-center">
                    <i
                        class="mr-20 vertical-align-middle relative"
                        nz-icon
                        nzType="antd:location-new"
                        style="font-size: 30px; z-index: 3"
                    ></i>
                    <span class="fs-16 fw-500 relative" style="z-index: 3">Shipping to My Fulfillment Warehouse</span>
                </div>

                <ng-container *ngTemplateOutlet="productsTpl;context:{$implicit:otherProducts}"></ng-container>
            </div>

            <div
                *ngIf="isAccountManagerView && !viewStatusControl.showAddNewProduct && isShoppingCartView"
                style="float: right; margin-right: 50px"
            >
                <ng-container *ngTemplateOutlet="addNewProductTpl"></ng-container>
            </div>

            <!-- Create Product -->
            <div *ngIf="viewStatusControl.showAddNewProduct" class="create-product">
                <ng-container
                    *ngTemplateOutlet="dividerTpl;context:{icon:'edit',title:'ADD NEW PRODUCT',isCustomIcon:false}"
                ></ng-container>

                <div style="padding-right: 50px; margin-top: 30px">
                    <!-- Product Image -->
                    <div class="mt-20">
                        Upload Product Image <span style="color: red">* </span>
                        <span style="color: #9f99a3">(Will be shown to creators)</span>
                    </div>
                    <div class="design-images d-flex justify-content-start align-items-start flex-wrap">
                        <div
                            *ngFor="let imageItem of imagesOfNewAddedProduct"
                            class="design-image"
                            appLoading
                            [maskVisible]="true"
                            [loading]="uploadingImageSet.has(imageItem)"
                        >
                            <img [src]="imageItem.src | safeUrl" />

                            <i class="remove" nz-icon nzType="close" nzTheme="outline" (click)="removeImage(imageItem)"></i>
                        </div>
                        <div class="design-image add-product-img">
                            <nz-upload
                                [nzShowUploadList]="false"
                                [nzAccept]="'image/jpeg,image/jpg,image/png'"
                                [nzBeforeUpload]="beforeUpload"
                            >
                                <div class="content">
                                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                                </div>
                            </nz-upload>
                        </div>
                    </div>

                    <!-- Product Name -->
                    <div class="mt-20">
                        Product Name <span style="color: red">* </span><span style="color: #9f99a3">(Will be shown to creators)</span>
                    </div>
                    <input
                        type="text"
                        nz-input
                        class="width-100p"
                        placeholder="Enter Product Name"
                        [(ngModel)]="addNewProductInfo.productName"
                    />

                    <!-- Quantity / unit Cost  -->
                    <div class="d-flex justify-content-between align-items-center mt-20">
                        <div class="flex-1 mr-20">
                            <div>
                                Quantity<span style="color: red">* </span><span style="color: #9f99a3">(Will be shown to creators)</span>
                            </div>
                            <nz-input-number
                                class="width-100p"
                                [nzPlaceHolder]="'Quantity'"
                                [(ngModel)]="addNewProductInfo.quantity"
                                [nzMin]="0"
                            ></nz-input-number>
                        </div>
                        <div class="flex-1 mr-20 ml-20">
                            <div>
                                Unit Cost <span style="color: red">* </span><span style="color: #9f99a3">(Will be shown to creators)</span>
                            </div>
                            <div class="width-100p relative">
                                <span class="absolute" style="top: 11px; left: 10px">$</span>
                                <nz-input-number
                                    class="width-100p"
                                    style="padding-left: 14px"
                                    [nzPlaceHolder]="'Unit Cost'"
                                    [(ngModel)]="addNewProductInfo.unit_cost"
                                    [nzMin]="0"
                                ></nz-input-number>
                            </div>
                        </div>
                    </div>

                    <!-- Type -->
                    <div class="d-flex justify-content-between align-items-center mt-20">
                        <div class="flex-1 mr-20">
                            <div>Type<span style="color: red">*</span></div>
                            <nz-select class="width-100p" [nzPlaceHolder]="'Type'" nzSize="large" [(ngModel)]="addNewProductInfo.is_sample">
                                <nz-option [nzValue]="true" [nzLabel]="'Sample'"></nz-option>
                                <nz-option [nzValue]="false" [nzLabel]="'Mass Production'"></nz-option>
                            </nz-select>
                        </div>

                        <div class="type-desc" style="color: #9f99a3">
                            <div class="mb-20"><b>Sample:</b> we will collect and ship to creator's address</div>
                            <div><b>Mass production:</b> ship to the store's designated warehouse address</div>
                        </div>
                    </div>

                    <!-- Instruction -->
                    <div class="mt-20">Instruction</div>
                    <textarea
                        nz-input
                        class="width-100p"
                        placeholder="Leave any instruction for purchase instruction, Google drive links for materials etc..."
                        [(ngModel)]="addNewProductInfo.instruction"
                    ></textarea>
                </div>

                <div class="d-flex justify-content-end align-items-center mt-30" style="padding-right: 50px">
                    <button nz-button style="width: 120px; margin-right: 30px" (click)="viewStatusControl.showAddNewProduct = false">
                        Cancel
                    </button>
                    <button
                        nz-button
                        style="width: 120px"
                        nzType="primary"
                        [nzLoading]="viewStatusControl.addingNewProduct"
                        [disabled]="disableAddNewProductBtn"
                        (click)="addToCart()"
                    >
                        Add
                    </button>
                </div>
            </div>

            <!-- Products Template -->
            <ng-template #productsTpl let-products>
                <div style="padding-right: 50px">
                    <div *ngFor="let product of products" class="product-box d-flex justify-content-between align-items-stretch">
                        <div class="left-img" style="padding-right: 30px">
                            <img
                                appImgLoadSmooth
                                class="object-fit-cover border-radius-12"
                                [src]="product.cart_json.image_url || 'assets/imgs/img_default@2x.png'"
                                [alt]="product.cart_json.title"
                                width="80"
                                height="80"
                            />
                            <div
                                *ngIf="isShoppingCartView"
                                class="d-flex mt-20 cursor-pointer user-select-none operation-btns"
                                style="color: #9f99a3"
                            >
                                <ng-container
                                    *ngIf="product.cart_json.type === 'advanced_customization_product' || product.cart_json.type === 'pod_product'; else showRemoveBtn"
                                >
                                    <div
                                        class="flex-1 btn-item"
                                        nz-tooltip
                                        [nzTooltipTitle]="editTooltip"
                                        nzTooltipColor="#F1F0F3"
                                        nzTooltipPlacement="bottom"
                                        style="border-right: 1px solid rgba(85, 71, 100, 0.08)"
                                        (click)="goAdvancedCustomEditPage(product)"
                                    >
                                        <i nz-icon nzType="edit" nzTheme="fill"></i>
                                    </div>
                                    <div
                                        class="flex-1 btn-item"
                                        nz-tooltip
                                        [nzTooltipTitle]="removeTooltip"
                                        nzTooltipColor="#F1F0F3"
                                        nzTooltipPlacement="bottom"
                                        (click)="removeProduct(product.id)"
                                    >
                                        <i nz-icon nzType="delete" nzTheme="fill"></i>
                                    </div>
                                </ng-container>
                                <ng-template #showRemoveBtn>
                                    <div
                                        class="flex-1 btn-item d-flex align-items-center justify-content-center"
                                        (click)="removeProduct(product.id)"
                                    >
                                        <i nz-icon nzType="delete" nzTheme="fill"></i>
                                        <span class="fs-12" style="margin-left: 5px">Remove</span>
                                    </div>
                                </ng-template>
                                <ng-template #editTooltip><div style="color: rgba(27, 34, 46, 0.7)">Edit</div></ng-template>
                                <ng-template #removeTooltip><div style="color: rgba(27, 34, 46, 0.7)">Remove</div></ng-template>
                            </div>
                        </div>

                        <div class="right-info flex-1">
                            <!-- Product -->
                            <div class="d-flex justify-content-between width-100p align-items-center fw-500 fs-14">
                                <div class="cursor-pointer active-mask pr-20" (click)="goAdvancedCustomEditPage(product)">
                                    <span
                                        [ngStyle]="{'text-decoration': product.cart_json.type === 'advanced_customization_product' || product.cart_json.type === 'pod_product' ? 'underline' : 'none'}"
                                    >
                                        {{product.cart_json.title}}
                                    </span>
                                    <span
                                        *ngIf="product.cart_json.type === 'advanced_customization_product' || product.cart_json.type === 'pod_product'"
                                        class="advanced-customization"
                                    >
                                        {{ product.cart_json.type === 'pod_product' ? 'Print On Demand' : 'Advanced Customization' }}
                                    </span>
                                </div>
                                <div>{{ calculateSingleProductAmount(product.cart_json).productAmount | currency }}</div>
                            </div>

                            <div class="mt-10 mb-10" style="color: #9f99a3">
                                Estimated Delivery Date: {{ shoppingCartService.calculateDeliverDate(product)}}
                            </div>

                            <div class="variant-table border-radius-12">
                                <div
                                    *ngFor="let variantItem of product.cart_json.variants"
                                    class="variant-item d-flex justify-content-between align-items-center width-100p"
                                >
                                    <div [ngStyle]="{width: isPaidOrderDetailView ? '40%' : '50%'}">{{variantItem.option}}</div>
                                    <div class="flex-1 pl-10">x{{variantItem.quantity}}</div>
                                    <div class="pl-10" [ngClass]="{'flex-1': isPaidOrderDetailView}">
                                        {{ variantItem.unit_cost | currency }}
                                    </div>
                                    <div
                                        *ngIf="isPaidOrderDetailView"
                                        style="width: 25%; min-width: 130px; text-align: right; color: #9f99a3"
                                    >
                                        {{getShippedByFulfillmentStatus(variantItem.fulfillment_status).name}}
                                        <img
                                            appImgLoadSmooth
                                            [src]="getShippedByFulfillmentStatus(variantItem.fulfillment_status).src"
                                            width="18"
                                            height="18"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="mt-20"
                                *ngIf="isAccountManagerView && isShoppingCartView && shouldShowRequireOnlinePictureForm(product)"
                            >
                                <label
                                    [nzChecked]="requireOnlinePictureProducts.has(product)"
                                    (nzCheckedChange)="requireOnlinePictureProductChange(product)"
                                    nz-checkbox
                                >
                                    Need lifo to take online pictures
                                </label>
                            </div>

                            <!-- Packaging -->
                            <ng-container *ngIf="product.cart_json.packaging?.length>0">
                                <div class="fw-500 fs-14 mt-20 mb-10">Customized Packaging</div>
                                <div class="package-items border-radius-12">
                                    <div
                                        *ngFor="let package of product.cart_json.packaging"
                                        class="package-item d-flex justify-content-between align-items-center"
                                    >
                                        <img
                                            appImgLoadSmooth
                                            class="mr-20"
                                            width="30"
                                            height="30"
                                            [src]="package.image_url"
                                            [alt]="package.type"
                                            style="border-radius: 50%"
                                        />
                                        <div class="flex-1">{{package.type | titlecase}}</div>
                                        <div>x{{calculateSingleProductAmount(product.cart_json).quantity}}</div>
                                        <div *ngIf="isPaidOrderDetailView" style="width: 30%; text-align: right; color: #9f99a3">
                                            {{getShippedByFulfillmentStatus(product.cart_json.variants[0].fulfillment_status).name}}
                                            <img
                                                appImgLoadSmooth
                                                [src]="getShippedByFulfillmentStatus(product.cart_json.variants[0].fulfillment_status).src"
                                                width="18"
                                                height="18"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- Divider Template -->
            <ng-template #dividerTpl let-icon="icon" let-title="title" let-isCustomIcon="isCustomIcon">
                <div
                    class="d-flex justify-content-start align-items-center width-100p fs-14"
                    style="color: rgba(27, 34, 46, 0.7); margin-top: -11px"
                >
                    <i *ngIf="isCustomIcon" class="fs-16 mr-10" nz-icon nzType="antd:{{icon}}"></i>
                    <i *ngIf="!isCustomIcon" class="fs-16 mr-10" nz-icon [nzType]="icon"></i>
                    <span class="mr-10">{{title}}</span>
                    <div class="flex-1" style="height: 1px; background-color: #ebeaed"></div>
                </div>
            </ng-template>
        </div>

        <!-- Right -->
        <div class="right-money">
            <!-- Calculation -->
            <div class="right-box-item">
                <div class="amount-item">
                    <span>Subtotal</span>
                    <span class="amount">{{amount.subTotal | currency}}</span>
                </div>
                <div class="amount-item" *ngIf="amount.packageOperationFee > 0">
                    <span>
                        <span>Package & Operation Fee</span>
                        <i
                            class="fs-20"
                            nz-icon
                            nzType="antd:info"
                            nz-popover
                            nzPopoverTrigger="hover"
                            [nzPopoverContent]="estDateTpl"
                            nzPopoverOverlayClassName="no-padding no-bg dark-arrow"
                            style="vertical-align: top; margin-left: 4px"
                        ></i>

                        <ng-template #estDateTpl>
                            <div style="width: 300px; background-color: #554764; padding: 20px; border-radius: 12px; color: #fff">
                                Fee for ordering customized packaging items and the warehouse operation. ($1.00 per piece)
                            </div>
                        </ng-template>
                    </span>
                    <span class="amount">{{amount.packageOperationFee | currency}}</span>
                </div>
                <div *ngIf="viewStatusControl.useCreditPay" class="amount-item">
                    <span>Lifo Credit</span>
                    <span class="amount" style="color: #55c3b9">{{-amount.creditUsedAmount | currency}}</span>
                </div>
                <!-- Temp Remove -->
                <div *ngIf="false" class="amount-item">
                    <span>Tax</span>
                    <span class="amount">{{amount.tax | currency}}</span>
                </div>
                <div *ngIf="amount.shipping>0" class="amount-item">
                    <span>
                        <span>Shipping</span>
                        <i
                            class="fs-20"
                            nz-icon
                            nzType="antd:info"
                            nz-popover
                            nzPopoverTrigger="hover"
                            [nzPopoverContent]="sampleShippingTpl"
                            nzPopoverOverlayClassName="no-padding no-bg dark-arrow"
                            style="vertical-align: top; margin-left: 4px"
                        ></i>
                        <ng-template #sampleShippingTpl>
                            <div style="width: 300px; background-color: #554764; padding: 20px; border-radius: 12px; color: #fff">
                                ​​${{amount.shipping}} shipping cost will be applied to your sample order. It usually takes 15-20 days to
                                deliver the sample(s) to your designated address.
                            </div>
                        </ng-template>
                    </span>
                    <span class="amount">{{amount.shipping | currency}}</span>
                </div>

                <div *ngIf="discount || isAccountManagerView" class="amount-item">
                    <span>Discount</span>
                    <div *ngIf="!viewStatusControl.discountInputVisible" class="amount">
                        <span style="color: #55c3b9">{{-discount | currency}}</span>
                        <i
                            *ngIf="isAccountManagerView && isShoppingCartView"
                            class="cursor-pointer"
                            nz-icon
                            nzType="edit"
                            nzTheme="outline"
                            style="margin-left: 4px"
                            (click)="viewStatusControl.discountInputVisible = true;"
                        ></i>
                    </div>

                    <div *ngIf="viewStatusControl.discountInputVisible" class="discount-input relative">
                        <span class="dollar">$</span>

                        <nz-input-number #discountInput [ngModel]="discount" [nzMin]="0" nzSize="small"></nz-input-number>

                        <span class="save-btn" (click)="saveDiscountAmount(discountInput.inputElement.nativeElement.value)">Save</span>
                    </div>
                </div>

                <div *ngIf="viewStatusControl.useBalancePay" class="amount-item">
                    <span>Balance</span>
                    <span class="amount" style="color: #55c3b9">{{-amount.balanceUsedAmount | currency}}</span>
                </div>
                <div class="amount-item mt-20 mb-20">
                    <span class="fw-500 fs-18">Total</span>
                    <span class="fs-24 fw-bold" style="color: #d8b165">{{amount.total | currency}}</span>
                </div>
            </div>

            <ng-container *ngIf="isPaidOrderDetailView; else paymentContainer">
                <div *ngFor="let item of paymentMethodEnum" class="pt-30">
                    <div class="d-flex justify-content-between">
                        <span>{{item.method}}</span>
                        <b>{{item.amount | currency}}</b>
                    </div>
                    <div class="d-flex justify-content-between" style="margin-top: 5px; color: rgba(27, 34, 46, 0.7)">
                        <span>{{item.method === 'Bank Account' ? connectedBankName : ''}}</span>
                        <span>{{paidAt | localtime}}</span>
                    </div>
                </div>
            </ng-container>

            <ng-template #paymentContainer>
                <ng-container *ngIf="!isAccountManagerView">
                    <ng-container *ngIf="!isFreePlan || isRequestOrderView || isUnPaidOrderDetailView">
                        <!-- Payment Method -->
                        <div class="right-box-item pt-30 pb-20">
                            <div class="fw-500">Payment Method</div>

                            <div *ngIf="accountBalance?.lifo_credit" class="credit d-flex justify-content-between align-items-center mt-30">
                                <div>
                                    <div>
                                        Use my
                                        <i nz-icon nzType="antd:dollar-wallet" style="margin: 0 3px"></i>
                                        <span style="color: #bd8f35">{{accountBalance?.lifo_credit|currency}}</span>
                                        Lifo Credit
                                        <i
                                            class="fs-20"
                                            nz-icon
                                            nzType="antd:info"
                                            nz-popover
                                            nzPopoverTrigger="hover"
                                            [nzPopoverContent]="estDateTpl"
                                            nzPopoverOverlayClassName="no-padding no-bg dark-arrow"
                                            style="vertical-align: top"
                                        ></i>

                                        <ng-template #estDateTpl>
                                            <div
                                                style="
                                                    width: 300px;
                                                    background-color: #554764;
                                                    padding: 20px;
                                                    border-radius: 12px;
                                                    color: #fff;
                                                "
                                            >
                                                Lifo credit can be used to deduct the total order cost (up to {{availableLifoCreditRate *
                                                100}}%) to help you get your business scaled up faster.
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="fs-12" style="color: #9f99a3">
                                        To deduct up to {{availableLifoCreditRate * 100}}% of the total item amount
                                    </div>
                                </div>

                                <nz-switch [(ngModel)]="viewStatusControl.useCreditPay" (ngModelChange)="calculateAmount()"></nz-switch>
                            </div>

                            <div
                                class="balance d-flex justify-content-between align-items-center mt-30"
                                [style.opacity]="accountBalance?.account_balance <= 0 ? 0.5 : 1"
                            >
                                <div>
                                    <ng-container *ngIf="accountBalance?.account_balance>0;else insufficientBalanceTpl">
                                        Use my
                                        <i nz-icon nzType="antd:balance-dollar" style="margin: 0 3px; vertical-align: middle"></i>
                                        <span style="color: #573357">{{accountBalance?.account_balance|currency}}</span>
                                        Balance
                                    </ng-container>
                                    <ng-template #insufficientBalanceTpl>
                                        Insufficient balance
                                        <i nz-icon nzType="antd:balance-dollar" style="margin: 0 3px; vertical-align: middle"></i>
                                        <span style="color: #573357">{{accountBalance?.account_balance|currency}}</span>
                                    </ng-template>
                                </div>

                                <nz-switch
                                    [disabled]="accountBalance?.account_balance<=0"
                                    [(ngModel)]="viewStatusControl.useBalancePay"
                                    (ngModelChange)="calculateAmount()"
                                ></nz-switch>
                            </div>
                        </div>

                        <!-- Rest Payment -->
                        <div *ngIf="amount.total > 0" class="rest-payment mt-20">
                            <div class="mb-20">
                                Pay
                                <span class="fw-500" style="color: #573357">{{amount.total | currency}}</span>
                                from
                            </div>

                            <!-- Bank -->
                            <div
                                class="radio-item user-select-none cursor-pointer"
                                [class.active]="selectedPaymentMethod === PaymentMethodEnum.BANK"
                                [style.opacity]="this.connectedBankName === undefined ? 0.5 : 1"
                                (click)="selectPaymentMethod(PaymentMethodEnum.BANK)"
                            >
                                <i
                                    class="mr-30"
                                    nz-icon
                                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.BANK ?'radio-checked':'radio-unchecked'}}"
                                    nzTheme="outline"
                                ></i>
                                <div>
                                    <div>Bank Account (US & CA banks Only)</div>
                                    <div *ngIf="connectedBankName" class="mt-10 fs-12">{{connectedBankName}}</div>
                                    <div
                                        *ngIf="connectedBankName === null && selectedPaymentMethod === PaymentMethodEnum.BANK"
                                        class="mt-10 fs-14 fw-bold d-flex justify-content-start align-items-center cursor-pointer user-select-none"
                                        style="color: #f3745e"
                                        [style.opacity]="viewStatusControl.connectingBank?0.5:1"
                                        (click)="!viewStatusControl.connectingBank ? connectPlaid() : null"
                                    >
                                        <i class="fs-14 fw-bold" nz-icon nzType="plus" style="margin-right: 4px"></i>
                                        <span>Connect{{viewStatusControl.connectingBank?'ing':''}}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Credit -->
                            <div
                                class="credit-radio-item radio-item user-select-none cursor-pointer align-items-start"
                                [class.active]="selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD"
                                (click)="selectPaymentMethod(PaymentMethodEnum.CREDIT_CARD)"
                            >
                                <i
                                    class="mr-30"
                                    nz-icon
                                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD ?'radio-checked':'radio-unchecked'}}"
                                    nzTheme="outline"
                                ></i>
                                <div class="flex-1">
                                    <div>Credit Card</div>
                                    <div [hidden]="selectedPaymentMethod !== PaymentMethodEnum.CREDIT_CARD" class="mt-30">
                                        <div ngxStripeCardGroup [elementsOptions]="elementsOptions">
                                            <div class="mb-20">
                                                <div>Cardholder Name <span class="necessary">*</span></div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="credit-card-box mr-20 flex-1">
                                                        <input
                                                            type="text"
                                                            class="input-item"
                                                            placeholder="First Name"
                                                            [(ngModel)]="stripeInfo.firstName"
                                                        />
                                                    </div>

                                                    <div class="credit-card-box ml-20 flex-1">
                                                        <input
                                                            type="text"
                                                            class="input-item"
                                                            placeholder="Last Name"
                                                            [(ngModel)]="stripeInfo.lastName"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-20">
                                                <div>Zip <span class="necessary">*</span></div>
                                                <div class="credit-card-box">
                                                    <input
                                                        type="text"
                                                        class="input-item"
                                                        placeholder="Zip Code"
                                                        [(ngModel)]="stripeInfo.zipCode"
                                                        (ngModelChange)="zipCodeChange()"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div>Card <span class="necessary">*</span></div>

                                                <ngx-stripe-card
                                                    [options]="cardOptions"
                                                    [elementsOptions]="elementsOptions"
                                                    (change)="cardInputChange($event)"
                                                ></ngx-stripe-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- PayPal -->
                            <div
                                class="credit-radio-item radio-item user-select-none cursor-pointer align-items-center"
                                [class.active]="selectedPaymentMethod === PaymentMethodEnum.PAYPAL"
                                (click)="selectPaymentMethod(PaymentMethodEnum.PAYPAL)"
                            >
                                <i
                                    class="mr-30"
                                    nz-icon
                                    nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.PAYPAL ?'radio-checked':'radio-unchecked'}}"
                                    nzTheme="outline"
                                ></i>
                                <div class="flex-1">
                                    <img src="assets/imgs/paypal.png" height="28" alt="PayPal" />
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isRequestOrderView || isUnPaidOrderDetailView">
                            <div
                                *ngIf="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL || amount.total <= 0"
                                class="mt-30 d-flex justify-content-between align-items-center"
                            >
                                <button *ngIf="isRequestOrderView" class="flex-1 mr-20" nz-button nzSize="large" (click)="closeModal()">
                                    Cancel
                                </button>
                                <button
                                    class="flex-1"
                                    nz-button
                                    nzType="primary"
                                    nzSize="large"
                                    [nzLoading]="viewStatusControl.checkingOut"
                                    [disabled]="checkoutDisabled"
                                    (click)="checkout()"
                                >
                                    Approve
                                </button>
                            </div>

                            <div
                                appLoading
                                [loading]="viewStatusControl.checkingOut"
                                [maskVisible]="true"
                                [hidden]="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL  || amount.total <= 0"
                                class="mt-30"
                            >
                                <ngx-paypal [config]="paypalConfig"></ngx-paypal>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isShoppingCartView">
                        <div *ngIf="!isFreePlan" class="mt-20">
                            <button
                                *ngIf="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL || amount.total <= 0"
                                nz-button
                                nzType="primary"
                                class="width-100p mt-20"
                                nzSize="large"
                                [nzLoading]="viewStatusControl.checkingOut"
                                (click)="checkout()"
                            >
                                Check Out
                            </button>

                            <div
                                appLoading
                                [hidden]="selectedPaymentMethod !== PaymentMethodEnum.PAYPAL || amount.total <= 0"
                                [loading]="viewStatusControl.checkingOut"
                                [maskVisible]="true"
                            >
                                <ngx-paypal [config]="paypalConfig"></ngx-paypal>
                            </div>
                        </div>

                        <div *ngIf="isFreePlan" class="mt-20">
                            <div class="fs-14 text-align-center mb-10" style="color: rgba(27, 34, 46, 0.7)">
                                Upgrade your subscription plan to get more services
                            </div>
                            <button nz-button nzType="primary" class="width-100p" nzSize="large" (click)="upgradePlan()">
                                Upgrade Plan
                            </button>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="isAccountManagerView">
                    <label class="checkbox" nz-checkbox [(ngModel)]="actualOrderCreate">Create Internal Purchase Request Before Paid</label>
                    <button
                        *ngIf="isShoppingCartView"
                        nz-button
                        class="width-100p mt-30"
                        nzSize="large"
                        nzType="primary"
                        [nzLoading]="viewStatusControl.checkingOut"
                        [disabled]="disableCreateOrderBtn || viewStatusControl.skipPaymentCheckingOut"
                        (click)="checkout()"
                    >
                        Create Order & Payment Request
                    </button>

                    <button
                        *ngIf="isShoppingCartView && amount.total <= 0"
                        nz-button
                        class="width-100p mt-30"
                        nzSize="large"
                        nzType="primary"
                        [nzLoading]="viewStatusControl.skipPaymentCheckingOut"
                        [disabled]="disableCreateOrderBtn || viewStatusControl.checkingOut"
                        (click)="checkout(false)"
                    >
                        Create Order & Skip Payment
                    </button>
                </ng-container>
            </ng-template>
        </div>
    </div>

    <!-- Cart is Empty -->
    <div
        *ngIf="products?.length === 0 && !viewStatusControl.showAddNewProduct && isShoppingCartView"
        class="width-100p height-100p d-flex justify-content-center align-items-center flex-direction-column"
        style="margin-top: 100px"
    >
        <img src="assets/imgs/no-activity.png" />
        <div style="color: #9f99a3; margin: 10px 0">Oops! Your cart is empty.</div>
        <button nz-button nzType="primary" [routerLink]="['/','nidavellir','product-list']">Create Product</button>

        <ng-container *ngIf="isAccountManagerView">
            <ng-container *ngTemplateOutlet="addNewProductTpl"></ng-container>
        </ng-container>
    </div>

    <!-- Get Data Failed -->
    <div
        *ngIf="viewStatusControl.isFetchDataFailed"
        class="width-100p height-100p d-flex justify-content-center align-items-center flex-direction-column"
        style="margin-top: 100px"
    >
        <img src="assets/svg/order_failed.svg" />
        <div style="color: #9f99a3; margin: 10px 0">Oops! Something wrong.</div>
        <button nz-button (click)="getProductsInCart()">Reload</button>
    </div>

    <ng-template #addNewProductTpl>
        <div style="color: #f3745e; margin: 20px auto" class="cursor-pointer" (click)="viewStatusControl.showAddNewProduct = true">
            <i nz-icon nzType="plus" nzTheme="outline" style="margin-right: 4px"></i>
            <span class="text-decoration-underline">Add New Product</span>
        </div>
    </ng-template>
</div>
