import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ShoppingCartService, PaymentMethodEnum } from '@shared/components/shopping-cart/shopping-cart.service';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { MessageService } from '@services/message.service';
import { PlaidService } from '@services/plaid.service';
import { ShopService } from '@services/shop.service';

@Component({
    selector: 'app-add-payment-method',
    templateUrl: './add-payment-method.component.html',
    styleUrls: ['./add-payment-method.component.less'],
})
export class AddPaymentMethodComponent implements OnInit {
    @Output() closeModal = new EventEmitter();
    @Output() saveSuccess = new EventEmitter();
    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    bankAccount;
    PaymentMethodEnum = PaymentMethodEnum;
    selectedPaymentMethod: PaymentMethodEnum = PaymentMethodEnum.CREDIT_CARD;
    stripeInfo = {
        firstName: '',
        lastName: '',
        zipCode: '',
    };
    isCreditCartInputCompleted = false;
    submitting = false;

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get cardOptions() {
        return this.shoppingCartService.cardOptions;
    }

    get elementsOptions() {
        return this.shoppingCartService.elementsOptions;
    }

    get confirmDisabled() {
        return (
            this.selectedPaymentMethod === this.PaymentMethodEnum.CREDIT_CARD &&
            (!this.stripeInfo.firstName || !this.stripeInfo.lastName || !this.stripeInfo.zipCode || !this.isCreditCartInputCompleted)
        );
    }

    constructor(
        public shoppingCartService: ShoppingCartService,
        private plaidService: PlaidService,
        private messageService: MessageService,
        private shopService: ShopService,
        private stripeService: StripeService
    ) {}

    ngOnInit(): void {}

    cardInputChange(e: StripeCardElementChangeEvent) {
        this.isCreditCartInputCompleted = e.complete;
    }

    zipCodeChange() {
        this.card.update({ value: { postalCode: this.stripeInfo.zipCode } });
    }

    submitCard() {
        this.submitting = true;

        if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
            this.plaidService
                .connectPlaid()
                .then(() => this.saveSuccess.emit())
                .catch(() => this.messageService.error())
                .finally(() => (this.submitting = false));
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
            this.stripeService
                .createPaymentMethod({
                    type: 'card',
                    card: this.card.element,
                    billing_details: {
                        name: `${this.stripeInfo.firstName} ${this.stripeInfo.lastName}`,
                    },
                })
                .subscribe(
                    result => {
                        if (result?.paymentMethod) {
                            this.shopService
                                .addCreditCard({ payment_method: result.paymentMethod })
                                .then(() => this.saveSuccess.emit())
                                .catch(() => this.messageService.error());
                        } else {
                            this.messageService.error(result.error.message);
                        }
                    },

                    // Strip confirm failed with no reason
                    () => this.messageService.error(),

                    // Finally
                    () => (this.submitting = false)
                );
        }
    }
}
