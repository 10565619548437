<div class="my-shop">
    <section class="selected">
        <span class="title">My Selections ({{ shopProductList.length }} / {{ maxSelection }})</span>
        <!-- <span class="how-it-works" (click)="showStoreTutorial()">How it works?</span> -->
    </section>
    <div class="selected">
        <div class="shop-item-container">
            <div class="loading-section" *ngIf="selectionProductLoading">
                <app-loading></app-loading>
            </div>
            <div class="shop-item" *ngFor="let product of shopProductList; index as i">
                <app-shop-item [product]="product" [showCommission]="true" (click)="videProductDetail(product)"> </app-shop-item>
                <button nz-button class="remove-button" (click)="removeProductFromShop(product)" [nzLoading]="product.loading">
                    Remove
                </button>
            </div>
        </div>
        <div class="empty-message" *ngIf="shopProductList.length === 0 && !selectionProductLoading">
            <img src="assets/png/img_money.png" />
            <div>Add products below to start earning</div>
        </div>
    </div>
    <div class="selected">
        <div class="title">What other people are selling?</div>
        <!-- <div class="description">Extra $10 commission for your first store sale</div> -->
    </div>

    <div class="selected">
        <div class="shop-item-container">
            <div class="loading-section" *ngIf="restProductLoading">
                <app-loading></app-loading>
            </div>
            <div class="shop-item" *ngFor="let product of remainingProductList; index as i">
                <app-shop-item [product]="product" [showCommission]="true" (click)="videProductDetail(product)"> </app-shop-item>
                <button
                    nz-button
                    class="buy-button"
                    (click)="addProductToShop(product, i)"
                    [ngClass]="{ disabled: product.loading || shopProductList.length >= maxSelection }"
                    [nzLoading]="product.loading"
                >
                    Add To Selection
                </button>
            </div>
        </div>
    </div>
</div>

<nz-modal
    [(nzVisible)]="showProductDetail"
    [nzFooter]="null"
    (nzOnCancel)="showProductDetail = false"
    [nzClosable]="false"
    [nzWidth]="750"
    [nzWrapClassName]="'ant-modal-centered'"
>
    <div *ngIf="selectedProduct" class="shop-item">
        <section class="padding-20 p-t-0">
            <div class="product-info">
                <app-image-viewer [imagesUrlsList]="selectedProductImages" style="width: 700px"></app-image-viewer>

                <!-- Product Title -->
                <div class="product-title">
                    <span class="brand-name">{{ selectedProduct.vendor }} </span>
                    <span class="product-title-text">{{ selectedProduct.title }}</span>
                </div>
                <!-- Price -->
                <div class="product-price">
                    <span
                        class="discount-price"
                        *ngIf="!finalProductVariant?.compareAtPrice || finalProductVariant?.compareAtPrice === finalProductVariant?.price"
                        >${{ finalProductVariant?.price | number: "1.0-2" }}</span
                    >
                    <ng-container
                        *ngIf="finalProductVariant?.compareAtPrice && finalProductVariant?.compareAtPrice !== finalProductVariant?.price"
                    >
                        <span class="discount-price">${{ finalProductVariant?.price | number: "1.0-2" }}</span>
                        <span class="original-price">${{ finalProductVariant?.compareAtPrice | number: "1.0-2" }}</span>
                    </ng-container>
                </div>

                <!-- Description -->
                <section class="description-title" style="font-weight: 500; color: #000">Description</section>
                <div class="product-description" [innerHtml]="selectedProduct.descriptionHtml"></div>
            </div>

            <!-- show if internal -->
            <!-- <div style="display: flex">
                <button
                    *ngIf="shopDetail"
                    nz-button
                    class="add-diy-to-selection lifo-btn"
                    [disabled]="isProductListFilled"
                    [nzLoading]="isAddingToSelection"
                    (click)="changeSelection(isProductSelected ? 'remove' : 'add')"
                >
                    {{ isProductSelected ? "Remove from Selection" : "Add To Selection" }}
                </button>
            </div> -->
        </section>
    </div>
</nz-modal>
