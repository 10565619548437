import { Component, Input, OnInit } from '@angular/core';
import { IExternalShop } from '@modules/external/shops/shops.service';

interface IPlatformInformation {
    platform: 'instagram' | 'tiktok' | 'youtube';
    platform_id: string;
    followers_count: number;
}

class PlatformInformation {
    platform: 'instagram' | 'tiktok' | 'youtube' = null;
    platform_id = '';
    followers_count = 0;

    constructor(platformInformation: IPlatformInformation = null) {
        if (platformInformation) {
            this.platform = platformInformation.platform;
            this.platform_id = platformInformation.platform_id;
            this.followers_count = platformInformation.followers_count;
        }
    }
}

@Component({
    selector: 'app-external-shop-card',
    templateUrl: './external-shop-card.component.html',
    styleUrls: ['./external-shop-card.component.less'],
})
export class ExternalShopCardComponent implements OnInit {
    shopObject: IExternalShop;
    @Input() set shop(shop: IExternalShop) {
        this.shopObject = shop;
        this.getPlatformInformation();
    }
    platformInformation: PlatformInformation = new PlatformInformation();

    constructor() {}

    ngOnInit(): void {}

    goToShop() {
        if (this.shopObject.shop_detail.is_production) {
            let urlToOpen = this.shopObject.domain;
            if (urlToOpen.indexOf('http://') === -1 || urlToOpen.indexOf('https://') === -1) {
                urlToOpen = `https://${urlToOpen}`;
            }
            window.open(`${urlToOpen}`, '_blank');
        }
    }

    getPlatformInformation() {
        const platforms = [new PlatformInformation()];
        if (this.shopObject.shop_detail.instagram_follower_count) {
            platforms.push(
                new PlatformInformation({
                    platform: 'instagram',
                    platform_id: this.shopObject.shop_detail.instagram_id,
                    followers_count: this.shopObject.shop_detail.instagram_follower_count,
                })
            );
        }
        if (this.shopObject.shop_detail.tiktok_follower_count) {
            platforms.push(
                new PlatformInformation({
                    platform: 'tiktok',
                    platform_id: this.shopObject.shop_detail.tiktok_id,
                    followers_count: this.shopObject.shop_detail.tiktok_follower_count,
                })
            );
        }
        if (this.shopObject.shop_detail.youtube_follower_count) {
            platforms.push(
                new PlatformInformation({
                    platform: 'youtube',
                    platform_id: this.shopObject.shop_detail.youtube_id,
                    followers_count: this.shopObject.shop_detail.youtube_follower_count,
                })
            );
        }

        this.platformInformation = platforms.reduce((prev, current) => (prev.followers_count > current.followers_count ? prev : current));
    }
}
