<section class="terms-of-use">
    <header>
        <img src="assets/svg/logo.svg" />
        <span><strong>Lifo</strong> Creator Hub</span>
    </header>
    <nz-tabset class="product-list-tabs" nzType="card" [nzTabBarGutter]="12" nzLinkRouter [(nzSelectedIndex)]="index">
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/privacy-policy']"> Privacy Policy </a>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-of-use']"> Terms-of-Use </a>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-and-conditions']"> Terms and Conditions </a>
            <section class="container">
                <h1>Terms and Conditions</h1>

                <section class="block">
                    <h2>1. Purchase of Products.</h2>
                    <p>
                        The parties shall agree on certain products to be sourced by Lifo, purchased by Lifo on Client’s behalf, and sold
                        via Lifo’s online consumer-facing platform (the “Customer Platform”) (such agreed-upon products, the “Products”).
                        The parties shall further agree on (a) the quantities of Product to be purchased and (b) the branding and designs
                        that appear on such Products. Lifo will not place any order for Products from a manufacturer, distributor or other
                        supplier (“Supplier”) without Client’s prior consent (such approved order, an “Order”).
                    </p>
                </section>

                <section class="block">
                    <h2>2. Shipping; Fulfillment; Service.</h2>
                    <p>
                        2.1 If requested by Client, Lifo shall source sample products from Suppliers for Client’s approval prior to placing
                        any Order therefor, provided Client will reimburse Lifo promptly for any out-of-pocket costs associate with such
                        samples.
                    </p>
                    <p>
                        2.2 During the term of this Agreement, Lifo will arrange for storage of all Products prior to sale to Client’s
                        customers (“Customers”) via the Customer Platform, which may be stored at Lifo’s or its contractors’ or agents’
                        facilities or may remain at Supplier’s facilities.
                    </p>
                    <p>
                        2.3 Lifo shall use reasonable efforts to (a) fulfill Products purchased by Customers and (b) provide first-tier
                        customer service to Customers during its normal business hours, related to the Customer Platform. Client shall make
                        itself available to answer Customer inquiries (through Lifo) regarding branding and marketing. Lifo and Customer
                        shall enter into an agreement regarding Customer’s use of the Customer Platform, provision of
                        personally-identifiable information to Lifo (“Customer Data”), and the purchase and sale of Products (the “EULA”).
                        Client is not a party or a third party beneficiary to the EULA. Lifo may, but is not required to, provide certain
                        Customer Data to Client upon request of Client.
                    </p>
                    <p>
                        2.4 Unless otherwise authorized by Client, Lifo shall notify Client of Products running low on inventory and, upon
                        written approval (email sufficing) of Client, restock such Product. Lifo may, but is not required to, offer Product
                        returns or replacements in its discretion, on terms determined by Lifo.
                    </p>
                    <p>
                        2.5 Lifo may opt to remove Product listings from the Customer Platform at any time (“Delisted Product”) if it
                        reasonably suspects such Product or any Content included with or in such Product violates any applicable law or any
                        third party right (including any intellectual property or other proprietary right).
                    </p>
                    <p>
                        2.6 In the event that Lifo removes the Delisted Product from the Customer Platform in accordance with Section 2.5,
                        Lifo shall provide advanced notice to Client of such removal and ship any remaining inventory of Delisted Products
                        to Client within fifteen (15) days of such removal. Client shall bear any costs and taxes associated shipping and
                        handling of the Delisted Products (the “Shipping Fees”). In the event that Client rejects the receipt of such
                        Delisted Products or refuses to pay for the Shipping Fees, Lifo reserves the right to destroy the Delisted Products
                        or sell the Delisted Products to a third party in its sole discretion.
                    </p>
                </section>

                <section class="block">
                    <h2>3. Price; Payment; [Cash Advance Services]; [Subscription Services].</h2>
                    <p>
                        3.1 Lifo will provide written (email sufficing) notice to Client upon placing an Order. Lifo will invoice Client on
                        a monthly basis for all Orders placed during the previous month, which invoice shall include the cost of Products
                        purchased, applicable taxes, shipping and logistics, and Product customization fee (if any) (the total for such
                        Order, the “Client Order Price”). Client shall pay all invoices within thirty (30) days following the date of the
                        applicable invoice. Client maintains title and risk of loss in all Products at all times.
                    </p>
                    <p>
                        3.2 Client may elect to participate in Lifo’s Cash Advance Services. If approved by Lifo on a case by case basis,
                        Lifo may advance Client the Cash Advance Amount as set forth in the Order Form to apply to the cost of the Client
                        Order Price. Lifo will recoup the Cash Advance Amount plus the Cash Advance Fee (together, the “Amount to be
                        Repaid”) by deducting the Remittance Percentage from the Revenue Share Remitted Amount (as defined below) each
                        month, until the Amount to be Repaid has been remitted. After Lifo has recouped over 25% of the total Amount to be
                        Repaid, Lifo will give Client the option to remit the remaining balance of the Amount to be Repaid in a single lump
                        sum payment. Client does not have to remit the Amount to be Repaid by any certain date and there will not be any
                        interest charged on the Amount to be Repaid. Client may view the remaining balance of the Amount to be Repaid and
                        Revenue Share Remitted Amount in the Client Platform.
                    </p>
                    <p>
                        3.3 Client may elect to use the subscription services offered by Lifo (“Subscription Services”) for the subscription
                        term listed in the Order Form or otherwise agreed by the parties (the “Subscription Term”). Use of the Subscription
                        Services will be subject to the pricing in accordance with the fees as set forth on the Order Form, for which Client
                        will be charged for the initial period followed by recurring period charges. Client may opt out of auto-renewal via
                        the <a href="https://creator.lifo.ai/" target="_blank">Client Platform</a>, at least
                        <span style="color: #f3745e">3</span> days prior to the end of the then-current Subscription Term, and the
                        applicable Subscription Services will not be renewed. However Client will not be eligible for a prorated refund of
                        any portion of the subscription fee paid for the then-current Subscription Term. Charges for the Subscription
                        Services may vary per Subscription Term depending on usage as set forth in the Order Form.
                    </p>
                </section>

                <section class="block">
                    <h2>4. Revenue Share; Reporting.</h2>
                    <p>
                        Lifo shall list each Product on the Customer Platform at a listed price established by Client and communicated to
                        Lifo when requested (“Listed Price”), and will use commercially reasonable efforts to sell each Product. For each
                        Product sold by Lifo to Customers on the Customer Platform, Client shall pay Lifo the Revenue Share Rate (as set
                        forth on the Order Form) of Net Sales (the “Revenue Share”). “Net Sales” means the gross revenue actually collected
                        by Lifo from Customers for sale of the Products via the Customer Platform less (i) applicable sales, use, value-add,
                        excise and other taxes, (ii) custom duties and other governmental assessments, (iii) Product returns, chargebacks,
                        reversals or credits, (iv) shipping and insurance, (v) storage and handling fees, including any charged by Supplier,
                        (v) Media Fees (defined below), and (vi) any additional mutually pre-approved costs, expenses and fees that the
                        parties agree in writing to deduct on a case by case basis. Within fifteen (15) days after the end of each calendar
                        month, Lifo shall calculate the Revenue Share for such month and pay the balance of Net Sales less the Revenue Share
                        to Client (or Client’s authorized agent) (such amount, the “Revenue Share Remitted Amount”), along with a report
                        detailing how the Net Share, Revenue Share and Revenue Share Remitted Amount were calculated. “Media Fees” means
                        amounts Lifo spends on paid media or promotions on behalf of Client, as authorized and requested by Client.
                    </p>
                </section>

                <section class="block">
                    <h2>5. Client Platform; Proprietary Rights.</h2>
                    <p>
                        5.1 Upon launch of an online Client-facing platform for reporting and analytics related to the Products and Orders
                        (the “Client Platform”), Lifo will provide Client access to such Client Platform. Lifo shall retain all right, title
                        and interest in and to the Client Platform. Except as expressly and unambiguously granted herein, no other right or
                        license is granted.
                    </p>
                    <p>
                        5.2 Subject to Client’s compliance with all terms and conditions in this Agreement, Lifo hereby grants (effective
                        only upon Lifo’s providing Client Platform access to Client) Client for the term of this Agreement a limited,
                        personal, nonexclusive, non-sublicensable, royalty-free, nontransferable license to access and use the Client
                        Platform, in the form it is provided to Client for the purpose of (a) requesting and/or agreeing on potential
                        Products, (b) approving Orders to be placed with Suppliers, (c) viewing reports and/or analytics provided by Lifo
                        relating to purchases of Products via the Customer Platform, and (d) viewing Product inventory yet to be sold via
                        the Customer Platform, each in accordance with the functionality of the Client Platform. Lifo may provide additional
                        or updated terms for use of the Client Platform at the time of Client being granted access thereto, and such
                        additional or updated terms must be agreed to by Client as a condition of access to the Client Platform.
                    </p>
                    <p>
                        5.3 Client hereby grants Lifo a worldwide, non-exclusive, royalty-free and transferable right to display, market and
                        sell Products containing the Content (as defined below). The term “Content” includes, without limitation, any
                        information, data, text, mark, logo, image, photograph, video, audio and graphics generated, provided, or otherwise
                        made accessible to Lifo by Client, whether via the Client Platform or otherwise (excluding, for clarity, any
                        Feedback (defined below). For clarity, the Content is licensed and not sold, and as between the parties, Client owns
                        all rights in the Content. Client shall be solely responsible for all Content Client provides or makes available
                        through the Client Platform or otherwise makes accessible to the Customers through the Customer Platform. Client
                        shall defend, indemnify and hold Lifo and its officers, directors, agents and employees harmless from liability
                        (including all damages, losses, costs and attorney’s fees) arising out of infringement by the Content of any
                        intellectual property rights of any third party, including patent rights, trademark rights, copyrights or other
                        proprietary rights.
                    </p>
                    <p>
                        5.4 Client may, at its discretion, provide feedback, suggestions, improvements, enhancements, and/or feature
                        requests to Lifo with respect to its use of the Client Platform (“Feedback”). Client shall and hereby does assign
                        all right, title, and interest in and to Feedback to Lifo.
                    </p>
                </section>

                <section class="block">
                    <h2>6. Restrictions.</h2>
                    <p>
                        Client shall not directly or indirectly (a) disassemble, decompile, reverse engineer or use any other means to
                        attempt to discover any source code of the Client Platform (except to the extent that such a prohibition would be
                        prohibited by law), (b) use the Client Platform in any manner that, or contribute any Content that, infringes or
                        violates the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of
                        any third party, (c) copy, distribute, manufacture, adapt, create derivative works of, translate, localize, port or
                        otherwise modify any part of the Client Platform, (d) use the Client Platform in any manner that, or contribute any
                        Content that, is harmful, fraudulent, deceptive, threatening, abusive, obscene, libelous, or otherwise
                        objectionable, or that violates any law, statute, ordinance, or regulation, or (e) permit any third party to engage
                        in any of the foregoing proscribed acts.
                    </p>
                </section>

                <section class="block">
                    <h2>7. Warranties; Warranty Disclaimer.</h2>
                    <p>
                        The parties shall agree on certain products to be sourced by Lifo, purchased by Lifo on Client’s behalf, and sold
                        via Lifo’s online consumer-facing platform (the “Customer Platform”) (such agreed-upon products, the “Products”).
                        The parties shall further agree on (a) the quantities of Product to be purchased and (b) the branding and designs
                        that appear on such Products. Lifo will not place any order for Products from a manufacturer, distributor or other
                        supplier (“Supplier”) without Client’s prior consent (such approved order, an “Order”).
                    </p>
                    <p>
                        7.2 LIFO MAKES NO EXPRESS OR IMPLIED WARRANTY, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR
                        FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, THE CLIENT PLATFORM AND CUSTOMER PLATFORM ARE
                        PROVIDED AS IS AND WITHOUT ANY WARRANTY OF AVAILABILITY.
                    </p>
                </section>

                <section class="block">
                    <h2>8. Limitation of Liability.</h2>
                    <p>
                        IN NO EVENT SHALL LIFO (OR ITS SUPPLIERS) BE LIABLE CONCERNING THE SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF
                        THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR ANY (A) MATTER
                        BEYOND ITS REASONABLE CONTROL, (B) LOSS OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING
                        SUBSTITUTE TECHNOLOGY, GOODS OR SERVICE, (C) INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR
                        CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES, PROFITS AND GOODWILL, AND TRADING
                        AND INVESTMENT LOSSES, OR (D) DAMAGES, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF $1000 OR THE FEES PAID BY
                        MERCHANT HEREUNDER IN THE ONE (1) MONTH PRECEDING THE APPLICABLE CLAIM EVEN IF LIFO HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL
                        APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN.
                    </p>
                </section>

                <section class="block">
                    <h2>9. Term, Termination, Survival, and Termination Liability.</h2>
                    <p>
                        This Agreement is effective for the Term as specified in the Order Form. If either party materially breaches this
                        Agreement and such breach is not remedied within thirty (30) days after receipt by the breaching party of a notice
                        thereof from the other party, the non-breaching party may immediately terminate this Agreement. Sections 3.2, 4, 5,
                        6, 7, 8, 9, 10, and 11 shall survive the termination or expiration of this Agreement. Other than termination for a
                        material breach on the part of Lifo, Client shall not terminate this Agreement prior to the end of the applicable
                        Term unless Client pays Lifo an early termination fee of $5,000 USD. If Lifo has placed an Order prior to
                        termination, it may, in its discretion, (a) sell such Products via the Customer Platform in accordance with the
                        terms hereof or (b) return such Products to Client in accordance with Section 3.6, or (c) cancel such Order and
                        invoice Client for any cancellation fees incurred in connection therewith (such invoice to be paid by Client within
                        thirty (30) days after invoice date).
                    </p>
                </section>

                <section class="block">
                    <h2>10. Confidentiality.</h2>
                    <p>
                        Any technical, financial, business or other information provided by one party (the “Disclosing Party”) to the other
                        party (the “Receiving Party”) (“Confidential Information”) shall be held in confidence and not disclosed to any
                        third party and shall not be used except to the extent necessary to carry out the Receiving Party’s obligations
                        hereunder. This obligation will not apply to information that (i) is generally and freely publicly available through
                        no fault of the Receiving Party, (ii) the Receiving Party otherwise rightfully obtains from third parties without
                        restriction, or (iii) is independently developed by employees of the Receiving Party with no knowledge of or access
                        to such information. For the avoidance of doubt, any information provided by Client relating to specifications or
                        composition or structure of Products or any part thereof or the production or properties thereof, any information
                        relating to Product purchase and sales, and any information regarding Customers (including Customer Data), is
                        Confidential Information solely of Lifo.
                    </p>
                </section>

                <section class="block">
                    <h2>11 General.</h2>
                    <p>
                        All notices under this Agreement shall be in writing, and shall be deemed given when personally delivered, when sent
                        by electronic mail, or three days after being sent by prepaid certified or registered U.S. mail to the address of
                        the party to be noticed as set forth herein or such other address as such party last provided to the other by
                        written notice. Neither party may assign its rights or obligations under this Agreement without the consent of the
                        other party, except that Lifo may assign its rights and obligations hereunder to an affiliate or to a successor to
                        all or substantially all of Lifo’s business or assets to which this Agreement relates. The failure of either party
                        to enforce its rights under this Agreement at any time for any period shall not be construed as a waiver of such
                        rights. This Agreement supersedes all proposals, oral or written, all negotiations, conversations, or discussions
                        between or among parties relating to the subject matter of this Agreement and all past dealing or industry custom.
                        No changes or modifications or waivers are to be made to this Agreement unless evidenced in writing and signed for
                        and on behalf of both parties. In the event that any provision of this Agreement shall be determined to be illegal
                        or unenforceable, that provision will be limited or eliminated to the minimum extent necessary so that this
                        Agreement shall otherwise remain in full force and effect and enforceable. This Agreement shall be governed by and
                        construed in accordance with the laws of the State of California (without regard to the conflicts of laws provisions
                        thereof or the UN Convention on the International Sale of Goods). The sole jurisdiction and venue for actions
                        related to the subject matter hereof shall be the state and federal courts located in San Francisco, California and
                        each party consents to the jurisdiction of such courts with respect to any such action. In any action or proceeding
                        arising out of this Agreement, the prevailing party will be entitled to recover costs and attorneys fees.
                    </p>
                </section>
            </section>
        </nz-tab>
    </nz-tabset>
    <section class="footer">v2.4 Ⓒ2022 by Lifo.ai</section>
</section>
