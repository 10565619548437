<div class="sourcing-request-parent">
    <!-- Cart -->
    <div style="position: fixed; top: 0; right: 0; z-index: 10">
        <app-credit-cart [newAddedProducts]="addedProducts" [showMainBody]="false"></app-credit-cart>
    </div>

    <section class="sourcing-request pb-30" appLoading [loading]="fetchingData" [maskVisible]="true">
        <!-- Info -->
        <section class="info d-flex justify-content-between align-items-center width-100p mb-10 pl-50">
            <div class="mr-30 pt-10 pb-10">
                <img style="width: 120px; height: 120px; object-fit: cover; border-radius: 50%" src="assets/imgs/lifo-logo-circle.png" />
            </div>
            <div
                class="right-text flex-1 d-flex justify-content-between align-items-start pb-20 pt-20 pr-50"
                style="border-bottom: 1px solid rgba(85, 71, 100, 0.08)"
            >
                <div>
                    <div class="fs-24 fw-bold">Product Recommendation for {{shopDetail?.shop_owner}}</div>
                    <div class="fs-14">{{deckInfo?.name}}</div>
                </div>

                <div class="cursor-pointer" (click)="goSourcingRequest()">
                    <span class="mr-10">Exit Deck</span>
                    <img width="40" src="/assets/imgs/logout.png" />
                </div>
            </div>
        </section>

        <!-- Products -->
        <section class="relative">
            <div #deckProducts class="hide-scrollbar" style="overflow-x: auto; padding: 10px 30px 50px; scroll-behavior: smooth">
                <div class="d-flex justify-content-start align-items-start deck-products">
                    <div *ngFor="let section of deckInfo?.section_list; let i = index">
                        <div class="category-title">
                            <img src="/assets/imgs/category.png" />
                            {{ section.category }} ({{ section.product_list.length }})
                        </div>
                        <div class="d-flex justify-content-start align-items-start deck-products">
                            <app-deck-product-card
                                *ngFor="let productItem of section?.product_list; let k = index"
                                class="mr-20 deck-product-card-comp"
                                [class.active]="productItem.product_id === selectedProduct.product_id"
                                [productItem]="productItem"
                                (click)="selectedProductChange(productItem,i,k)"
                            ></app-deck-product-card>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="deckInfo">
                <button
                    nzShape="circle"
                    class="absolute arrow-btn"
                    style="top: 50%; left: 34px; transform: translateY(calc(-50% - 24px))"
                    nz-button
                    (click)="goProductCard(-1)"
                >
                    <i nz-icon nzType="left" nzTheme="outline"></i>
                </button>
                <button
                    nzShape="circle"
                    class="absolute arrow-btn"
                    style="top: 50%; right: 34px; transform: translateY(calc(-50% - 24px))"
                    nz-button
                    (click)="goProductCard(1)"
                >
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                </button>
            </ng-container>
        </section>

        <!-- Product Description -->
        <section *ngIf="selectedProduct?.description" class="product-description mt-30 mb-30 pl-30 pr-30">
            <div
                class="d-flex justify-content-start align-items-center pt-10 pb-10 pl-20 pr-20"
                style="background-color: rgba(85, 71, 100, 0.04); border-radius: 12px"
            >
                <div
                    class="mr-20 fs-12 text-align-center"
                    style="width: 16px; height: 16px; border-radius: 50%; background-color: rgba(85, 71, 100, 0.5); color: #fff"
                >
                    <i nz-icon nzType="info" nzTheme="outline"></i>
                </div>
                <div class="flex-1" style="color: #554764">{{selectedProduct.description}}</div>
            </div>
        </section>

        <!-- Product Images -->
        <section class="relative">
            <div
                *ngFor="let productItem of deckInfo?.product_list"
                [hidden]="productItem !== selectedProduct"
                [@fadeIn]="productItem === selectedProduct"
                class="product-images"
                [ngClass]="{'no-reverse': selectedProduct.images.length > 10}"
            >
                <div *ngFor="let imgItem of productItem.images;let i = index;" class="img-item" [style.width]="imageWidth">
                    <div class="relative width-100p" style="padding-top: 100%">
                        <img
                            appImgLoadSmooth
                            class="absolute width-100p height-100p object-fit-cover"
                            style="top: 0; left: 0"
                            [src]="imgItem.src"
                            [ngClass]="{'selected-img': selectedProductImage === imgItem}"
                        />

                        <div class="comment-img" (click)="selectProductImage(imgItem)">
                            <span *ngIf="imgItem.comments?.length" class="fw-500 mr-10" style="font-size: 12px">
                                {{imgItem.comments.length}} Comment{{imgItem.comments.length>1?'s':''}}
                            </span>
                            <i nz-icon nzType="antd:chat-grey"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Comment Button -->
        <div class="comment-button">
            <span class="pl-20 pr-20" (click)="commentsModalVisible = true">
                <i nz-icon nzType="antd:chat" style="font-size: 26px; vertical-align: middle; margin-right: 5px"></i>
                <span>{{selectedProduct?.comments?.length || 0}} Comment{{selectedProduct?.comments?.length>1?'s':''}}</span>
            </span>
            <div class="cart" (click)="showOrderProductModal()">
                <img class="fs-20" src="assets/imgs/shopping_cart.png" />
            </div>
        </div>

        <div class="comment-modal-box" *ngIf="commentsModalVisible" [@slideEnter] [@slideLeave] (@slideEnter.start)="moveToBottom()">
            <app-deck-comment
                [selectedProduct]="selectedProduct"
                [productImage]="selectedProductImage"
                (closeModal)="closeCommentModal()"
                (moveToBottom)="moveToBottom()"
            ></app-deck-comment>
        </div>
    </section>
</div>
