import { ProfileService } from '@services/profile.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AsYouType, validatePhoneNumberLength, isValidPhoneNumber } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { countryCodeList, supportedUPSCountry } from './address-form.data';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.less'],
})
export class AddressFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('addressText') addressText: any;
    @Input() addressInfo: any;

    supportedUPSCountryCodeList = supportedUPSCountry;
    supportedUPSCountryList = countryCodeList.filter(item => supportedUPSCountry.find(code => code === item.code));

    countryCode: string;
    addressForm = this.formBuilder.group({
        address_line_1: ['', Validators.required],
        address_line_2: [null],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        short_country: [''],
        state: ['', Validators.required],
        zip: ['', Validators.required],
        phone_number: ['', Validators.required],
        country_code: [null],
    });

    isSetAsDefault = false;
    saveBtnActivated = false;

    countryData = countryCodeList;

    destroy$ = new Subject();

    constructor(private formBuilder: FormBuilder, private ref: ChangeDetectorRef, private profileService: ProfileService) {}

    ngOnInit(): void {
        if (!this.profileService.currentProfile?.address1) {
            this.isSetAsDefault = true;
        }

        // Country Change
        this.addressForm
            .get('country')
            .valueChanges.pipe(
                takeUntil(this.destroy$),
                filter(item => !!item)
            )
            .subscribe(val => {
                const countryItem = this.countryData.find(item => item.name === val);
                const phoneNumber = this.addressForm.get('phone_number').value;
                this.addressForm.get('short_country').patchValue(countryItem.code);
                this.countryCode = countryItem.dial_code;
                if (phoneNumber) {
                    this.validatePhoneNumber(phoneNumber);
                }
            });

        // Phone Number Validator
        this.addressForm
            .get('phone_number')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: string) => this.validatePhoneNumber(val));

        if (this.addressInfo) {
            this.setFormValue(this.addressInfo);
        }
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.addressInfo.currentValue) {
            this.setFormValue(this.addressInfo);
        }
    }

    ngAfterViewInit() {
        this.initGoogleMap();
    }

    validatePhoneNumber(val: string) {
        const country = this.addressForm.get('short_country').value;
        const handledVal = new AsYouType(country).input(val || '');
        const lengthCode = validatePhoneNumberLength(handledVal, country);
        const phoneNumberControl = this.addressForm.get('phone_number');

        if (!handledVal.includes(val)) {
            phoneNumberControl.patchValue(handledVal);
        }

        switch (lengthCode) {
            case 'TOO_SHORT':
                phoneNumberControl.setErrors({ length: 'Too Short' });
                break;

            // Phone Number is too long, so I will slice it
            case 'TOO_LONG':
                phoneNumberControl.patchValue(handledVal.slice(0, handledVal.length - 1));
                break;
            case 'INVALID_LENGTH':
                phoneNumberControl.setErrors({ length: 'Incorrect Format' });
                break;

            // Valid Phone Number
            case undefined:
                phoneNumberControl.clearValidators();
        }

        if (!phoneNumberControl.getError('length') && !isValidPhoneNumber(phoneNumberControl.value, country)) {
            phoneNumberControl.setErrors({ length: 'Incorrect Format' });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setFormValue(data) {
        this.addressForm.get('address_line_1').patchValue(data.address_line_1);
        this.addressForm.get('address_line_2').patchValue(data.address_line_2);
        this.addressForm.get('first_name').patchValue(data.first_name);
        this.addressForm.get('last_name').patchValue(data.last_name);
        this.addressForm.get('city').patchValue(data.city);
        this.addressForm.get('state').patchValue(data.state);
        this.addressForm.get('zip').patchValue(data.zip);
        this.addressForm.get('phone_number').patchValue(data.phone_number);
        this.addressForm.get('country').patchValue(data.country);
    }

    initGoogleMap() {
        const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
            types: ['address'], // 'establishment' / 'address' / 'geocode'
        });
        // google.maps.event.addListener(autocomplete, '', () => {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < place.address_components.length; i++) {
                const itemType = place.address_components[i].types[0];
                const longItemValue = place.address_components[i].long_name;
                const shortItemValue = place.address_components[i].short_name;
                if (itemType === 'postal_code') {
                    this.addressForm.get('zip').patchValue(longItemValue);
                } else if (itemType === 'administrative_area_level_1') {
                    this.addressForm.get('state').patchValue(longItemValue);
                } else if (itemType === 'country') {
                    const matchedCountryItem = this.supportedUPSCountryList.find(item => item.code === shortItemValue);
                    if (matchedCountryItem) {
                        this.addressForm.get('country').patchValue(longItemValue);
                        this.addressForm.get('short_country').patchValue(shortItemValue);
                    } else {
                        this.addressForm.get('country').patchValue('');
                        this.addressForm.get('short_country').patchValue('');
                    }
                } else if (itemType === 'locality') {
                    this.addressForm.get('city').patchValue(longItemValue);
                }
            }

            this.addressForm.get('address_line_1').patchValue(place.name);
            this.ref.markForCheck();
            this.ref.detectChanges();
        });
    }
}
