import { Component, OnInit } from '@angular/core';
import { SourcingRequestDeck } from '@src/app/modules/pages-no-sub-wrapper/sourcing-request/sourcing-request.type';
import { SourcingRequestService } from '@src/app/modules/pages-no-sub-wrapper/sourcing-request/sourcing-request.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-recommend-product',
    templateUrl: './recommend-product.component.html',
    styleUrls: ['./recommend-product.component.less'],
})
export class RecommendProductComponent implements OnInit {
    deckList: SourcingRequestDeck[];
    loading = false;

    constructor(private sourcingRequestService: SourcingRequestService, private message: NzMessageService) {}

    ngOnInit(): void {
        this.getRecommendList();
    }

    getRecommendList() {
        this.loading = true;
        this.sourcingRequestService
            .getDeckList()
            .then(res => {
                this.deckList = res;
                this.loading = false;
            })
            .catch(() => {
                this.message.create('error', 'Get recommended products failed, please try again later');
            });
    }
}
