import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourcingRequestComponent } from './sourcing-request.component';
import { DeckProductCardComponent } from './deck-product-card/deck-product-card.component';
import { DeckCommentComponent } from './deck-comment/deck-comment.component';
import { SharedModule } from '@shared/shared.module';

import { SourcingRequestService } from './sourcing-request.service';
import { OrderProductModalComponent } from './order-product-modal/order-product-modal.component';
import { OrderProductModalTabComponent } from './order-product-modal/order-product-modal-tab/order-product-modal-tab.component';

@NgModule({
    declarations: [
        SourcingRequestComponent,
        DeckProductCardComponent,
        DeckCommentComponent,
        OrderProductModalComponent,
        OrderProductModalTabComponent,
    ],
    imports: [CommonModule, SharedModule],
    providers: [SourcingRequestService],
})
export class SourcingRequestModule {}
