import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    loadingStatus$ = new BehaviorSubject(false);

    constructor() {}

    showLoading() {
        this.loadingStatus$.next(true);
    }

    hideLoading() {
        this.loadingStatus$.next(false);
    }
}
