import { cloneDeep } from 'lodash';
import { StorefrontStateService, SocialMediaEnum } from './../../storefront-state.service';
import { ShowModalService } from '@services/show-modal.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { StorefrontMediaEnum, StorefrontService, TemplateItem } from './../../storefront.service';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-global-theme-configuration',
    templateUrl: './global-theme-configuration.component.html',
    styleUrls: ['./global-theme-configuration.component.less'],
})
export class GlobalThemeConfigurationComponent implements OnInit, OnChanges {
    @Input() shopDetail: ShopProfile;

    environment = environment;

    domainCheckingStatus: 'yes' | 'no' | 'checking';

    logoUploading = false;

    templateList = cloneDeep(this.storefrontService.templateList);

    fontList = this.storefrontService.fontList;

    get domainControl() {
        return this.storefrontStateService.domainControl;
    }

    get isDefaultLogo() {
        return this.storefrontService.isDefaultImage(StorefrontMediaEnum.LOGO, this.shopDetail?.theme_settings?.logoImage);
    }

    constructor(
        private storefrontService: StorefrontService,
        private storefrontStateService: StorefrontStateService,
        private firebaseUploadService: FirebaseFileUploadService,
        private showModalService: ShowModalService
    ) {}

    ngOnInit(): void {}

    ngOnChanges(e: SimpleChanges) {
        if (e.shopDetail?.currentValue) {
            this.domainControl.setValue(this.getDomainByUrl());

            // When themeStyle is custom update the data of templateList
            if (this.shopDetail.theme_settings?.themeStyle === 'custom') {
                this.mainColorChange(this.shopDetail.theme_settings.color3);
                this.accentColorChange(this.shopDetail.theme_settings.color4);
                this.fontChange(this.shopDetail.theme_settings.fontBody, 'body');
                this.fontChange(this.shopDetail.theme_settings.fontTitle, 'title');
                this.cornerStyleChange(this.shopDetail.theme_settings.cornerStyle);
            }
        }
    }

    checkoutImagePixel(fileList: FileList) {
        if (fileList.length === 0) {
            return;
        }

        this.logoUploading = true;
        this.onDrop(fileList[0]).finally(() => (this.logoUploading = false));
    }

    onDrop(file: File) {
        const { uploadTask } = this.firebaseUploadService.startUpload(file);
        return uploadTask.then(downloadURL => (this.shopDetail.theme_settings.logoImage = downloadURL));
    }

    changeTemplate(data: TemplateItem) {
        const { value, fontBody, fontTitle, color1, color2, color3, color4, color5, color6, cornerStyle } = data;
        this.shopDetail.theme_settings.themeStyle = value;
        this.shopDetail.theme_settings.fontTitle = fontTitle;
        this.shopDetail.theme_settings.fontBody = fontBody;
        this.shopDetail.theme_settings.color1 = color1;
        this.shopDetail.theme_settings.color2 = color2;
        this.shopDetail.theme_settings.color3 = color3;
        this.shopDetail.theme_settings.color4 = color4;
        this.shopDetail.theme_settings.color5 = color5;
        this.shopDetail.theme_settings.color6 = color6;
        this.shopDetail.theme_settings.cornerStyle = cornerStyle;
    }

    mainColorChange(val: string) {
        const customTemplate = this.templateList.find(item => item.value === 'custom');
        customTemplate.color3 = val;
    }

    accentColorChange(val: string) {
        const customTemplate = this.templateList.find(item => item.value === 'custom');
        this.shopDetail.theme_settings.color5 = val;
        customTemplate.color5 = val;
        customTemplate.color4 = val;
    }

    cornerStyleChange(val: 'round' | 'straight') {
        const customTemplate = this.templateList.find(item => item.value === 'custom');
        customTemplate.cornerStyle = val;
        this.shopDetail.theme_settings.cornerStyle = val;
    }

    fontChange(val: string, type: 'title' | 'body') {
        const currentTemplate = this.templateList.find(item => item.value === this.shopDetail.theme_settings.themeStyle);
        if (type === 'title') {
            currentTemplate.fontTitle = val;
        }
        if (type === 'body') {
            currentTemplate.fontBody = val;
        }
    }

    previewOnlineStore() {
        if (!this.shopDetail.theme_id || !this.shopDetail.domain) {
            this.showModalService.showShopPreparationModal();
            return false;
        }

        if (this.shopDetail.domain.indexOf('http') !== 0) {
            window.open(`http://${this.shopDetail.domain}`, '_blank');
        } else {
            window.open(this.shopDetail.domain, '_blank');
        }
    }

    getDomainByUrl() {
        return this.shopDetail.domain?.split('://')[1]?.split('.')[0] || '';
    }
}
