<section class="store-settings">
    <div class="top">
        <div class="title">Store Settings</div>
        <div class="preview" (click)="previewOnlineStore()">
            <span>Preview </span>
            <i nz-icon nzType="right" nzTheme="outline"></i>
        </div>

        <div class="edit">
            <button *ngIf="pageMode === 'view'; else saveButton" nz-button nzType="primary" (click)="editContent()">Edit</button>
        </div>
        <ng-template #saveButton>
            <div class="edit">
                <button class="mr-20" nz-button nzType="default" (click)="cancelEdit()">Cancel</button>
                <button nz-button nzType="primary" (click)="saveContent()" [nzLoading]="submitting">Save</button>
            </div>
        </ng-template>
    </div>

    <div *ngIf="shopDetail">
        <div class="shop-info">
            <div class="name">Upload Store Cover</div>
            <div class="banner-img" *ngIf="pageMode === 'view'; else desktopImgEdit">
                <img *ngIf="shopDetail?.shop_image_url" [src]="shopDetail?.shop_image_url" />
                <img *ngIf="!shopDetail?.shop_image_url" src="assets/imgs/shop-default-bg.png" />
            </div>
            <ng-template #desktopImgEdit>
                <div class="image-holder" appLoading [loading]="desktopImgUploading" [maskVisible]="true">
                    <img class="image-show" [src]="shopDetail?.shop_image_url" *ngIf="isFirebasestorage(shopDetail?.shop_image_url)" />
                    <div class="add-logo" [ngClass]="{'isOpcity': isFirebasestorage(shopDetail?.shop_image_url) }">
                        <i
                            nz-icon
                            nzType="plus"
                            nzTheme="outline"
                            *ngIf="!isFirebasestorage(shopDetail?.shop_image_url) && !desktopImgUploading"
                        ></i>
                        <div class="edit-button" *ngIf="isFirebasestorage(shopDetail?.shop_image_url)">
                            <img src="assets/imgs/swap.png" />
                            <p>Edit</p>
                        </div>
                        <input
                            class="file-input"
                            type="file"
                            id="inputFile"
                            (change)="checkoutImagePixel($event.target['files'])"
                            accept=".png,.jpg"
                        />
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="shop-info">
            <div class="name">Shop Name</div>
            <div class="content" *ngIf="pageMode === 'view'; else titleEdit">{{ shopDetail.shop_name }}</div>
            <ng-template #titleEdit>
                <div class="content">
                    <nz-form-control nzErrorTip="Please enter a title">
                        <input [(ngModel)]="shopDetail.shop_name" [formControl]="nameControl" nz-input />
                    </nz-form-control>
                </div>
            </ng-template>
        </div>
        <div class="shop-info">
            <div class="name">Unique Store URL</div>
            <div class="content" *ngIf="pageMode === 'view'; else contentEdit">{{shopHost}}/<b>{{ shopDetail.shop_url }}</b></div>
            <ng-template #contentEdit>
                <div class="content">
                    <nz-form-control nzErrorTip="Please enter a title">
                        <input [(ngModel)]="shopDetail.shop_url" [formControl]="urlControl" nz-input />
                    </nz-form-control>
                </div>
            </ng-template>
        </div>

        <div class="shop-info">
            <div class="name">Enable Shopping Cart</div>
            <nz-switch [disabled]="pageMode === 'view'" [(ngModel)]="shopDetail.enable_shoping_cart"></nz-switch>
        </div>
    </div>
</section>
