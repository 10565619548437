<section class="referral-container" *ngIf="referralCampaign">
    <section *ngIf="referralData" class="referral-top">
        <img src="/assets/imgs/referral-iphone.png" alt="" class="referral-iphone-img" />
        <h4 class="text-align-center sub-title">{{referralCampaign.title}}</h4>
        <p class="text-align-center mb-30">
            {{referralCampaign.content}}
            <u
                *ngIf="referralCampaign.id"
                class="cursor-pointer"
                style="color: #f3745e; margin-left: 5px"
                (click)="eventDetailsVisible = true"
            >
                View Details
            </u>
        </p>
        <h4 class="text-align-center sub-title">Share Your Link</h4>
        <div class="d-flex justify-content-center align-items-center">
            <div class="mr-30 referral-link">{{referralData.referralLink}}</div>
            <button nz-button nzType="primary" class="fs-18 copy-link-btn" (click)="copyLink()">Copy Link</button>
        </div>
    </section>

    <section *ngIf="invitationHistory" class="invitation-history" [ngStyle]="{'margin-bottom': friendList?.length ? '0px' : '50px'}">
        <div class="text-align-center sub-title">Invitation History</div>
        <div class="d-flex justify-content-center invitation-numbers">
            <div class="text-align-center card">
                <div style="color: #22bcb9">{{invitationHistory.friendsInvited}}</div>
                Friends Invited
            </div>
            <div class="text-align-center card">
                <div style="color: #1b222e">{{invitationHistory.friendsSignedUp}}</div>
                Friends Signed Up
            </div>
            <div *ngIf="referralCampaign.id" class="text-align-center card">
                <div style="color: #f3745e">
                    {{invitationHistory.tickets + invitationHistory.listProductTickets + invitationHistory.orderTickets}}
                </div>
                Tickets
            </div>
        </div>
        <div class="invitation-accounts" [@collapse]="isHideReferralStatus" [ngStyle]="{width: referralCampaign.id ? '100%' : '700px'}">
            <nz-table #accountTable [nzData]="invitationHistory.referralInvitations" nzBordered [nzShowPagination]="false" class="mb-20">
                <thead>
                    <tr>
                        <th nzWidth="375px" style="background: #ebeaed">Account</th>
                        <th nzWidth="220px" nzAlign="center" style="background: #f8f8f9">Status</th>
                        <th *ngIf="referralCampaign.id" nzWidth="585px" nzAlign="center" style="background: rgba(243, 153, 105, 0.2)">
                            Rewards
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of accountTable.data">
                        <td style="padding: 9px 16px; background: #f8f8f9">{{data.friendEmail}}</td>
                        <td nzAlign="center" style="padding: 9px 16px; background: #fff">{{data.status}}</td>
                        <td *ngIf="referralCampaign.id" nzAlign="center" style="padding: 9px 16px; background: rgba(243, 146, 119, 0.1)">
                            <img *ngIf="data.ticketId; else notTicket" src="/assets/svg/ticket_diagonal_icon.svg" style="margin: 0 7px" />
                            <ng-template #notTicket>N/A</ng-template>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            <div *ngIf="referralCampaign.id || invitationHistory.beInvitedTickets" class="text-align-center mb-20">
                Other Tickets
                <span *ngIf="invitationHistory.beInvitedTickets" style="margin-left: 40px">
                    Create Account:
                    <img src="/assets/svg/ticket_diagonal_icon.svg" alt="" style="margin: 0 10px" />
                </span>
                <span *ngIf="referralCampaign.id" style="margin-left: 40px">
                    List Products:
                    <img
                        *ngIf="invitationHistory.listProductTickets; else notTickets"
                        src="/assets/svg/ticket_diagonal_icon.svg"
                        alt=""
                        style="margin: 0 10px"
                    />
                </span>
                <span *ngIf="referralCampaign.id" style="margin-left: 40px">
                    Orders:
                    <ng-container *ngIf="invitationHistory.orderTickets; else notTickets">
                        <img src="/assets/svg/ticket_diagonal_icon.svg" alt="" style="margin: 0 10px" />
                        <span *ngIf="invitationHistory.orderTickets > 1">x {{invitationHistory.orderTickets}}</span>
                    </ng-container>
                </span>
                <ng-template #notTickets>
                    <span style="margin: 0 10px">0</span>
                </ng-template>
            </div>
        </div>
        <button nz-button class="show-referral-btn" (click)="isHideReferralStatus = !isHideReferralStatus">
            {{isHideReferralStatus ? 'Show Referral Status' : 'Hide Referral Status'}}
        </button>
    </section>

    <section *ngIf="friendList?.length" class="friends-box">
        <div class="text-align-center sub-title">Friends You May Know</div>
        <section class="d-flex flex-wrap friends-list">
            <div *ngFor="let data of friendList" class="d-flex align-items-center friends-item">
                <label nz-checkbox [(ngModel)]="data.checked" (ngModelChange)="changeFriendChecked(data)"></label>
                <img [src]="data.picture" width="50" style="margin: 0 20px; border-radius: 50%" />
                <div>
                    <h4 class="fs-16 name">@{{data.username}}</h4>
                    <div class="fs-12 d-flex align-items-center">
                        <i nz-icon [nzType]="socialMediaType" nzTheme="outline" class="fs-16" style="color: #554764"></i>
                        <b style="margin: 0 5px">{{data.formatFollowers}}</b>followers
                    </div>
                </div>
            </div>
        </section>
        <div class="text-align-center invite-friends-btn">
            <button
                nz-button
                nzType="primary"
                [disabled]="!selectedFriendsSet.size"
                [nzLoading]="invitationSubmitting"
                class="fs-18"
                (click)="inviteEmail()"
            >
                Invite Friends
            </button>
        </div>
    </section>
</section>

<nz-modal [(nzVisible)]="eventDetailsVisible" [nzFooter]="null" [nzClosable]="false" nzWidth="600px">
    <section class="event-details" style="padding: 25px">
        <div class="d-flex align-items-center justify-content-between title">
            <label class="fs-20 fw-600">Event Details</label>
            <div class="cursor-pointer" (click)="eventDetailsVisible = false">
                <img src="assets/svg/close_icon.svg" />
            </div>
        </div>

        <div [innerHtml]="referralCampaign?.detail | safeHtml"></div>

        <div class="text-align-center" style="margin-top: 30px">
            <button nz-button nzType="primary" class="fs-18" style="width: 200px; height: 50px" (click)="eventDetailsVisible = false">
                Refer Friends
            </button>
        </div>
    </section>
</nz-modal>
