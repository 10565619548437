import { StorefrontService, StorefrontMediaEnum } from './../storefront.service';
import { StorefrontStateService, SocialMediaEnum } from './../storefront-state.service';
import { AddSocialMediaModalComponent } from './../add-social-media-modal/add-social-media-modal.component';
import { clone, cloneDeep } from 'lodash';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs/operators';
import { MessageService } from '@services/message.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.less'],
})
export class SocialMediaComponent implements OnInit, OnDestroy {
    @Input() siteView: 'mobile' | 'desktop';
    @Input() shopDetail: ShopProfile;

    platforms: SocialMediaEnum[] = [
        SocialMediaEnum.TWITTER,
        SocialMediaEnum.YOUTUBE,
        SocialMediaEnum.FACEBOOK,
        SocialMediaEnum.SNAPCHAT,
        SocialMediaEnum.INSTAGRAM,
        SocialMediaEnum.PINTEREST,
        SocialMediaEnum.SPOTIFY,
        SocialMediaEnum.TIKTOK,
        SocialMediaEnum.LINKEDIN,
        SocialMediaEnum.TUMBLR,
        SocialMediaEnum.DISCORD,
        SocialMediaEnum.PATREON,
        SocialMediaEnum.MEDIUM,
        SocialMediaEnum.APPLE_PODCASTS,
        SocialMediaEnum.TWITCH,
        SocialMediaEnum.WEBSITE,
    ];

    desktopImgUploading = false;
    mobileImgUploading = false;

    addedSocial: SocialMediaEnum[] = []; // original data

    nonAddedSocial = cloneDeep(this.platforms); // original data

    subscription = new Subscription();

    StorefrontMediaEnum = StorefrontMediaEnum;

    get originShopDetail() {
        return this.storefrontStateService.shopDetail;
    }

    get scaleRatio() {
        return 1 / this.storefrontStateService.scaleRatio || 1;
    }

    constructor(
        private modalService: NzModalService,
        private storefrontStateService: StorefrontStateService,
        private messageService: MessageService,
        private firebaseUploadService: FirebaseFileUploadService,
        public storefrontService: StorefrontService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.storefrontStateService.shopDetail$
                .pipe(filter(item => !!item))
                .subscribe(shopDetail => this.setDataByShopDetail(shopDetail))
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setDataByShopDetail(shopDetail: ShopProfile) {
        this.addedSocial.length = 0;
        this.nonAddedSocial = clone(this.platforms);

        this.platforms.forEach(platform => {
            if ((shopDetail.theme_settings.socialMedia || {})[platform]) {
                this.addPlatform(platform, this.addedSocial, this.nonAddedSocial);
            }
        });
    }

    getNewSocialIcon(platform: SocialMediaEnum) {
        return `antd:social_media_${platform}`;
    }

    removeSocialMedia(platform: SocialMediaEnum) {
        this.shopDetail.theme_settings.socialMedia[platform] = '';
        this.addedSocial = this.addedSocial.filter(item => item !== platform);
        this.nonAddedSocial.push(platform);
    }

    addPlatform(platform: SocialMediaEnum, addedArr = this.addedSocial, nonAddedArr = this.nonAddedSocial) {
        const index = nonAddedArr.indexOf(platform);
        if (index !== -1) {
            nonAddedArr.splice(index, 1);
        }
        addedArr.push(platform);
    }

    showAddSocialModal() {
        this.modalService.create({
            nzContent: AddSocialMediaModalComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWidth: '630px',
            nzComponentParams: {
                shopDetail: this.shopDetail,
                addedSocial: this.addedSocial,
                platforms: this.platforms,
                nonAddedSocial: this.nonAddedSocial,
            },
            nzOnOk: comp => this.addPlatform(comp.selectedSocial),
        });
    }

    uploadDesktop = file => {
        this.checkoutImagePixel(file, 'desktopImg');
        return false;
    };

    uploadMobile = file => {
        this.checkoutImagePixel(file, 'mobileImg');
        return false;
    };

    checkoutImagePixel(file, imgStat: 'desktopImg' | 'mobileImg') {
        // Image Uploading
        let key: string;

        if (imgStat === 'desktopImg') {
            key = 'desktopImgUploading';
        }
        if (imgStat === 'mobileImg') {
            key = 'mobileImgUploading';
        }

        this[key] = true;

        this.onDrop(file, imgStat)
            .catch(() => this.messageService.error())
            .finally(() => (this[key] = false));

        return false;
    }

    onDrop(file: any, imgStat: 'desktopImg' | 'mobileImg') {
        const { uploadTask } = this.firebaseUploadService.startUpload(file);
        return uploadTask.then(downloadURL => {
            if (imgStat === 'desktopImg') {
                this.shopDetail.theme_settings.socialMediaSection.desktopBackgroundImage = downloadURL;
            }
            if (imgStat === 'mobileImg') {
                this.shopDetail.theme_settings.socialMediaSection.mobileBackgroundImage = downloadURL;
            }
        });
    }

    removeMedia(type: 'desktopImg' | 'mobileImg') {
        if (type === 'desktopImg') {
            // eslint-disable-next-line max-len
            this.shopDetail.theme_settings.socialMediaSection.desktopBackgroundImage = this.storefrontService.defaultThemeSettings.socialMediaSection.desktopBackgroundImage;
        }

        if (type === 'mobileImg') {
            // eslint-disable-next-line max-len
            this.shopDetail.theme_settings.socialMediaSection.mobileBackgroundImage = this.storefrontService.defaultThemeSettings.socialMediaSection.mobileBackgroundImage;
        }
    }
}
