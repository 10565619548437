import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { CARD_LIST, SubscribePlan, SubscribePlanNameEnum } from '../subscription.data';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-subscribe-plan-cards',
    templateUrl: './subscribe-plan-cards.component.html',
    styleUrls: ['./subscribe-plan-cards.component.less'],
})
export class SubscribePlanCardsComponent implements OnInit {
    @Input() enableChoose = true;
    @Input() selectedPlan: SubscribePlan;
    @Input() type: string;
    @Output() choosePlan = new EventEmitter<SubscribePlan>();

    cardList = CARD_LIST;

    shownCard: 'subscription' | 'payment' = 'subscription';

    recommendPlan: SubscribePlan = this.cardList[3];

    get renewDate() {
        return dayjs(this.shopProfile?.subscription.sub_end_date).add(1, 'day').format('MMM D, YYYY');
    }

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get isCurrentPlanSameWithNextPlan() {
        const nextPlan = this.shopProfile?.subscription.next_sub_plan_id;
        const currentPlan = this.shopProfile?.subscription.sub_plan_id;
        return !nextPlan || currentPlan === nextPlan;
    }

    get isFreePlan() {
        return this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER;
    }

    get isBillingFailed() {
        return this.shopProfile?.subscription.billing_status === 'failed';
    }

    constructor(private shopService: ShopService) {}

    ngOnInit(): void {}

    getIntegerOfNumber(num: number) {
        return Math.floor(num);
    }

    getDecimalOfNumber(num: number) {
        return Math.round((num % 1) * 100);
    }

    chooseCardItem(data: SubscribePlan) {
        this.choosePlan.emit(data);
    }

    isPrevPlan(data: SubscribePlan) {
        return data.plan_text === this.shopProfile?.subscription.prev_sub_plan_id;
    }

    isNextPlan(data: SubscribePlan) {
        const nextPlan = this.shopProfile?.subscription.next_sub_plan_id || this.shopProfile?.subscription_plan;
        return nextPlan === data.plan_text;
    }

    isCurrentPlan(data: SubscribePlan) {
        return data.plan_text === this.shopProfile?.subscription_plan;
    }

    isUsedFreeTrial(data: SubscribePlan) {
        return (
            data.plan_text !== SubscribePlanNameEnum.ADVANCED ||
            this.shopProfile?.subscription?.prev_sub_plan_id ||
            this.shopProfile?.subscription?.trial_end_date ||
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.PREMIUM
        );
    }

    isFreeTrialPlan(data: SubscribePlan) {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.ADVANCED &&
            data.plan_text === SubscribePlanNameEnum.ADVANCED &&
            this.shopProfile?.subscription.trial_end_date &&
            dayjs(this.shopProfile?.subscription.trial_end_date).valueOf() >= dayjs().valueOf()
        );
    }

    isAdvancedPlan(data: SubscribePlan) {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.ADVANCED &&
            data.plan_text === SubscribePlanNameEnum.ADVANCED &&
            (!this.shopProfile?.subscription?.trial_end_date ||
                dayjs(this.shopProfile?.subscription?.trial_end_date).valueOf() < dayjs().valueOf())
        );
    }
}
