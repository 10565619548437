<section class="balance-history-list-box">
    <ng-container *ngIf="pendingTransactionList?.length > 0">
        <section class="title">Pending</section>
        <section class="table-content table-pending">
            <app-balance-history-table
                times="Created Time"
                timeField="created_at"
                [emptyTableDesc]="'No pending activities'"
                [transactionList]="pendingTransactionList"
                [loading]="loadingData"
                #pendingTable
            ></app-balance-history-table>
        </section>
    </ng-container>
    <section class="title">Recent Activities</section>
    <section class="table-content table-recent">
        <app-balance-history-table
            times="Time"
            timeField="transaction_time"
            [emptyTableDesc]="'No recent activities'"
            [transactionList]="recentTransactionList"
            [loading]="loadingData"
            #recentTable
        >
        </app-balance-history-table>
    </section>
</section>
