<section class="terms-of-use">
    <header>
        <img src="assets/svg/logo.svg" />
        <span><strong>Lifo</strong> Creator Hub</span>
    </header>
    <nz-tabset class="product-list-tabs" nzType="card" [nzTabBarGutter]="12" nzLinkRouter [(nzSelectedIndex)]="index">
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/privacy-policy']"> Privacy Policy </a>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-of-use']"> Terms-of-Use </a>
            <section class="container">
                <h1>Terms of Use</h1>

                <section class="block">
                    <p>Last updated: August 10, 2021</p>
                    <br />

                    <p>
                        Welcome to Lifo. Please read on to learn the rules and restrictions that govern your use of our website(s),
                        products, services and applications (the “Services”), including, without limitation, any request to receive
                        information about, or to purchase any of the products made available through our website(s) (each, a “Product”).
                        Your purchase of any Products will also be governed by any other terms made available by us to you during the sales
                        process. If you have any questions, comments, or concerns regarding these terms or the Services, please contact us
                        at:
                    </p>

                    <ul>
                        <li>Email: <a>info@lifo.ai</a></li>
                        <li>Phone: 833-800-LIFO</li>
                        <li>Address: 35640 Fremont Boulevard, #607, Fremont, CA, 94536</li>
                    </ul>

                    <p>
                        These Terms of Use (the “Terms”) are a binding contract between you and LIFO INC. (“Lifo,” “we” and “us”). Your use
                        of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while
                        you use the Services. These Terms include the provisions in this document as well as those in the
                        <a>Privacy Policy</a> and any other accompanying terms and conditions of sale entered into between you and us for
                        the sale of any Products.
                        <strong
                            >Your use of or participation in certain Services may also be subject to additional policies, rules and/or
                            conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that
                            by using or participating in any such Services, you agree to also comply with these Additional Terms.
                        </strong>
                    </p>
                </section>

                <section class="mt-20 block">
                    <p>
                        <strong>Please read these Terms carefully.</strong> They cover important information about Services provided to you.
                        <strong>These Terms include information</strong> <a>about future changes to these Terms</a>,
                        <a>limitations of liability</a>,
                        <a>a class action waiver and resolution of disputes by arbitration instead of in court</a>.
                        <strong
                            >PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE
                            TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</strong
                        >
                    </p>

                    <p>
                        <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE
                        <a>ARBITRATION AGREEMENT SECTION BELOW</a>, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
                        INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
                    </p>
                </section>

                <section class="block">
                    <h2>Will these Terms ever change?</h2>
                    <p>
                        We are constantly trying to improve our Products and Services, so these Terms may need to change along with our
                        Products and Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on
                        our site located at
                        <a href="https://creator.lifo.ai/terms-of-use" target="_blank">https://creator.lifo.ai/terms-of-use</a>, send you an
                        email, and/or notify you by some other means.
                    </p>
                    <p>
                        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be
                        able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means
                        you agree to all of the changes.
                    </p>
                    <p>
                        Except for changes by us as described here, no other amendment or modification of these Terms will be effective
                        unless in writing and signed by both you and us.
                    </p>
                </section>

                <section class="block">
                    <h2>What about my privacy?</h2>
                    <p>
                        Lifo takes the privacy of its users very seriously. For the current Lifo Privacy Policy, please click
                        <a [routerLink]="['/privacy-policy']">here</a>.
                    </p>
                    <h3>Children’s Online Privacy Protection Act</h3>
                    <p>
                        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
                        consent before they knowingly collect personally identifiable information online from children who are under
                        thirteen (13). We do not knowingly collect or solicit personally identifiable information from children under
                        thirteen (13); if you are a child under thirteen (13), please do not attempt to register for or otherwise use the
                        Services or send us any personal information. If we learn we have collected personal information from a child under
                        thirteen (13), we will delete that information as quickly as possible. If you believe that a child under thirteen
                        (13) may have provided us personal information, please contact us at <a>info@lifo.ai</a>.
                    </p>
                </section>

                <section class="block">
                    <h2>What are the basics of using Lifo?</h2>
                    <p>
                        You may be required to sign up for an account, select a password and user name (“Lifo User ID”), and provide us with
                        certain information or data, such as your contact information. You promise to provide us with accurate, complete,
                        and updated registration information about yourself. You may not select as your Lifo User ID a name that you do not
                        have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer
                        your account to anyone else without our prior written permission.
                    </p>
                    <p>
                        Additionally, you may be able to access certain parts or features of the Services by using your account credentials
                        from other services (each, a “Third Party Account”), such as those offered by Google and Facebook. By using the
                        Services through a Third Party Account, you permit us to access certain information from such account for use by the
                        Services. You are ultimately in control of how much information is accessible to us and may exercise such control by
                        adjusting your privacy settings on your Third Party Account.
                    </p>
                    <p>
                        You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve
                        received your parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree
                        to these Terms on your behalf). If you’re agreeing to these Terms on behalf of an organization or entity, you
                        represent and warrant that you are authorized to agree to these Terms on that organization’s or entity’s behalf and
                        bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this
                        sentence, refer to that organization or entity).
                    </p>
                    <p>
                        All orders of our Products must be for personal use only. By purchasing our Products, you hereby agree not to resell
                        or distribute such products for any commercial purposes. If we have reason to believe that your order is not for
                        personal use, we reserve the right to reject or cancel any order that you place. You will only use the Services and
                        Products you obtain for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of
                        any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services or
                        Products is prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be
                        responsible for your using the Services or Products in a way that breaks the law.
                    </p>
                    <p>
                        You will not share your Lifo User ID, account or password with anyone, and you must protect the security of your
                        Lifo User ID, account, password and any other access tools or credentials. You’re responsible for any activity
                        associated with your Lifo User ID and account.
                    </p>
                </section>

                <section class="block">
                    <h2>What about messaging?</h2>
                    <p>
                        As part of the Services, you may receive communications through the Services, including messages that Lifo sends you
                        (for example, via email). When signing up for the Services, you will receive a welcome message and instructions on
                        how to stop receiving messages.
                    </p>
                </section>

                <section class="block">
                    <h2>Are there restrictions in how I can use the Services?</h2>
                    <p>
                        You represent, warrant, and agree that you will not provide or contribute anything, including any Content or User
                        Submission (as those terms are defined below), to the Services, or otherwise use or interact with the Services, in a
                        manner that:
                    </p>
                    <dl>
                        <dd>
                            (a) infringes or violates the intellectual property rights or any other rights of anyone else (including Lifo);
                        </dd>
                        <dd>
                            (b) violates any law or regulation, including, without limitation, any applicable export control laws, privacy
                            laws or any other purpose not reasonably intended by Lifo;
                        </dd>
                        <dd>
                            (c) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
                            objectionable;
                        </dd>
                        <dd>
                            (d) jeopardizes the security of your Lifo User ID, account or anyone else’s (such as allowing someone else to
                            log in to the Services as you);
                        </dd>
                        <dd>
                            (e) attempts, in any manner, to obtain the password, account, or other security information from any other user;
                        </dd>
                        <dd>(f) violates the security of any computer network, or cracks any passwords or security encryption codes;</dd>
                        <dd>
                            (g) runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or
                            are activated while you are not logged into the Services, or that otherwise interfere with the proper working of
                            the Services (including by placing an unreasonable load on the Services’ infrastructure);
                        </dd>
                        <dd>
                            (h) “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content
                            (through use of manual or automated means);
                        </dd>
                        <dd>(i) copies or stores any significant portion of the Content; or</dd>
                        <dd>
                            (j) decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
                            information of or relating to the Products or Services.
                        </dd>
                    </dl>
                    <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
                </section>

                <section class="block">
                    <h2>What are my rights in the Services?</h2>
                    <p>
                        The materials displayed or performed or available on or through the Services, including, but not limited to, text,
                        graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of
                        the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to
                        abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access
                        through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit,
                        distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content
                        not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone
                        else’s (including Lifo's) rights.
                    </p>
                    <p>
                        Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and
                        non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the
                        Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using
                        the Services is expressly prohibited without prior written permission from us. You understand that Lifo owns the
                        Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as
                        expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The
                        Services may allow you to copy or download certain Content, but please remember that even where these
                        functionalities exist, all the restrictions in this section still apply.
                    </p>
                </section>

                <section class="block">
                    <h2>What about anything I contribute to the Services – do I have to grant any licenses to Lifo or to other users?</h2>
                    <h3>User Submissions</h3>
                    <p>
                        Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission”. Some
                        User Submissions may be viewable by other users. You are solely responsible for all User Submissions you contribute
                        to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in
                        compliance with all applicable laws, rules and regulations.
                    </p>
                    <p>
                        You agree that you will not post, upload, share, store, or otherwise provide through the Services any User
                        Submissions that: (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.);
                        (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content
                        or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence;
                        (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level
                        marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home
                        businesses, or any other dubious money-making ventures; or (viii) that violate any law.
                    </p>
                    <h3>Licenses</h3>
                    <p>
                        In order to display your User Submissions on the Services, and to allow other users to enjoy them (where
                        applicable), you grant us certain rights in those User Submissions (see below for more information). Please note
                        that all of the following licenses are subject to our Privacy Policy to the extent they relate to User Submissions
                        that are also your personally-identifiable information.
                    </p>
                    <p>
                        By submitting User Submissions through the Services, you hereby do and shall grant Lifo a worldwide, non-exclusive,
                        perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate,
                        aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the
                        User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses,
                        including without limitation for promoting and redistributing part or all of this site or the Services (and
                        derivative works thereof) in any media formats and through any media channels (including, without limitation, third
                        party websites and feeds), and including after your termination of your account or the Services. You also hereby do
                        and shall grant each user of this site and/or the Services a non-exclusive, perpetual license to access your User
                        Submissions through this site and/or the Services, and to use, edit, modify, reproduce, distribute, prepare
                        derivative works of, display and perform such User Submissions, including after your termination of your account or
                        the Services. For clarity, the foregoing license grants to us and our users do not affect your other ownership or
                        license rights in your User Submissions, including the right to grant additional licenses to your User Submissions,
                        unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us
                        without infringement or violation of any third party rights, including without limitation, any privacy rights,
                        publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.
                    </p>
                    <p>
                        Finally, you understand and agree that Lifo, in performing the required technical steps to provide the Services to
                        our users (including you), may need to make changes to your User Submissions to conform and adapt those User
                        Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing
                        licenses include the rights to do so.
                    </p>
                </section>

                <section class="block">
                    <h2>What if I see something on the Services that infringes my copyright?</h2>
                    <p>
                        In accordance with the DMCA, we’ve adopted the following policy toward copyright infringement. We reserve the right
                        to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been
                        illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2)
                        remove and discontinue service to repeat offenders.
                    </p>
                    <ul>
                        <li>
                            <strong>Procedure for Reporting Copyright Infringements</strong>. If you believe that material or content
                            residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you
                            are authorized to act on behalf of), please send a notice of copyright infringement containing the following
                            information to Lifo's Designated Agent to Receive Notification of Claimed Infringement (our “Designated Agent,”
                            whose contact details are listed below):
                            <dl>
                                <dd>
                                    (a) A physical or electronic signature of a person authorized to act on behalf of the owner of the
                                    copyright that has been allegedly infringed;
                                </dd>
                                <dd>(b) Identification of works or materials being infringed;</dd>
                                <dd>
                                    (c) Identification of the material that is claimed to be infringing including information regarding the
                                    location of the infringing materials that the copyright owner seeks to have removed, with sufficient
                                    detail so that Company is capable of finding and verifying its existence;
                                </dd>
                                <dd>
                                    (d) Contact information about the notifier including address, telephone number and, if available, email
                                    address;
                                </dd>
                                <dd>
                                    (e) A statement that the notifier has a good faith belief that the material identified in (1)(c) is not
                                    authorized by the copyright owner, its agent, or the law; and
                                </dd>
                                <dd>
                                    (f) A statement made under penalty of perjury that the information provided is accurate and the
                                    notifying party is authorized to make the complaint on behalf of the copyright owner.
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <strong>Once Proper Bona Fide Infringement Notification is Received by the Designated Agent</strong>. Upon
                            receipt of a proper notice of copyright infringement, we reserve the right to:
                            <dl>
                                <dd>(a) remove or disable access to the infringing material;</dd>
                                <dd>
                                    (b) notify the content provider who is accused of infringement that we have removed or disabled access
                                    to the applicable material; and
                                </dd>
                                <dd>(c) terminate such content provider's access to the Services if he or she is a repeat offender.</dd>
                            </dl>
                        </li>
                        <li>
                            <strong>Procedure to Supply a Counter-Notice to the Designated Agent</strong>. If the content provider believes
                            that the material that was removed (or to which access was disabled) is not infringing, or the content provider
                            believes that it has the right to post and use such material from the copyright owner, the copyright owner's
                            agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following
                            information to the Designated Agent:
                            <dl>
                                <dd>(a) A physical or electronic signature of the content provider;</dd>
                                <dd>
                                    (b) Identification of the material that has been removed or to which access has been disabled and the
                                    location at which the material appeared before it was removed or disabled;
                                </dd>
                                <dd>
                                    (c) A statement that the content provider has a good faith belief that the material was removed or
                                    disabled as a result of mistake or misidentification of the material; and
                                </dd>
                                <dd>
                                    (d) Content provider's name, address, telephone number, and, if available, email address, and a
                                    statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial
                                    district in which the content provider’s address is located, or, if the content provider's address is
                                    located outside the United States, for any judicial district in which Company is located, and that such
                                    person or entity will accept service of process from the person who provided notification of the alleged
                                    infringement.
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    <p>
                        If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the
                        counter-notice to the original complaining party informing that person that Company may replace the removed material
                        or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against
                        the content provider accused of committing infringement, the removed material may be replaced or access to it
                        restored in 10 to 14 business days or more after receipt of the counter-notice, at Company's discretion.<br />Please
                        contact Lifo's Designated Agent at the following address:
                    </p>
                    <dl>
                        <dd>Lifo inc.</dd>
                        <dd>Attn: DMCA Designated Agent</dd>
                        <dd>Address: 35640 Fremont Boulevard, #607, Fremont, CA, 94536</dd>
                    </dl>
                </section>

                <section class="block">
                    <h2>Who is responsible for what I see and do on the Services?</h2>
                    <p>
                        Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility
                        of the person from whom such Content originated, and you access all such information and Content at your own risk,
                        and we aren’t liable for any errors or omissions in that information or Content or for any damages or loss you might
                        suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret
                        and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby
                        release us from all liability for you having acquired or not acquired Content through the Services. We can’t
                        guarantee the identity of any users with whom you interact in using the Services and are not responsible for which
                        users gain access to the Services.
                    </p>
                    <p>
                        You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant
                        you have all rights necessary to do so, in the manner in which you contribute it.
                    </p>
                    <p>
                        The Services may contain links or connections to third-party websites or services that are not owned or controlled
                        by Lifo. When you access third-party websites or use third-party services, you accept that there are risks in doing
                        so, and that Lifo is not responsible for such risks.
                    </p>
                    <p>
                        Lifo has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices
                        of or opinions expressed in any third-party websites or by any third party that you interact with through the
                        Services. In addition, Lifo will not and cannot monitor, verify, censor or edit the content of any third-party site
                        or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and
                        privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release
                        and hold us harmless from any and all liability arising from your use of any third-party website or service.
                    </p>
                    <p>
                        Your interactions with organizations and/or individuals found on or through the Services, including payment and
                        delivery of goods or services, and any other terms, conditions, warranties or representations associated with such
                        dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation
                        you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third
                        parties. You agree that Lifo shall not be responsible or liable for any loss or damage of any sort incurred as the
                        result of any such dealings.
                    </p>
                    <p>
                        If there is a dispute between participants on this site or Services, or between users and any third party, you agree
                        that Lifo is under no obligation to become involved. In the event that you have a dispute with one or more other
                        users, you release Lifo, its directors, officers, employees, agents, and successors from claims, demands, and
                        damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out
                        of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code
                        Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to
                        claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of
                        executing the release and that, if known by him or her, would have materially affected his or her settlement with
                        the debtor or released party.”
                    </p>
                </section>

                <section class="block">
                    <h2>Will Lifo ever change the Services?</h2>
                    <p>
                        We’re always trying to improve our Products and Services, so they may change over time. We may suspend or
                        discontinue any part of the Services, or we may introduce new features or impose limits on certain features or
                        restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the
                        Services that would adversely affect you, but this isn’t always practical. We reserve the right to remove any
                        Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you
                        contributed that Content in violation of these Terms), in our sole discretion, and without notice.
                    </p>
                </section>

                <section class="block">
                    <h2>What are the terms of purchasing products via the Services?</h2>
                    <p>
                        Products are offered at the prices set forth on our display page, and you may choose to purchase Products through
                        the Services. Lifo may limit or cancel quantities of Products purchased, and it reserves the right to refuse any
                        order. In the event Lifo needs to make a change to an order, it will attempt to notify you by contacting the email
                        address, billing address, and/or phone number provided at the time the order was made. The prices displayed are
                        quoted in U.S. currency and are valid only in the United States. Prices are subject to change at any time. Sales tax
                        will be determined by the shipping address of the order and will automatically be added to the order. Lifo is
                        required by law to apply sales tax to orders to certain states. Purchased Products will be shipped to the shipping
                        address of the order. Information about our shipping rates, return policy [and limited warranty] can all be found on
                        the website where you purchased the products. Any payment terms presented to you in the process of purchasing
                        Products are deemed part of these Terms.
                    </p>
                    <p>
                        [Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all
                        such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine
                        what rates, charges, fees or costs may apply to your use of the Services.]
                    </p>
                    <ul>
                        <li class="mt-20">
                            <strong>Billing</strong>. We may use a third-party payment processor (the “Payment Processor”) to bill you
                            through a payment account linked to your account on the Services (your “Billing Account”) for use of the Paid
                            Services. The processing of payments will be subject to the terms, conditions and privacy policies of the
                            Payment Processor in addition to these Terms. Currently, we use Shopify, Inc. as our Payment Processor. You can
                            access Shopify’s Terms of Service at
                            <a href="https://www.shopify.com/legal/terms" target="_blank">https://www.shopify.com/legal/terms</a> and their
                            Privacy Policy at
                            <a href="https://www.shopify.com/legal/privacy" target="_blank">https://www.shopify.com/legal/privacy</a>. We
                            are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to
                            purchase Products, you agree to pay us, through the Payment Processor, all charges at the prices then in effect
                            for any use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through
                            the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment
                            using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment
                            Processor makes even if it has already requested or received payment. Please note that information submitted by
                            you will be transmitted to and shared with third parties that may be located in other countries, in order to
                            provide services to you, including but not limited to transaction processing and fraud prevention.
                        </li>
                        <li class="mt-20">
                            <strong>Payment Method</strong>. The terms of your payment will be based on your Payment Method and may be
                            determined by agreements between you and the financial institution, credit card issuer or other provider of your
                            chosen Payment Method. If we, through the Payment Processors, do not receive payment from you, you agree to pay
                            all amounts due on your Billing Account upon demand.
                        </li>
                        <li class="mt-20">
                            <strong>Current Information Required</strong>. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR
                            YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE
                            AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU
                            MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR
                            IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER
                            NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE IN YOUR CUSTOMER PORTAL. IF YOU FAIL TO PROVIDE ANY OF
                            THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SUBSCRIPTIONS UNDER
                            YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SUBSCRIPTIONS AS SET FORTH ABOVE.
                        </li>
                        <li class="mt-20">
                            <strong>Change in Amount Authorized</strong>. If the amount to be charged to your Billing Account varies from
                            the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes),
                            you have the right to receive, and we shall provide, notice of the amount to be charged and the date of the
                            charge before the scheduled date of the transaction. Any agreement you have with your payment provider will
                            govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one
                            or more aggregate charges, including during or at the end of each billing cycle.
                        </li>
                    </ul>
                </section>

                <section class="block">
                    <h2>What if I want to stop using the Services?</h2>
                    <p>
                        You’re free to do that at any time by contacting us at <a>info@lifo.ai</a>; please refer to our
                        <a class="text-underline">Privacy Policy</a>, as well as the licenses above, to understand how we treat information
                        you provide to us after you have stopped using our Services.
                    </p>
                    <p>
                        Lifo is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our
                        discretion, including your breach of these Terms. Lifo has the sole right to decide whether you are in violation of
                        any of the restrictions set forth in these Terms.
                    </p>
                    <p>
                        Account termination may result in destruction of any Content associated with your account, so keep that in mind
                        before you decide to terminate your account.
                    </p>
                    <p>
                        If you have deleted your account by mistake, contact us immediately at <a>info@lifo.ai</a> – we will try to help,
                        but unfortunately, we can’t promise that we can recover or restore anything.
                    </p>
                    <p>
                        Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of
                        example, all of the following will survive termination: any obligation you have to indemnify us, any limitations on
                        our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between
                        us, including without limitation the arbitration agreement.
                    </p>
                </section>

                <section class="block">
                    <h2>What else do I need to know?</h2>
                    <p>
                        <strong>Limited Warranty</strong>. Any Product purchased from Lifo will be free of defects in materials and
                        workmanship for a period of [thirty (30) days] from the date of sale (“Warranty Period”). If the Product fails to
                        conform to this Limited Warranty during the Warranty Period, Lifo will, at its sole discretion, either (a) repair or
                        replace any defective Product or component; or (b) accept the return of the Product and refund the money actually
                        paid by the original purchaser for the Product. Repair or replacement may be made with a new or refurbished product
                        or components, at Lifo's sole discretion. If the Product or a component incorporated within it is no longer
                        available, Lifo may, at Lifo's sole discretion, replace the Product with a similar product of similar function. This
                        is your sole and exclusive remedy for breach of this Limited Warranty. Any Product that has either been repaired or
                        replaced under this Limited Warranty will be covered by the terms of this Limited Warranty for the longer of (a)
                        thirty (30) days from the date of delivery of the repaired Product or replacement Product, or (b) the remaining
                        Warranty Period. Before making a claim under this Limited Warranty, the owner of the Product must (a) notify Lifo of
                        the intention to make a claim by contacting us at <a>info@lifo.ai</a> during the Warranty Period and providing a
                        description of the alleged failure, and (b) comply with Lifo's return shipping instructions. Lifo will have no
                        warranty obligations with respect to a returned Product if it determines, in its reasonable discretion after
                        examination of the returned Product, that the Product is an Ineligible Product (defined below). This Limited
                        Warranty does not cover the following (collectively “Ineligible Products”): Products that have been subject to: (a)
                        modifications, alterations, tampering, or improper maintenance or repairs; (b) handling, storage, installation,
                        testing, or use not in accordance with the Terms of Use or other instructions provided by Lifo; (c) abuse or misuse
                        of the Product; (d) events outside of Lifo's control, including breakdowns, fluctuations, or interruptions in
                        electric power or the telecommunications network; or (e) Acts of God, including but not limited to lightning, flood,
                        tornado, earthquake, or hurricane. This Limited Warranty does not apply to anything other than the Products,
                        including the Services, even if packaged or sold with the Products. Lifo does not warrant that the operation of the
                        product will be uninterrupted or error-free. This Limited Warranty gives you specific legal rights. You may also
                        have other legal rights that vary by state, province, or jurisdiction. Likewise, some of the limitations in this
                        Limited Warranty may not apply in certain states. The terms of this Limited Warranty will apply to the extent
                        permitted by applicable law. For a full description of your legal rights you should refer to the laws applicable in
                        your jurisdiction and you may wish to contact a relevant consumer advisory service.]
                    </p>
                    <p>
                        <strong>Limitation of Liability</strong>. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES
                        AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY
                        OF THE LIFO PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
                        CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
                        GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES
                        OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND
                        OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR
                        CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                    </p>
                    <p>
                        <strong>Indemnity</strong>. You agree to indemnify and hold the Lifo Parties harmless from and against any and all
                        claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising
                        from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a
                        third party using your account)and any Products, and (b) your violation of these Terms. In the event of such a
                        claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have
                        for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification
                        obligations hereunder).
                    </p>
                    <p>
                        <strong>Assignment</strong>. You may not assign, delegate or transfer these Terms or your rights or obligations
                        hereunder, or your Services account, in any way (by operation of law or otherwise) without Lifo's prior written
                        consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
                    </p>
                    <p>
                        <strong>Choice of Law</strong>. These Terms are governed by and will be construed under the Federal Arbitration Act,
                        applicable federal law, and the laws of the State of California, without regard to the conflicts of laws provisions
                        thereof.
                    </p>
                    <p>
                        <strong>Arbitration Agreement</strong>. Please read the following ARBITRATION AGREEMENT carefully because it
                        requires you to arbitrate certain disputes and claims with Lifo and limits the manner in which you can seek relief
                        from Lifo. Both you and Lifo acknowledge and agree that for the purposes of any dispute arising out of or relating
                        to the subject matter of these Terms, Lifo's officers, directors, employees and independent contractors
                        (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel
                        will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the
                        third-party beneficiary hereof.
                    </p>
                    <dl>
                        <dd>
                            (a) Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to
                            settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these
                            Terms directly through good-faith negotiations, which shall be a precondition to either party initiating
                            arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration
                            in San Francisco County, California. The arbitration will proceed in the English language, in accordance with
                            the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator
                            with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator
                            shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the
                            award rendered by such arbitrator may be entered in any court of competent jurisdiction.
                        </dd>
                        <dd>
                            (b) Costs of Arbitration. The Rules will govern payment of all arbitration fees. Lifo will pay all arbitration
                            fees for claims less than seventy-five thousand ($75,000) dollars. Lifo will not seek its attorneys’ fees and
                            costs in arbitration unless the arbitrator determines that your claim is frivolous.
                        </dd>
                        <dd>
                            (c) Small Claims Court; Infringement. Either you or Lifo may assert claims, if they qualify, in small claims
                            court in San Francisco County, California or any United States county where you live or work. Furthermore,
                            notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue
                            injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the
                            actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade
                            secrets, patents or other intellectual property rights.
                        </dd>
                        <dd>
                            (d) Waiver of Jury Trial. YOU AND LIFO WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
                            TRIAL IN FRONT OF A JUDGE OR JURY. You and Lifo are instead choosing to have claims and disputes resolved by
                            arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules
                            applicable in court and are subject to very limited review by a court. In any litigation between you and Lifo
                            over whether to vacate or enforce an arbitration award, YOU AND LIFO WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
                            instead to have the dispute be resolved by a judge.
                        </dd>
                        <dd>
                            (e) Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN
                            ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER
                            OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither
                            you nor Lifo is entitled to arbitration; instead all claims and disputes will be resolved in a court as set
                            forth in (g) below.
                        </dd>
                        <dd>
                            (f) Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your
                            decision to opt out to the following address: 35640 Fremont Boulevard, #607, Fremont, CA, 94536 postmarked
                            within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address,
                            (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that
                            you want to opt out of these Terms’ arbitration agreement.
                        </dd>
                        <dd>
                            (g) Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing
                            arbitration agreement permits either you or Lifo to litigate any dispute arising out of or relating to the
                            subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party,
                            and both you and Lifo agree that any judicial proceeding (other than small claims actions) will be brought in
                            the state or federal courts located in, respectively, San Francisco County, California, or the federal district
                            in which that county falls.
                        </dd>
                        <dd>
                            (h) Severability. If the prohibition against class actions and other claims brought on behalf of third parties
                            contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement
                            section will be null and void. This arbitration agreement will survive the termination of your relationship with
                            Lifo.
                        </dd>
                    </dl>
                    <p>
                        <strong>Miscellaneous</strong>. You will be responsible for paying, withholding, filing, and reporting all taxes,
                        duties, and other governmental assessments associated with your activity in connection with the Services, provided
                        that the Lifo may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The
                        failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further
                        rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be
                        limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and
                        effect and enforceable. You and Lifo agree that these Terms are the complete and exclusive statement of the mutual
                        understanding between you and Lifo, and that these Terms supersede and cancel all previous written and oral
                        agreements, communications and other understandings relating to the subject matter of these Terms. You hereby
                        acknowledge and agree that you are not an employee, agent, partner, or joint venture of Lifo, and you do not have
                        any authority of any kind to bind Lifo in any respect whatsoever.
                    </p>
                    <p>
                        Except as expressly set forth in the section above regarding the arbitration agreement, you and Lifo agree there are
                        no third-party beneficiaries intended under these Terms.
                    </p>
                </section>
            </section>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-and-conditions']"> Terms and Conditions </a>
        </nz-tab>
    </nz-tabset>
    <section class="footer">v2.4 Ⓒ2022 by Lifo.ai</section>
</section>
