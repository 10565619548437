<div appLoading [loading]="!shopProfile" [maskVisible]="true" class="big-box">
    <div class="content">
        <div class="content-box relative">
            <h1 class="welcome">Hi, {{shopProfile?.shop_owner}}</h1>

            <p>Welcome to Lifo. Let us help you to easily build up and run your social commerce business.</p>

            <div class="text-align-center">
                <img src="assets/imgs/sign-in-condition.png" style="width: 280px; margin: 20px auto" />
            </div>

            <div style="color: #1b222e">
                <div>
                    <label nz-checkbox [(ngModel)]="checkingStatus">
                        <span class="fs-14" style="padding-left: 15px">Checking the box means:</span>
                    </label>

                    <ul class="mt-10" style="padding-left: 55px">
                        <li>
                            I have read and agree to the
                            <span class="agree-link" (click)="goTermsCondition()"> Terms and Conditions </span>.
                        </li>
                        <li *ngIf="shopProfile?.shop_detail?.service_fee_rate > 0 && shopProfile?.shop_detail?.is_ambassador">
                            I agree that I will be paid {{shopProfile?.shop_detail?.service_fee_rate * 100 || '15'}}% commission of net
                            sales for all products/services sold and fulfilled by Lifo.
                        </li>
                        <li *ngIf="shopProfile?.shop_detail?.service_fee_rate > 0 && !shopProfile?.shop_detail?.is_ambassador">
                            I agree to pay {{shopProfile?.shop_detail?.service_fee_rate * 100 || '20'}}% net sale commission to Lifo.
                        </li>
                    </ul>
                </div>
            </div>

            <div class="text-align-center">
                <button
                    class="mt-20"
                    nz-button
                    nzType="primary"
                    style="width: 300px"
                    nzSize="large"
                    [disabled]="!checkingStatus"
                    [nzLoading]="submitting"
                    (click)="confirm()"
                >
                    Confirm
                </button>
            </div>

            <span class="logout" (click)="logout()">Log Out</span>
        </div>
    </div>

    <div class="footer">
        <span>Social Commerce for Creators</span>
    </div>
</div>
