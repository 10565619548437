import { Component, OnInit } from '@angular/core';
import { copy } from '@src/app/shared/utils/operations';
import { environment } from '@src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ShopifyService } from './shopify.service';

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.less'],
})
export class ShopComponent implements OnInit {
    shopDetail;

    constructor(private message: NzMessageService, private shopifyService: ShopifyService) {}

    ngOnInit(): void {
        this.shopifyService.getShopDetailInternal().then(shopDetail => {
            this.shopDetail = shopDetail;
        });
    }

    copyShopLink() {
        const link = `${environment.shopHost}/${this.shopDetail.shop_url || this.shopDetail.shop_id}`;

        copy(link);
        this.message.success('Store URL Copied!');
    }

    openPreviewPage() {
        const link = `${environment.shopHost}/${this.shopDetail.shop_url || this.shopDetail.shop_id}`;
        window.open(link, '_blank');
    }
}
