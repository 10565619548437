<div class="contact-us-modal">
    <div class="fs-20 fw-bold">Contact Us</div>

    <div style="margin: 40px 0">{{description}}</div>

    <div>
        <div class="fw-500">How can we better assist you?</div>

        <nz-radio-group [(ngModel)]="requestType">
            <label *ngFor="let type of requestTypes" nz-radio [nzValue]="type">{{type}}</label>
        </nz-radio-group>

        <textarea nz-input placeholder="Details" style="margin-bottom: 40px; height: 100px" [(ngModel)]="notes"></textarea>

        <button nz-button nzType="primary" class="w-100p" [disabled]="sendBtnDisabled" (click)="confirmSend()" [nzLoading]="submitting">
            Send
        </button>
    </div>
</div>
