import { Component, Input, OnInit } from '@angular/core';
import { ProductCreation } from './plan-full-feature-table.data';

@Component({
    selector: 'app-plan-full-feature-table',
    templateUrl: './plan-full-feature-table.component.html',
    styleUrls: ['./plan-full-feature-table.component.less'],
})
export class PlanFullFeatureTableComponent implements OnInit {
    productCreation = ProductCreation;

    constructor() {}

    ngOnInit(): void {}

    isTdShouldBeHide(data) {
        return data.title && !data.starterFree && !data.experienced && !data.advanced && !data.pro && !data.premium;
    }
}
