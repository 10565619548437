<section class="choose-video-date">
    <h2>Choose Your Preferred Time Slots (PST)</h2>

    <div class="available-dates" appLoading [loading]="!dateList" style="min-height: 100px">
        <div *ngFor="let item of dateList" class="available-date-item d-flex justify-content-between align-items-center text-align-center">
            <div style="width: 50px">
                <label [nzChecked]="selectedDates.has(item)" (nzCheckedChange)="change(item)" nz-checkbox></label>
            </div>
            <div class="flex-1">{{item.date}}</div>
            <div class="flex-1">{{item.week}}</div>
            <div style="width: 100px">{{item.timeRange}}</div>
        </div>
    </div>

    <div class="d-flex width-100p justify-content-between align-items-center mt-20">
        <button nz-button style="width: 48%" (click)="modalRef.triggerCancel()">Close</button>
        <button
            nz-button
            nzType="primary"
            style="width: 48%"
            [disabled]="selectedDates.size === 0"
            [nzLoading]="submitting"
            (click)="modalRef.triggerOk()"
        >
            Send
        </button>
    </div>
</section>
