<section class="balance-table">
    <nz-table #basicTable [nzData]="transactionList" [nzLoading]="loading" [nzNoResult]="noResult">
        <thead>
            <tr>
                <th nzWidth="30%">Items</th>
                <th nzWidth="25%" [nzSortOrder]="sortByOrder" [nzSortFn]="sortByTime">{{ times }}</th>
                <th nzWidth="25%">Type</th>
                <th nzWidth="20%">Amount</th>
            </tr>
        </thead>
        <ng-template #noResult>
            <app-empty-table [description]="emptyTableDesc"></app-empty-table>
        </ng-template>
        <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>
                    <div>
                        {{ data.items }}
                        <span *ngIf="data.status==='FAILED'" class="failed-status">Failed</span>
                    </div>
                    <div *ngIf="data.meta_data?.paypal && data.transaction_type==='CASH_OUT'" style="font-size: 12px">
                        PayPal: {{data.meta_data?.paypal | emailAsterisk}}
                    </div>
                    <div *ngIf="data.meta_data?.bank_account && data.transaction_type==='CASH_OUT'" style="font-size: 12px">
                        {{data.meta_data?.bank_account}}
                    </div>
                    <div *ngIf="data.meta_data?.paypal && data.transaction_type==='CAMPAIGN_PAY'" style="font-size: 12px">
                        {{data.meta_data?.brand_name }}
                    </div>
                    <div *ngIf="data.type === 'Payment' && data.payMethod === PaymentMethodEnum.BANK" style="font-size: 12px">
                        <div class="bank-name">{{ data.bankNumber }}</div>
                    </div>
                </td>
                <td>{{ data[timeField] | date: "yyyy-MM-dd HH:mm" }}</td>
                <td>
                    {{ data.type }}
                    <span
                        class="arrow-down"
                        *ngIf="data.payMethod === 'Split Payment'"
                        nz-popover
                        nzPopoverPlacement="top"
                        nzPopoverTrigger="click"
                        nzPopoverOverlayClassName="no-padding no-bg"
                        [nzPopoverContent]="detailPopover"
                        (click)="paymentDetail = generatePaymentDetail(data)"
                    >
                        <i nz-icon nzType="caret-down" nzTheme="outline" style="margin-left: 5px"></i>
                    </span>
                    <ng-template #detailPopover>
                        <app-amount-detail-popover [revenueDetail]="null" [paymentDetail]="paymentDetail"></app-amount-detail-popover>
                    </ng-template>
                    <a
                        class="tooltips-icon"
                        [nzTooltipTitle]="typePayPalTooltip"
                        [nzTooltipOverlayClassName]="'connect-type-tooltip'"
                        nzTooltipPlacement="bottomLeft"
                        nz-tooltip
                        *ngIf="data.type==='Campaign Commission' && data.status === 'PENDING'"
                    >
                        ！
                    </a>
                    <ng-template #typePayPalTooltip>
                        <div class="type-tooltip-content">
                            <p class="connect-title">Your pending commission is on the way! 🎉</p>
                            <p class="connect-now">It takes about 14 days after campaign completion for the commission to settle.</p>
                        </div>
                    </ng-template>
                </td>
                <td class="amount">
                    <span [ngClass]="{out: isOut(data)}" class="count-operation">
                        <span *ngIf="isOut(data); else content" class="operation"> - </span>
                        <ng-template #content>
                            <span class="operation"> + </span>
                        </ng-template>

                        <span class="count">{{ data.amount | currency }}</span>
                    </span>
                </td>
            </tr>
        </tbody>
    </nz-table>
</section>
