<div class="d-flex justify-content-center align-items-center flex-direction-column">
    <ng-container *ngIf="type === PaymentResultEnum.SUCCESS">
        <img src="assets/svg/img_email.svg" />
        <div class="fs-20 fw-bold mt-10 mb-10">Your order has been placed. Thank You!</div>
        <button style="margin-top: 50px; width: 200px" nz-button nzType="primary" (click)="gotIt()">View Order</button>
    </ng-container>

    <ng-container *ngIf="type === PaymentResultEnum.CREATE_ORDER_SUCCESS">
        <img src="assets/svg/img_email.svg" />
        <div class="fs-20 fw-bold mt-10 mb-10">Order & Payment Request Sent</div>
        <button style="margin-top: 50px; width: 200px" nz-button nzType="primary" (click)="gotIt()">Got It</button>
    </ng-container>

    <ng-container *ngIf=" type === PaymentResultEnum.FAILED">
        <img src="assets/svg/order_failed.svg" />
        <div style="color: #9f99a3; margin: 10px 0">Oops! Something wrong.</div>
        <button nz-button (click)="gotItFailed()">View Shopping Cart</button>
    </ng-container>

    <ng-container *ngIf="type === PaymentResultEnum.PENDING">
        <img width="150" src="assets/imgs/img_pending.png" />
        <div class="fs-20 fw-bold mt-10 mb-10">Charge is Pending</div>
        <div class="fs-10">Your order was received and is awaiting payment confirmation.</div>
        <button style="margin-top: 50px; width: 200px" nz-button nzType="primary" (click)="gotIt()">View Order</button>
    </ng-container>

    <ng-container *ngIf="type === PaymentResultEnum.INACTIVE_ITEMS">
        <img width="150" src="assets/svg/order_failed.svg" />
        <div class="fs-20 fw-bold mt-10 mb-10">Inactive Products</div>
        <div class="fs-10" style="text-align: center">
            One or more items in your shopping cart is not available anymore.<br />
            Are you sure your want to checkout out now?
        </div>
        <div style="display: flex; margin-top: 50px; justify-content: space-between; align-items: center; width: 100%">
            <button style="width: 200px" nz-button nzType="default" (click)="gotItFailed()">Cancel</button>
            <button style="width: 200px" nz-button nzType="primary" [nzLoading]="loading" (click)="gotIt()">Confirm</button>
        </div>
    </ng-container>
</div>
