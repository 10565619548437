import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ShoppingCartService } from '../shopping-cart.service';
import { NidavellirService } from '@modules/nidavellir/nidavellir.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

declare const Klarna: any;

export interface KlarnaInitInfo {
    client_token: string;
    checkout_id: number;
    payment_method_categories: { identifier: string }[];
    session_id: string;
}

@Component({
    selector: 'app-klarna-modal',
    templateUrl: './klarna-modal.component.html',
    styleUrls: ['./klarna-modal.component.less'],
})
export class KlarnaModalComponent implements OnInit, AfterViewInit {
    @Input() klarnaData: KlarnaInitInfo;

    disableConfirm = true;
    confirmLoading = false;

    constructor(
        private shoppingCartService: ShoppingCartService,
        private nidavellirService: NidavellirService,
        private modalRef: NzModalRef,
        private messageService: NzMessageService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.shoppingCartService.initializeKlarna('#klarna-box', this.klarnaData, () => (this.disableConfirm = false));
    }

    confirm() {
        const payment_method_category = this.klarnaData.payment_method_categories[0].identifier;
        this.confirmLoading = true;
        Klarna.Payments.authorize(
            { payment_method_category },
            null,
            (res: { approved: boolean; show_form: boolean; authorization_token: string }) => {
                if (res.approved) {
                    this.nidavellirService
                        .placeKlarnaOrder(this.klarnaData.checkout_id, res.authorization_token)
                        .then(
                            (placeOrderRes: {
                                redirect_url: string;
                                fraud_status: string;
                                authorized_payment_method: string;
                                order_id: string;
                            }) => {
                                if (placeOrderRes.fraud_status === 'ACCEPTED') {
                                    window.location.href = placeOrderRes.redirect_url;
                                } else {
                                    this.confirmLoading = true;
                                }
                            }
                        )
                        .catch(() => {
                            this.messageService.error('Oops! Something went wrong. Please try again.');
                            this.confirmLoading = true;
                        });
                } else {
                    // Rejected
                    this.modalRef.close();
                }
            }
        );
    }
}
