import { SubscribePlanNameEnum } from '@shared/components/subscription/subscription.data';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ShopProfile } from '@typings/profile.typing';
import { RequestService } from './request.service';

import * as dayjs from 'dayjs';

export interface FaqListItem {
    category: string;
    question: string;
    response: string;
}

export interface ReferralData {
    id: number;
    invitationCode: string;
    invitorEmail: string;
    invitorName: string;
    referralLink: string;
    shopId: number;
}

export interface ReferralInvitationItem {
    friendEmail: string;
    friendName: string;
    id: number;
    refLinkId: number;
    shopId: number;
    status: 'Signed Up' | 'Invited';
    ticketId: number;
}

export interface InvitationHistory {
    friendsInvited: number;
    friendsSignedUp: number;
    tickets: number;
    listProductTickets: number;
    orderTickets: number;
    beInvitedTickets: number;
    referralInvitations: ReferralInvitationItem[];
}

export interface ReferralCampaign {
    content: string;
    detail: string;
    endTime: string;
    id: number;
    manuShow: string;
    startTime: string;
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class ShopService {
    shopProfile$ = new BehaviorSubject<ShopProfile>(null);

    referralCampaign;

    get shopProfile() {
        return this.shopProfile$.getValue();
    }

    constructor(private requestService: RequestService) {}

    getShopDetailInternal() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop-detail',
            api: 'api-shop',
        });
    }

    getShopDetail(): Promise<ShopProfile> {
        return this.requestService
            .sendRequest<ShopProfile>({
                method: 'GET',
                url: '/influencer/shop_management/shop_detail',
                api: 'shop-api',
            })
            .then(res => {
                this.handleSubscriptionInShopProfile(res);
                this.shopProfile$.next(res);
                return res;
            });
    }

    skipSubscriptionPlan() {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/skip_subscription_plan',
            api: 'shop-api',
        });
    }

    getFaqAll() {
        return this.requestService.sendRequest<FaqListItem[]>({
            method: 'GET',
            url: '/shared/faq?type=creatorhub',
            api: 'shop-api',
        });
    }

    selectSubscriptionPlan(data: { subscription_plan: SubscribePlanNameEnum; payment_method?: 'Credit Card' | 'Bank Account' }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/select_subscription_plan',
            api: 'shop-api',
            data,
        });
    }

    confirmSubscriptionPlan(data: { shop_subscription_id: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/confirm_subscription_plan',
            api: 'shop-api',
            data,
        });
    }

    checkShopName(name) {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/check_store_name?name=${name}`,
            api: 'data-shop',
        });
    }

    updateShopDetail(data) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/shop_management/shop_detail',
            data,
            api: 'data-shop',
        });
    }

    confirmShopAgreement() {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/shop_management/accept_agreement',
            data: {},
            api: 'data-shop',
        });
    }

    addCreditCard(data: { payment_method: any }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/add_credit_card',
            api: 'shop-api',
            data,
        });
    }

    getReferralLink() {
        return this.requestService.sendRequest<ReferralData>({
            method: 'GET',
            url: '/influencer/referral/referral_link',
            api: 'data-api',
        });
    }

    getReferralCampaign(): Promise<ReferralCampaign> {
        return this.requestService
            .sendRequest<ReferralCampaign>({
                method: 'GET',
                url: '/influencer/referral/referral_campaign',
                api: 'data-api',
            })
            .then(res => {
                this.referralCampaign = res;
                return res;
            });
    }

    getInvitationHistory() {
        return this.requestService.sendRequest<InvitationHistory>({
            method: 'GET',
            url: '/influencer/referral/invitation_history',
            api: 'data-api',
        });
    }

    inviteEmail(data: { email: string; friendName?: string }[]) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/referral/invite_email',
            api: 'data-api',
            data,
        });
    }

    getModashProfile() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/modash/profile',
            api: 'shop-api',
        });
    }

    private handleSubscriptionInShopProfile(shopProfile: ShopProfile) {
        if (shopProfile.subscription) {
            shopProfile.subscription.sub_plan_id = shopProfile.subscription.sub_plan_id || SubscribePlanNameEnum.STARTER;
        } else {
            shopProfile.subscription = { sub_plan_id: shopProfile.subscription_plan || SubscribePlanNameEnum.STARTER } as any;
        }
    }
}
