import { ShopService } from '@services/shop.service';
import { ProfileService } from '@services/profile.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Component({
    selector: 'app-custom-sign-in',
    templateUrl: './custom-sign-in.component.html',
    styleUrls: ['./custom-sign-in.component.less'],
})
export class CustomSignInComponent implements OnInit {
    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private profileService: ProfileService,
        private shopService: ShopService
    ) {}

    ngOnInit(): void {
        // Sign in with custom token
        this.auth
            .signOut()
            .then(() => {
                this.profileService.currentProfileSubject.next(null);
                this.shopService.shopProfile$.next(null);
            })
            .finally(() => {
                const token = this.activateRoute.snapshot.queryParamMap.get('idToken');
                if (token) {
                    this.signInWithToken(token);
                } else {
                    this.router.navigate(['/', 'auth', 'sign-in']);
                }
            });
    }

    signInWithToken(token: string) {
        this.auth
            .signInWithCustomToken(token)
            .then(async () => {
                await this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
                const redirectTo = this.activateRoute.snapshot.queryParamMap.get('redirectTo');
                if (redirectTo) {
                    this.router.navigate([redirectTo]);
                } else {
                    this.router.navigate(['/', 'home', 'dashboard']);
                }
            })
            .catch(() => this.router.navigate(['/', 'auth', 'sign-in']));
    }
}
