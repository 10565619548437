import { ProductListItem } from '@modules/product-management/product-management.service';
import { ShopProfile } from '@typings/profile.typing';
import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { StorefrontStateService } from '../../storefront-state.service';

@Component({
    selector: 'app-choose-single-product-modal',
    templateUrl: './choose-single-product-modal.component.html',
    styleUrls: ['./choose-single-product-modal.component.less'],
})
export class ChooseSingleProductModalComponent implements OnInit {
    @Input() shopDetail: ShopProfile;

    @Input() selectedProductId: any;

    get productList() {
        return this.storefrontStateService.productList;
    }

    constructor(public modalRef: NzModalRef, private storefrontStateService: StorefrontStateService) {}

    ngOnInit(): void {}

    chooseProduct(product: ProductListItem) {
        if (product.product_id === this.selectedProductId) {
            this.selectedProductId = null;
        } else {
            this.selectedProductId = product.product_id;
        }
    }
}
