<ng-template #titleTemplate>
    <app-toolbar
        *ngIf="currentEditingTextSectionData"
        [color]="currentEditingTextSectionData.styles.color"
        [textAlign]="currentEditingTextSectionData.styles['text-align']"
        [textDecoration]="currentEditingTextSectionData.styles['text-decoration']"
        [selectedToolbars]="currentEditingTextSectionData.selectedToolBars"
        (menuClicked)="storefrontStateService.toolbarMenuChange($event)"
    ></app-toolbar>
</ng-template>

<!-- Banner -->
<div class="banner-box">
    <div [hidden]="siteView === 'mobile'" class="desktop-banner" appLoading [loading]="desktopImgUploading" [maskVisible]="true">
        <div
            class="banner-img relative"
            [ngClass]="{
                'banner-img-center': shopDetail.theme_settings?.banner?.layout === 'on_the_image',
                'banner-img-left': shopDetail.theme_settings?.banner?.layout === 'right',
                'banner-img-right': shopDetail.theme_settings?.banner?.layout === 'left'
            }"
            [ngStyle]="{
                'background-image':'url('+ shopDetail.theme_settings.banner.desktopImg +')',
                'background-repeat':'no-repeat',
                'background-position':'center',
                'background-size':'cover'
            }"
        >
            <div class="add-media" [style.transform]="'scale(' + scaleRatio + ')'" style="transform-origin: top left">
                <nz-upload
                    [nzShowUploadList]="false"
                    [nzBeforeUpload]="beforeUploadDesktop"
                    [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                >
                    <div class="banner-upload-icon-text">
                        <img src="assets/antd/banner-upload.svg" />
                        <div>
                            <div class="text">Add Image</div>
                            <div class="fs-10">1440 * 500px</div>
                        </div>
                    </div>
                </nz-upload>
            </div>

            <div
                *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.BANNER_DESKTOP, shopDetail.theme_settings.banner.desktopImg)"
                class="remove-media"
                (click)="removeMedia('desktopBanner')"
                [style.transform]="'scale(' + scaleRatio + ')'"
                style="transform-origin: top right"
            >
                <i nz-icon nzType="close" nzTheme="outline"></i>
            </div>

            <div class="d-flex align-items-center shop-info">
                <div class="width-100p">
                    <h4 class="title-item" [class.title-item-m]="siteView === 'mobile'" [style.color]="bannerHeadingColor">
                        <div
                            class="edit-input"
                            nz-popover
                            nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                            nzPopoverPlacement="top"
                            [nzPopoverContent]="titleTemplate"
                            [nzPopoverTrigger]="'click'"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontTitle"
                            [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.BANNER_TITLE)?.styles"
                            (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.BANNER_TITLE)"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.title"
                        ></div>
                    </h4>

                    <div class="content-text" [style.color]="shopDetail.theme_settings.color2" [style.color]="bannerBodyColor">
                        <div
                            class="edit-input"
                            nz-popover
                            nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                            nzPopoverPlacement="top"
                            [nzPopoverContent]="titleTemplate"
                            [nzPopoverTrigger]="'click'"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                            [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.BANNER_SUB_TITLE)?.styles"
                            (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.BANNER_SUB_TITLE)"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.content"
                        ></div>
                    </div>
                    <span
                        *ngIf="shopDetail?.theme_settings.banner.heroButtonVisible"
                        class="banner-cta"
                        [ngClass]="{'corner-round ': shopDetail?.theme_settings?.cornerStyle === 'round'}"
                        [style.background]=" bannerButtonBackground"
                    >
                        <div
                            class="edit-input"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                            [ngStyle]="{
                                color: bannerButtonColor,
                                height:'100%',
                                'font-family': shopDetail?.theme_settings?.fontBody,
                                'border-radius': shopDetail?.theme_settings?.cornerStyle === 'round' ? '20px' : '0'
                            }"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.heroButtonText"
                        ></div>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="siteView === 'desktop'" class="mobile-banner">
        <div
            class="banner-img relative"
            appLoading
            [loading]="mobileImgUploading"
            [maskVisible]="true"
            [ngClass]="{
                'justify-content-center':shopDetail.theme_settings.banner.mobileTextPosition === 'center',
                'justify-content-start':shopDetail.theme_settings.banner.mobileTextPosition === 'top',
                'justify-content-end':shopDetail.theme_settings.banner.mobileTextPosition === 'bottom'
            }"
            [ngStyle]="{
                'background-image':'url(' + shopDetail.theme_settings.banner.mobileImg + ')',
                'background-repeat':'no-repeat',
                'background-position':'center',
                'background-size':'cover'
            }"
        >
            <div class="add-media">
                <nz-upload
                    [nzShowUploadList]="false"
                    [nzBeforeUpload]="beforeUploadMobile"
                    [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                >
                    <div class="banner-upload-icon-text">
                        <img src="assets/antd/banner-upload.svg" />
                        <div>
                            <div class="text">Add Image</div>
                            <div class="fs-10">640 * 1200px</div>
                        </div>
                    </div>
                </nz-upload>
            </div>

            <div
                *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.BANNER_MOBILE,shopDetail.theme_settings.banner.mobileImg)"
                class="remove-media"
                (click)="removeMedia('mobileBanner')"
            >
                <i nz-icon nzType="close" nzTheme="outline"></i>
            </div>

            <div class="d-flex align-items-center shop-info shop-info-view width-100p">
                <div class="width-100p" style="text-align: center">
                    <h4 class="title-item" [class.title-item-m]="siteView === 'mobile'" [style.color]="bannerHeadingColor">
                        <div
                            class="edit-input"
                            nz-popover
                            nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                            nzPopoverPlacement="top"
                            [nzPopoverContent]="titleTemplate"
                            [nzPopoverTrigger]="'click'"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontTitle"
                            [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.BANNER_TITLE)?.styles"
                            (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.BANNER_TITLE)"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.title"
                        ></div>
                    </h4>

                    <div class="content-text" [style.color]="bannerBodyColor">
                        <div
                            class="edit-input"
                            nz-popover
                            nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                            nzPopoverPlacement="top"
                            [nzPopoverContent]="titleTemplate"
                            [nzPopoverTrigger]="'click'"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                            [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.BANNER_SUB_TITLE)?.styles"
                            (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.BANNER_SUB_TITLE)"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.content"
                        ></div>
                    </div>
                    <span
                        *ngIf="shopDetail?.theme_settings.banner.heroButtonVisible"
                        class="banner-cta"
                        [ngClass]="{'corner-round ': shopDetail?.theme_settings?.cornerStyle === 'round'}"
                        [style.background]="bannerButtonBackground"
                    >
                        <div
                            class="edit-input"
                            [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                            [ngStyle]="{
                                color: bannerButtonColor, 
                                height: '100%',
                                'font-family': shopDetail?.theme_settings?.fontBody,
                                'border-radius': shopDetail?.theme_settings?.cornerStyle === 'round' ? '20px' : '0'
                            }"
                            appContentEditableModel
                            [(contentEditableModel)]="shopDetail.theme_settings.banner.heroButtonText"
                        ></div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
