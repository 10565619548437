import { Component, OnInit } from '@angular/core';
import { ProductCreation } from '@shared/components/subscription/plan-full-feature-table/plan-full-feature-table.data';
import { CARD_LIST } from '@shared/components/subscription/subscription.data';

export enum CardListStarter {
    STARTER = 'starter',
    EXPERIENCED = 'experienced',
    ADVANCED = 'advanced',
    PRO = 'pro',
    PREMIUM = 'premium',
}

@Component({
    selector: 'app-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.less'],
})
export class PlansComponent implements OnInit {
    shopProfile: any;
    cardList = CARD_LIST;

    data = ProductCreation;

    constructor() {}

    ngOnInit() {}

    isTdShouldBeHide(data) {
        return data.title && !data.starterFree && !data.experienced && !data.advanced && !data.pro && !data.premium;
    }
}
