import { cloneDeep } from 'lodash';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input } from '@angular/core';
import { SocialMediaEnum } from './../storefront-state.service';
import { StorefrontService } from '../storefront.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-add-social-media-modal',
    templateUrl: './add-social-media-modal.component.html',
    styleUrls: ['./add-social-media-modal.component.less'],
})
export class AddSocialMediaModalComponent implements OnInit {
    @Input() shopDetail: ShopProfile;
    @Input() addedSocial: any[];
    @Input() platforms: SocialMediaEnum[];
    @Input() nonAddedSocial: SocialMediaEnum[];

    socialMedialPopOverVisible = false;
    addSocialModalVisible = false;

    selectedSocial: SocialMediaEnum;

    constructor(private storefrontService: StorefrontService, public modalRef: NzModalRef) {}

    ngOnInit(): void {}

    selectPlatForm(platform: SocialMediaEnum) {
        this.selectedSocial = platform;
        this.socialMedialPopOverVisible = false;
    }

    addPlatform() {
        this.modalRef.triggerOk();
    }

    getSocialIcon(platform: SocialMediaEnum) {
        return `assets/svg/platform_icons/new_${platform}.svg`;
    }

    updateSocialLink(platform: SocialMediaEnum) {
        const url = this.storefrontService.addHttpPrefix(this.shopDetail.theme_settings.socialMedia[platform]);
        this.shopDetail.theme_settings.socialMedia[platform] = url;
    }

    getSocialName(platform: SocialMediaEnum) {
        return platform === SocialMediaEnum.APPLE_PODCASTS ? 'apple podcasts' : platform;
    }
}
