<div class="login-container">
    <div class="content">
        <div class="logo-title">
            <img src="assets/imgs/project-logo.jpg" alt="" width="100" />
            <span><span class="strong">Lifo</span> Creator Hub</span>
        </div>

        <div class="content-box">
            <div class="left-img">
                <img src="/assets/imgs/login-left-img.png" />
                <div class="left-text">Building brands and communities through social commerce.</div>
            </div>

            <div class="right-form">
                <router-outlet></router-outlet>
            </div>
        </div>

        <div class="footer">
            <span>Social Commerce for Creators</span>
        </div>
    </div>
</div>
