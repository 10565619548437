import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CustomSignInComponent } from './custom-sign-in/custom-sign-in.component';

@NgModule({
    declarations: [AuthComponent, LoginComponent, ForgotPasswordComponent, SignUpComponent, CustomSignInComponent],
    imports: [CommonModule, SharedModule, NzFormModule, ReactiveFormsModule, RouterModule],
    providers: [{ provide: Window, useValue: window }],
})
export class AuthModule {}
