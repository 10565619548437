import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorefrontComponent } from './storefront.component';
import { ProductManagementService } from '../product-management/product-management.service';
import { BannerComponent } from './banner/banner.component';
import { StorefrontStateService } from './storefront-state.service';
import { RecommendedProductsComponent } from './recommended-products/recommended-products.component';
import { AboutComponent } from './about/about.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { AddSocialMediaModalComponent } from './add-social-media-modal/add-social-media-modal.component';
import { ChooseProductModalComponent } from './choose-product-modal/choose-product-modal.component';
import { YoutubeVideoModalComponent } from './youtube-video-modal/youtube-video-modal.component';
import { ChooseSingleProductModalComponent } from './banner/choose-single-product-modal/choose-single-product-modal.component';
import { GlobalThemeConfigurationComponent } from './configuration-panel/global-theme-configuration/global-theme-configuration.component';
import { SectionConfigurationComponent } from './configuration-panel/section-configuration/section-configuration.component';

@NgModule({
    declarations: [
        StorefrontComponent,
        BannerComponent,
        RecommendedProductsComponent,
        AboutComponent,
        SocialMediaComponent,
        AddSocialMediaModalComponent,
        ChooseProductModalComponent,
        YoutubeVideoModalComponent,
        ChooseSingleProductModalComponent,
        GlobalThemeConfigurationComponent,
        SectionConfigurationComponent,
    ],
    imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, FormsModule],
    providers: [ProductManagementService, StorefrontStateService],
})
export class StorefrontModule {}
