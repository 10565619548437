import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.less'],
})
export class TermsOfUseComponent implements OnInit {
    index = 1;
    constructor() {}

    ngOnInit(): void {}
}
