import { PaymentMethodEnum } from '@shared/components/shopping-cart/shopping-cart.service';
import { TransactionItem } from './../balance-history.service';
import { Component, Input, OnInit } from '@angular/core';
import { PlaidService } from '@services/plaid.service';

@Component({
    selector: 'app-balance-history-table',
    templateUrl: './balance-history-table.component.html',
    styleUrls: ['./balance-history-table.component.less'],
})
export class BalanceHistoryTableComponent implements OnInit {
    @Input() times: string;
    @Input() timeField: string;
    @Input() emptyTableDesc: string;
    @Input() transactionList = [];
    @Input() loading: boolean;

    PaymentMethodEnum = PaymentMethodEnum;

    sortByOrder = 'descend';
    paymentDetail: any;

    constructor(public plaidService: PlaidService) {}

    ngOnInit() {}

    isOut(data: TransactionItem) {
        return (
            data.transaction_type === 'CASH_OUT' ||
            data.transaction_type === 'SHOP_PAYMENT' ||
            data.transaction_type === 'RESTOCK' ||
            data.transaction_type === 'AUTO_RESTOCK' ||
            data.transaction_type === 'Restock Payment' ||
            data.transaction_type === 'Weekly Shipping Cost' ||
            data.transaction_type === 'Monthly Shipping Cost' ||
            data.transaction_type === 'Product Procurement Cost' ||
            data.transaction_type === 'Payment for Customized Order' ||
            data.transaction_type === 'Warehouse Operation Cost' ||
            data.type === 'Payment'
        );
    }

    sortByTime = (a, b) => a[this.timeField]?.localeCompare(b[this.timeField]) ?? -1;

    generatePaymentDetail(data: TransactionItem) {
        return {
            amount: data.amount,
            balance: data.meta_data.paymentMethod.find(payment => payment.method === PaymentMethodEnum.BALANCE)?.amount,
            bank: data.meta_data.paymentMethod.find(payment => payment.method === PaymentMethodEnum.BANK)?.amount,
            bankName: data.meta_data.paymentMethod.find(payment => payment.method === PaymentMethodEnum.BANK)?.bankNumber,
            lifoCredit: data.meta_data.paymentMethod?.find(payment => payment.method === PaymentMethodEnum.LIFO_CREDIT)?.amount,
            creditCard: data.meta_data.paymentMethod?.find(payment => payment.method === PaymentMethodEnum.CREDIT_CARD)?.amount,
            paypal: data.meta_data.paymentMethod?.find(payment => payment.method === PaymentMethodEnum.PAYPAL)?.amount,
        };
    }
}
