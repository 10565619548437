import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AccountBalance } from '@typings/earnings.typings';
import { UserProfile } from '@typings/profile.typing';

@Injectable({
    providedIn: 'root',
})
export class MyEarningsService {
    BASEURL = 'https://campaign-test.lifo.ai';

    constructor(private requestService: RequestService) {}

    getAccountBalance() {
        return this.requestService.sendRequest<AccountBalance>({
            method: 'GET',
            url: '/influencer/account_balance',
            api: 'data-api',
        });
    }

    cashOut(requestData) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/cash_out',
            data: requestData,
        });
    }

    updateAccountInfo(userData: UserProfile) {
        return this.requestService.sendRequest<UserProfile>({
            method: 'PUT',
            url: '/influencer/current-profile',
            data: userData,
            api: 'shop-api',
        });
    }
}
