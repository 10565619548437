<section class="order-detail-box">
    <header class="nav" (click)="backToOrderList()">
        <img width="18" height="18" src="assets/svg/arrow-left.svg" />
        <span>Order History</span>
    </header>

    <div class="content">
        <!-- Product info -->
        <div class="product-info">
            <div class="title-content">
                <div class="sub-title">
                    <div class="circle-icon">
                        <div class="inner-circle"></div>
                    </div>

                    <span class="text">{{ orderDetail?.name }}</span>

                    <div class="payment-status" [class.refunded]="orderDetail?.financial_status === 'refunded'">
                        <div class="dot fill-dot"></div>
                        <span style="text-transform: capitalize">{{ paymentStatusMap.get(orderDetail?.financial_status) }}</span>
                    </div>

                    <div [ngSwitch]="orderDetail?.fulfillment_status">
                        <div *ngSwitchCase="'fulfilled'" class="fulfillment-status" style="background-color: #ffe77f">
                            <div class="box"></div>
                            <div class="text">Fulfilled</div>
                        </div>
                        <div *ngSwitchCase="'partial'" class="fulfillment-status" style="background-color: #ffbf6e">
                            <div class="box partially-box"></div>
                            <div class="text">Partially Fulfilled</div>
                        </div>
                        <div *ngSwitchDefault class="fulfillment-status" style="background-color: #f1f0f3">
                            <div class="box" style="border-color: #5b6069"></div>
                            <div class="text" style="color: #5b6069">Unfulfilled</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-list">
                <nz-skeleton *ngIf="loading" [nzActive]="true"></nz-skeleton>

                <div *ngFor="let productInfo of productList" class="product-info-item">
                    <div class="info">
                        <img class="product-img" [src]="productInfo.image" />
                        <div class="text-info">
                            <div class="product-name">{{ productInfo.title }}</div>
                            <div class="product-sku">Variant: {{ productInfo?.variant_json.title }}</div>
                            <div class="product-sku">SKU: {{ productInfo.sku }}</div>
                        </div>
                    </div>

                    <div style="margin-right: 20px; font-weight: bold">
                        <div appStatusBtn [color]="'#17B5B7'" [showDot]="false">Lifo Inventory</div>
                    </div>

                    <div class="count">{{ productInfo.price | currency }} x {{ productInfo.quantity }}</div>

                    <div class="price">{{ productInfo.price * productInfo.quantity || 0 | currency }}</div>
                </div>
            </div>
        </div>

        <!-- Customer / Payment -->
        <div class="customer-payment">
            <div class="customer-info">
                <div class="title-content">
                    <div class="sub-title">
                        <div class="circle-icon">
                            <div class="inner-circle"></div>
                        </div>

                        <span class="text">Customer</span>
                    </div>
                </div>

                <div class="customer-content">
                    <div class="info-item">
                        <div class="key">Name</div>
                        <div class="value">
                            <span>{{ orderDetail?.original_order_detail.customer?.first_name }}</span>
                            <span> {{ orderDetail?.original_order_detail.customer?.last_name }}</span>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="key">Location</div>
                        <div class="value">
                            <span *ngIf="orderDetail?.original_order_detail.shipping_address?.city">
                                {{ orderDetail?.original_order_detail.shipping_address?.city }}{{', '}}
                            </span>
                            <span *ngIf="orderDetail?.original_order_detail.shipping_address?.province">
                                {{ orderDetail?.original_order_detail.shipping_address?.province }}{{', '}}
                            </span>
                            <span *ngIf="orderDetail?.original_order_detail.shipping_address?.country">
                                {{ orderDetail?.original_order_detail.shipping_address?.country }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Payment -->
            <div class="payment">
                <section class="title-content">
                    <section class="sub-title">
                        <section class="circle-icon">
                            <section class="inner-circle"></section>
                        </section>

                        <span style="text-transform: capitalize" class="text">
                            {{ paymentStatusMap.get(orderDetail?.financial_status) }}
                        </span>
                    </section>
                </section>

                <section class="payment-content">
                    <section class="subtotal">
                        <span class="name">Subtotal</span>
                        <span class="count">{{ orderDetail?.original_order_detail.subtotal_price | currency }}</span>
                    </section>
                    <section class="total">
                        <span class="name">Total</span>
                        <span class="count">{{ orderDetail?.original_order_detail.total_price | currency }}</span>
                    </section>

                    <nz-divider></nz-divider>

                    <section class="paid-money">
                        <span class="name">Paid By Customer</span>
                        <span class="count">{{ orderDetail?.original_order_detail.total_price | currency }}</span>
                    </section>
                </section>
            </div>
        </div>

        <!-- Timeline -->
        <section *ngIf="true" class="timeline">
            <section class="title-content">
                <section class="sub-title">
                    <section class="circle-icon">
                        <section class="inner-circle"></section>
                    </section>

                    <span class="text">Tracking</span>
                </section>
            </section>

            <section class="content">
                <section *ngIf="orderDetail?.original_order_detail.fulfillments?.length > 0" class="timeline-item">
                    <section class="left-dot"></section>
                    <section class="timeline-item-content">
                        <section class="date">
                            {{ (orderDetail?.original_order_detail.fulfillments)[0].updated_at | date: 'yyyy-MM-dd HH:mm' }}
                        </section>
                        <section class="name">
                            Order shipped < Tracking id: {{ (orderDetail?.original_order_detail.fulfillments)[0].tracking_number }} >
                        </section>
                    </section>
                </section>

                <section class="timeline-item">
                    <section class="left-dot"></section>
                    <section class="timeline-item-content">
                        <section class="date">{{ orderDetail?.created_at | date: 'yyyy-MM-dd HH:mm' }}</section>
                        <section class="name">
                            Order confirmation email was sent to {{ orderDetail?.original_order_detail.customer?.first_name }} {{
                            orderDetail?.original_order_detail.customer?.last_name }}
                        </section>
                    </section>
                </section>

                <section class="timeline-item">
                    <section class="left-dot"></section>
                    <section class="timeline-item-content">
                        <section class="date">{{ orderDetail?.created_at | date: 'yyyy-MM-dd HH:mm' }}</section>
                        <section class="name">Order Created</section>
                    </section>
                </section>
            </section>
        </section>
    </div>
</section>
