import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NidavellirService } from '@modules/nidavellir/nidavellir.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.less'],
})
export class ContactUsComponent implements OnInit {
    @Input() requestTypes: string[];
    @Input() description: string;
    @Input() requestType: string;
    @Input() notes: string;

    submitting = false;

    get sendBtnDisabled() {
        return !this.requestType || !this.notes?.trim();
    }

    constructor(private modalRef: NzModalRef, private nidavellirService: NidavellirService, private message: NzMessageService) {}

    ngOnInit(): void {}

    confirmSend() {
        if (!this.sendBtnDisabled) {
            const data = {
                service_type: this.requestType,
                note: this.notes,
            };
            this.submitting = true;

            this.nidavellirService
                .updateServiceRequest(data)
                .then((res: any) => {
                    if (res.error) {
                        this.message.create('error', res.error);
                        return false;
                    }
                    this.modalRef.close();
                    this.message.create('success', 'Sent successfully!');
                })
                .catch(() => this.message.create('error', 'Oops, something went wrong. Please try again or contact us.'))
                .finally(() => (this.submitting = false));
        }
    }
}
