import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';

export enum PaymentResultEnum {
    SUCCESS = 'success',
    FAILED = 'failed',
    CREATE_ORDER_SUCCESS = 'create_order_success',
    PENDING = 'pending',
    INACTIVE_ITEMS = 'inactive_items',
}

@Component({
    selector: 'app-payment-successful',
    templateUrl: './payment-successful.component.html',
    styleUrls: ['./payment-successful.component.less'],
})
export class PaymentSuccessfulComponent implements OnInit {
    @Input() type: PaymentResultEnum;

    loading = false;

    PaymentResultEnum = PaymentResultEnum;

    constructor(private router: Router, private modalRef: NzModalRef) {}

    ngOnInit(): void {}

    gotIt() {
        this.modalRef.triggerOk();
    }

    gotItFailed() {
        this.modalRef.triggerCancel();
    }
}
