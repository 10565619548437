<div class="shop-card" (click)="goToShop()" [ngClass]="{'active': shopObject.shop_detail.is_production}">
    <div class="shop-card-header">
        <div class="shop-name">{{platformInformation.platform_id}}</div>
        <div class="shop-followers">
            <img src="assets/svg/ic_ins.svg" *ngIf="platformInformation.platform === 'instagram'" />
            <img src="assets/svg/ic_tiktok.svg" *ngIf="platformInformation.platform === 'tiktok'" />
            <img src="assets/svg/ic_youtube.svg" *ngIf="platformInformation.platform === 'youtube'" />
            <span class="short-number">{{platformInformation.followers_count | shortNumber}}</span>
            <span class="subscribers-text">Followers</span>
        </div>
    </div>
    <div class="shop-card-image" [style.background-image]="'url('+shopObject.shop_detail.profile_image_src+')'"></div>
    <div class="shop-card-footer" *ngIf="shopObject.shop_detail.is_production">
        <span>Enter ➞ {{shopObject.name}}</span>
    </div>
</div>
