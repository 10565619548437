<section class="payment-method-box">
    <div class="d-flex align-items-center justify-content-between title">
        <label>Add Payment Method</label>
        <div class="cursor-pointer" (click)="closeModal.emit()">
            <img src="assets/svg/close_icon.svg" />
        </div>
    </div>
    <div class="store-preparing-tip">
        Your ADVANCED plan will start from {{shopProfile?.subscription.trial_end_date | date: 'yyyy-MM-dd'}}. <span>$1.99 first month</span>
    </div>
    <div class="payment-content">
        <!-- Credit -->
        <div
            class="credit-radio-item radio-item user-select-none cursor-pointer align-items-start"
            [class.active]="selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD"
            (click)="selectedPaymentMethod = PaymentMethodEnum.CREDIT_CARD"
        >
            <i
                class="mr-30"
                nz-icon
                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD ?'radio-checked':'radio-unchecked'}}"
                nzTheme="outline"
            ></i>
            <div class="flex-1">
                <div>Credit Card</div>
                <div [hidden]="selectedPaymentMethod !== PaymentMethodEnum.CREDIT_CARD" class="mt-30">
                    <div ngxStripeCardGroup [elementsOptions]="elementsOptions">
                        <div class="mb-20">
                            <div>Cardholder Name <span class="necessary">*</span></div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="credit-card-box mr-20 flex-1">
                                    <input type="text" class="input-item" placeholder="First Name" [(ngModel)]="stripeInfo.firstName" />
                                </div>

                                <div class="credit-card-box ml-20 flex-1">
                                    <input type="text" class="input-item" placeholder="Last Name" [(ngModel)]="stripeInfo.lastName" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-20">
                            <div>Zip <span class="necessary">*</span></div>
                            <div class="credit-card-box">
                                <input
                                    type="text"
                                    class="input-item"
                                    placeholder="Zip Code"
                                    [(ngModel)]="stripeInfo.zipCode"
                                    (ngModelChange)="zipCodeChange()"
                                />
                            </div>
                        </div>

                        <div>
                            <div>Card <span class="necessary">*</span></div>

                            <ngx-stripe-card
                                [options]="cardOptions"
                                [elementsOptions]="elementsOptions"
                                (change)="cardInputChange($event)"
                            ></ngx-stripe-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Bank -->
        <div
            class="radio-item user-select-none cursor-pointer"
            [class.active]="selectedPaymentMethod === PaymentMethodEnum.BANK"
            (click)="selectedPaymentMethod = PaymentMethodEnum.BANK"
        >
            <i
                class="mr-30"
                nz-icon
                nzType="antd:{{selectedPaymentMethod === PaymentMethodEnum.BANK ?'radio-checked':'radio-unchecked'}}"
                nzTheme="outline"
            ></i>
            <div class="flex-1">
                <div>Bank Account</div>
                <div class="fs-12" style="color: rgba(27, 34, 46, 0.7)">It can be used for balance withdrawal and subscription payment</div>
            </div>
            <div class="d-flex align-items-center" style="background: rgba(243, 153, 105, 0.2); padding: 3px 10px; border-radius: 16px">
                <i nz-icon nzType="antd:star" nzTheme="outline" style="color: #f3745e; font-size: 12px; margin-right: 8px"></i>
                <span style="color: #f3745e; font-size: 12px">Recommended</span>
            </div>
        </div>
    </div>
    <div class="footer-btns">
        <button nz-button nzType="default" (click)="closeModal.emit()">Cancel</button>
        <button nz-button nzType="primary" [nzLoading]="submitting" [disabled]="confirmDisabled" (click)="submitCard()">Confirm</button>
    </div>
</section>
