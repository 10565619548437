import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { PagesNoSubWrapperComponent } from './pages-no-sub-wrapper.component';
import { SourcingRequestModule } from './sourcing-request/sourcing-request.module';
import { SignInConditionContractComponent } from './sign-in-condition-contract/sign-in-condition-contract.component';

@NgModule({
    declarations: [PagesNoSubWrapperComponent, SignInConditionContractComponent],
    imports: [CommonModule, RouterModule, SourcingRequestModule, SharedModule],
})
export class PagesNoSubWrapperModule {}
