import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

@Injectable({
    providedIn: 'root',
})
export class FundsService {
    constructor(private requestService: RequestService) {}

    getLifoCredit() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/funds/lifo_credit',
            api: 'data-api',
        });
    }
}
