import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { HomeService } from '@modules/home/home.service';
import { OrderItem } from '@typings/order.typings';
import { ShopService } from '@services/shop.service';

import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.less'],
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
    orderList: OrderItem[];
    orderList$: BehaviorSubject<OrderItem[]> = new BehaviorSubject([]);

    loadingData = true;

    get shopDetail() {
        return this.shopService.shopProfile;
    }

    constructor(
        private homeService: HomeService,
        private router: Router,
        private message: NzMessageService,
        private shopService: ShopService
    ) {}

    ngOnInit(): void {
        this.getOrders();
    }

    ngOnDestroy() {
        this.orderList$.unsubscribe();
    }

    getOrders() {
        this.homeService
            .getOrders()
            .then(res => {
                this.loadingData = false;
                this.orderList = res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
                if (!this.orderList$.closed) {
                    this.orderList$.next(this.orderList);
                }
            })
            .catch(() => this.message.create('error', 'Get order history failed, please try again later'));
    }

    backToDashboard() {
        this.router.navigateByUrl('/home/dashboard');
    }

    goOrderDetailPage(order: OrderItem) {
        this.router.navigateByUrl(`/home/order-detail/${order.order_id}`);
    }
}
