<section class="revenue-detail" *ngIf="revenueDetail">
    <section *ngIf="revenueDetail.report_summary?.weekly_revenue !== undefined" class="revenue-item mb-20">
        <span class="name">Revenue</span>
        <span class="count">{{ revenueDetail.report_summary.weekly_revenue | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.monthly_revenue !== undefined" class="revenue-item mb-20">
        <span class="name">Revenue</span>
        <span class="count">{{ revenueDetail.report_summary.monthly_revenue | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.service_fee" class="revenue-item mb-20">
        <span class="name">Service Fee</span>
        <span class="count">{{ revenueDetail.report_summary.service_fee | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.return_items !== undefined" class="revenue-item mb-20">
        <span class="name">Returned Items</span>
        <span class="count">{{ revenueDetail.report_summary.return_items | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.return_service_fee !== undefined" class="revenue-item">
        <span class="name">Service Fee Refund</span>
        <span class="count">{{ revenueDetail.report_summary?.return_service_fee | currency }}</span>
    </section>

    <nz-divider></nz-divider>

    <section class="revenue-item">
        <span class="name">Net Revenue</span>
        <span class="count">{{ revenueDetail.amount | currency }}</span>
    </section>
</section>
