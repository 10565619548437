import { FormControl, Validators } from '@angular/forms';
import { ProductListItem } from '@modules/product-management/product-management.service';
import { ShopProfile } from '@typings/profile.typing';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ToolBarEnum } from '@shared/components/toolbar/toolbar.component';
import { Injectable } from '@angular/core';

export enum TextEditableEnum {
    BANNER_TITLE,
    BANNER_SUB_TITLE,
    RECOMMENDED_PRODUCT_TITLE,
    ABOUT_TITLE,
    ABOUT_CONTENT,
}

export enum SocialMediaEnum {
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    SNAPCHAT = 'snapchat',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
    SPOTIFY = 'spotify',
    TIKTOK = 'tiktok',
    LINKEDIN = 'linkedin',
    TUMBLR = 'tumblr',
    DISCORD = 'discord',
    PATREON = 'patreon',
    MEDIUM = 'medium',
    APPLE_PODCASTS = 'applePodcasts',
    TWITCH = 'twitch',
    WEBSITE = 'website',
}

@Injectable()
export class StorefrontStateService {
    TextEditableEnum = TextEditableEnum;
    currentEditingTextSection: TextEditableEnum;
    editableTextSectionDataMap = new Map<TextEditableEnum, any>();

    shopDetail: ShopProfile;
    shopDetail$ = new BehaviorSubject(null);

    productList: ProductListItem[];
    productList$ = new BehaviorSubject<ProductListItem[]>(null);

    originalSelectedProducts: ProductListItem[] = [];
    currentSelectedProducts: ProductListItem[] = [];
    currentSelectedProducts$ = new BehaviorSubject<ProductListItem[]>(null);

    aboutSectionMediaType: 'image' | 'video' = 'image';

    aboutYoutubeVideoUrl: string;

    domainControl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern(/^[0-9a-zA-Z]*$/)]);

    scaleRatio = 1;

    get currentEditingTextSectionData() {
        return this.editableTextSectionDataMap.get(this.currentEditingTextSection);
    }

    constructor() {
        this.shopDetail$.subscribe(result => (this.shopDetail = result));
    }

    toolbarMenuChange(data: { [key: string]: any; selectedToolBars: Set<ToolBarEnum> }) {
        this.editableTextSectionDataMap.set(this.currentEditingTextSection, cloneDeep(data));
    }

    selectEditableTextSection(data: TextEditableEnum) {
        this.currentEditingTextSection = data;
        if (!this.editableTextSectionDataMap.has(data)) {
            this.editableTextSectionDataMap.set(data, {
                styles: {
                    [ToolBarEnum.TEXT_COLOR]: '#000',
                },
                selectedToolBars: new Set([ToolBarEnum.TEXT_ALIGN]),
            });
        }
    }
}
