<section class="order-product">
    <h1>Order Product</h1>
    <nz-tabset appLoading [loading]="loading" [maskVisible]="true" [(nzSelectedIndex)]="tabIndex">
        <nz-tab nzTitle="Order Sample">
            <app-order-product-modal-tab
                #sampleTabComp
                [productDetail]="productDetail"
                [enableEditQty]="isAccountManagerView"
            ></app-order-product-modal-tab>
        </nz-tab>
        <nz-tab nzTitle="Mass Production">
            <app-order-product-modal-tab #massTabComp [productDetail]="productDetail" [enableEditQty]="true"></app-order-product-modal-tab>
        </nz-tab>
    </nz-tabset>

    <div class="btns d-flex justify-content-between align-items-center width-100p mt-30">
        <button nz-button style="width: 45%" (click)="cancel()">Cancel</button>
        <button nz-button nzType="primary" style="width: 45%" [disabled]="addToCartDisabled" [nzLoading]="submitting" (click)="addToCart()">
            Confirm
        </button>
    </div>
</section>
