<div style="min-height: 100vh; position: relative">
    <nz-tabset class="product-list-tabs" nzType="card">
        <nz-tab nzTitle="My Shop">
            <div class="share">
                <button nz-button nzType="primary" class="lifo-btn share-btn" (click)="copyShopLink()">
                    <img src="assets/imgs/send-icon.png" />
                    <span>Share Store</span>
                </button>
                <button nz-button class="preview-btn" (click)="openPreviewPage()">
                    <img src="assets/imgs/market-white.png" />
                    <span class="text">Preview</span>
                </button>
            </div>
            <app-select-product></app-select-product>
        </nz-tab>
        <nz-tab nzTitle="Settings">
            <app-shop-settings></app-shop-settings>
        </nz-tab>
    </nz-tabset>
</div>
