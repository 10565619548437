<section class="operators section-operators">
    <!-- Banner -->
    <div class="operate-item">
        <div class="operate-name" (click)="operateSection('banner')">Banner</div>
        <div class="operate-content">
            <div class="position-box">
                <div class="label">Choose Texts Position</div>

                <div *ngIf="siteView === 'desktop'">
                    <nz-radio-group [(ngModel)]="shopDetail.theme_settings.banner.layout">
                        <label
                            nz-radio
                            [nzValue]="'on_the_image'"
                            [ngClass]="{selected: shopDetail.theme_settings.banner.layout === 'on_the_image'}"
                        >
                            Center
                        </label>
                        <label nz-radio [nzValue]="'left'" [ngClass]="{selected: shopDetail.theme_settings.banner.layout === 'left'}">
                            Left
                        </label>
                        <label nz-radio [nzValue]="'right'" [ngClass]="{selected: shopDetail.theme_settings.banner.layout === 'right'}">
                            Right
                        </label>
                    </nz-radio-group>
                </div>

                <div *ngIf="siteView === 'mobile'">
                    <nz-radio-group [(ngModel)]="shopDetail.theme_settings.banner.mobileTextPosition">
                        <label
                            nz-radio
                            [nzValue]="'top'"
                            [ngClass]="{selected: shopDetail.theme_settings.banner.mobileTextPosition === 'top'}"
                        >
                            Top
                        </label>
                        <label
                            nz-radio
                            [nzValue]="'center'"
                            [ngClass]="{selected: shopDetail.theme_settings.banner.mobileTextPosition === 'center'}"
                        >
                            Center
                        </label>
                        <label
                            nz-radio
                            [nzValue]="'bottom'"
                            [ngClass]="{selected: shopDetail.theme_settings.banner.mobileTextPosition === 'bottom'}"
                        >
                            Bottom
                        </label>
                    </nz-radio-group>
                </div>

                <div class="mt-20">
                    <div class="label">Call To Action Button</div>
                    <div>
                        <nz-radio-group [(ngModel)]="shopDetail.theme_settings.banner.heroButtonVisible" style="margin-bottom: 0">
                            <label nz-radio [nzValue]="false" [ngClass]="{selected: !shopDetail.theme_settings.banner.heroButtonVisible}">
                                No
                            </label>
                            <label nz-radio [nzValue]="true" [ngClass]="{selected: shopDetail.theme_settings.banner.heroButtonVisible}">
                                Yes
                            </label>
                        </nz-radio-group>

                        <div *ngIf="shopDetail.theme_settings.banner.heroButtonVisible" style="background-color: #f8f8f9" class="p-10">
                            <div class="label pt-10 pb-10" style="padding-left: 5px; padding-right: 5px">Direction</div>
                            <nz-radio-group
                                [(ngModel)]="buttonLinkType"
                                style="margin-bottom: 0"
                                class="banner-product"
                                (ngModelChange)="buttonLinkTypeChange($event)"
                            >
                                <label nz-radio [nzValue]="'all'" [ngClass]="{selected: buttonLinkType === 'all'}">
                                    All products page
                                </label>
                                <label nz-radio [nzValue]="'single'" [ngClass]="{selected: buttonLinkType === 'single'}">
                                    Single Product
                                </label>
                            </nz-radio-group>

                            <button
                                *ngIf="buttonLinkType === 'single'"
                                nz-button
                                nzSize="small"
                                style="margin: 10px auto; display: block"
                                (click)="showSelectProductModal()"
                            >
                                Choose Product
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="hasOngoingCampaign"
                    class="mt-30 d-flex justify-content-space-between align-items-center width-100p p-10"
                    style="border-radius: 12px; background-color: rgba(243, 153, 105, 0.1); color: #f39969"
                >
                    <i nz-icon nzType="info-circle" nzTheme="outline" class="mr-10"></i>
                    <span class="fs-12" style="line-height: 16px"> You have an ongoing event using the event banner. </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Recommended Product -->
    <div class="operate-item">
        <div class="operate-name" (click)="operateSection('recommended')">Recommended Products</div>
        <div class="operate-content">
            <div class="position-box">
                <div class="label">Choose Layout</div>
                <div class="mt-10 mb-10" style="color: #969696; font-size: 12px">
                    *Changes will also apply to the {{siteView === 'desktop' ? 'mobile' : 'desktop'}} version
                </div>
                <div *ngIf="siteView === 'desktop'">
                    <nz-radio-group [(ngModel)]="shopDetail.theme_settings.recommended.layout">
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'one_product'}"
                            [nzValue]="'one_product'"
                            nz-radio
                        >
                            Single Product
                        </label>
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'swiper_product'}"
                            [nzValue]="'swiper_product'"
                            nz-radio
                        >
                            Small Inventory
                        </label>
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'four_product_grid'}"
                            [nzValue]="'four_product_grid'"
                            nz-radio
                        >
                            Large Inventory
                        </label>
                    </nz-radio-group>
                </div>
                <div *ngIf="siteView === 'mobile'">
                    <nz-radio-group [(ngModel)]="shopDetail.theme_settings.recommended.layout">
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'one_product'}"
                            [nzValue]="'one_product'"
                            nz-radio
                        >
                            Single Product
                        </label>
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'swiper_product'}"
                            [nzValue]="'swiper_product'"
                            nz-radio
                        >
                            Small Inventory
                        </label>
                        <label
                            [ngClass]="{selected: shopDetail.theme_settings.recommended.layout === 'four_product_grid'}"
                            [nzValue]="'four_product_grid'"
                            nz-radio
                        >
                            Large Inventory
                        </label>
                    </nz-radio-group>
                </div>

                <button (click)="showChooseProductModal()" class="width-100p" nz-button style="margin-top: 15px">Add Recommendation</button>
            </div>
        </div>
    </div>

    <!-- About Me -->
    <div class="operate-item">
        <div class="operate-name" (click)="operateSection('about')">About Me</div>
        <div class="operate-content">
            <div class="position-box">
                <ng-container *ngIf="siteView === 'desktop'">
                    <div class="label">Choose Texts Position</div>
                    <div>
                        <nz-radio-group [(ngModel)]="shopDetail.theme_settings.aboutMe.direction">
                            <label
                                [ngClass]="{selected: shopDetail.theme_settings.aboutMe.direction === 'reverse'}"
                                [nzValue]="'reverse'"
                                nz-radio
                            >
                                Left
                            </label>
                            <label
                                [ngClass]="{selected: shopDetail.theme_settings.aboutMe.direction === 'basic'}"
                                [nzValue]="'basic'"
                                nz-radio
                            >
                                Right
                            </label>
                        </nz-radio-group>
                    </div>
                </ng-container>

                <ng-container *ngIf="siteView === 'mobile'">
                    <div class="label">Profile Layout</div>
                    <div>
                        <nz-radio-group [(ngModel)]="shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle">
                            <label
                                [ngClass]="{selected: shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'full_size'}"
                                [nzValue]="'full_size'"
                                nz-radio
                            >
                                Full Size
                            </label>
                            <label
                                [ngClass]="{selected: shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained'}"
                                [nzValue]="'contained'"
                                nz-radio
                            >
                                Contained
                            </label>
                        </nz-radio-group>
                    </div>
                </ng-container>

                <div class="label mt-30">Profile</div>
                <div>
                    <nz-radio-group (ngModelChange)="aboutMediaTypeChange($event)" [(ngModel)]="aboutSectionMediaType">
                        <label [ngClass]="{selected: aboutSectionMediaType === 'image'}" [nzValue]="'image'" nz-radio> Image </label>
                        <label [ngClass]="{selected: aboutSectionMediaType === 'video'}" [nzValue]="'video'" nz-radio>
                            Link Online Video
                        </label>
                    </nz-radio-group>
                </div>
            </div>
        </div>
    </div>
</section>
