import { ReuseService } from './services/route-reuse.service';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, ComponentFactoryResolver, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import en from '@angular/common/locales/en';

import { SharedModule } from '@src/app/shared/shared.module';
import { IntercomModule } from 'ng-intercom';
import { AuthModule } from './modules/auth/auth.module';
import { SourcingRequestModule } from '@src/app/modules/pages-no-sub-wrapper/sourcing-request/sourcing-request.module';
import { PlansComponent } from './modules/plans/plans.component';
import { FaqComponent } from '@modules/faq/faq.component';

import { HomeModule } from '@src/app/modules/home/home.module';
import { BalanceHistoryModule } from '@modules/balance-history/balance-history.module';
import { nzConfigFactory } from '@shared/components/antd-global-config/antd-global-config.component';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { ProfileModule } from '@modules/profile/profile.module';
import { StorefrontModule } from './modules/storefront/storefront.module';
import { ShopModule } from './modules/shop/shop.module';
import { GlobalErrorHandler } from './services/global-error-handler';
import { NgxStripeModule } from 'ngx-stripe';
import { PagesNoSubWrapperModule } from '@modules/pages-no-sub-wrapper/pages-no-sub-wrapper.module';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { ReferralComponent } from './modules/referral/referral.component';

registerLocaleData(en);

@NgModule({
    declarations: [AppComponent, PlansComponent, FaqComponent, ReferralComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AuthModule,
        BrowserAnimationsModule,
        IntercomModule.forRoot({
            appId: 'etq9z8nj', // from your Intercom config
            updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
        }),
        HomeModule,
        BalanceHistoryModule,
        ProfileModule,
        StorefrontModule,
        SourcingRequestModule,
        ShopModule,
        PagesNoSubWrapperModule,
        AngularFireAnalyticsModule,
        NgxStripeModule.forRoot(environment.stripe.token),
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: NZ_CONFIG, useFactory: nzConfigFactory, deps: [Injector, ComponentFactoryResolver] },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: APP_NAME,
            useValue: 'Creator Hub',
        },
        {
            provide: APP_VERSION,
            useValue: 'r2.0',
        },
        ScreenTrackingService,
        UserTrackingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
