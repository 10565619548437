<div class="image-gallery-container">
    <div class="gallery-header">
        <div *ngIf="selectedImage.name" class="image-title">{{selectedImage.name}}</div>
        <a class="close-button" (click)="closeGallery.emit()"><img src="assets/svg/ic_close.svg" /></a>
    </div>

    <div class="gallery-content">
        <a class="change-image-button previous" (click)="changeImage('prev')">
            <div *ngIf="prev_index !== null && !isOnlyOneImage">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M28.0052 33.7937C27.6073 33.7937 27.2073 33.6416 26.9031 33.3374L19.6385 26.1082C19.3448 25.8145 19.1802 25.4166 19.1802 24.9999C19.1802 24.5853 19.3448 24.1874 19.6385 23.8937L26.9031 16.6603C27.5135 16.052 28.501 16.052 29.1115 16.6645C29.7198 17.277 29.7177 18.2666 29.1073 18.8749L22.9552 24.9999L29.1073 31.1249C29.7177 31.7332 29.7198 32.7207 29.1115 33.3332C28.8073 33.6416 28.4052 33.7937 28.0052 33.7937Z"
                    />
                </svg>
            </div>
        </a>

        <div class="height-100p">
            <ng-container *ngFor="let item of imagesList">
                <div
                    *ngIf="item.type === 'image'"
                    [hidden]="item.url !== selectedImage.url"
                    class="image-container"
                    [style.background-image]="'url('+item.url+')'"
                ></div>
                <div *ngIf="item.type === 'video'" class="image-container" [hidden]="item.url !== selectedImage.url">
                    <video [src]="item.url" width="100%" height="100%" style="object-fit: contain" controls></video>
                </div>
            </ng-container>
        </div>

        <a class="change-image-button next" (click)="changeImage('next')">
            <div *ngIf="next_index !== null && !isOnlyOneImage">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.9948 16.2063C22.3927 16.2063 22.7927 16.3584 23.0969 16.6626L30.3615 23.8918C30.6552 24.1855 30.8198 24.5834 30.8198 25.0001C30.8198 25.4147 30.6552 25.8126 30.3615 26.1063L23.0969 33.3397C22.4865 33.948 21.499 33.948 20.8885 33.3355C20.2802 32.723 20.2823 31.7334 20.8927 31.1251L27.0448 25.0001L20.8927 18.8751C20.2823 18.2668 20.2802 17.2793 20.8885 16.6668C21.1927 16.3584 21.5948 16.2063 21.9948 16.2063Z"
                        fill="white"
                    />
                </svg>
            </div>
        </a>
    </div>

    <div *ngIf="!isOnlyOneImage" class="gallery-footer">
        <div
            class="image-preview"
            style="background-color: #eee"
            [class.active]="file.url === selectedImage.url"
            *ngFor="let file of imagesList"
            appFilePreview
            [file]="file"
            (click)="selectedImage = file"
        ></div>
    </div>
</div>
