<div class="mass-product-modal-box">
    <div class="title fw-bold fs-24">{{title}}</div>
    <p *ngIf="productDetail?.product_type === ProductTypeEnum.STANDARD_PRODUCT" style="color: #9f99a3">
        Restock to my fulfillment warehouse.
    </p>

    <div appLoading [loading]="!productDetail" [maskVisible]="true" style="min-height: 200px">
        <ng-container *ngIf="productDetail?.sku_list?.length">
            <div class="mb-10 mt-30">
                <span>{{productDetail?.title | titlecase}}</span>
                <span *ngIf="productDetail?.moq" style="margin-left: 10px; color: #9f99a3">MOQ:{{productDetail.moq}}</span>
            </div>

            <div style="min-height: 50px" class="border-radius-12 sample-variant-items">
                <div *ngFor="let item of productDetail?.sku_list" class="sample-variant-item relative pl-20 pr-20">
                    <div class="d-flex justify-content-start align-items-center" style="height: 70px">
                        <img
                            class="mr-20 border-radius-4 object-fit-cover"
                            width="32"
                            height="32"
                            [src]="item.image || 'assets/imgs/img_default@2x.png'"
                        />
                        <div class="flex-1">{{item.variant_json?.title}}</div>
                        <div class="flex-1" style="text-align: center">
                            {{(item.unit_cost + (isOrderSample ? (item.fulfillment_cost || 0) : 0)) | currency}}
                        </div>
                        <div (click)="$event.preventDefault()">
                            x
                            <nz-input-number
                                style="width: 100px; height: 32px"
                                [ngModel]="variantAmountMap.get(item)"
                                (ngModelChange)="amountChange(item,$event)"
                                [nzFormatter]="formatterInteger"
                                [nzMin]="0"
                                [nzStep]="1"
                                [nzPlaceHolder]="'Quantity'"
                                [style.border]="productDetail.product_type === ProductTypeEnum.STANDARD_PRODUCT && variantAmountMap.get(item) > MAX_ORDER_PRODUCT ? '1px solid #D72828':'none'"
                            ></nz-input-number>
                        </div>
                    </div>

                    <div
                        *ngIf="productDetail.product_type === ProductTypeEnum.STANDARD_PRODUCT && variantAmountMap.get(item) > MAX_ORDER_PRODUCT"
                        class="fs-12"
                        style="color: #d72828; position: absolute; right: 4px; bottom: 0"
                    >
                        <img src="assets/svg/info.svg" width="14" style="margin-right: 4px; vertical-align: middle" />
                        Maximum order quantity for each variant: {{MAX_ORDER_PRODUCT}}
                    </div>
                </div>
            </div>

            <!-- Customize -->
            <div class="mt-30" *ngIf="productDetail?.product_type === ProductTypeEnum.STANDARD_PRODUCT">
                <h3 class="fs-14 fw-500 mb-10">Add Custom packaging</h3>
                <div
                    class="sample-variant-item d-flex justify-content-start align-items-center pl-20 pr-20 border-radius-12"
                    style="height: 50px; background-color: #f8f8f9"
                >
                    <label style="width: 40px" nz-checkbox [(ngModel)]="stickerSectionVisible"></label>
                    <div class="flex-1">Customizable Sticker</div>
                    <div class="flex-1">
                        <div>{{1 | currency}}</div>
                        <div class="fs-12" style="color: #9f99a3">Operation fee per product</div>
                    </div>
                    <div>x{{totalCount}}</div>
                </div>
            </div>

            <!-- Sticker -->
            <div *ngIf="stickerSectionVisible && productDetail?.product_type === ProductTypeEnum.STANDARD_PRODUCT" class="sticker-info">
                <!-- How it works -->
                <div class="how-it-work mt-20">
                    <div class="width-100p d-flex justify-content-between align-items-center">
                        <span>Choose a Sticker or Upload a New Sticker</span>
                        <span class="fs-12 text-decoration-underline" style="color: #9f99a3" (click)="expanded=!expanded"
                            >How it works?</span
                        >
                    </div>

                    <div
                        *ngIf="expanded"
                        [@collapse]
                        class="d-flex justify-content-between align-items-center mt-20 p-20"
                        style="background-color: #f8f8f9; border-radius: 12px"
                    >
                        <img style="width: 45%" src="assets/imgs/sticker-example-1.png" />
                        <img style="width: 45%" src="assets/imgs/sticker-example-2.png" />
                    </div>
                </div>

                <div class="sticker">
                    <div
                        class="image-container"
                        *ngFor="let sticker of stickers"
                        appLoading
                        [loading]="!sticker.url"
                        [maskVisible]="true"
                        [style.opacity]="sticker === selectedSticker?'0.5':1"
                        (click)="selectedSticker=sticker"
                    >
                        <img
                            *ngIf="sticker.url"
                            width="100%"
                            height="100%"
                            class="object-fit-cover"
                            [src]="sticker.url"
                            style="border-radius: 6px"
                        />
                        <img
                            *ngIf="sticker === selectedSticker"
                            src="assets/svg/checkbox-checked.svg"
                            style="position: absolute; top: 0; right: 0"
                        />

                        <div
                            *ngIf="sticker !== selectedSticker"
                            style="
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                border-radius: 4px;
                                background-color: #fff;
                                border: 2px solid #9f99a3;
                            "
                        ></div>
                    </div>
                    <div class="image-container cursor-pointer" [style.border]=" '1px solid rgba(85, 71, 100, 0.08)'">
                        <nz-upload
                            [nzShowUploadList]="false"
                            [nzBeforeUpload]="beforeUpload"
                            [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                        >
                            <div class="add-image-button">
                                <img class="add-image" src="assets/svg/add_icon.svg" />
                                <span>New Sticker</span>
                            </div>
                        </nz-upload>
                    </div>
                </div>
            </div>

            <!-- Total -->
            <div class="amount-calculate mt-30" style="text-align: right">
                <ng-container *ngIf="productDetail?.product_type === ProductTypeEnum.STANDARD_PRODUCT">
                    <div class="sub-total mt-10 text-align-right">
                        <span style="color: #9f99a3">Subtotal:</span>
                        <span class="value">{{subTotal | currency}}</span>
                    </div>
                    <div
                        class="sub-total mt-10 text-align-right"
                        style="padding-bottom: 5px; border-bottom: 1px solid #eee; width: 310px; display: inline-flex"
                    >
                        <span style="color: #9f99a3">Package & Operation Fee:</span>
                        <span class="value">{{(selectedSticker?totalCount:0) | currency}}</span>
                    </div>
                </ng-container>

                <div class="sub-total mt-10 text-align-right">
                    <span style="color: #333333">Total:</span>
                    <span class="value fs-18" style="color: #d8b165">{{ total | currency}}</span>
                </div>
            </div>

            <div *ngIf="productDetail" class="btns d-flex justify-content-between align-items-center width-100p mt-30">
                <button nz-button style="width: 45%" (click)="cancel()">Cancel</button>
                <button nz-button nzType="primary" style="width: 45%" [nzLoading]="submitting" (click)="addToCart()">Add to Cart</button>
            </div>
        </ng-container>
        <div class="empty-table-box" *ngIf="productDetail && !productDetail.sku_list?.length">
            <img src="assets/imgs/no-bulk-order-variants.png" width="105" />
            <div class="desc" style="margin-top: 10px">Sorry, this product has incomplete information.</div>
            <div class="desc mt-20">Please contact your account manager for more information.</div>
            <button nz-button style="width: 45%; margin: 45px 0 20px" (click)="cancel()">Close</button>
        </div>
    </div>
</div>
