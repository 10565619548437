<section class="funds-main-content">
    <div class="loading-box" appLoading [loading]="!accountBalance">
        <ng-container *ngIf="accountBalance">
            <section *ngIf=" !accountBalance.lifo_credit" class="title-container">
                <h1 class="title">You're Eligible for Lifo Creator Funds</h1>
                <p class="sub-title">Receive additional capital support to kick off your business.</p>
                <section class="text-container">
                    <div class="background-box">
                        <section class="left">
                            <span class="box-title">Get Up to $5,000 Creator Funds</span>
                            <section class="box-container">
                                <h1>$<span class="num">5,000</span>.00</h1>
                                <p>You’ll return <strong>10% of your daily sales</strong> until you’ve remitted $5,000.</p>
                                <a target="_blank" href="https://lifo.typeform.com/to/dmDw5ZgC">
                                    <button nz-button nzType="primary">Apply</button>
                                </a>
                            </section>
                        </section>
                    </div>
                </section>
            </section>
            <section *ngIf="accountBalance.lifo_credit" class="title-container">
                <h1 class="title">My Creator Funds</h1>
                <p class="sub-title">Lifo Creator Funds gives you quick and easy access to financing to help support your business.</p>
                <section class="text-container-funds">
                    <div class="background-box">
                        <div class="left">
                            <section class="box-container">
                                <div class="left-box"><img src="assets/svg/total_credit.svg" /></div>
                                <div class="right-box">
                                    <div class="credit-title">
                                        <span>Total Credit</span>
                                        <i
                                            nz-icon
                                            nzType="question"
                                            nzTheme="outline"
                                            nz-tooltip
                                            [nzTooltipTitle]="fundsTooltip"
                                            [nzTooltipOverlayClassName]="'common-tooltip'"
                                            [nzTooltipTrigger]="'click'"
                                            [nzTooltipVisible]="fundsTooltipVisible"
                                            [nzTooltipPlacement]="'right'"
                                            style="
                                                margin-left: 10px;
                                                color: #554764;
                                                background-color: #ebeaed;
                                                border-radius: 50%;
                                                padding: 4px;
                                                font-size: 10px;
                                                cursor: pointer;
                                            "
                                        ></i>
                                        <ng-template #fundsTooltip>
                                            <div class="funds-tooltip-content">
                                                <span class="tooltip-title">Where can I use the funds?<br /><br /></span>
                                                You can use your Creator Funds in purchasing samples and merchandise products. You can use
                                                the funds to deduct up to 40% of the total amount.<br /><br />
                                                Visit the “Create” tab on the left sidebar to stock up for your business!
                                            </div>
                                        </ng-template>
                                    </div>

                                    <h1>
                                        <span class="num">{{ getBalanceBeforeDot(lifoCredit?.totalCredit) | number:'1.0-0' }}</span>.{{
                                        getBalanceAfterDot(lifoCredit?.totalCredit) }}<span class="pts"> pts</span>
                                    </h1>
                                </div>
                            </section>
                            <section class="box-container">
                                <div class="left-box"><img src="assets/svg/available_credit.svg" /></div>
                                <div class="right-box">
                                    <div class="credit-title">Available Credit</div>
                                    <h1>
                                        <span class="num">{{ getBalanceBeforeDot(lifoCredit?.availableCredit) | number:'1.0-0' }}</span>.{{
                                        getBalanceAfterDot(lifoCredit?.availableCredit) }}<span class="pts"> pts</span>
                                    </h1>
                                </div>
                            </section>
                            <section class="box-container">
                                <div class="left-box"><img src="assets/svg/used_credit.svg" /></div>
                                <div class="right-box">
                                    <div class="credit-title">Used Credit</div>
                                    <h1>
                                        <span class="num">{{ getBalanceBeforeDot(lifoCredit?.usedCredit) | number:'1.0-0'}}</span>.{{
                                        getBalanceAfterDot(lifoCredit?.usedCredit) }}<span class="pts"> pts</span>
                                    </h1>
                                </div>
                            </section>
                            <section class="box-container">
                                <div class="left-box"><img src="assets/svg/remitted_credit.svg" /></div>
                                <div class="right-box">
                                    <div class="credit-title">Remitted Credit</div>
                                    <h1>
                                        <span class="num">{{ getBalanceBeforeDot(lifoCredit?.remittedCredit) | number:'1.0-0' }}</span>.{{
                                        getBalanceAfterDot(lifoCredit?.remittedCredit) }}<span class="pts"> pts</span>
                                    </h1>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </ng-container>
    </div>

    <section class="question-container">
        <h1>How Do Lifo Creator Funds Work?</h1>
        <div class="main">
            <div class="list">
                <div class="left">
                    <img src="assets/imgs/funds_edit.png" width="40" />
                </div>
                <div class="right">
                    <h3>Skip the Lengthy Process</h3>
                    <p>Apply the funds with your previous sales record, either selling your own products or brand partnerships.</p>
                </div>
            </div>

            <div class="list">
                <div class="left">
                    <img src="assets/imgs/funds_send.png" width="40" />
                </div>
                <div class="right">
                    <h3>Get Funds Quickly</h3>
                    <p>
                        Once you’re approved, we’ll deposit the funds into your Lifo Creator Hub account as soon as the next business day.
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="left">
                    <img src="assets/imgs/funds_wallet.png" width="40" />
                </div>
                <div class="right">
                    <h3>Pay No Interests</h3>
                    <p>No additional interests involved during the entire process</p>
                </div>
            </div>

            <div class="list">
                <div class="left">
                    <img src="assets/imgs/funds_sales.png" width="40" />
                </div>
                <div class="right">
                    <h3>Repay from Sales</h3>
                    <p>Return funding as a percentage of your sales, with payments that flex to fit your business.</p>
                </div>
            </div>
        </div>
    </section>
</section>
