<section *ngIf="shopDetail?.theme_settings" class="add-social-modal">
    <div class="d-flex justify-content-between align-items-center" style="height: 30px; margin-bottom: 30px">
        <label class="fs-20 fw-500">Add Social Media</label>
        <div class="close-button" [style.cursor]="'pointer'" (click)="modalRef.triggerCancel()">
            <img src="assets/svg/close_icon.svg" />
        </div>
    </div>
    <div class="d-flex social-modal-content">
        <div
            nz-popover
            nzPopoverPlacement="bottom"
            nzPopoverTrigger="click"
            nzPopoverOverlayClassName="no-padding no-bg"
            [nzPopoverContent]="socialMediaPopover"
            [(nzPopoverVisible)]="socialMedialPopOverVisible"
            class="d-flex align-items-center justify-content-center cursor-pointer select-box"
        >
            <img *ngIf="selectedSocial" [src]="getSocialIcon(selectedSocial)" />
            <span [ngStyle]="{color: selectedSocial ? '#1B222E' : '#9F99A3'}">
                {{selectedSocial ? getSocialName(selectedSocial) : 'Choose'}}
            </span>
            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
        </div>
        <input
            nz-input
            [(ngModel)]="shopDetail.theme_settings.socialMedia[selectedSocial]"
            (blur)="updateSocialLink(selectedSocial)"
            placeholder="Paste your social platform URL here"
        />
    </div>
    <div class="modal-footer">
        <button nz-button nzType="default" (click)="modalRef.triggerCancel()">Cancel</button>
        <button
            nz-button
            nzType="primary"
            [disabled]="!selectedSocial || !shopDetail.theme_settings.socialMedia[selectedSocial]"
            (click)="addPlatform()"
        >
            Choose
        </button>
    </div>
</section>

<ng-template #socialMediaPopover>
    <div class="social-list">
        <div class="social-row" *ngFor="let platform of nonAddedSocial" (click)="selectPlatForm(platform)">
            <img [src]="getSocialIcon(platform)" />
            {{ getSocialName(platform) }}
        </div>
    </div>
</ng-template>
