import { ViewChild, Component, OnInit, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';
import { DeckProductCardComponent } from './deck-product-card/deck-product-card.component';
import { slideInRightOnEnterAnimation, slideOutRightOnLeaveAnimation, fadeInAnimation } from 'angular-animations';
import { ShopService } from '@services/shop.service';
import { MessageService } from '@services/message.service';
import {
    SourcingRequestDeck,
    SourcingRequestDeckProduct,
    SourcingRequestDeckProductImage,
    SourcingRequestSectionProduct,
} from './sourcing-request.type';
import { SourcingRequestService } from './sourcing-request.service';
import { OrderProductModalComponent } from './order-product-modal/order-product-modal.component';

@Component({
    selector: 'app-sourcing-request',
    templateUrl: './sourcing-request.component.html',
    styleUrls: ['./sourcing-request.component.less'],
    animations: [
        fadeInAnimation({ anchor: 'fadeIn', duration: 1000 }),
        slideInRightOnEnterAnimation({ anchor: 'slideEnter', duration: 300 }),
        slideOutRightOnLeaveAnimation({ anchor: 'slideLeave', duration: 300 }),
    ],
})
export class SourcingRequestComponent implements OnInit {
    @ViewChildren(DeckProductCardComponent) deckProductCardComps: QueryList<DeckProductCardComponent>;
    @ViewChild('deckProducts') deckProducts: ElementRef<HTMLDivElement>;

    imageWidth: string;

    selectedProduct: SourcingRequestDeckProduct;
    selectedProductImage: SourcingRequestDeckProductImage;

    deckInfo: SourcingRequestDeck;

    commentsModalVisible = false;

    fetchingData = false;

    imageCountPerRow: number;
    addedProducts;

    currentSection = 0;
    currentProduct = 0;

    get shopDetail() {
        return this.shopService.shopProfile;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private shopService: ShopService,
        private sourcingRequestService: SourcingRequestService,
        private messageService: MessageService,
        private router: Router,
        private modalService: NzModalService
    ) {}

    @HostListener('document:keydown.escape')
    closeCommentModal() {
        this.commentsModalVisible = false;
        this.selectedProductImage = null;
    }

    ngOnInit(): void {
        this.shopService.getShopDetail();
        this.getDeckDetail();
    }

    selectedProductChange(product: SourcingRequestSectionProduct, i: any, k: any) {
        let index = 0;
        for (let j = 0; j < i; j++) {
            index += this.deckInfo.section_list[j].product_list.length;
        }
        index += k;
        this.currentSection = i;
        this.currentProduct = k;
        this.selectedProduct = this.deckInfo.product_list.find(ele => ele.product_id === product.product_id);
        this.calculateImageSize();
        this.makeSelectProductCenter(index);
        this.closeCommentModal();
    }

    moveToBottom() {
        const dom = document.querySelector('.comment-modal-box .comments-box');
        dom.scrollTop = dom.scrollHeight;
    }

    goProductCard(goNext: 1 | -1) {
        const sectionsLen = this.deckInfo.section_list.length;
        const productsLen = this.deckInfo.section_list[this.currentSection].product_list.length;
        if (goNext > 0) {
            // Next
            if (this.currentProduct + 1 < productsLen) {
                this.currentProduct += 1;
            } else {
                if (this.currentSection + 1 < sectionsLen) {
                    this.currentSection += 1;
                    this.currentProduct = 0;
                } else {
                    this.currentSection = 0;
                    this.currentProduct = 0;
                }
            }
        } else {
            // Previous
            if (this.currentProduct > 0) {
                this.currentProduct -= 1;
            } else {
                if (this.currentSection > 0) {
                    this.currentSection -= 1;
                    this.currentProduct = this.deckInfo.section_list[this.currentSection].product_list.length - 1;
                } else {
                    this.currentSection = sectionsLen - 1;
                    this.currentProduct = this.deckInfo.section_list[this.currentSection].product_list.length - 1;
                }
            }
        }
        this.selectedProductChange(
            this.deckInfo.section_list[this.currentSection].product_list[this.currentProduct],
            this.currentSection,
            this.currentProduct
        );
    }

    goSourcingRequest() {
        this.router.navigate(['/', 'nidavellir', 'product-list'], {
            queryParams: { tab: 'recommendation' },
        });
    }

    selectProductImage(imageItem) {
        this.selectedProductImage = imageItem;
        this.commentsModalVisible = true;
    }

    showOrderProductModal() {
        this.modalService.create({
            nzContent: OrderProductModalComponent,
            nzFooter: null,
            nzComponentParams: {
                productId: this.selectedProduct.standard_product_id,
            },
            nzOnOk: comp => (this.addedProducts = comp.addedProducts),
        });
    }

    private makeSelectProductCenter(index: number) {
        const comp = this.deckProductCardComps.toArray()[index];

        if (comp) {
            const screenWidth = document.querySelector('.sourcing-request-parent').clientWidth;
            const divWidth = comp.ele.nativeElement.clientWidth;
            const offset = (screenWidth - divWidth) / 2;
            const offsetLeft = comp.ele.nativeElement.offsetLeft;
            this.deckProducts.nativeElement.scroll(offsetLeft - offset, 0);
        }
    }

    private getDeckDetail() {
        this.fetchingData = true;
        const deckId = this.activatedRoute.snapshot.paramMap.get('deckId');
        this.sourcingRequestService
            .getDeckDetailById(deckId)
            .then(res => {
                res.product_list.forEach(productItem => {
                    productItem.images = productItem.images.filter(item => item.display);
                });

                res.section_list.forEach(section => {
                    section.product_list = section.product_list.filter(product => product.images.length > 0);
                });

                this.deckInfo = res;
                this.selectedProduct = this.deckInfo.product_list[0];
                this.calculateImageSize();
                this.cdr.detectChanges();
                this.makeSelectProductCenter(0);
            })
            .catch(() => this.router.navigate(['/page-not-found']))
            .finally(() => (this.fetchingData = false));
    }

    private calculateImageSize() {
        this.selectedProduct.images = this.selectedProduct.images.slice(0, 20);
        const imageCount = this.selectedProduct.images.length;
        this.imageCountPerRow = imageCount > 4 ? Math.ceil((imageCount > 10 ? 10 : imageCount) / 2) : imageCount;
        this.imageWidth = `${((1 / this.imageCountPerRow) * 100).toFixed(2)}%`;
    }
}
