import { SourcingRequestSectionProduct } from './../sourcing-request.type';
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-deck-product-card',
    templateUrl: './deck-product-card.component.html',
    styleUrls: ['./deck-product-card.component.less'],
})
export class DeckProductCardComponent implements OnInit {
    @Input() productItem: SourcingRequestSectionProduct;
    @ViewChild('images') images: ElementRef;

    constructor(public ele: ElementRef<HTMLDivElement>) {}

    get widthOfImages() {
        if (this.images) {
            return `${Math.max(this.images.nativeElement.offsetWidth, 500)} px`;
        } else {
            return '500px';
        }
    }

    ngOnInit(): void {
        this.productItem.images = this.productItem.images.filter(image => image.display === true);
    }
}
