import { Directive, Input, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appStatusBtn]',
})
export class StatusBtnDirective implements AfterViewInit, OnChanges {
    @Input() color = '#4F4F4F'; // format: #ffffff
    @Input() type: 'success' | 'error' | 'default';
    @Input() showDot = true;

    private typeColorMap = new Map([
        ['success', '#27AE60'],
        ['error', '#db5757'],
        ['info', '#2F80ED'],
        ['default', '#4F4F4F'],
    ]);

    private dotSpan: HTMLSpanElement;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.elementHandler(this.el.nativeElement);
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.color?.currentValue) {
            if (this.dotSpan) {
                this.dotSpan.style.backgroundColor = this.color;
            }

            if (this.el.nativeElement) {
                (this.el.nativeElement as HTMLDivElement).style.color = this.color;
                (this.el.nativeElement as HTMLDivElement).style.backgroundColor = `${this.color}1A`;
            }
        }

        if (e.type?.currentValue) {
            if (this.dotSpan) {
                this.dotSpan.style.backgroundColor = this.typeColorMap.get(this.type);
            }

            if (this.el.nativeElement) {
                (this.el.nativeElement as HTMLDivElement).style.color = this.typeColorMap.get(this.type);
                (this.el.nativeElement as HTMLDivElement).style.backgroundColor = `${this.typeColorMap.get(this.type)}1A`;
            }
        }
    }

    elementHandler(element: HTMLElement) {
        if (this.showDot) {
            const firstEle = element.firstChild;
            this.dotSpan = document.createElement('span');
            this.dotSpan.setAttribute(
                'style',
                `display:inline-block; width:10px; height:10px; border-radius:50%; vertical-align:middle; margin-right:4px;
                background-color:${this.typeColorMap.get(this.type) || this.color}`
            );

            element.insertBefore(this.dotSpan, firstEle);
        }

        element.setAttribute(
            'style',
            `padding:4px 8px; font-size:14px; line-height:14px; border-radius:12px; white-space:nowrap;
            background-color:${this.typeColorMap.get(this.type) || this.color}1A;
            color:${this.typeColorMap.get(this.type) || this.color}`
        );
    }
}
