import { StorefrontStateService } from './../../storefront-state.service';
import { ChooseProductModalComponent } from './../../choose-product-modal/choose-product-modal.component';
import { isArray } from 'lodash';
import { YoutubeVideoModalComponent } from './../../youtube-video-modal/youtube-video-modal.component';
import { ChooseSingleProductModalComponent } from './../../banner/choose-single-product-modal/choose-single-product-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Item } from '@syncfusion/ej2-angular-navigations';

@Component({
    selector: 'app-section-configuration',
    templateUrl: './section-configuration.component.html',
    styleUrls: ['./section-configuration.component.less'],
})
export class SectionConfigurationComponent implements OnInit, OnChanges {
    @Input() shopDetail: ShopProfile;
    @Input() siteView: 'mobile' | 'desktop';
    @Input() hasOngoingCampaign: boolean;

    aboutYoutubeVideoUrl: string;
    buttonLinkType: 'single' | 'all' = 'all';

    get aboutSectionMediaType() {
        return this.storefrontStateService.aboutSectionMediaType;
    }
    set aboutSectionMediaType(val) {
        this.storefrontStateService.aboutSectionMediaType = val;
    }

    constructor(private modalService: NzModalService, private storefrontStateService: StorefrontStateService) {}

    ngOnInit(): void {}

    ngOnChanges(e: SimpleChanges) {
        if (e.shopDetail?.currentValue) {
            if (this.shopDetail.theme_settings.banner.heroButtonLinkProductId) {
                this.buttonLinkType = 'single';
            } else {
                this.buttonLinkType = 'all';
            }

            if (this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl) {
                this.aboutYoutubeVideoUrl = this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl;
                this.aboutSectionMediaType = 'video';
            }
        }
    }

    buttonLinkTypeChange(val) {
        if (val === 'single') {
            this.showSelectProductModal();
        }

        if (val === 'all') {
            this.shopDetail.theme_settings.banner.heroButtonLinkProductId = null;

            if (this.shopDetail.shop_type === 'Shared') {
                this.shopDetail.theme_settings.banner.heroButtonLink = '/product/product-list';
            } else {
                this.shopDetail.theme_settings.banner.heroButtonLink = '/collections/all';
            }
        }
    }

    // CTA
    showSelectProductModal() {
        this.modalService.create({
            nzContent: ChooseSingleProductModalComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWidth: '700px',
            nzComponentParams: {
                selectedProductId: String(this.shopDetail.theme_settings.banner.heroButtonLinkProductId || ''),
                shopDetail: this.shopDetail,
            },
            nzOnOk: comp => {
                this.shopDetail.theme_settings.banner.heroButtonLinkProductId = comp.selectedProductId;

                if (this.shopDetail.shop_type === 'Shared') {
                    this.shopDetail.theme_settings.banner.heroButtonLink = `/product/product-details/${comp.selectedProductId}`;
                } else {
                    const productShopifyLink = comp.productList.find(product => product.product_id === comp.selectedProductId)?.link;
                    this.shopDetail.theme_settings.banner.heroButtonLink = isArray(productShopifyLink)
                        ? productShopifyLink[0]
                        : productShopifyLink;
                }
            },
            nzOnCancel: () => {
                if (!this.shopDetail.theme_settings.banner.heroButtonLinkProductId) {
                    this.buttonLinkType = 'all';
                }
            },
        });
    }

    // Single Product
    showSelectSingleProductModal() {
        this.modalService.create({
            nzContent: ChooseSingleProductModalComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWidth: '700px',
            nzComponentParams: {
                selectedProductId: String(this.storefrontStateService.currentSelectedProducts[0]?.product_id || ''),
                shopDetail: this.shopDetail,
            },
            nzOnOk: comp => {
                this.storefrontStateService.currentSelectedProducts = this.storefrontStateService.productList.filter(
                    item => String(item.product_id) === String(comp.selectedProductId)
                );
                this.storefrontStateService.currentSelectedProducts$.next(this.storefrontStateService.currentSelectedProducts);
            },
        });
    }

    recommendedLayoutChange(val: 'one_product' | 'swiper_product' | 'four_grid') {
        if (val === 'one_product' && this.storefrontStateService.currentSelectedProducts?.length) {
            this.storefrontStateService.currentSelectedProducts = [this.storefrontStateService.currentSelectedProducts[0]];
        }
    }

    aboutMediaTypeChange(val: 'video' | 'image') {
        if (val === 'video') {
            this.showAddYoutubeVideoModal();
        }
    }

    showChooseProductModal() {
        if (this.shopDetail.theme_settings.recommended.layout === 'one_product') {
            this.showSelectSingleProductModal();
        } else {
            this.modalService.create({
                nzContent: ChooseProductModalComponent,
                nzComponentParams: {
                    shopDetail: this.shopDetail,
                },
                nzWidth: '555px',
                nzFooter: null,
                nzMaskClosable: false,
                nzWrapClassName: 'ant-modal-centered color-panel-modal',
            });
        }
    }

    showAddYoutubeVideoModal() {
        this.modalService.create({
            nzContent: YoutubeVideoModalComponent,
            nzComponentParams: {
                aboutYoutubeVideUrl: this.aboutYoutubeVideoUrl,
            },
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzOnOk: comp => {
                this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl = comp.aboutYoutubeVideUrl;
                this.aboutYoutubeVideoUrl = comp.aboutYoutubeVideUrl;
            },
            nzOnCancel: () => {
                if (!this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl) {
                    this.aboutSectionMediaType = 'image';
                }
            },
        });
    }

    operateSection(sectionName: string) {
        if (sectionName === 'banner') {
            document.querySelector('.banner-box').scrollIntoView({ behavior: 'smooth' });
        }

        if (sectionName === 'recommended') {
            document.querySelector('.recommended-box').scrollIntoView({ behavior: 'smooth' });
        }

        if (sectionName === 'about') {
            document.querySelector('.about-box').scrollIntoView({ behavior: 'smooth' });
        }
    }
}
