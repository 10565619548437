export enum SubscribePlanNameEnum {
    STARTER = 'starter',
    EXPERIENCED = 'experienced',
    ADVANCED = 'advanced',
    PRO = 'pro',
    PREMIUM = 'premium',
}

export interface SubscribePlan {
    plan_text: SubscribePlanNameEnum;
    monthly_fee: number;
    order_fulfillment_quota: number;
    product_sourcing_quota: number;
    lifo_funds: number;
}

export const CARD_LIST: SubscribePlan[] = [
    {
        plan_text: SubscribePlanNameEnum.ADVANCED,
        monthly_fee: 29.99,
        order_fulfillment_quota: 20,
        product_sourcing_quota: 1,
        lifo_funds: 5000,
    },
    {
        plan_text: SubscribePlanNameEnum.PREMIUM,
        monthly_fee: Infinity,
        order_fulfillment_quota: Infinity,
        product_sourcing_quota: Infinity,
        lifo_funds: 5000,
    },
];
