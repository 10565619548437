import { ShoppingCartComponent } from '@shared/components/shopping-cart/shopping-cart.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
    selector: 'app-new-order-payment-modal',
    templateUrl: './new-order-payment-modal.component.html',
    styleUrls: ['./new-order-payment-modal.component.less'],
})
export class NewOrderPaymentModalComponent implements OnInit {
    @ViewChild('cart') cart: ShoppingCartComponent;
    @Input() paymentItem: any;

    constructor(private modalRef: NzModalRef, private router: Router) {}

    ngOnInit(): void {}

    approve() {
        this.modalRef.triggerOk();
        this.router.navigate(['/', 'my-purchase', 'orders']);
    }
}
