<section *ngIf="shopDetail?.theme_settings" class="add-social-modal product-modal">
    <div class="d-flex justify-content-between align-items-center" style="height: 30px; margin-bottom: 30px">
        <label class="fs-20 fw-500">Choose One of the Products Below:</label>
    </div>
    <nz-table class="product-list" #productTable [nzData]="productList" [nzPageSize]="5" [nzOuterBordered]="true" [nzNoResult]="emptyPage">
        <tbody>
            <tr *ngFor="let data of productTable.data" (click)="chooseProduct(data)">
                <td [width]="'40px'">
                    <i
                        nz-icon
                        [nzType]="data.product_id === selectedProductId ? 'antd:radio-checked':'antd:radio-unchecked'"
                        style="cursor: pointer; font-size: 16px"
                    ></i>
                </td>
                <td [width]="'50px'">
                    <section class="product-img">
                        <img *ngIf="data.image" [src]="data.image" />
                        <img *ngIf="!data.image" src="assets/imgs/img_default@2x.png" />
                    </section>
                </td>
                <td>{{data.title}}</td>
                <td>${{data.price | number: "1.2-2"}}</td>
            </tr>
        </tbody>
    </nz-table>
    <ng-template #emptyPage>
        <div class="empty-table-box">
            <img src="assets/imgs/no-activity.png" width="105" />
            <div class="desc mt-20">No product.</div>
            <div class="desc mt-30 mb-30">Create your custom product for selling now!</div>
        </div>
    </ng-template>
    <div class="modal-footer">
        <button nz-button nzType="default" (click)="modalRef.triggerCancel()">Cancel</button>
        <button
            *ngIf="productList?.length; else createProduct"
            nz-button
            nzType="primary"
            [disabled]="!selectedProductId"
            (click)="modalRef.triggerOk()"
        >
            Choose
        </button>
        <ng-template #createProduct>
            <button nz-button nzType="primary" [routerLink]="['/','nidavellir','product-list']" (click)="modalRef.triggerCancel()">
                Create Product
            </button>
        </ng-template>
    </div>
</section>
