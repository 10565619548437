import { NgModule } from '@angular/core';
import { LocaltimePipe } from '@pipes/localtime.pipe';
import { EmailAsteriskPipe } from '@pipes/email-asterisk.pipe';
import { ShortNumberPipe } from '@pipes/short-number.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SlicePipe } from './slice.pipe';
import { SplitPipe } from './split.pipe';

@NgModule({
    declarations: [LocaltimePipe, EmailAsteriskPipe, ShortNumberPipe, SafeUrlPipe, SafeHtmlPipe, SlicePipe, SplitPipe],
    exports: [LocaltimePipe, EmailAsteriskPipe, ShortNumberPipe, SafeUrlPipe, SafeHtmlPipe, SlicePipe, SplitPipe],
})
export class PipesModule {}
