import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NidavellirService } from '@modules/nidavellir/nidavellir.service';
import * as dayjs from 'dayjs';

interface PreferredDateItem {
    date: string;
    timeRange: string;
    week: string;
    id: any;
}

@Component({
    selector: 'app-choose-video-date',
    templateUrl: './choose-video-date.component.html',
    styleUrls: ['./choose-video-date.component.less'],
})
export class ChooseVideoDateComponent implements OnInit {
    dateList: PreferredDateItem[];

    selectedDates = new Set<PreferredDateItem>();

    submitting = false;

    constructor(public modalRef: NzModalRef, private nidavellirService: NidavellirService) {}

    ngOnInit(): void {
        this.nidavellirService.getTimeSlot().then((data: any) => {
            this.dateList = this.generateDateList(data);
        });
    }

    change(val: PreferredDateItem) {
        if (this.selectedDates.has(val)) {
            this.selectedDates.delete(val);
        } else {
            this.selectedDates.add(val);
        }
    }

    generateDateList(data: { id: any; start_time: string; end_time: string }[]) {
        const res: PreferredDateItem[] = [];

        data.forEach(item => {
            const date = dayjs(item.start_time).tz('PST').format('YYYY-MM-DD');
            const week = dayjs(item.start_time).tz('PST').format('dddd');

            const startTime = dayjs(item.start_time).tz('PST').format('HH:mm');
            const endTime = dayjs(item.end_time).tz('PST').format('HH:mm');

            res.push({ date, week, timeRange: `${startTime} - ${endTime}`, id: item.id });
        });

        return res;
    }
}
