import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    constructor() {}

    checkIfImagePixelValid(file: File, limitations: { width: number; height: number; size: number }) {
        return new Promise<void>((resolve, reject) => {
            const pURL = window.URL || window.webkitURL;
            const img = new Image();
            img.onload = () => {
                const valid = img.width <= limitations.width && img.height <= limitations.height && file.size <= limitations.size * 1024;
                if (valid) {
                    resolve();
                } else {
                    reject();
                }
            };
            img.src = pURL.createObjectURL(file);
        });
    }

    checkIfImagePixelGreater(file, limitations: { width: number; height: number }) {
        return new Promise<void>((resolve, reject) => {
            const URL = window.URL || window.webkitURL;
            const img = new Image();
            img.onload = () => {
                const valid = img.width >= limitations.width && img.height >= limitations.height;
                if (valid) {
                    resolve();
                } else {
                    reject();
                }
            };
            img.src = URL.createObjectURL(file);
        });
    }

    replaceNewLineSignal(data: string) {
        return data.replace(new RegExp('\r?\n', 'g'), '<br />') || '';
    }

    async downloadImage(imageSrc: string, fileName: string) {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
