import { UploadedFile } from '@shared/directives/file-preview.directive';
import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ImageGalleryComponent } from '@shared/components/image-gallery/image-gallery.component';

@Injectable({
    providedIn: 'root',
})
export class ImagePreviewFullscreenService {
    private rootViewContainer: ViewContainerRef;

    constructor(private factoryResolver: ComponentFactoryResolver) {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    showModal(images: UploadedFile[], selectedImage: UploadedFile) {
        const factory = this.factoryResolver.resolveComponentFactory(ImageGalleryComponent);
        const component = factory.create(this.rootViewContainer.parentInjector);
        component.instance.imagesList = images;
        component.instance.selectedImage = selectedImage;

        component.instance.closeGallery.subscribe(() => {
            component.destroy();
        });

        this.rootViewContainer.insert(component.hostView);
    }
}
