<div>
    <h2 class="mb-30 fs-20 fw-bold">Add Recommended Products</h2>

    <div class="product-container mb-30">
        <h3 class="fs-16 mb-20">Home Page Recommendation</h3>

        <div
            cdkDropList
            #selectedList="cdkDropList"
            [cdkDropListData]="selectedProducts"
            [cdkDropListConnectedTo]="[unselectedList]"
            class="product-list"
            (cdkDropListDropped)="drop($event)"
        >
            <div class="product-box" *ngFor="let item of selectedProducts;let i = index;" cdkDrag>
                <div class="item-placeholder" *cdkDragPlaceholder></div>

                <div class="mr-20">
                    <img class="object-fit-cover border-radius-8" [src]="item.image" width="32" height="32" />
                </div>

                <div class="flex-1 column">{{item.title | nzEllipsis: 20:'...'}}</div>

                <div class="ml-20 mr-20">
                    <span [class]="'order-item order-' + i" [style.opacity]="i > 2 ? 0 : 1">{{i + 1}}</span>
                </div>

                <div class="mr-20" style="width: 80px; text-align: right">{{item.price | currency}}</div>

                <div class="product-handle" style="height: 24px" cdkDragHandle>
                    <i nz-icon nzType="svg:drag_icon" style="font-size: 24px; color: #9f99a3; cursor: grab"></i>
                </div>
            </div>

            <div *ngIf="selectedProducts.length === 0" class="empty-box pt-20 pb-20">
                <img src="assets/imgs/null.png" height="90px" />
                <p class="mt-20 fs-12" style="color: #f3745e">No recommended products in home page</p>
            </div>
        </div>
    </div>

    <div class="product-container">
        <h3 class="fs-16 mb-10">All Listed Products</h3>

        <nz-alert
            nzType="warning"
            nzMessage="Highlight your products in the home page"
            nzShowIcon
            [nzIconType]="'bulb'"
            class="mb-10"
        ></nz-alert>

        <div
            cdkDropList
            cdkDropListSortingDisabled
            #unselectedList="cdkDropList"
            class="product-list unselected-list"
            [cdkDropListData]="unselectedProducts"
            [cdkDropListConnectedTo]="[selectedList]"
            (cdkDropListDropped)="drop($event)"
        >
            <div class="product-box" *ngFor="let item of unselectedProducts;let i = index;" cdkDrag>
                <div class="item-placeholder" *cdkDragPlaceholder></div>

                <div class="mr-20">
                    <img class="object-fit-cover border-radius-8" [src]="item.image" width="32" height="32" />
                </div>

                <div class="flex-1 column">{{item.title | nzEllipsis: 20:'...'}}</div>

                <div class="ml-20 mr-20" style="width: 80px; text-align: right">{{item.price | currency}}</div>

                <div class="product-handle" style="height: 24px" cdkDragHandle>
                    <i nz-icon nzType="svg:drag_icon" style="font-size: 24px; color: #9f99a3; cursor: grab"></i>
                </div>
            </div>

            <div *ngIf="unselectedProducts.length === 0" class="empty-box">No other listing available</div>
        </div>

        <div class="d-flex justify-content-around align-items-center mt-30">
            <button nz-button style="width: 40%" (click)="cancel()">Cancel</button>
            <button nz-button style="width: 40%" nzType="primary" [disabled]="selectedProducts.length ===0" (click)="ok()">Save</button>
        </div>
    </div>
</div>
