<section class="deck-product-card-item p-20">
    <div #images class="relative d-flex justify-content-start align-items-center overflow-hidden" style="border-radius: 12px">
        <img
            appImgLoadSmooth
            *ngFor="let imgItem of productItem.images | slice:5"
            style="width: 100px; height: 100px; object-fit: cover"
            [src]="imgItem.src"
        />
    </div>
</section>
