import { PaypalService } from './../../services/paypal.service';
import { ConnectPaymentComponent } from '@shared/components/connect-payment/connect-payment.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CUSTOM_ANALYTICS_EVENT } from './../../services/firebase-event.service';
import { FirebaseEventsService } from '@services/firebase-event.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from '@services/profile.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import { merge, Subject, Observable } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { AvailablePaymentMethods } from '@typings/profile.typing';
import { PlaidService } from '@services/plaid.service';
import { ShopService } from '@services/shop.service';
import { SubscribePlanNameEnum } from '@src/app/shared/components/subscription/subscription.data';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less'],
})
export class ProfileComponent implements OnInit, OnDestroy {
    @ViewChild('subscriptionCard') subscriptionCard;
    isChangePasswordFormDisplayed = false;
    isPasswordChangedMessageDisplayed = false;
    isOverlayDisplayed = false;

    changePasswordForm = this.fb.group({
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required]],
    });
    connectedBankName: string;
    paymentMethod: null | AvailablePaymentMethods = null;
    gettingPlaidInstitution = false;
    errorMessage = {
        isDisplayed: false,
        text: null,
    };
    addPaymentMethodVisible = false;
    isBindCard = true;
    paypalAccount: string;

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get freeTrialInUse() {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.ADVANCED &&
            this.shopProfile.subscription.trial_end_date &&
            dayjs(this.shopProfile.subscription.trial_end_date).isAfter(dayjs())
        );
    }

    get isFreePlanAndTrialEnded() {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER &&
            this.shopProfile.subscription.trial_end_date &&
            dayjs(this.shopProfile.subscription.trial_end_date).isBefore(dayjs())
        );
    }

    get isFreePlanAndNoTrial() {
        return this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER && !this.shopProfile.subscription.trial_start_date;
    }

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private fb: FormBuilder,
        private auth: AngularFireAuth,
        private shopService: ShopService,
        private firebaseEventService: FirebaseEventsService,
        private modalService: NzModalService,
        private paypalService: PaypalService,
        public plaidService: PlaidService,
        public profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_PROFILE);

        merge(this.changePasswordForm.controls.newPassword.valueChanges, this.changePasswordForm.controls.confirmPassword.valueChanges)
            .pipe(takeUntil(this.unsubscribe), debounceTime(300))
            .subscribe(() => {
                if (this.changePasswordForm.controls.confirmPassword.dirty) {
                    this.changePasswordForm.controls.confirmPassword.markAsTouched();
                    this.changePasswordForm.controls.confirmPassword.setErrors(this.checkPasswords(this.changePasswordForm));
                }
            });

        this.profileService.currentProfileSubject
            .pipe(
                takeUntil(this.unsubscribe),
                filter(currentProfile => !!currentProfile)
            )
            .subscribe(() => this.getSelectedBankAccount());

        this.changePasswordForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.errorMessage.isDisplayed) {
                this.errorMessage.isDisplayed = false;
            }
        });
    }

    checkPasswords(group: FormGroup) {
        const pass = group.controls.newPassword.value;
        const confirmPass = group.controls.confirmPassword.value;
        return !!pass && !!confirmPass && pass === confirmPass ? null : { notSame: true };
    }

    async updatePassword() {
        this.changePasswordForm.updateValueAndValidity();
        if (!this.changePasswordForm.valid) {
            return;
        }
        const rawForm = this.changePasswordForm.getRawValue();
        const credentials = EmailAuthProvider.credential(this.profileService.currentProfile.email, rawForm.currentPassword);
        const currentUser = await this.auth.currentUser;
        try {
            const reauthenticationResult = await currentUser.reauthenticateWithCredential(credentials);
            if (reauthenticationResult && rawForm.newPassword === rawForm.confirmPassword) {
                await currentUser.updatePassword(rawForm.newPassword);
                this.closeUpdatePasswordForm();
                this.showPasswordSuccessfullyChangedMessage();
            }
        } catch (e) {
            if (e.code === 'auth/wrong-password') {
                this.errorMessage.text = 'The current password provided was incorrect.';
            } else {
                this.errorMessage.text = e.message;
            }
            this.errorMessage.isDisplayed = true;
        }
    }

    showPasswordSuccessfullyChangedMessage() {
        this.isPasswordChangedMessageDisplayed = true;
        setTimeout(() => {
            this.isPasswordChangedMessageDisplayed = false;
        }, 10000);
    }

    closeUpdatePasswordForm() {
        this.isChangePasswordFormDisplayed = false;
        this.changePasswordForm.reset();
        Object.keys(this.changePasswordForm.controls).forEach(controlKey => {
            this.changePasswordForm.controls[controlKey].markAsUntouched();
            this.changePasswordForm.controls[controlKey].setErrors([]);
        });
    }

    getSelectedBankAccount() {
        this.gettingPlaidInstitution = true;
        return this.plaidService
            .getPaymentMethod()
            .then(data => {
                this.isBindCard = !!data.bank_account || !!data.credit_card;
                this.paypalAccount = data.paypal ?? null;
                this.connectedBankName = data.bank_account
                    ? `${data.bank_account.account.name} ******* ${data.bank_account.account.mask || ''}`
                    : null;

                if (this.profileService.currentProfile.selected_payment_method) {
                    this.paymentMethod = this.profileService.currentProfile.selected_payment_method;
                } else {
                    if (data.bank_account) {
                        this.paymentMethod = AvailablePaymentMethods.bank_account;
                    } else if (data.paypal) {
                        this.paymentMethod = AvailablePaymentMethods.paypal;
                    }
                }
            })
            .finally(() => (this.gettingPlaidInstitution = false));
    }

    async connectPlaid() {
        this.isOverlayDisplayed = true;
        try {
            const linkToken = await this.plaidService.fetchLinkToken();
            const publicToken = await this.plaidService.getPublicToken(linkToken.link_token);
            if (publicToken) {
                await this.plaidService.exchangePublicToken(publicToken);
                await this.profileService.getCurrentProfile().then(currentProfile => {
                    this.profileService.currentProfile = currentProfile;
                });
            }
        } finally {
            this.isOverlayDisplayed = false;
        }
    }

    addPaymentMethod() {
        this.addPaymentMethodVisible = true;
    }

    addPaymentMethodSuccess() {
        this.addPaymentMethodVisible = false;
        this.getSelectedBankAccount();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    showPaymentMethodModal() {
        this.modalService.create({
            nzContent: ConnectPaymentComponent,
            nzFooter: null,
        });
    }

    connectWithPaypal() {
        window.location.href = this.paypalService.getPaypalLink();
    }
}
