import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from '@modules/profile/profile.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from '@modules/home/home.module';

@NgModule({
    declarations: [ProfileComponent],
    imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, HomeModule],
})
export class ProfileModule {}
