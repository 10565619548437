import { Component, Input, OnInit } from '@angular/core';
import { ReportItem } from 'src/app/modules/home/home.service';

@Component({
    selector: 'app-amount-detail-popover',
    templateUrl: './amount-detail-popover.component.html',
    styleUrls: ['./amount-detail-popover.component.less'],
})
export class AmountDetailPopoverComponent implements OnInit {
    @Input() revenueDetail: ReportItem;
    @Input() paymentDetail: any;

    constructor() {}

    ngOnInit(): void {}
}
