import { ShopService } from '@services/shop.service';
import { YoutubeVideoModalComponent } from './../youtube-video-modal/youtube-video-modal.component';
import { StorefrontService, StorefrontMediaEnum } from './../storefront.service';
import { MessageService } from '@services/message.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { ProfileService } from '@services/profile.service';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StorefrontStateService, TextEditableEnum } from '../storefront-state.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.less'],
})
export class AboutComponent implements OnInit, OnDestroy {
    @Input() shopDetail: ShopProfile;
    @Input() pageMode: 'view' | 'edit';
    @Input() siteView: 'mobile' | 'desktop';

    TextEditableEnum = TextEditableEnum;

    desktopBackgroundImageUploading = false;
    mobileBackgroundImageUploading = false;
    portraitImgUploading = false;

    portraitImageStyles: any[] = ['full_size', 'contained'];

    subscription = new Subscription();

    StorefrontMediaEnum = StorefrontMediaEnum;

    get originShopDetail() {
        return this.storefrontStateService.shopDetail;
    }

    get currentEditingTextSection() {
        return this.storefrontStateService.currentEditingTextSection;
    }
    get editableTextSectionDataMap() {
        return this.storefrontStateService.editableTextSectionDataMap;
    }
    get currentEditingTextSectionData() {
        return this.storefrontStateService.currentEditingTextSectionData;
    }

    get embedYoutubeVideoUrl() {
        const url = this.shopDetail?.theme_settings.aboutMe.youtubeVideoUrl || '';
        const urlArr = url.split('?')[0].split('/');
        const id = urlArr[urlArr.length - 1];
        return `https://www.youtube.com/embed/${id}?branding=0&showinfo=0&modestbranding=1&playsinline=1`;
    }

    get aboutBackground() {
        if (this.shopDetail?.theme_settings?.aboutMe?.direction === 'reverse') {
            return this.shopDetail?.theme_settings?.color5;
        } else {
            return this.shopDetail?.theme_settings?.color3;
        }
    }

    get aboutYoutubeVideoUrl() {
        return this.storefrontStateService.aboutYoutubeVideoUrl;
    }

    get aboutSectionMediaType() {
        return this.storefrontStateService.aboutSectionMediaType;
    }

    get scaleRatio() {
        return 1 / this.storefrontStateService.scaleRatio || 1;
    }

    constructor(
        private shopService: ShopService,
        private firebaseUploadService: FirebaseFileUploadService,
        private messageService: MessageService,
        private modalService: NzModalService,
        public storefrontService: StorefrontService,
        public storefrontStateService: StorefrontStateService
    ) {}

    ngOnInit(): void {
        this.subscribeShopDetail();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    subscribeShopDetail() {
        this.subscription.add(
            this.shopService.shopProfile$.pipe(filter(item => !!item)).subscribe(shopDetail => {
                if (shopDetail.theme_settings?.aboutMe.youtubeVideoUrl) {
                    this.storefrontStateService.aboutYoutubeVideoUrl = shopDetail.theme_settings.aboutMe.youtubeVideoUrl;
                    this.storefrontStateService.aboutSectionMediaType = 'video';
                }
            })
        );
    }

    uploadPortrait = file => {
        this.checkoutImagePixel(file, 'portrait');
        return false;
    };

    uploadDesktopBg = file => {
        this.checkoutImagePixel(file, 'desktopBg');
        return false;
    };

    uploadMobileBg = file => {
        this.checkoutImagePixel(file, 'mobileBg');
        return false;
    };

    checkoutImagePixel(file: any, imgStat: 'portrait' | 'desktopBg' | 'mobileBg') {
        // Image Uploading
        let key: string;
        if (imgStat === 'portrait') {
            key = 'portraitImgUploading';
        }
        if (imgStat === 'desktopBg') {
            key = 'desktopBackgroundImageUploading';
        }
        if (imgStat === 'mobileBg') {
            key = 'mobileBackgroundImageUploading';
        }

        this[key] = true;

        this.onDrop(file, imgStat)
            .catch(() => this.messageService.error())
            .finally(() => (this[key] = false));
    }

    onDrop(file: any, imgStat: 'portrait' | 'desktopBg' | 'mobileBg') {
        const { uploadTask } = this.firebaseUploadService.startUpload(file);
        return uploadTask.then(downloadURL => {
            if (imgStat === 'desktopBg') {
                this.shopDetail.theme_settings.aboutMe.desktopBackgroundImage = downloadURL;
            }
            if (imgStat === 'mobileBg') {
                this.shopDetail.theme_settings.aboutMe.mobileBackgroundImage = downloadURL;
            }
            if (imgStat === 'portrait') {
                this.shopDetail.theme_settings.aboutMe.portraitImage = downloadURL;
            }
        });
    }

    aboutMediaTypeChange(val: 'video' | 'image') {
        if (val === 'video') {
            this.showAddYoutubeVideoModal();
        }
    }

    showAddYoutubeVideoModal() {
        this.modalService.create({
            nzContent: YoutubeVideoModalComponent,
            nzComponentParams: {
                aboutYoutubeVideUrl: this.aboutYoutubeVideoUrl,
            },
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzOnOk: comp => (this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl = comp.aboutYoutubeVideUrl),
        });
    }

    removeMedia(type: 'portrait' | 'desktopBg' | 'mobileBg') {
        if (type === 'portrait') {
            this.shopDetail.theme_settings.aboutMe.portraitImage = this.storefrontService.defaultThemeSettings.aboutMe.portraitImage;
        }

        if (type === 'desktopBg') {
            // eslint-disable-next-line max-len
            this.shopDetail.theme_settings.aboutMe.desktopBackgroundImage = this.storefrontService.defaultThemeSettings.aboutMe.desktopBackgroundImage;
        }

        if (type === 'mobileBg') {
            // eslint-disable-next-line max-len
            this.shopDetail.theme_settings.aboutMe.mobileBackgroundImage = this.storefrontService.defaultThemeSettings.aboutMe.mobileBackgroundImage;
        }
    }
}
