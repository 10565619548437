<div class="sign-up-box">
    <div class="sub-title">Welcome to Lifo Creator Hub!</div>
    <div class="title">
        Sign Up
        <img class="logo" src="assets/imgs/project-logo.jpg" alt="" height="26" />
    </div>
    <form nz-form [formGroup]="validateForm" class="sign-up-form" (ngSubmit)="submitForm()">
        <div class="labels">Email</div>
        <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your email address">
                <input type="text" nz-input formControlName="email" placeholder="Please enter your email address" />
            </nz-form-control>
        </nz-form-item>
        <div class="labels">Password</div>
        <div class="passwords">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="passwordEmptyErrorTpl">
                    <input type="password" nz-input formControlName="password" placeholder="Password 8+ characters" />
                    <ng-template #passwordEmptyErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('minlength')">
                            <span class="input-tip">Short password!</span>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                            <span class="input-tip">Please enter your password</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="confirmPasswordErrorTpl">
                    <input type="password" nz-input formControlName="confirm_password" placeholder="Confirm your password" />

                    <ng-template #confirmPasswordErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('notSame')">
                            <span class="input-tip">Passwords don't match</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="labels">Primary Social Account</div>
        <div class="social-account">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="socialAccountEmptyErrorTpl">
                    <div
                        nz-popover
                        nzPopoverPlacement="bottom"
                        nzPopoverTrigger="click"
                        nzPopoverOverlayClassName="no-padding no-bg"
                        [nzPopoverContent]="socialMediaPopover"
                        [(nzPopoverVisible)]="socialMedialPopOverVisible"
                        class="select-platform"
                    >
                        <div class="d-flex justify-content-between align-items-center selected-platform">
                            <img [src]="getSocialIcon(validateForm.controls.platform.value)" />
                            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                        </div>
                    </div>
                    <input type="text" nz-input formControlName="platform_id" placeholder="Enter your social account" />
                    <ng-template #socialAccountEmptyErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            <span class="input-tip">Please enter your primary social account</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="labels">Invitation Code <span>(Optional)</span></div>
        <nz-form-control [nzErrorTip]="invitationCodeErrorTpl">
            <input type="text" nz-input formControlName="invitation_code" placeholder="Your invitation code " />
            <ng-template #invitationCodeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('invalidCode')">
                    <span class="input-tip">{{control.getError('invalidCode')}}</span>
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                    <span class="input-tip">Please input invitation code!</span>
                </ng-container>
            </ng-template>
        </nz-form-control>
        <div nz-row class="sign-up-form-margin terms-of-use">
            <label nz-checkbox formControlName="terms_of_use">
                <span>I agree to the</span>
            </label>
            <a [routerLink]="['/', 'terms-of-use']" target="_blank">Terms of Use</a>
            &nbsp;and&nbsp;
            <a [routerLink]="['/', 'privacy-policy']" target="_blank">Privacy Policy</a>
        </div>
        <button
            nz-button
            [nzLoading]="submitting"
            class="sign-up-form-button sign-up-form-margin"
            [nzType]="'primary'"
            [disabled]="invitationCode"
            nzSize="large"
        >
            Start 90-Day Free Trial
        </button>
    </form>

    <div class="sign-in"><span>Already have an account?</span><a [routerLink]="['/auth/sign-in']">Sign In</a></div>
</div>

<ng-template #socialMediaPopover>
    <div class="social-list">
        <div class="social-row" *ngFor="let platform of platforms" (click)="selectPlatform(platform)">
            <img [src]="getSocialIcon(platform)" />
            {{ platform }}
        </div>
    </div>
</ng-template>
