import { cloneDeep } from 'lodash';
import { ShopProfile } from '@typings/profile.typing';
import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';
import { StorefrontStateService, TextEditableEnum } from './storefront-state.service';
import { UtilService } from '@services/util.service';

export type ThemeSettings = Partial<FullThemeSettings>;

export interface FullThemeSettings {
    themeStyle: string;
    cornerStyle: 'round' | 'straight';
    fontTitle: string;
    fontBody: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    announcement: string;
    banner: {
        title: string;
        content: string;
        mobileImg: string;
        desktopImg: string;
        layout: string;
        mobileTextPosition: 'top' | 'center' | 'bottom'; // 移动端文字区块位置
        heroButtonVisible: boolean; // 按钮是否可见
        heroButtonText?: string;
        heroButtonLink?: string;
        heroButtonLinkProductId?: any;
    };
    recommended: {
        title: string;
        layout: string;
    };
    aboutMe: {
        content: string;
        title: string;
        direction: string;
        portraitImage: string; // PC端图片
        mobilePortraitImageStyle: 'full_size' | 'contained'; // 移动端图片样式
        youtubeVideoUrl?: string;
        desktopBackgroundImage?: string; // PC端背景图片
        mobileBackgroundImage?: string; // 移动端背景图片
    };
    logoImage: string;
    socialMedia: {
        instagram: string;
        twitter: string;
        facebook: string;
        youtube: string;
        snapchat: string;
        spotify: string;
        tiktok: string;
        pinterest: string;
        linkedin: string;
        tumblr: string;
        discord: string;
        patreon: string;
        medium: string;
        applePodcasts: string;
        twitch: string;
        website: string;
    };

    // social media 模块配置
    socialMediaSection: {
        desktopBackgroundImage: string; // PC端背景图片
        mobileBackgroundImage: string; // 移动端背景图片
    };
}

export interface TemplateItem {
    name: string;
    value: string;
    icon: string;
    fontTitle: string;
    fontBody: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    cornerStyle: 'straight' | 'round';
}

export enum StorefrontMediaEnum {
    // Logo
    LOGO = 'logo',

    // Banner
    BANNER_DESKTOP = 'desktopBanner',
    BANNER_MOBILE = 'mobileBanner',

    // About
    ABOUT_PORTRAIT = 'aboutPortrait',
    ABOUT_DESKTOP_TEXT_BG = 'aboutDesktopBgImg',
    ABOUT_MOBILE_TEXT_BG = 'aboutMobileBgImg',

    // Social Media
    SOCIAL_DESKTOP_BG = 'socialDesktopBgImg',
    SOCIAL_MOBILE_BG = 'socialMobileBgImg',
}

@Injectable({
    providedIn: 'root',
})
export class StorefrontService {
    defaultThemeSettings: FullThemeSettings = {
        themeStyle: 'dark',
        cornerStyle: 'straight',
        fontTitle: "'Lora', serif",
        fontBody: "'Work Sans', sans-serif",
        color1: '#FFFFFF',
        color2: '#000000',
        color3: '#000000',
        color4: '#FFFFFF',
        color5: '#000000',
        color6: '#000000',
        announcement: '',
        banner: {
            title: 'Welcome To My Store',
            content: 'Check out my design just for you!',
            mobileImg: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-banner.png',
            desktopImg: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-banner.png',
            layout: 'on_the_image',
            heroButtonText: 'SHOP NOW',
            heroButtonLink: '/collections/all',
            heroButtonVisible: true,
            mobileTextPosition: 'center',
            heroButtonLinkProductId: null,
        },
        recommended: {
            layout: 'swiper_product',
            title: 'Recommended Products',
        },
        aboutMe: {
            content:
                'Welcome to my shop. You can buy your favorite products designed by me. If you' +
                ' want to know more about me, welcome to visit my social media account via the quick link at the bottom.',
            portraitImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-portrait.png',
            title: 'About Me',
            direction: 'reverse',
            desktopBackgroundImage: '',
            mobileBackgroundImage: '',
            mobilePortraitImageStyle: 'full_size',
        },
        logoImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-logo.png',
        socialMedia: {
            instagram: '',
            twitter: '',
            facebook: '',
            youtube: '',
            snapchat: '',
            spotify: '',
            tiktok: '',
            pinterest: '',
            linkedin: '',
            tumblr: '',
            discord: '',
            patreon: '',
            medium: '',
            applePodcasts: '',
            website: '',
            twitch: '',
        },
        socialMediaSection: {
            desktopBackgroundImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-social-media.png',
            mobileBackgroundImage:
                'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-social-media-mobile.png',
        },
    };

    templateList: TemplateItem[] = [
        {
            name: 'Dark',
            value: 'dark',
            icon: 'assets/svg/storefront-template-dark.svg',
            fontTitle: "'Lora', serif",
            fontBody: "'Work Sans', sans-serif",
            color1: '#FFFFFF',
            color2: '#000000',
            color3: '#000000',
            color4: '#FFFFFF',
            color5: '#000000',
            color6: '#000000',
            cornerStyle: 'straight',
        },
        {
            name: 'Bright',
            value: 'light',
            icon: 'assets/svg/storefront-template-bright.svg',
            fontTitle: "'Poppins', sans-serif",
            fontBody: "'Karla', sans-serif",
            color1: '#FFFFFF',
            color2: '#000000',
            color3: '#F17395',
            color4: '#223B47',
            color5: '#223B47',
            color6: '#FFFFFF',
            cornerStyle: 'straight',
        },
        {
            name: 'Sweet',
            value: 'sweet',
            icon: 'assets/svg/storefront-template-sweet.svg',
            fontTitle: "'Knewave', cursive",
            fontBody: "'Karla', sans-serif",
            color1: '#FFFFFF',
            color2: '#000000',
            color3: '#FFD5D2',
            color4: '#AF7D6A',
            color5: '#AF7D6A',
            color6: '#FFFFFF',
            cornerStyle: 'round',
        },
        {
            name: 'Custom',
            value: 'custom',
            icon: 'assets/svg/storefront-template-custom.svg',
            fontTitle: "'Work Sans', sans-serif",
            fontBody: "'Work Sans', sans-serif",
            color1: '#FFFFFF',
            color2: '#000000',
            color3: '#CCC8D1',
            color4: '#9991A2',
            color5: '#9991A2',
            color6: '#FFFFFF',
            cornerStyle: 'straight',
        },
    ];

    fontList = [
        { value: "'Archivo', sans-serif", label: 'Archivo' },
        { value: "'Karla', sans-serif", label: 'Karla' },
        { value: "'Knewave', cursive", label: 'Knewave' },
        { value: "'Lora', serif", label: 'Lora' },
        { value: "'Merriweather', serif", label: 'Merriweather' },
        { value: "'Montserrat', sans-serif", label: 'Montserrat' },
        { value: "'Nunito', sans-serif", label: 'Nunito' },
        { value: "'Oswald', sans-serif", label: 'Oswald' },
        { value: "'Playfair Display', serif", label: 'Playfair Display' },
        { value: "'Poppins', sans-serif", label: 'Poppins' },
        { value: "'Roboto', sans-serif", label: 'Roboto' },
        { value: "'Ubuntu', sans-serif", label: 'Ubuntu' },
        { value: "'Work Sans', sans-serif", label: 'Work Sans' },
        { value: "'Comic Neue', cursive", label: 'Comic Neue' },
    ];
    fontListMap = new Map([
        ["'Archivo', sans-serif", 'Archivo'],
        ["'Karla', sans-serif", 'Karla'],
        ["'Knewave', cursive", 'Knewave'],
        ["'Lora', serif", 'Lora'],
        ["'Merriweather', serif", 'Merriweather'],
        ["'Montserrat', sans-serif", 'Montserrat'],
        ["'Nunito', sans-serif", 'Nunito'],
        ["'Oswald', sans-serif", 'Oswald'],
        ["'Playfair Display', serif", 'Playfair Display'],
        ["'Poppins', sans-serif", 'Poppins'],
        ["'Roboto', sans-serif", 'Roboto'],
        ["'Ubuntu', sans-serif", 'Ubuntu'],
        ["'Work Sans', sans-serif", 'Work Sans'],
        ["'Comic Neue', cursive", 'Comic Neue'],
    ]);

    defaultProducts = [
        { image: 'assets/svg/storefront_default_product.svg', title: 'Product Name', price: 0 },
        { image: 'assets/svg/storefront_default_product.svg', title: 'Product Name', price: 0 },
        { image: 'assets/svg/storefront_default_product.svg', title: 'Product Name', price: 0 },
        { image: 'assets/svg/storefront_default_product.svg', title: 'Product Name', price: 0 },
    ];

    constructor(
        private requestService: RequestService,
        private storefrontStateService: StorefrontStateService,
        private utilService: UtilService
    ) {}

    updateShopTheme(data: ThemeSettings) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/shop_management/shop_theme',
            api: 'data-api',
            data,
        });
    }

    getFavoriteProduct() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/get_favorite_product',
            api: 'shop-api',
        });
    }

    updateFavoriteProduct(data: { product_ids: string[] }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/update_favorite_product',
            api: 'shop-api',
            data,
        });
    }

    getCampaignList() {
        return this.requestService.sendRequest<any[]>({
            method: 'GET',
            url: '/influencer/market_campaign',
            api: 'data-shop',
        });
    }

    updateDomain(domain: string) {
        return this.requestService.sendRequest<any[]>({
            method: 'PUT',
            url: '/influencer/shop_management/update_shop_domain',
            api: 'shop-api',
            data: { domain },
        });
    }

    getHandledThemeSettings(themeSettings: ThemeSettings) {
        // Social Media
        Object.keys(themeSettings.socialMedia || {}).forEach(key => {
            const url: string = (themeSettings.socialMedia[key] || '').trim();
            if (url) {
                themeSettings.socialMedia[key] = this.addHttpPrefix(url);
            } else {
                themeSettings.socialMedia[key] = '';
            }
        });

        return themeSettings;
    }

    handleBannerImage(themeSettings: ThemeSettings) {
        // Banner
        if (
            this.isDefaultImage(StorefrontMediaEnum.BANNER_MOBILE, themeSettings.banner.mobileImg) &&
            !this.isDefaultImage(StorefrontMediaEnum.BANNER_DESKTOP, themeSettings.banner.desktopImg)
        ) {
            themeSettings.banner.mobileImg = themeSettings.banner.desktopImg;
        }
        if (
            this.isDefaultImage(StorefrontMediaEnum.BANNER_DESKTOP, themeSettings.banner.desktopImg) &&
            !this.isDefaultImage(StorefrontMediaEnum.BANNER_MOBILE, themeSettings.banner.mobileImg)
        ) {
            themeSettings.banner.desktopImg = themeSettings.banner.mobileImg;
        }
    }

    addHttpPrefix(url: string) {
        const handledUrl = (url || '').trim();
        if (handledUrl.startsWith('http') || !handledUrl) {
            return handledUrl;
        }

        return `http://${handledUrl}`;
    }

    getHtmlStringByTextAndStyle(text: string, styleObj: { [key: string]: string }) {
        const styleArr: string[] = [];
        Object.keys(styleObj).forEach(key => {
            styleArr.push(`${key}:${styleObj[key]};`);
        });

        if (text.trim()) {
            return `<div style="${styleArr.join('')}">${text}</div>`;
        }

        return '';
    }

    getTextAndStyleFromHtmlString(htmlString: string) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        const text = (div.firstChild as HTMLDivElement)?.innerHTML || htmlString || '';

        const { style, keys } = this.getStyleValue((div.firstElementChild as HTMLDivElement)?.style);

        return { text, styleObj: style, selectedToolBars: keys };
    }

    handleAllEditableText(shopDetail: ShopProfile): ShopProfile {
        const newShopDetail = cloneDeep(shopDetail);
        if (shopDetail.theme_settings) {
            const themeSettings = shopDetail.theme_settings;
            const newThemeSettings = newShopDetail.theme_settings;

            // Banner Title
            const bannerTitleData = this.getTextAndStyleFromHtmlString(themeSettings.banner.title);
            newThemeSettings.banner.title = bannerTitleData.text;
            this.storefrontStateService.editableTextSectionDataMap.set(TextEditableEnum.BANNER_TITLE, {
                styles: bannerTitleData.styleObj,
                selectedToolBars: new Set(bannerTitleData.selectedToolBars),
            });

            // Banner SubTitle
            const bannerSubTitleData = this.getTextAndStyleFromHtmlString(themeSettings.banner.content);
            newThemeSettings.banner.content = bannerSubTitleData.text;
            this.storefrontStateService.editableTextSectionDataMap.set(TextEditableEnum.BANNER_SUB_TITLE, {
                styles: bannerSubTitleData.styleObj,
                selectedToolBars: new Set(bannerSubTitleData.selectedToolBars),
            });

            // Recommended Products Title
            const recommendedProductsTitleData = this.getTextAndStyleFromHtmlString(themeSettings.recommended.title);
            newThemeSettings.recommended.title = recommendedProductsTitleData.text;
            this.storefrontStateService.editableTextSectionDataMap.set(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE, {
                styles: recommendedProductsTitleData.styleObj,
                selectedToolBars: new Set(recommendedProductsTitleData.selectedToolBars),
            });

            // About Title
            const aboutTitleData = this.getTextAndStyleFromHtmlString(themeSettings.aboutMe.title);
            newThemeSettings.aboutMe.title = aboutTitleData.text;
            this.storefrontStateService.editableTextSectionDataMap.set(TextEditableEnum.ABOUT_TITLE, {
                styles: aboutTitleData.styleObj,
                selectedToolBars: new Set(aboutTitleData.selectedToolBars),
            });

            // About Content
            const aboutContentData = this.getTextAndStyleFromHtmlString(themeSettings.aboutMe.content);
            newThemeSettings.aboutMe.content = aboutContentData.text;

            this.storefrontStateService.editableTextSectionDataMap.set(TextEditableEnum.ABOUT_CONTENT, {
                styles: aboutContentData.styleObj,
                selectedToolBars: new Set(aboutContentData.selectedToolBars),
            });
        }
        return newShopDetail;
    }

    setAllEditableText(shopDetail: ShopProfile): ShopProfile {
        const newShopDetail = cloneDeep(shopDetail);
        if (shopDetail.theme_settings) {
            const themeSettings = shopDetail.theme_settings;
            const newThemeSettings = newShopDetail.theme_settings;

            // Banner Title
            newThemeSettings.banner.title = this.getHtmlStringByTextAndStyle(
                newThemeSettings.banner.title,
                this.storefrontStateService.editableTextSectionDataMap.get(TextEditableEnum.BANNER_TITLE).styles
            );

            // Banner SubTitle
            newThemeSettings.banner.content = this.getHtmlStringByTextAndStyle(
                newThemeSettings.banner.content,
                this.storefrontStateService.editableTextSectionDataMap.get(TextEditableEnum.BANNER_SUB_TITLE).styles
            );

            // Recommended Products Title
            newThemeSettings.recommended.title = this.getHtmlStringByTextAndStyle(
                newThemeSettings.recommended.title,
                this.storefrontStateService.editableTextSectionDataMap.get(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE).styles
            );

            // About Title
            newThemeSettings.aboutMe.title = this.getHtmlStringByTextAndStyle(
                newThemeSettings.aboutMe.title,
                this.storefrontStateService.editableTextSectionDataMap.get(TextEditableEnum.ABOUT_TITLE).styles
            );

            // About Content
            newThemeSettings.aboutMe.content = this.getHtmlStringByTextAndStyle(
                newThemeSettings.aboutMe.content,
                this.storefrontStateService.editableTextSectionDataMap.get(TextEditableEnum.ABOUT_CONTENT).styles
            );
        }
        return newShopDetail;
    }

    getStyleValue(styleObj: CSSStyleDeclaration) {
        if (styleObj) {
            const arr = ['font-weight', 'color', 'font-style', 'text-align', 'text-decoration'];
            const styleRes = {};
            const keyRes = [];
            arr.filter(item => styleObj[item]).forEach(key => {
                styleRes[key] = styleObj[key];
                keyRes.push(key);
            });
            return { style: styleRes, keys: keyRes };
        }

        return { style: {}, keys: [] };
    }

    isDefaultImage(type: StorefrontMediaEnum, value: string) {
        // LOGO
        if (type === StorefrontMediaEnum.LOGO) {
            return value === this.defaultThemeSettings.logoImage;
        }

        // Banner
        if (type === StorefrontMediaEnum.BANNER_DESKTOP) {
            return value === this.defaultThemeSettings.banner.desktopImg;
        }

        if (type === StorefrontMediaEnum.BANNER_MOBILE) {
            return value === this.defaultThemeSettings.banner.mobileImg;
        }

        // About
        if (type === StorefrontMediaEnum.ABOUT_PORTRAIT) {
            return value === this.defaultThemeSettings.aboutMe.portraitImage;
        }

        if (type === StorefrontMediaEnum.ABOUT_DESKTOP_TEXT_BG) {
            return value === this.defaultThemeSettings.aboutMe.desktopBackgroundImage;
        }

        if (type === StorefrontMediaEnum.ABOUT_MOBILE_TEXT_BG) {
            return value === this.defaultThemeSettings.aboutMe.mobileBackgroundImage;
        }

        // Social Media
        if (type === StorefrontMediaEnum.SOCIAL_DESKTOP_BG) {
            return value === this.defaultThemeSettings.socialMediaSection.desktopBackgroundImage;
        }

        if (type === StorefrontMediaEnum.SOCIAL_MOBILE_BG) {
            return value === this.defaultThemeSettings.socialMediaSection.mobileBackgroundImage;
        }

        return false;
    }
}
