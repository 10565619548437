import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['../terms-of-use/terms-of-use.component.less'],
})
export class PrivacyPolicyComponent implements OnInit {
    index = 0;
    constructor() {}

    ngOnInit() {}
}
