export const ProductCreation = [
    {
        name: 'PRODUCT CREATION',
        title: true,
        starterFree: 'Starter - Free',
        experienced: 'Experienced',
        advanced: 'Advanced',
        pro: 'Pro',
        premium: 'Premium',
    },
    {
        name: 'Design and order your own signature product with advanced customization options',
        starterFree: true,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Sell advanced customization products with crowdfunding campaign',
        starterFree: true,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Start selling with print-on-demand products',
        starterFree: true,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Design your own packaging',
        starterFree: true,
        experienced: true,
        advanced: false,
        pro: true,
        premium: true,
    },
    // Logistics & Inventory management
    {
        name: 'LOGISTICS & INVENTORY MANAGEMENT',
        title: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
    },
    {
        name: 'Logistics and warehousing managed by Lifo',
        tooltips:
            "With Lifo managed logistics and warehousing service, your wholesale products \
            purchased will be sent to Lifo's warehouse. We will fully store, \
            manage and protect the products for you, as well as handle all the \
            order fulfillments, returns, and customer service.",
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Fulfilled By Lifo (FBL) ',
        tooltips:
            '<p>With FBL, Lifo will pick, pack and ship the customer orders for you and handle all \
            the returns and customer services for your products in our warehouses.</p><p>Your audience \
            will have excellent customer service and a seamless customer experience handled by Lifo so \
            that you can focus on your content</p>',
        starterFree: false,
        experienced: false,
        advanced: true,
        pro: false,
        premium: true,
    },
    {
        name: 'Automated global fulfillment',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Automated US & CA domestic fulfillment',
        describe: '(Standard USPS/UPS/Fedex shipping rate)',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Returns management',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: '24/7 customer care',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    // E-COMMERCE STOREFRONT
    {
        name: 'E-COMMERCE STOREFRONT',
        title: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
    },
    {
        name: 'Host your site with custom url',
        starterFree: false,
        experienced: false,
        advanced: true,
        pro: false,
        premium: true,
        advancedDescribe: 'Your own lifo.store link',
        premiumDescribe: 'Domain of choice',
    },
    {
        name: 'Website building & development',
        starterFree: false,
        experienced: false,
        advanced: true,
        pro: false,
        premium: true,
    },
    {
        name: 'Dedicated website designer',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    {
        name: '24/ 7 website maintenance',
        starterFree: false,
        experienced: false,
        advanced: true,
        pro: false,
        premium: true,
    },
    {
        name: 'Store analytics reporting',
        starterFree: false,
        experienced: false,
        advanced: true,
        pro: false,
        premium: true,
    },
    {
        name: '‘My Links’ page',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    // SOCIAL COMMERCE STRATEGY
    {
        name: 'SOCIAL COMMERCE STRATEGY',
        title: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
    },
    {
        name: 'Dedicated account manager',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    {
        name: 'Social Commerce branding & marketing strategy',
        subName: ['Social commerce branding call', 'Social commerce kick off guidance call'],
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    {
        name: 'Exclusive access to creator community',
        describe: '(coming soon)',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    // MARKETING & BRANDING (COMING SOON)
    {
        name: 'MARKETING & BRANDING (COMING SOON)',
        title: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
    },
    {
        name: 'Seeding campaign',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Email marketing tools',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'SMS marketing tools',
        starterFree: false,
        experienced: true,
        advanced: true,
        pro: true,
        premium: true,
    },
    {
        name: 'Paid ads marketing',
        describe: '(Ads spending not included)',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: true,
    },
    // FINANCIAL
    {
        name: 'FINANCIAL',
        title: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
    },
    {
        name: 'Lifo Creator Funds',
        footer: true,
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
        experiencedDescribe: '$5,000',
        advancedDescribe: '$5,000',
        proDescribe: '$5,000',
        premiumDescribe: '$5,000',
    },
    {
        name: 'Lifo service fee',
        starterFree: false,
        experienced: false,
        advanced: false,
        pro: false,
        premium: false,
        advancedDescribe: '5% of total revenue',
        premiumDescribe: 'Contact for details',
    },
];
