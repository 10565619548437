<div class="login-container">
    <div class="content-box">
        <div class="logo-title">
            <img class="cursor-pointer" [routerLink]="['/']" src="assets/imgs/project-logo.jpg" width="100" />
            <span class="ch-title"><span class="strong">Lifo</span> Creator Hub</span>
        </div>
        <div class="login-box" *ngIf="forgotEmail">
            <div *ngIf="!code" class="back" (click)="backToSignIn()">
                <img width="16" height="16" src="assets/svg/arrow-left.svg" alt="" />
                <span>Back</span>
            </div>
            <div class="title">Reset Password</div>
            <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="resetPassword()">
                <!-- Send email -->
                <ng-container *ngIf="!code">
                    <div class="labels">Your Registration Email</div>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please input your email">
                            <input type="text" class="emails" nz-input formControlName="email" placeholder="Email address" />
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <!-- Reset password -->
                <ng-container *ngIf="code">
                    <div class="labels">New Password</div>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your password">
                            <input
                                type="password"
                                class="emails"
                                nz-input
                                formControlName="password"
                                placeholder="Password 8+characters"
                                (ngModelChange)="validateConfirmPassword()"
                            />
                        </nz-form-control>
                    </nz-form-item>
                    <div class="labels">Confirm Password</div>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please confirm your password">
                            <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirm your password" />
                        </nz-form-control>
                    </nz-form-item>
                    <div class="success-tips" *ngIf="isSuccess">
                        <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                        Your new password is set!
                    </div>
                </ng-container>
                <button
                    nz-button
                    *ngIf="!isSuccess"
                    [disabled]="!validateForm.valid"
                    [nzLoading]="submitting"
                    class="login-form-button login-form-margin"
                    [nzType]="'primary'"
                >
                    {{ code ? "Reset" : "Send Email" }}
                </button>
            </form>
        </div>

        <div class="login-box" *ngIf="!forgotEmail">
            <div class="img">
                <img src="assets/imgs/send-mails-logo.jpg" alt="" width="150" />
                <h4>Email Sent</h4>
            </div>
            <div class="send-mail-title">Please check your email to reset password.</div>
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" (click)="backToSignIn()">
                Back to Sign In page
            </button>
        </div>
    </div>
    <div class="footer">
        <span>Social Commerce for Creators</span>
    </div>
</div>
