<div class="d-flex justify-content-between align-items-center credit-cart-box relative">
    <ng-container *ngIf="showMainBody">
        <div *ngIf="accountBalance?.lifo_credit" class="credit">
            <i nz-icon nzType="antd:dollar-wallet" style="margin-right: 5px"></i>
            <span>{{(accountBalance?.lifo_credit || 0) | currency}} credit available</span>
        </div>

        <div class="cart relative cursor-pointer active-mask" [routerLink]="['/','nidavellir','shopping-cart']">
            <i nz-icon nzType="shopping-cart" nzTheme="outline"></i>
            <div class="count absolute">{{productsInShoppingCart?.length || 0}}</div>
        </div>
    </ng-container>
    <!-- New added product -->
    <div *ngIf="newAddedProducts" class="fixed" style="top: 0; right: 0; z-index: 999">
        <div class="new-added-products" style="background-color: #fff">
            <h1 class="fw-bold fs-20 mb-30 d-flex justify-content-between align-items-center" style="color: #1b222e">
                <span>Just Added to Your Cart</span>
                <i nz-icon nzType="close" class="cursor-pointer active-mask" (click)="newAddedProducts = null"></i>
            </h1>

            <!-- Product -->
            <div *ngFor="let product of newAddedProducts">
                <div class="d-flex justify-content-between align-items-stretch fs-14">
                    <img [src]="product.image_url" class="object-fit-cover" style="width: 80px; height: 80px; border-radius: 12px" />
                    <div class="pl-20 height-100p flex-1">
                        <div class="mb-20">{{product.title}}</div>
                        <div>Qty: {{quantity}}</div>
                    </div>
                </div>

                <div
                    *ngFor="let packageItem of product.packaging"
                    class="mt-20"
                    style="background-color: #f8f8f9; border-radius: 12px; padding: 10px 15px"
                >
                    <div style="font-size: 12px; color: #554764">Customized Packaging</div>
                    <div class="mt-10 d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img class="mr-30" style="width: 40px; height: 40px; border-radius: 50%" [src]="packageItem.image_url" />
                            <span>{{packageItem.type | titlecase}}</span>
                        </div>

                        <div>x{{quantity}}</div>
                    </div>
                </div>

                <button class="width-100p mt-30" nz-button nzType="primary" [routerLink]="['/','nidavellir','shopping-cart']">
                    View Cart ({{productsInShoppingCart?.length || 0}})
                </button>
            </div>
        </div>
    </div>
</div>
