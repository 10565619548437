<section [loading]="!shopDetail" appLoading class="storefront" style="min-height: 100vh; padding-top: 80px">
    <header *ngIf="shopDetail && isStoreReady" class="top">
        <div class="title">Storefront Editor</div>

        <div class="mode-toggle">
            <div class="mode-item" [class.active]="siteView === 'mobile'" (click)="siteView = 'mobile'">
                <img src="assets/imgs/mobile.png" />
                <span>Mobile</span>
            </div>
            <div class="mode-item" [class.active]="siteView === 'desktop'" (click)="siteView = 'desktop';calculate()">
                <img src="assets/imgs/desktop.png" />
                <span>Desktop</span>
            </div>
        </div>

        <button
            (click)="saveContent()"
            [disabled]="saveBtnDisabled"
            [nzLoading]="submitting"
            nz-button
            nzType="primary"
            style="width: 140px"
        >
            Save
        </button>
    </header>

    <section *ngIf="shopDetail && isStoreReady" class="box">
        <section class="left-operate">
            <nz-tabset>
                <nz-tab nzTitle="Settings">
                    <app-global-theme-configuration [shopDetail]="shopDetail"></app-global-theme-configuration>
                </nz-tab>
                <nz-tab nzTitle="Sections">
                    <app-section-configuration
                        [shopDetail]="shopDetail"
                        [siteView]="siteView"
                        [hasOngoingCampaign]="hasOngoingCampaign"
                    ></app-section-configuration>
                </nz-tab>
            </nz-tabset>
        </section>
        <section #rightOperateEle class="right-operate">
            <div
                class="site-box"
                [ngClass]="{'mobile-site-box':siteView === 'mobile','desktop-site-box':siteView === 'desktop'}"
                [style.height]="siteView === 'mobile' ? 'auto' : height+'px'"
            >
                <div
                    #siteContainer
                    class="site-container"
                    [ngStyle]="{
                        transform:siteView === 'mobile' ? 'scale(1)' : 'scale('+scaleRatio+')'
                    }"
                >
                    <!-- Header -->
                    <header class="page-header desktop-header" *ngIf="siteView === 'desktop'">
                        <img class="logo" [src]="shopDetail.theme_settings.logoImage" />
                        <div class="links fs-14 flex-1">
                            <span class="pl-20 pr-20">Home</span>
                            <span class="pl-20">Shop</span>
                        </div>

                        <i
                            *ngIf="shopDetail.shop_type === 'Shared'"
                            class="fs-20 mr-20"
                            style="margin-top: -2px"
                            nz-icon
                            nzType="user"
                            nzTheme="outline"
                        ></i>

                        <div class="shopping-cart-icon">
                            <i nz-icon nzType="antd:shopping-cart"></i>
                            <span class="num">0</span>
                        </div>
                    </header>

                    <!-- Header -->
                    <header class="page-header mobile-header" *ngIf="siteView === 'mobile'">
                        <img class="logo" [src]="shopDetail.theme_settings.logoImage" />
                        <div class="d-flex justify-content-between align-items-center">
                            <i
                                *ngIf="shopDetail.shop_type === 'Shared'"
                                class="fs-20 mr-20"
                                style="margin-top: -2px"
                                nz-icon
                                nzType="user"
                                nzTheme="outline"
                            ></i>

                            <div class="shopping-cart-icon">
                                <i nz-icon nzType="antd:shopping-cart"></i>
                                <span class="num">0</span>
                            </div>

                            <div class="expand">
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </header>

                    <!-- Banner -->
                    <app-banner
                        #bannerComp
                        [hasOngoingCampaign]="hasOngoingCampaign"
                        [shopDetail]="shopDetail"
                        [siteView]="siteView"
                    ></app-banner>

                    <!-- Recommended Products -->
                    <app-recommended-products
                        #recommendedProductsComp
                        [shopDetail]="shopDetail"
                        [siteView]="siteView"
                    ></app-recommended-products>

                    <!-- About -->
                    <app-about #aboutComp [shopDetail]="shopDetail" [siteView]="siteView"></app-about>

                    <!-- Social Media -->
                    <app-social-media #socialMediaComp [shopDetail]="shopDetail" [siteView]="siteView"></app-social-media>
                </div>
            </div>
        </section>
    </section>

    <div
        *ngIf="shopDetail && !isStoreReady"
        style="height: calc(100vh - 80px); display: flex; justify-content: center; align-items: center; flex-direction: column"
    >
        <img src="/assets/imgs/no-ready-store.png" width="400" />
        <p class="mt-30 fs-14" style="color: #9f99a3">You can set the custom storefront, when your store is ready.</p>
    </div>
</section>
