import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { NgxPlaidLinkService, PlaidLinkHandler } from 'ngx-plaid-link';
import { ISelectedAccount } from '@typings/plaid.typings';
import { ProfileService } from './profile.service';
import { environment } from '@src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PlaidService {
    constructor(
        protected requestService: RequestService,
        private plaidLinkService: NgxPlaidLinkService,
        private profileService: ProfileService
    ) {}

    fetchLinkToken(): Promise<any> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/plaid/fetch-link-token',
            api: 'shop-api',
        });
    }

    exchangePublicToken(data: { public_token: string; metadata: any }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/plaid/access-token',
            data,
            api: 'shop-api',
        });
    }

    async getPublicToken(linkToken: string): Promise<{ public_token: string; metadata: any }> {
        return new Promise(async (resolve, reject) => {
            const linkHandler: PlaidLinkHandler = await this.plaidLinkService.createPlaid({
                token: linkToken,
                env: environment.plaid.env,
                product: ['auth'],
                onSuccess: (public_token, metadata) => {
                    resolve({ public_token, metadata });
                },
                onExit: err => {
                    if (err != null) {
                        reject(err);
                    }
                    resolve(null);
                },
            });
            linkHandler.open();
        });
    }

    getBankAccount() {
        return this.requestService.sendRequest<ISelectedAccount>({
            method: 'GET',
            url: '/influencer/plaid/bank-account',
            api: 'shop-api',
        });
    }

    getPaymentMethod() {
        return this.requestService.sendRequest<{ bank_account: ISelectedAccount; credit_card: any; paypal: string }>({
            method: 'GET',
            url: '/influencer/payment_method',
            api: 'shop-api',
        });
    }

    connectPlaid(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const linkToken = await this.fetchLinkToken();
                const publicToken = await this.getPublicToken(linkToken.link_token);
                if (publicToken) {
                    await this.exchangePublicToken(publicToken);
                    await this.profileService.getCurrentProfile().then(currentProfile => {
                        this.profileService.currentProfile = currentProfile;
                    });
                    resolve();
                }
                reject();
            } catch (e) {
                reject();
            }
        });
    }

    couponCheck(data: { coupon_id: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/coupon/check',
            data,
            api: 'shop-api',
        });
    }
}
