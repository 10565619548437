import { Component, OnInit } from '@angular/core';
import { MessageService } from '@services/message.service';
import { copy } from '@src/app/shared/utils/operations';
import { ShopService, ReferralData, InvitationHistory } from '@services/shop.service';
import { LoadingService } from '@services/loading.service';
import { collapseAnimation, collapseOnLeaveAnimation } from 'angular-animations';
import { isArray } from 'lodash';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.less'],
    animations: [collapseAnimation(), collapseOnLeaveAnimation()],
})
export class ReferralComponent implements OnInit {
    referralData: ReferralData;
    invitationHistory: InvitationHistory;
    isHideReferralStatus = true;
    eventDetailsVisible = false;
    invitationSubmitting = false;
    friendList;
    selectedFriendsSet = new Set();
    socialMediaType;

    get referralCampaign() {
        return this.shopService.referralCampaign;
    }

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    constructor(private message: MessageService, private shopService: ShopService, private loadingService: LoadingService) {}

    ngOnInit(): void {
        this.socialMediaType = `antd:social_media_${this.shopProfile.shop_detail.primary_platform}`;

        this.getReferralLink();
        this.getInvitationHistory();
        this.getModashProfile();
    }

    getReferralLink() {
        this.loadingService.showLoading();
        this.shopService
            .getReferralLink()
            .then(res => {
                this.referralData = res;
            })
            .catch(() => this.message.error())
            .finally(() => this.loadingService.hideLoading());
    }

    getInvitationHistory() {
        this.shopService.getInvitationHistory().then(res => {
            this.invitationHistory = res;
        });
    }

    getModashProfile() {
        this.shopService.getModashProfile().then((res: any) => {
            this.friendList = res?.audience?.notableUsers
                .filter(ele => ele.email?.length)
                .map(ele => {
                    let formatFollowers = `${ele.followers}`;
                    if (ele.followers >= 1000000) {
                        formatFollowers = `${Math.floor(ele.followers / 100000) / 10}M`;
                    } else if (ele.followers >= 1000) {
                        formatFollowers = `${Math.floor(ele.followers / 100) / 10}K`;
                    }
                    return { ...ele, formatFollowers };
                });
        });
    }

    copyLink() {
        copy(this.referralData?.referralLink).then(() => {
            this.message.success('Link copied. Share now!');
        });
    }

    changeFriendChecked(data) {
        if (this.selectedFriendsSet.has(data.userId)) {
            this.selectedFriendsSet.delete(data.userId);
        } else {
            this.selectedFriendsSet.add(data.userId);
        }
    }

    inviteEmail() {
        const inviteParams = this.friendList
            .filter(ele => this.selectedFriendsSet.has(ele.userId))
            .map(ele => ({ email: isArray(ele.email) ? ele.email[0] : ele.email, friendName: ele.username }));

        this.invitationSubmitting = true;

        this.shopService
            .inviteEmail(inviteParams)
            .then(res => {
                this.message.success('Invitation succeeded');
                this.getInvitationHistory();
            })
            .catch(err => this.message.error())
            .finally(() => (this.invitationSubmitting = false));
    }
}
