<section class="image-viewer">
    <div class="large-image" [style.background-image]="selectedImage ? 'url('+selectedImage+')' : 'url(\'assets/svg/no_pic.svg\')'"></div>
    <div class="images">
        <div
            class="image-preview"
            *ngFor="let imageUrl of urlsList; let i = index"
            [style.background-image]="'url('+imageUrl+')'"
            (click)="selectedImage = urlsList[i]"
        ></div>
    </div>
</section>
