import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators } from '@angular/forms';
import { ProfileService } from '@src/app/services/profile.service';
import { environment } from '@src/environments/environment';
import { random } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize } from 'rxjs/operators';
import { ShopifyService } from '../shopify.service';

@Component({
    selector: 'app-shop-settings',
    templateUrl: './shop-settings.component.html',
    styleUrls: ['./shop-settings.component.less'],
})
export class ShopSettingsComponent implements OnInit {
    pageMode = 'view';

    shopDetail;
    originalShopDetail;

    nameControl = new FormControl('', Validators.required);
    urlControl = new FormControl('', Validators.required);

    duplicateUrl = false;
    duplicateName = false;

    desktopImgUploading = false;
    submitting = false;

    uploadTask;
    snapshot;

    get shopHost() {
        return environment.shopHost;
    }

    constructor(
        private shopifyService: ShopifyService,
        private profileService: ProfileService,
        private storage: AngularFireStorage,
        private message: NzMessageService
    ) {}

    ngOnInit(): void {
        this.getShopDetail();
    }

    getShopDetail() {
        this.shopifyService.getShopDetailInternal().then(shopDetail => {
            this.shopDetail = shopDetail;
        });
    }

    isFirebasestorage(src: string) {
        return src?.includes('firebasestorage');
    }

    checkoutImagePixel(fileList: FileList) {
        if (fileList.length === 0) {
            return;
        }

        // Image Uploading
        this.desktopImgUploading = true;

        this.profileService
            .checkIfImagePixelValid(fileList[0], { width: 4472, height: 4472, size: 20 * 1024 })
            .then(() => this.onDrop(fileList))
            .catch(() => {
                this.message.create('error', 'Please upload an image less than 4472 by 4472 pixels and 20 MB');
                // Image Uploaded
                this.desktopImgUploading = false;
            });
    }

    onDrop(fileList: FileList) {
        const file = fileList[0];
        const indexOfDot = file.name.lastIndexOf('.');
        const fileType = file.name.slice(indexOfDot);
        const path = `shop_theme/imgs/${Date.now()}${random(0, 100000)}${indexOfDot === -1 ? '' : fileType}`;
        // Reference to storage bucket
        const ref = this.storage.ref(path);
        // The main task
        this.uploadTask = this.storage.upload(path, file);
        this.uploadTask.percentageChanges();
        this.snapshot = this.uploadTask.snapshotChanges().pipe(
            // The file's download URL
            finalize(async () => {
                const downloadURL = await ref.getDownloadURL().toPromise();
                this.desktopImgUploading = false;
                this.shopDetail.shop_image_url = downloadURL;
            })
        );
        this.snapshot.subscribe();
    }

    previewOnlineStore() {
        const link = `${environment.shopHost}/${this.shopDetail.shop_url || this.shopDetail.shop_id}`;
        window.open(link, '_blank');
    }

    editContent() {
        this.originalShopDetail = { ...this.shopDetail };
        this.pageMode = 'edit';
    }

    cancelEdit() {
        this.pageMode = 'view';
        this.shopDetail = { ...this.originalShopDetail };
    }

    saveContent() {
        this.submitting = true;
        const objToUpdate: any = {
            shop_image_url: this.shopDetail.shop_image_url,
            enable_shoping_cart: this.shopDetail.enable_shoping_cart,
        };

        if (this.shopDetail.shop_name !== this.originalShopDetail.shop_name) {
            objToUpdate.shop_name = this.shopDetail.shop_name;
        }
        if (this.shopDetail.shop_url !== this.originalShopDetail.shop_url) {
            objToUpdate.shop_url = this.shopDetail.shop_url;
        }

        this.shopifyService
            .updateShopDetails(objToUpdate)
            .then(() => this.getShopDetail())
            .catch(() => this.message.error('Oops, something went wrong. Please try again or contact us.', { nzDuration: 3000 }))
            .finally(() => {
                this.submitting = false;
                this.pageMode = 'view';
            });
    }
}
