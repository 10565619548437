<div class="shop-item">
    <div class="product-image">
        <img src="{{ product.image }}" />
        <div class="special" *ngIf="product.promoted && product.promotion_type !== 'donation'">❤️ Special Edition</div>
        <div class="special donation" *ngIf="product.promoted && product.promotion_type === 'donation'">
            <img src="assets/images/donation.png" /> Donation
        </div>
        <div class="discount-message" *ngIf="product.enable_sharing">
            <img src="assets/svg/diamond.svg" />
            Up to {{ product?.sharing_max_discount }}% off
        </div>
        <div class="Commission__main" *ngIf="showCommission && showCommissionEvent">
            <div>
                <p>+ Commission</p>
            </div>
            <div class="timer__Commission">
                {{ math.floor((product.commission_offer_deadline - date) / 3600) | number: "2.0-0" }}:{{
                math.floor((product.commission_offer_deadline - date) / 60) % 60 | number: "2.0-0" }}:{{ (product.commission_offer_deadline
                - date) % 60 | number: "2.0-0" }}
            </div>
        </div>
        <div class="Commission__main" *ngIf="!showCommission && showDiscountEvent">
            <div>
                <p>Limited Time</p>
            </div>
            <div class="timer__Commission">
                {{ math.floor((product.discount_deadline - date) / 3600) | number: "2.0-0" }}:{{ math.floor((product.discount_deadline -
                date) / 60) % 60 | number: "2.0-0" }}:{{ (product.discount_deadline - date) % 60 | number: "2.0-0" }}
            </div>
        </div>
    </div>
</div>
<div class="product-info">
    <div class="product-title"><span class="vendor">{{ product.vendor }} </span>{{ displayTitle(product.title) }}</div>
    <!-- <div *ngIf="showCommission">
        <span class="product-sold">{{ product.sold }} items sold</span>
    </div> -->
    <div class="commission" *ngIf="showCommission">
        <div>Est. Commission</div>
        <div class="discount-price">${{ product.commission }}</div>
    </div>
    <div class="product-price" *ngIf="!showCommission">
        <span class="same-price" *ngIf="!product.compare_at_price || product.compare_at_price === product.price">${{ product.price }}</span>
        <span class="discount-price" *ngIf="product.compare_at_price && product.compare_at_price !== product.price"
            >${{ product.price }}</span
        >
        <span class="original-price" *ngIf="product.compare_at_price && product.compare_at_price !== product.price"
            >${{ product.compare_at_price }}</span
        >
    </div>
    <div class="product-price-internal" *ngIf="showCommission">
        <span>Price</span>
        <span>
            <span class="same-price" *ngIf="!product.compare_at_price || product.compare_at_price === product.price"
                >${{ product.price }}</span
            >
            <span class="original-price" *ngIf="product.compare_at_price && product.compare_at_price !== product.price"
                >${{ product.compare_at_price }}</span
            >
            <span class="discount-price" *ngIf="product.compare_at_price && product.compare_at_price !== product.price"
                >${{ product.price }}</span
            >
        </span>
    </div>
</div>
