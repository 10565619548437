import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopComponent } from './shop.component';
import { ShopSettingsComponent } from './shop-settings/shop-settings.component';
import { SelectProductComponent } from './select-product/select-product.component';
import { ShopItemComponent } from './shop-item/shop-item.component';

@NgModule({
    declarations: [ShopComponent, ShopSettingsComponent, SelectProductComponent, ShopItemComponent],
    imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, FormsModule],
})
export class ShopModule {}
