import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaypalService } from '@services/paypal.service';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { IPaypalCustomerInfo } from '@typings/paypal.typings';
import { ProfileService } from '@services/profile.service';
import { AvailablePaymentMethods } from '@typings/profile.typing';

@Component({
    selector: 'app-connect-paypal',
    templateUrl: './paypal-redirection-page.component.html',
    styleUrls: ['./paypal-redirection-page.component.less'],
})
export class ConnectPaypalComponent implements OnInit, OnDestroy {
    code: string;
    page = '/home/dashboard';

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private paypalService: PaypalService,
        private router: Router,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.code = this.activatedRoute.snapshot.queryParamMap.get('code');
        if (this.activatedRoute.snapshot.queryParamMap.get('page')) {
            this.page = this.activatedRoute.snapshot.queryParamMap.get('page');
        }
        if (!this.code) {
            this.router.navigate([this.page]);
        }

        this.paypalService
            .getPaypalData(this.code)
            .pipe(
                takeUntil(this.unsubscribe),
                catchError(error => {
                    console.error(error);
                    this.router.navigate([this.page, { message: error.message }]);
                    return of(null);
                }),
                switchMap((res: IPaypalCustomerInfo | null) => {
                    if (res !== null) {
                        const primaryEmail = res.emails.find(email => email.primary);
                        if (primaryEmail) {
                            this.profileService.currentProfile.paypal = primaryEmail.value;
                            const paypal_address = {
                                street_address: res.address.street_address,
                                locality: res.address.locality,
                                region: res.address.region,
                                postal_code: res.address.postal_code,
                                country: res.address.country,
                            };
                            return this.profileService.updateCurrentProfile(
                                Object.assign(
                                    {},
                                    {
                                        paypal: this.profileService.currentProfile.paypal,
                                        selected_payment_method: AvailablePaymentMethods.paypal,
                                    },
                                    { paypal_address }
                                )
                            );
                        }
                    }
                    return of(null);
                })
            )
            .subscribe(updatedProfile => {
                if (updatedProfile !== null) {
                    this.profileService.currentProfile = updatedProfile;
                    this.router.navigate([this.page]);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
