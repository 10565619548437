<section class="text-align-center">
    <ng-container *ngIf="!contactSuccess;else contactSuccessTpl">
        <img src="assets/imgs/send-mails-logo.jpg" style="width: 140px; margin: 20px auto" />

        <div class="fs-20 fw-bold">Contact Us</div>
        <div>Contact us to change your subscription plan.</div>

        <div class="width-100p d-flex justify-content-between align-items-center mt-30">
            <button style="width: 200px" nz-button [disabled]="contacting" (click)="modalRef.triggerCancel()">Cancel</button>
            <button style="width: 200px" nz-button nzType="primary" [nzLoading]="contacting" (click)="confirm()">Confirm</button>
        </div>
    </ng-container>
</section>

<ng-template #contactSuccessTpl>
    <img src="assets/svg/img_email.svg" style="width: 140px; margin: 20px auto" />

    <div class="fs-20 fw-bold">Success</div>
    <div>Your account manager will reach out to you soon for the next steps.</div>

    <div class="width-100p d-flex justify-content-center align-items-center mt-30">
        <button style="width: 200px" nz-button nzType="primary" (click)="modalRef.triggerOk()">Got It</button>
    </div>
</ng-template>
