<ng-template #titleTemplate>
    <app-toolbar
        (menuClicked)="storefrontStateService.toolbarMenuChange($event)"
        *ngIf="currentEditingTextSectionData"
        [color]="currentEditingTextSectionData.styles.color"
        [selectedToolbars]="currentEditingTextSectionData.selectedToolBars"
        [textAlign]="currentEditingTextSectionData.styles['text-align']"
        [textDecoration]="currentEditingTextSectionData.styles['text-decoration']"
    ></app-toolbar>
</ng-template>

<div *ngIf="shopDetail?.theme_settings" class="recommended-box">
    <div [hidden]="siteView === 'mobile'" class="recommended recommended-desktop">
        <h4
            *ngIf="shopDetail.theme_settings.recommended.layout !== 'one_product'"
            [ngStyle]="{'font-family': shopDetail.theme_settings.fontTitle}"
            [style.color]="shopDetail.theme_settings.color5"
            class="content-title"
        >
            <div
                class="edit-input text-align-center"
                nz-input
                nz-popover
                nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                nzPopoverPlacement="top"
                [nzPopoverContent]="titleTemplate"
                [nzPopoverTrigger]="'click'"
                [style.fontFamily]="shopDetail?.theme_settings?.fontTitle"
                [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE)?.styles"
                (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE)"
                appContentEditableModel
                [(contentEditableModel)]="shopDetail.theme_settings.recommended.title"
            ></div>
        </h4>

        <div
            *ngIf="shopDetail.theme_settings.recommended?.layout === 'one_product' && selectedProducts?.length"
            class="d-flex single-product-layout"
        >
            <div class="product-img" style="width: 50%; margin-right: 40px">
                <img [src]="firstSelectedProduct?.image" class="big-img" />
                <div class="small-imgs">
                    <img [src]="firstSelectedProduct?.image" />
                    <img [src]="firstSelectedProduct?.image" />
                </div>
            </div>
            <div class="flex-1 product-info">
                <h5
                    [ngStyle]="{color: '#000', 'font-family': shopDetail.theme_settings.fontTitle,'font-size':'32px' }"
                    class="product-name"
                >
                    {{firstSelectedProduct?.title}}
                </h5>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex justify-content-between mb-20 product-price"
                >
                    <span>${{firstSelectedProduct?.price | number: "1.2-2"}}</span>
                    <span>Tax included</span>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>COLOR</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>MATERIAL</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>SIZE</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="d-flex align-items-center choose-qty">
                    <label style="white-space: nowrap">Choose quantity</label>
                    <div class="d-flex align-items-center justify-content-between qty-box">
                        <i nz-icon nzTheme="outline" nzType="minus"></i>
                        <span>1</span>
                        <i nz-icon nzTheme="outline" nzType="plus"></i>
                    </div>
                </div>
                <div
                    [ngClass]="{'corner-round ': shopDetail.theme_settings.cornerStyle === 'round'}"
                    [ngStyle]="{'white-space': 'nowrap' ,background: shopDetail.theme_settings.color3, color: shopDetail.theme_settings.color1, 'font-family': shopDetail.theme_settings.fontBody}"
                    class="mb-20 add-to-cart"
                >
                    ADD TO CART
                </div>
                <div [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="product-tip">
                    Your product description will show up here
                </div>
            </div>
        </div>

        <div *ngIf="shopDetail.theme_settings.recommended?.layout === 'swiper_product'" class="small-inventory-layout relative">
            <div
                class="d-flex justify-content-between product-list"
                [ngClass]="{
                    'justify-content-between': selectedProducts?.length >= 3,
                    'justify-content-center': selectedProducts?.length < 3
                }"
            >
                <div *ngFor="let data of selectedProducts | slice:3" class="product-item pl-10 pr-10">
                    <img [src]="data.image" />
                    <h5 [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="width-100p overflow-ellipsis product-name">
                        {{data.title}}
                    </h5>
                    <p [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="product-price">
                        ${{data.price | number: "1.2-2"}}
                    </p>
                    <span
                        [ngClass]="{'corner-round ': shopDetail.theme_settings.cornerStyle === 'round'}"
                        [ngStyle]="{background: shopDetail.theme_settings.color3, color: shopDetail.theme_settings.color1, 'font-family': shopDetail.theme_settings.fontBody}"
                        class="add-to-cart"
                    >
                        ADD TO CART
                    </span>
                </div>

                <img
                    *ngIf="selectedProducts?.length >= 3"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                    class="swiper-left"
                />
                <img
                    *ngIf="selectedProducts?.length >= 3"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                    class="swiper-right"
                />
            </div>
        </div>

        <div *ngIf="shopDetail.theme_settings.recommended?.layout === 'four_product_grid'" class="grid-style-layout">
            <div class="d-flex justify-content-between product-list">
                <div *ngFor="let data of selectedProducts" class="d-flex align-items-center justify-content-between product-item">
                    <div class="flex-1 d-flex flex-direction-column justify-content-start align-items-start">
                        <div
                            [ngStyle]="{color: '#000', 'font-family': shopDetail.theme_settings.fontBody}"
                            class="overflow-ellipsis-2 product-name fs-28"
                        >
                            {{data.title}}
                        </div>
                        <p [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="product-price fs-16">
                            ${{data.price | number: "1.2-2"}}
                        </p>
                        <span
                            [ngClass]="{'corner-round ': shopDetail.theme_settings.cornerStyle === 'round'}"
                            [ngStyle]="{background: shopDetail.theme_settings.color3, color: shopDetail.theme_settings.color1, 'font-family': shopDetail.theme_settings.fontBody}"
                            class="add-to-cart"
                        >
                            ADD TO CART
                        </span>
                    </div>
                    <img [src]="data.image" />
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="siteView === 'desktop'" class="recommended recommended-mobile">
        <h4
            *ngIf="shopDetail.theme_settings.recommended.layout !=='one_product'"
            [ngStyle]="{'font-family': shopDetail.theme_settings.fontTitle}"
            class="content-title"
            [style.color]="shopDetail.theme_settings.color5"
        >
            <div
                class="edit-input text-align-center"
                nz-input
                nz-popover
                nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                nzPopoverPlacement="top"
                [nzPopoverContent]="titleTemplate"
                [nzPopoverTrigger]="'click'"
                [style.fontFamily]="shopDetail?.theme_settings?.fontTitle"
                [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE)?.styles"
                (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.RECOMMENDED_PRODUCT_TITLE)"
                appContentEditableModel
                [(contentEditableModel)]="shopDetail.theme_settings.recommended.title"
            ></div>
        </h4>

        <div
            *ngIf="shopDetail.theme_settings.recommended.layout === 'swiper_product' || shopDetail.theme_settings.recommended.layout === 'four_product_grid'; else singleProductTpl"
            class="products"
            [ngClass]="{
                'product-four-grid-box':shopDetail.theme_settings.recommended.layout === 'four_product_grid'
            }"
        >
            <div *ngFor="let productItem of selectedProducts" class="mobile-product-item">
                <div
                    [style.backgroundImage]="'url(' + productItem.image + ')'"
                    class="image"
                    style="width: 100%; height: 0; padding-top: 100%"
                ></div>

                <div class="fs-16">
                    <div
                        class="overflow-ellipsis-2 mb-10"
                        [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody, 'height':'36px','line-height':'1.1'}"
                    >
                        {{productItem.title}}
                    </div>

                    <div class="mb-10">${{productItem.price | number:'0.2-2'}}</div>
                </div>

                <div
                    [ngClass]="{'corner-round ': shopDetail.theme_settings.cornerStyle === 'round'}"
                    [ngStyle]="{'white-space': 'nowrap' ,background: shopDetail.theme_settings.color3, color: shopDetail.theme_settings.color1, 'font-family': shopDetail.theme_settings.fontBody}"
                    class="mb-20 add-to-cart"
                >
                    ADD TO CART
                </div>
            </div>
        </div>
    </div>

    <ng-template #singleProductTpl>
        <div class="d-flex single-product-layout single-product-layout-mobile">
            <div class="product-img">
                <img [src]="firstSelectedProduct?.image" class="big-img" />
                <div class="small-imgs">
                    <img [src]="firstSelectedProduct?.image" />
                    <img [src]="firstSelectedProduct?.image" />
                </div>
            </div>
            <div class="flex-1 product-info">
                <h5
                    [ngStyle]="{color: '#000', 'font-family': shopDetail.theme_settings.fontTitle}"
                    class="product-name"
                    style="text-align: left; font-size: 24px"
                >
                    {{firstSelectedProduct?.title}}
                </h5>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex justify-content-between mb-20 product-price"
                >
                    <span>${{firstSelectedProduct?.price | number: "1.2-2"}}</span>
                    <span>Tax included</span>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>COLOR</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>MATERIAL</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex align-items-center justify-content-between product-option"
                >
                    <span>SIZE</span>
                    <i nz-icon nzTheme="outline" nzType="down"></i>
                </div>
                <div
                    [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}"
                    class="d-flex width-100p align-items-center justify-content-between choose-qty"
                >
                    <div class="d-flex align-items-center justify-content-between qty-box flex-1" style="margin-left: 0">
                        <i nz-icon nzTheme="outline" nzType="minus"></i>
                        <span>1</span>
                        <i nz-icon nzTheme="outline" nzType="plus"></i>
                    </div>

                    <div
                        [ngClass]="{'corner-round ': shopDetail.theme_settings.cornerStyle === 'round'}"
                        [ngStyle]="{'white-space': 'nowrap' ,background: shopDetail.theme_settings.color3, color: shopDetail.theme_settings.color1, 'font-family': shopDetail.theme_settings.fontBody}"
                        class="ml-20 add-to-cart flex-1"
                    >
                        ADD TO CART
                    </div>
                </div>

                <div [ngStyle]="{'font-family': shopDetail.theme_settings.fontBody}" class="product-tip">
                    Your product description will show up here
                </div>
            </div>
        </div>
    </ng-template>
</div>
