import { SubscribePlan, CARD_LIST, SubscribePlanNameEnum } from './../../subscription.data';
import { Component, Input, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MessageService } from '@services/message.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-downgrade-confirm',
    templateUrl: './downgrade-confirm.component.html',
})
export class DowngradeConfirmComponent implements OnInit {
    @Input() selectedPlan: SubscribePlan;

    loading = false;

    constructor(public shopService: ShopService, public modalRef: NzModalRef, private messageService: MessageService) {}

    get renewDate() {
        return dayjs(this.shopService.shopProfile?.subscription.sub_end_date).add(1, 'day').format('MMM D, YYYY');
    }

    ngOnInit(): void {}

    getFeeByPlanName(planName: SubscribePlanNameEnum) {
        return CARD_LIST.find(item => item.plan_text === planName)?.monthly_fee;
    }

    confirmDownGrade() {
        this.loading = true;
        const data = { subscription_plan: this.selectedPlan.plan_text };
        this.shopService
            .selectSubscriptionPlan(data)
            .then(() => this.modalRef.triggerOk())
            .catch(() => {
                this.loading = false;
                this.messageService.error();
            });
    }
}
