<div class="crowdfunding-overview" *ngIf="!fetchingCrowdfundingDetail" (click)="$event.stopPropagation()">
    <div class="flex-1">
        <button
            *ngIf="!crowdfundingDetail"
            nz-button
            nzType="primary"
            nzSize="small"
            style="width: 100px"
            [class.disabled]="disableStartCrowdfunding"
            (click)="startCrowdFunding();$event.stopPropagation()"
        >
            Start
        </button>
        <div *ngIf="crowdfundingDetail" class="crowdfunding-progress width-100p">
            <div class="d-flex justify-content-between align-items-center width-100p">
                <div class="bar flex-1" style="height: 8px; border-radius: 4px; background-color: #ebeaed">
                    <div
                        class="height-100p"
                        style="border-radius: 4px"
                        [style.width]="percentRate + '%'"
                        [ngClass]="{
                            'crowdfunding-in-progress':percentRate < 100,
                            'crowdfunding-success':percentRate === 100
                        }"
                    ></div>
                </div>
                <span style="color: #5f646d; white-space: nowrap; margin-left: 5px; font-size: 12px">
                    <span>{{crowdfundingDetail.item_sold || 0}}</span>
                    /{{crowdfundingDetail.minimal_quantity}}
                </span>
            </div>
            <div *ngIf="!isCrowdfundingEnd" class="fs-12 text-align-left" style="color: #9f99a3">
                <i nz-icon nzType="antd:count-down" style="margin-right: 5px"></i>
                <span>{{remainingDate}} remaining</span>
            </div>
            <div *ngIf="isCrowdfundingEnd" class="fs-12 text-align-center" style="color: #9f99a3">Ended</div>
        </div>
    </div>

    <i
        *ngIf="!isCrowdfundingEnd && crowdfundingDetail"
        nz-icon
        nzType="edit"
        style="margin-left: 10px; color: #9f99a3"
        (click)="editCrowdFunding()"
    ></i>
</div>
