import { ReuseService } from '@services/route-reuse.service';
// eslint-disable-next-line max-len
import { StartCrowdfundingModalComponent } from '@shared/components/crowdfunding/start-crowdfunding-modal/start-crowdfunding-modal.component';
import { GeneralDialogComponent } from '@shared/components/general-dialog/general-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CrowdFundingDetail } from '@modules/nidavellir/nidavellir.type';
import { Component, OnInit, Input } from '@angular/core';
import { NidavellirService } from '@modules/nidavellir/nidavellir.service';
import { DateTimeService } from '@services/date-time.service';
import { MessageService } from '@services/message.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-crowdfunding-detail',
    templateUrl: './crowdfunding-detail.component.html',
    styleUrls: ['./crowdfunding-detail.component.less'],
})
export class CrowdfundingDetailComponent implements OnInit {
    @Input() productId: any;
    @Input() moq: number;

    @Input() crowdFundingDetail: CrowdFundingDetail;

    percentRate: number;

    fetchingCrowdfundingDetail = false;

    remainingDate: string;

    isCrowdfundingEnd = false;

    switching = false;

    constructor(
        private nidavellirService: NidavellirService,
        private messageService: MessageService,
        private modalService: NzModalService,
        public dateTimeService: DateTimeService
    ) {}

    ngOnInit(): void {
        if (!this.crowdFundingDetail) {
            this.nidavellirService.getCrowdFundingDetailByProductId(this.productId).then((res: CrowdFundingDetail) => {
                if (res) {
                    this.setLocalDataByDetail(res);
                }
            });
        } else {
            this.setLocalDataByDetail(this.crowdFundingDetail);
        }
    }

    getFulfilledProgress(data: CrowdFundingDetail) {
        return Math.floor((data.item_sold / data.minimal_quantity) * 100);
    }

    toggleVisibleInStore(val: boolean) {
        this.crowdFundingDetail.hide_from_store = !val;
        this.switching = true;
        this.nidavellirService
            .toggleVisibleOfCrowdfundingInStore({
                product_id: this.productId,
                hide_from_store: this.crowdFundingDetail.hide_from_store,
            })
            .catch(() => {
                this.messageService.error();
                this.crowdFundingDetail.hide_from_store = !this.crowdFundingDetail.hide_from_store;
            })
            .finally(() => (this.switching = false));
    }

    endCrowdfunding() {
        this.modalService.create({
            nzContent: GeneralDialogComponent,
            nzFooter: null,
            nzWrapClassName: 'no-padding',
            nzComponentParams: {
                confirmText: 'Confirm',
                cancelText: 'Cancel',
                message: 'Want to End the Crowdfunding?',
            },
            nzOnOk: comp => {
                comp.submitting = true;
                return new Promise((resolve, reject) => {
                    this.nidavellirService
                        .endCrowdfunding(Number(this.productId))
                        .then((data: any) => {
                            ReuseService.removeRouteCache('/product-management/product-list');
                            this.setLocalDataByDetail(data);
                            resolve();
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => (comp.submitting = false));
                });
            },
        });
    }

    editCrowdFunding() {
        this.modalService.create({
            nzContent: StartCrowdfundingModalComponent,
            nzFooter: null,
            nzMaskClosable: false,
            nzComponentParams: {
                moq: this.moq || 1,
                quantity: this.crowdFundingDetail.minimal_quantity,
                endTime: this.dateTimeService.parseDateWithTimeZone(this.crowdFundingDetail.end_date),
                isEditMode: true,
            },
            nzOnOk: comp => {
                const data = {
                    product_id: this.productId,
                    end_date: this.dateTimeService.replaceCurrentTzWithPST(comp.endTime),
                    minimal_quantity: comp.quantity,
                };
                comp.submitting = true;
                return new Promise((resolve, reject) => {
                    this.nidavellirService
                        .updateCrowdFundingInfo(data)
                        .then((res: CrowdFundingDetail) => {
                            ReuseService.removeRouteCache('/product-management/product-list');
                            resolve();
                            if (res) {
                                this.setLocalDataByDetail(res);
                            }
                        })
                        .catch(() => {
                            reject();
                            this.messageService.error();
                        })
                        .finally(() => (comp.submitting = false));
                });
            },
        });
    }

    private setLocalDataByDetail(data) {
        this.crowdFundingDetail = data;
        this.percentRate = this.getFulfilledProgress(this.crowdFundingDetail);
        this.remainingDate = this.getRemainingTimes(this.crowdFundingDetail.end_date);
        this.isCrowdfundingEnd = dayjs(this.crowdFundingDetail.end_date).isSameOrBefore(dayjs().add(10, 'seconds'));
    }

    private getRemainingTimes(date: string) {
        const diffTime = dayjs.duration(dayjs(date).diff(dayjs()), 'millisecond');

        const day = Math.floor(diffTime.asDays());
        const hours = diffTime.hours();
        const minutes = String(diffTime.minutes()).padStart(2, '0');
        const seconds = String(diffTime.seconds()).padStart(2, '0');

        if (day >= 1) {
            return day > 1 ? `${day} days ${hours} : ${minutes} : ${seconds}` : `${day} day ${hours} : ${minutes} : ${seconds}`;
        }

        return `${hours} : ${minutes} : ${seconds}`;
    }
}
