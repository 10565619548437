import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BalanceHistoryComponent } from './balance-history.component';
import { BalanceHistoryService } from './balance-history.service';
import { BalanceHistoryListComponent } from './balance-history-list/balance-history-list.component';
import { BalanceHistoryTableComponent } from './balance-history-table/balance-history-table.component';

@NgModule({
    declarations: [BalanceHistoryComponent, BalanceHistoryListComponent, BalanceHistoryTableComponent],
    imports: [CommonModule, RouterModule, SharedModule, NzTableModule, NzToolTipModule],
    providers: [BalanceHistoryService],
})
export class BalanceHistoryModule {}
