import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AntdModulesModule } from '../libs/antd-modules.module';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxStripeModule } from 'ngx-stripe';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigationWrapperComponent } from './navigation-wrapper/navigation-wrapper.component';
import { LifoTableComponent } from './lifo-table/lifo-table.component';
import { ConnectPaypalComponent } from '@shared/components/paypal-redirection-page/paypal-redirection-page.component';
import { GeneralDialogComponent } from './general-dialog/general-dialog.component';
import { LifoRichEditorComponent } from './lifo-rich-editor/lifo-rich-editor.component';
import { AntdEmptyTableComponent, AntdGlobalConfigComponent } from './antd-global-config/antd-global-config.component';
import { LoadingComponent } from './loading/loading.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AmountDetailPopoverComponent } from './amount-detail-popover/amount-detail-popover.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ExternalShopCardComponent } from './external-shop-card/external-shop-card.component';
import { PipesModule } from '@pipes/pipes.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FundsComponent } from '@modules/funds/funds.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AddressFormComponent } from './shopping-cart/address-form/address-form.component';
import { RecommendProductComponent } from './recommed-product/recommend-product.component';
import { PlanFullFeatureTableComponent } from './subscription/plan-full-feature-table/plan-full-feature-table.component';
import { SubscribePlanCardsComponent } from './subscription/subscribe-plan-cards/subscribe-plan-cards.component';
import { DowngradeConfirmComponent } from './subscription/modals/downgrade-confirm/downgrade-confirm.component';
import { SubscriptionContactUsComponent } from './subscription/modals/contact-us/subscription-contact-us.component';
import { CreditCartComponent } from './credit-cart/credit-cart.component';
import { MassProductModalComponent } from './mass-product-modal/mass-product-modal.component';
import { GalleryImagesDisplayComponent } from './gallery-images-display/gallery-images-display.component';
import { GeneralPaymentComponent } from './general-payment/general-payment.component';
import { CrowdfundingOverviewComponent } from './crowdfunding/crowdfunding-overview/crowdfunding-overview.component';
import { CrowdfundingDetailComponent } from './crowdfunding/crowdfunding-detail/crowdfunding-detail.component';
import { StartCrowdfundingModalComponent } from './crowdfunding/start-crowdfunding-modal/start-crowdfunding-modal.component';
// eslint-disable-next-line max-len
import { CrowdfundingInProgressModalComponent } from './crowdfunding/crowdfunding-in-progress-modal/crowdfunding-in-progress-modal.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { ChooseVideoDateComponent } from './choose-video-date/choose-video-date.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { ConnectPaymentComponent } from './connect-payment/connect-payment.component';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        NavigationWrapperComponent,
        LifoTableComponent,
        ConnectPaypalComponent,
        GeneralDialogComponent,
        LifoRichEditorComponent,
        AntdEmptyTableComponent,
        AntdGlobalConfigComponent,
        LoadingComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        AmountDetailPopoverComponent,
        ImageViewerComponent,
        ImageGalleryComponent,
        ExternalShopCardComponent,
        ContactUsComponent,
        FundsComponent,
        ShoppingCartComponent,
        SubscriptionComponent,
        AddressFormComponent,
        RecommendProductComponent,
        PlanFullFeatureTableComponent,
        SubscribePlanCardsComponent,
        DowngradeConfirmComponent,
        SubscriptionContactUsComponent,
        CreditCartComponent,
        MassProductModalComponent,
        GalleryImagesDisplayComponent,
        GeneralPaymentComponent,
        CrowdfundingOverviewComponent,
        CrowdfundingDetailComponent,
        StartCrowdfundingModalComponent,
        CrowdfundingInProgressModalComponent,
        ToolbarComponent,
        ChooseVideoDateComponent,
        ConnectPaymentComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AntdModulesModule,
        RichTextEditorModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        NgxMaskModule,
        NgxStripeModule,
        ColorPickerModule,
        NgxPayPalModule,
    ],
    exports: [
        PageNotFoundComponent,
        NavigationWrapperComponent,
        LifoTableComponent,
        GeneralDialogComponent,
        LifoRichEditorComponent,
        AntdEmptyTableComponent,
        AntdGlobalConfigComponent,
        LoadingComponent,
        AmountDetailPopoverComponent,
        ImageViewerComponent,
        ImageGalleryComponent,
        ExternalShopCardComponent,
        ShoppingCartComponent,
        SubscriptionComponent,
        AddressFormComponent,
        RecommendProductComponent,
        PlanFullFeatureTableComponent,
        SubscribePlanCardsComponent,
        CreditCartComponent,
        MassProductModalComponent,
        GalleryImagesDisplayComponent,
        GeneralPaymentComponent,
        CrowdfundingOverviewComponent,
        CrowdfundingDetailComponent,
        ToolbarComponent,
        ChooseVideoDateComponent,
        NgxPayPalModule,
    ],
})
export class ComponentsModule {}
