import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, take } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { IPaypalAuthTokenResponse, IPaypalCustomerInfo } from '@typings/paypal.typings';
import { environment } from '@src/environments/environment';
import { Base64Service } from '@services/base64.service';
import { RequestService } from '@services/request.service';

@Injectable({
    providedIn: 'root',
})
export class PaypalService {
    readonly clientId: string = environment.paypal.clientId;

    paypalTokenInfo: IPaypalAuthTokenResponse;

    constructor(private http: HttpClient, private base64: Base64Service, private requestService: RequestService) {}

    getPaypalData(code: string): Observable<IPaypalCustomerInfo> {
        return this.getAccessToken(code).pipe(
            switchMap(tokenResponse => {
                this.paypalTokenInfo = tokenResponse;
                return this.getCustomerInfo();
            })
        );
    }

    getPaypalLink(redirectParamsString = null) {
        const url = `${environment.paypal.websiteUrl}/connect`;
        const flowEntry = 'flowEntry=static';
        const client_id = `client_id=${environment.paypal.clientId}`;
        const scope = 'scope=email%20address';
        const redirect_uri = `redirect_uri=${window?.origin}/connect-paypal`;
        return `${url}?${flowEntry}&${client_id}&${scope}&${redirect_uri}${redirectParamsString ? redirectParamsString : ''}`;
    }

    async getPayPalDataPromise(code: string): Promise<IPaypalCustomerInfo> {
        this.paypalTokenInfo = (await this.getAccessToken(code).pipe(take(1)).toPromise()) as IPaypalAuthTokenResponse;
        return this.getCustomerInfo().pipe(take(1)).toPromise() as Promise<IPaypalCustomerInfo>;
    }

    private getAccessToken(code: string): Observable<IPaypalAuthTokenResponse> {
        return from(
            this.requestService.sendRequest<IPaypalAuthTokenResponse>({
                method: 'POST',
                url: '/influencer/paypal/access-token',
                data: {
                    code,
                },
            })
        );
    }

    private getCustomerInfo(): Observable<IPaypalCustomerInfo> {
        const url = `${environment.paypal.apiUrl}/v1/identity/oauth2/userinfo?schema=paypalv1.1`;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this.paypalTokenInfo.access_token}`);
        return this.http.get<IPaypalCustomerInfo>(url, {
            headers,
        });
    }
}
