<nz-spin class="global-loading" [nzIndicator]="indicatorTemplate" [nzSize]="'large'" [nzSpinning]="globalLoadingStatus">
    <section class="app-box">
        <section class="left-navigation">
            <section class="avator">
                <img class="avator-img" src="assets/imgs/seller-center-logo.png" alt="" />
                <div *ngIf="authService.isAccountManagerSignIn" class="fs-12 mt-10" style="color: #9f99a3">{{shopProfile?.name}}</div>
            </section>

            <ul nz-menu nzMode="inline">
                <li nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/home.svg" />
                    <a [routerLink]="['/', 'home']">Home</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/create.svg" />
                    <a [routerLink]="['/', 'nidavellir']">Create</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/listings.svg" />
                    <a [routerLink]="['/', 'product-management']">Listings</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/antd/my-purchase.svg" />
                    <a [routerLink]="['/', 'my-purchase']">My Purchases</a>
                </li>
                <li *ngIf="false" nz-menu-item nzMatchRouter>
                    <img
                        class="sidebar-icon"
                        src="assets/svg/sidebar_icons/campaign-discount.svg"
                        style="width: 24px; height: 24px; margin-right: 6px"
                    />
                    <a [routerLink]="['/', 'campaign']">Campaign</a>
                </li>
                <!-- Only subscribed/ -->
                <li *ngIf="accessToStorefront" nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/storefront.svg" />
                    <a [routerLink]="['/', 'storefront']">Storefront</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/billing.svg" />
                    <a [routerLink]="['/', 'balance-history']">Billing</a>
                </li>
                <li
                    nz-menu-item
                    nzMatchRouter
                    *ngIf="shopProfile && shopProfile.subscription.sub_plan_id === SubscribePlanNameEnum.PREMIUM"
                >
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/funds.svg" />
                    <a [routerLink]="['/', 'funds']">Funds</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="shopProfile?.shop_detail?.enable_site_traffic">
                    <img class="sidebar-icon" src="assets/svg/sidebar_icons/store_traffic.svg" />
                    <a [routerLink]="['/', 'site-traffic']">Store Traffic</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="shopProfile?.shop_detail?.enable_cosell">
                    <img class="sidebar-icon" src="assets/imgs/co-sell.png" />
                    <a [routerLink]="['/', 'co-sell']">Co-Sell</a>
                </li>
            </ul>

            <ul nz-menu nzMode="inline" class="quick-access">
                <div class="title">Quick Access</div>
                <li nz-menu-item nzMatchRouter>
                    <a (click)="visitOnlineStore()">
                        <span>View Store</span>
                        <i nz-icon nzType="eye" style="margin-left: 6px; font-size: 16px; vertical-align: middle"></i>
                    </a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a [routerLink]="['/', 'referral']">{{referralCampaign?.manuShow}}</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a [routerLink]="['/', 'profile']">Profile</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a [routerLink]="['/', 'faq']">FAQ</a>
                </li>
                <button class="logout-icon mb-20" nz-button nzType="primary" (click)="signOut()">
                    <img width="40" style="max-width: unset" src="/assets/imgs/logout.png" />
                </button>
            </ul>
        </section>

        <section class="right-content">
            <router-outlet></router-outlet>
        </section>
    </section>
</nz-spin>
<ng-template #indicatorTemplate>
    <div class="spinner-wrapper">
        <div class="container">
            <div class="row">
                <img class="loading-image" src="assets/imgs/loading-icon.jpg" width="40" />
                <p>Loading...</p>
            </div>
        </div>
    </div>
</ng-template>

<nz-modal
    [nzClassName]="'subscription-modal'"
    [(nzVisible)]="subscriptionVisible"
    [nzTitle]="showSubscriptionTitle ? subscriptionTitle : null"
    [nzFooter]="null"
    [nzMaskClosable]="false"
    [nzClosable]="isShowClosable"
    (nzOnCancel)="showModalService.hideSubscriptionModal()"
    nzWidth="920px"
>
    <ng-template #subscriptionTitle>
        <b class="fs-20" *ngIf="showSubscriptionTitle">{{getSubscriptionTitle}}</b>
    </ng-template>
    <section class="subscription-content">
        <app-subscription #subscriptionCard [showSkipBtnInput]="true" type="modal"></app-subscription>
    </section>
</nz-modal>

<nz-modal
    [nzClassName]="'store-preparing-modal'"
    [(nzVisible)]="storePreparingVisible"
    [nzFooter]="storePreparingFooter"
    [nzClosable]="false"
    [nzWidth]="shopStatus === 'preparing' ? '475px' : '600px'"
>
    <section *ngIf="shopStatus === 'not_exist'" class="text-align-center">
        <div class="d-flex align-items-center justify-content-between title">
            <label></label>
            <div class="cursor-pointer" (click)="storePreparingVisible = false">
                <img src="assets/svg/close_icon.svg" />
            </div>
        </div>
        <img src="assets/imgs/not_exist_shop.png" />
        <h5 class="text-center" style="font-size: 18px; margin-top: 30px">You don’t have an online shop yet!</h5>
        <p class="text-center" style="margin-bottom: 30px">Upgrade subscription plan, lifo will help you open a shop</p>
    </section>
    <section *ngIf="shopStatus === 'preparing'">
        <div class="d-flex align-items-center justify-content-between title">
            <label> {{shopProfile?.subscription_plan === 'premium' ? 'We’re Working on Your Store' : 'You’re on the waiting list'}} </label>
            <div class="cursor-pointer" (click)="storePreparingVisible = false">
                <img src="assets/svg/close_icon.svg" />
            </div>
        </div>
        <div class="store-preparing-tip">You will be notified when it’s ready.</div>
    </section>
    <section *ngIf="shopStatus === 'expired'" class="text-align-center">
        <div class="d-flex align-items-center justify-content-between title">
            <label></label>
            <div class="cursor-pointer" (click)="storePreparingVisible = false">
                <img src="assets/svg/close_icon.svg" />
            </div>
        </div>
        <img src="assets/imgs/expired_shop.png" />
        <h5 class="text-center" style="font-size: 18px; margin-top: 30px">Your subscription plan is expired.</h5>
        <p class="text-center" style="margin-bottom: 30px">
            Upgrade your plan before {{getShopClosedDate | date: 'yyyy-MM-dd'}}, or your shop will be closed
        </p>
    </section>
    <ng-template #storePreparingFooter>
        <button *ngIf="shopStatus === 'not_exist'" nz-button nzType="primary" (click)="showSubscriptionModal()">Upgrade Plan</button>
        <button *ngIf="shopStatus === 'preparing'" nz-button nzType="default" (click)="storePreparingVisible = false">Got It</button>
        <button *ngIf="shopStatus === 'expired'" nz-button nzType="primary" (click)="showSubscriptionModal()">Subscribe Plan</button>
    </ng-template>
</nz-modal>
