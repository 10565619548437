<section class="order-history">
    <section class="nav" (click)="backToDashboard()">
        <img width="18" height="18" src="assets/svg/arrow-left.svg" />
        <span>Home</span>
    </section>

    <section class="content">
        <section class="d-flex justify-content-between align-items-center mb-20" style="width: 100%">
            <div>
                <span class="title">Order History</span>
                <span class="count">{{ orderList?.length || 0 }}</span>
            </div>
        </section>

        <section class="table-content">
            <ng-template #noData>
                <app-empty-table description="No Data"></app-empty-table>
            </ng-template>
            <nz-table #basicTable [nzData]="orderList$ | async" [nzLoading]="loadingData" [nzNoResult]="noData">
                <thead>
                    <tr>
                        <th>Order</th>
                        <th>Time</th>
                        <th>Customer</th>
                        <th>Items</th>
                        <th>Amount</th>
                        <th>Fulfillment Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data" (click)="goOrderDetailPage(data)">
                        <td>{{ data.name }}</td>
                        <td>{{ data.created_at | localtime }}</td>
                        <td>{{ data.customer_name }}</td>
                        <td>x{{ data.line_items_count }}</td>
                        <td class="add">{{ data.total_price | currency }}</td>
                        <td style="color: #805817" [ngSwitch]="data.fulfillment_status">
                            <div *ngSwitchCase="'fulfilled'" class="fulfillment-status" style="background-color: #ffe77f">
                                <div class="box"></div>
                                <div class="text">Fulfilled</div>
                            </div>
                            <div *ngSwitchCase="'partial'" class="fulfillment-status" style="background-color: #ffbf6e">
                                <div class="box partially-box"></div>
                                <div class="text">Partially Fulfilled</div>
                            </div>
                            <div *ngSwitchDefault class="fulfillment-status" style="background-color: #f1f0f3">
                                <div class="box" style="border-color: #5b6069"></div>
                                <div class="text" style="color: #5b6069">Unfulfilled</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </section>
    </section>
</section>
