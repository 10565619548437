import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CrowdFundingDetail } from '@modules/nidavellir/nidavellir.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DateTimeService } from '@services/date-time.service';

@Component({
    selector: 'app-crowdfunding-in-progress-modal',
    templateUrl: './crowdfunding-in-progress-modal.component.html',
    styleUrls: ['./crowdfunding-in-progress-modal.component.less'],
})
export class CrowdfundingInProgressModalComponent implements OnInit {
    @Input() crowdFundingDetail: CrowdFundingDetail;
    @Input() productItem: { [key: string]: any; product_id: any };

    constructor(private router: Router, private modalRef: NzModalRef, private dateTimeService: DateTimeService) {}

    ngOnInit(): void {}

    goEditProduct() {
        this.modalRef.triggerOk();
        this.router.navigate(['/', 'product-management', 'product-detail', this.productItem.product_id]);
        window.open(this.productItem.link, '_blank');
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    formatEndDate(date: any) {
        return this.dateTimeService.formateDateWithTimezone(date, 'MM/DD/YYYY HH:mm PST');
    }
}
