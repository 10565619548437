import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import firebase from 'firebase/app';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    validateForm!: FormGroup;
    subscriptions: Subscription[] = [];
    checked = true;
    submitting = false;
    constructor(
        private fb: FormBuilder,
        private auth: AngularFireAuth,
        private router: Router,
        private message: NzMessageService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [false, [Validators.required]],
        });
    }

    submitForm(): void {
        if (this.submitting) {
            return;
        }
        if (!this.validateForm.valid) {
            throw new Error('Form is not valid');
        }
        this.submitting = true;
        const signInInformation = this.validateForm.getRawValue();
        this.auth.signInWithEmailAndPassword(signInInformation.email, signInInformation.password).then(
            async () => {
                await this.auth.setPersistence(
                    signInInformation.remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
                );

                const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
                if (redirectTo) {
                    this.router.navigate([redirectTo]);
                } else {
                    this.router.navigate(['/home/dashboard']);
                }
            },
            err => this.errorHandler(err)
        );
    }

    private errorHandler(error) {
        console.error(error);
        this.submitting = false;

        if (error.code === 'auth/wrong-password') {
            return this.message.create('error', 'Sorry, the email or password is incorrect.');
        }

        if (error.code === 'auth/user-not-found') {
            return this.message.create('error', 'Incorrect email/password combination, or email doesn’t exit');
        }

        this.message.create('error', error.message);
    }
}
