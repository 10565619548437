import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pages-no-sub-wrapper',
    templateUrl: './pages-no-sub-wrapper.component.html',
    styleUrls: ['./pages-no-sub-wrapper.component.less'],
})
export class PagesNoSubWrapperComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
