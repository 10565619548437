import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-youtube-video-modal',
    templateUrl: './youtube-video-modal.component.html',
    styleUrls: ['./youtube-video-modal.component.less'],
})
export class YoutubeVideoModalComponent implements OnInit {
    @Input() aboutYoutubeVideUrl: string;

    constructor(public modalRef: NzModalRef) {}

    ngOnInit(): void {}

    confirm() {
        this.modalRef.triggerOk();
    }
}
