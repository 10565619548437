import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AntdModulesModule } from './libs/antd-modules.module';
import { ComponentsModule } from './components/components.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@pipes/pipes.module';
import { NgxStripeModule } from 'ngx-stripe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AntdModulesModule,
        NgxMaskModule.forRoot(),
        DirectivesModule,
        PipesModule,
        NgxStripeModule,
        NgxMaskModule,
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        ColorPickerModule,
    ],
    exports: [
        AntdModulesModule,
        ComponentsModule,
        NgxMaskModule,
        NgxPlaidLinkModule,
        DragDropModule,
        DirectivesModule,
        PipesModule,
        NgxStripeModule,
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        ColorPickerModule,
    ],
})
export class SharedModule {}
