import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-gallery-images-display',
    templateUrl: './gallery-images-display.component.html',
    styleUrls: ['./gallery-images-display.component.less'],
})
export class GalleryImagesDisplayComponent implements OnInit {
    @Input() images: {
        src: string;
    }[];

    constructor() {}

    ngOnInit(): void {}
}
