<section class="main-card" [ngClass]="{'justify-content-center': type === 'modal'}">
    <div
        *ngFor="let item of cardList"
        [ngClass]="{'experienced': item.plan_text !== 'starter','premium': item.plan_text === 'premium'}"
        class="starter"
    >
        <div class="header">
            <span class="title">{{ item.plan_text }}</span>
            <span *ngIf="item.plan_text !== 'premium' && item.plan_text !== 'starter'" class="price">
                <!-- <i>
                    <strong>$</strong>
                    <span>{{ item.monthly_fee }}</span>
                </i>
                <i class="priceCount">
                    .00
                    <b>/Month</b>
                </i> -->
                <img
                    *ngIf="item.plan_text === shopProfile?.subscription_plan"
                    class="current"
                    src="/assets/imgs/current-icon.jpg"
                    width="100%"
                />
            </span>
            <span *ngIf="item.plan_text === 'premium'" class="price">
                <img src="/assets/imgs/diamond-icon.jpg" width="100%" />
                <img
                    *ngIf="item.plan_text === shopProfile?.subscription_plan"
                    class="current object-fit-contain"
                    src="/assets/imgs/current-icon.jpg"
                    width="100%"
                />
            </span>
            <span *ngIf="item.plan_text === 'starter'" class="price">
                Free
                <img *ngIf="shopProfile && isFreePlan && enableChoose" class="current" src="/assets/imgs/current-icon.jpg" width="100%" />
            </span>
        </div>
        <div *ngIf="item.plan_text !== 'starter'" class="text-content">
            <p *ngIf="item.plan_text !== 'premium'">
                <b style="color: #1b222e; font-size: 24px">${{ getIntegerOfNumber(item.monthly_fee) }}</b>
                <strong style="color: #1b222e">.{{getDecimalOfNumber(item.monthly_fee)}}</strong>
                <i style="color: #9f99a3; font-style: normal; margin-left: 5px">/Month</i>
            </p>
            <!-- <p *ngIf="item.plan_text === 'premium'" class="fs-18 fw-500 mt-30" style="color: #1b222e">Request Pricing</p> -->
            <img
                *ngIf="item.plan_text === 'premium'"
                src="/assets/imgs/premium-card-icon.png"
                class="absolute"
                style="bottom: -1px; left: 2px"
            />
            <!-- <p>Lifo Auto-fulfills {{item.plan_text === 'premium' ? '' : 'Up To'}}</p>
            <p>
                <span *ngIf="item.plan_text !== 'premium'">{{ item.order_fulfillment_quota }}</span>
                <img *ngIf="item.plan_text === 'premium'" src="/assets/imgs/gigantic-icon.jpg" width="23" />
                Orders And
            </p>
            <p>
                <span *ngIf="item.plan_text !== 'premium'">{{ item.product_sourcing_quota }}</span>
                <img *ngIf="item.plan_text === 'premium'" src="/assets/imgs/gigantic-icon.jpg" width="23" />
                Free Product Recommendation{{item.plan_text === 'premium' ? 's' : ''}}
            </p>
            <p class="describe">Per Month</p> -->
            <!-- <p
                *ngIf="item.plan_text === 'advanced'"
                style="margin-top: -8px; line-height: 26px; color: rgba(27, 34, 46, 0.7); font-size: 12px"
            >
                $1.00/per Extra order fulfillment <br />
                $10.00/per Extra Recommendations
            </p> -->
            <!-- <p>
                Up to
                <b>${{ item.lifo_funds | number }}</b>
                Lifo Creator Funds
            </p> -->
        </div>
        <div *ngIf="item.plan_text === 'starter'" class="text-content">
            <p>Full access to Co-sell feature. <br />Limited access to supply chain features.</p>
            <p>Paid Service for Product Recommendations</p>
            <p class="describe freeInfo">
                You would like to quickly get started with social commerce, and would like to try it out with zero inventory risks.
            </p>
        </div>
        <ng-container *ngIf="enableChoose">
            <div
                *ngIf="item === recommendPlan && !isCurrentPlan(item) && !isNextPlan(item) && shopProfile?.subscription_plan !== 'premium'"
                class="recommended"
            >
                <img src="/assets/imgs/star-icon.jpg" width="16" /> Recommended
            </div>
            <div
                *ngIf="isCurrentPlan(item) && !isCurrentPlanSameWithNextPlan"
                class="recommended"
                style="background: none; text-align: center; color: #554764; font-size: 12px"
            >
                Expires on {{shopProfile?.subscription.sub_end_date | date:'yyyy-MM-dd'}}
            </div>
            <div
                *ngIf="isAdvancedPlan(item)"
                class="recommended"
                style="background: none; text-align: center; color: #554764; font-size: 12px"
            >
                Automatic renewal: {{renewDate}}
            </div>

            <div *ngIf="isBillingFailed && isPrevPlan(item)" class="renew-failed">
                <span>Automatic renewal failed</span>
                <i nz-icon nzType="close" nzTheme="outline"></i>
            </div>

            <div
                *ngIf="isFreeTrialPlan(item)"
                class="recommended"
                style="background: none; text-align: center; color: #554764; font-size: 12px"
            >
                Free Trial: {{shopProfile.subscription.trial_start_date | date: 'MM/dd'}} - {{shopProfile.subscription.trial_end_date |
                date: 'MM/dd'}}
            </div>

            <button
                *ngIf="!isNextPlan(item)"
                (click)="chooseCardItem(item)"
                [ngClass]="{'premium-button': item.plan_text === 'premium'}"
                [nzType]="'primary'"
                class="default-button"
                nz-button
            >
                {{ item.plan_text === 'premium' || shopProfile?.subscription_plan === 'premium' ? 'Contact Us' : isUsedFreeTrial(item) ?
                'Choose' : 'Try free for 90-days' }}
            </button>
            <button
                *ngIf="isNextPlan(item)"
                [nzType]="'primary'"
                class="default-button choose-button"
                nz-button
                [style.backgroundColor]="item.plan_text === 'premium'?'#D8B165':'#554764'"
            >
                <i nz-icon nzType="antd:choose-icon" style="font-size: 30px; color: #fff"></i>
            </button>
        </ng-container>
    </div>
</section>
