<section class="terms-of-use">
    <header>
        <img src="assets/svg/logo.svg" />
        <span><strong>Lifo</strong> Creator Hub</span>
    </header>
    <nz-tabset class="product-list-tabs" nzType="card" nzLinkRouter [nzTabBarGutter]="12" [(nzSelectedIndex)]="index">
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/privacy-policy']"> Privacy Policy </a>

            <section class="container">
                <h1>Privacy Policy</h1>

                <section class="block">
                    <p>Last updated: August 10, 2021</p>

                    <p>
                        At Lifo, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data.
                        <strong
                            >By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies
                            outlined below, and you hereby consent that we will collect, use and share your information as described in this
                            Privacy Policy.</strong
                        >
                    </p>

                    <p>
                        Remember that your use of Lifo's Services is at all times subject to our
                        <a routerLink="/terms-of-use">Terms of Use</a>, which incorporates this Privacy Policy. Any terms we use in this
                        Policy without defining them have the definitions given to them in the Terms of Use.
                    </p>

                    <p>
                        You may print a copy of this Privacy Policy by clicking here
                        <a href="https://creator.lifo.ai/privacy-policy" target="_blank">https://creator.lifo.ai/privacy-policy</a>. If you
                        have a disability, you may access this Privacy Policy in an alternative format by contacting :
                    </p>

                    <p>Email: info@lifo.ai</p>

                    <p>Phone: 833-800-LIFO</p>

                    <p>Address: 35640 Fremont Boulevard, #607, Fremont, CA, 94536</p>
                </section>

                <section class="block">
                    <h2>What this Privacy Policy Covers</h2>
                    <p>
                        This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal
                        Data” means any information that identifies or relates to a particular individual and also includes information
                        referred to as “personally identifiable information” or “personal information” under applicable data privacy laws,
                        rules or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or
                        people we don’t manage.
                    </p>
                </section>
                <section class="block">
                    <h2>Personal Data</h2>
                    <h3>Categories of Personal Data We Collect</h3>
                    <p>This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</p>
                    <table border="1">
                        <thead>
                            <tr>
                                <th>Personal Data</th>
                                <th>Examples of Personal Data We Collect</th>
                                <th>Categories of Third Parties With Whom We Share this Personal Data:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>Profile or Contact Data</strong></td>
                                <td>
                                    <ul>
                                        <li>First and last name</li>
                                        <li>Email</li>
                                        <li>Phone number</li>
                                        <li>Unique identifiers such as passwords</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>Payment Data</strong></td>
                                <td>
                                    <ul>
                                        <li>Payment card type</li>
                                        <li>Last 4 digits of payment card</li>
                                        <li>Billing address, phone number, and email</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers [(specifically our payment processing partner, currently Stripe, Inc)]</li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>Commercial Data</strong></td>
                                <td>
                                    <ul>
                                        <li>Purchase history</li>
                                        <li>Consumer profiles</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Device/IP Data</strong></td>
                                <td>
                                    <ul>
                                        <li>IP address</li>
                                        <li>Device ID</li>
                                        <li>Domain server</li>
                                        <li>Type of device/operating system/browser used to access the Services</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Web Analytics</strong></td>
                                <td>
                                    <ul>
                                        <li>Web page interactions</li>
                                        <li>Referring webpage/source through which you accessed the Services</li>
                                        <li>Non-identifiable request IDs</li>
                                        <li>Statistics associated with the interaction between device or browser and the Services</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Social Network Data</strong></td>
                                <td>
                                    <ul>
                                        <li>Email</li>
                                        <li>Phone number</li>
                                        <li>User name</li>
                                        <li>IP address</li>
                                        <li>Device ID</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Consumer Demographic Data</strong></td>
                                <td>
                                    <ul>
                                        <li>Age / date of birth</li>
                                        <li>Zip code</li>
                                        <li>Gender</li>
                                        <li>Race</li>
                                        <li>Ethnicity</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Geolocation Data</strong></td>
                                <td>
                                    <ul>
                                        <li>IP-address-based location information</li>
                                        <li>GPS data</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Sensory Data</strong></td>
                                <td>
                                    <ul>
                                        <li>Photos, videos or recordings of your environment</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Geolocation Data</strong></td>
                                <td>
                                    <ul>
                                        <li>IP-address-based location information</li>
                                        <li>GPS data</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Other Identifying Information that You Voluntarily Choose to Provide</strong></td>
                                <td>
                                    <ul>
                                        <li>Identifying information in emails or letters you send us</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Analytics Partners</li>
                                        <li>Business Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section class="block">
                    <h3>Categories of Sources of Personal Data</h3>
                    <p>We collect Personal Data about you from the following categories of sources:</p>
                    <ul>
                        <li>
                            <h3>You</h3>
                            <ul>
                                <li>
                                    <i>When you provide such information directly to us.</i>
                                    <ul>
                                        <li>When you create an account or use our interactive tools and Services.</li>
                                        <li>
                                            When you voluntarily provide information in free-form text boxes through the Services or through
                                            responses to surveys or questionnaires.
                                        </li>
                                        <li>When you send us an email or otherwise contact us.</li>
                                    </ul>
                                </li>
                                <li>
                                    <i>When you use the Services and such information is collected automatically.</i>
                                    <ul>
                                        <li>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
                                        <li>If you use a location-enabled browser, we may receive information about your location.</li>
                                        <li>
                                            If you download and install certain applications and software we make available, we may receive
                                            and collect information transmitted from your computing device for the purpose of providing you
                                            the relevant Services, such as information regarding when you are logged on and available to
                                            receive updates or alert notices.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Third Parties</h3>
                            <ul>
                                <li>
                                    <i>Vendors</i>
                                    <ul>
                                        <li>
                                            We may use analytics providers to analyze how you interact and engage with the Services, or
                                            third parties may help us provide you with customer support.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <i>Advertising Partners</i>
                                    <ul>
                                        <li>
                                            We receive information about you from some of our vendors who assist us with marketing or
                                            promotional services related to how you interact with our websites, applications, products,
                                            Services, advertisements or communications.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <i>Social Networks</i>
                                    <ul>
                                        <li>
                                            If you provide your social network account credentials to us or otherwise sign in to the
                                            Services through a third-party site or service, some content and/or information in those
                                            accounts may be transmitted into your account with us.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section class="block">
                    <h3>Our Commercial or Business Purposes for Collecting Personal Data</h3>
                    <ul>
                        <li>
                            <h3>Providing, Customizing and Improving the Services</h3>
                            <ul>
                                <li>Creating and managing your account or other user profiles.</li>
                                <li>Providing you with the products, services or information you request.</li>
                                <li>Meeting or fulfilling the reason you provided the information to us.</li>
                                <li>Providing support and assistance for the Services.</li>
                                <li>Improving the Services, including testing, research, internal analytics and product development.</li>
                                <li>Personalizing the Services, website content and communications based on your preferences.</li>
                                <li>Doing fraud protection, security and debugging.</li>
                                <li>
                                    Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth
                                    in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Marketing the Services</h3>
                            <ul>
                                <li>Marketing and selling the Services.</li>
                                <li>Showing you advertisements, including interest-based or online behavioral advertising.</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Corresponding with You</h3>
                            <ul>
                                <li>
                                    Responding to correspondence that we receive from you, contacting you when necessary or requested, and
                                    sending you information about Lifo or the Services.
                                </li>
                                <li>
                                    Sending emails and other communications according to your preferences or that display content that we
                                    think will interest you.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Meeting Legal Requirements and Enforcing Legal Terms</h3>
                            <ul>
                                <li>
                                    Fulfilling our legal obligations under applicable law, regulation, court order or other legal process,
                                    such as preventing, detecting and investigating security incidents and potentially illegal or prohibited
                                    activities.
                                </li>
                                <li>Protecting the rights, property or safety of you, Lifo or another party.</li>
                                <li>Enforcing any agreements with you.</li>
                                <li>Responding to claims that any posting or other content violates third-party rights.</li>
                                <li>Resolving disputes.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        We will not collect additional categories of Personal Data or use the Personal Data we collected for materially
                        different, unrelated or incompatible purposes without providing you notice.
                    </p>
                </section>

                <section class="block">
                    <h2>How We Share Your Personal Data</h2>
                    <p>
                        We disclose your Personal Data to the categories of service providers and other parties listed in this section.
                        Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your
                        Personal Data. For more information, please refer to the state-specific sections below.
                    </p>
                    <ul>
                        <li>
                            <strong>Service Providers.</strong>
                            These parties help us provide the Services or perform business functions on our behalf. They include:
                            <ul>
                                <li>Hosting, technology and communication providers.</li>
                                <li>Security and fraud prevention consultants.</li>
                                <li>Support and customer service vendors.</li>
                                <li>Product fulfillment and delivery providers.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Advertising Partners.</strong>
                            These parties help us market our services and provide you with other offers that may be of interest to you. They
                            include:
                            <ul>
                                <li>Ad networks.</li>
                                <li>Data brokers.</li>
                                <li>Marketing providers.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Analytics Partners.</strong>
                            These parties provide analytics on web traffic or usage of the Services. They include:
                            <ul>
                                <li>Companies that track how users found or were referred to the Services.</li>
                                <li>Companies that track how users interact with the Services.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Business Partners, including Influencers.</strong>
                            These parties partner with us in offering various goods and services to you. They include:
                            <ul>
                                <li>
                                    Influencers have approved or endorse the Influencer Goods that you can view and purchase via the
                                    Services, including those whose name(s), mark(s), likeness(es), logo(s) and other content or material
                                    may appear on the Influencer Goods.
                                </li>
                                <li>Companies that we partner with to offer joint promotional offers or opportunities.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Parties You Authorize, Access or Authenticate.</strong>
                            <ul>
                                <li>Third parties you access through the services.</li>
                                <li>Social media services.</li>
                                <li>Other users.</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section class="block">
                    <h3>Legal Obligations</h3>
                    <p>
                        We may share any Personal Data that we collect with third parties in conjunction with any of the activities set
                        forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for
                        Collecting Personal Data” section above.
                    </p>
                </section>
                <section class="block">
                    <h3>Legal Obligations</h3>
                    <p>
                        All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition,
                        bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part).
                        Should one of these events occur, we will make reasonable efforts to notify you before your information becomes
                        subject to different privacy and security policies and practices.
                    </p>
                </section>
                <section class="block">
                    <h3>Legal Obligations</h3>
                    <p>
                        We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing
                        information that makes the data personally identifiable to a particular user. We may use such aggregated,
                        de-identified or anonymized data and share it with third parties for our lawful business purposes, including to
                        analyze, build and improve the Services and promote our business, provided that we will not share such data in a
                        manner that could identify you.
                    </p>
                </section>

                <section class="block">
                    <h2>Tracking Tools, Advertising and Opt-Out</h2>
                    <p>
                        The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript
                        (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and
                        use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small
                        pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that
                        device to access our Services. We may also supplement the information we collect from you with information received
                        from third parties, including third parties that have placed their own Cookies on your device(s). Please note that
                        because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.
                    </p>
                    <p>We use the following types of Cookies:</p>
                    <p>
                        <strong>• Essential Cookies.</strong> Essential Cookies are required for providing you with features or services
                        that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling
                        these Cookies may make certain features and services unavailable.
                    </p>
                    <p>
                        <strong>• Functional Cookies.</strong> Functional Cookies are used to record your choices and settings regarding our
                        Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help
                        us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of
                        language or region).
                    </p>
                    <p>
                        <strong>• Performance/Analytical Cookies.</strong> Performance/Analytical Cookies allow us to understand how
                        visitors use our Services. They do this by collecting information about the number of visitors to the Services, what
                        pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical
                        Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns
                        and the Services’ content for those who engage with our advertising. For example, Google Inc. (“Google”) uses
                        cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by
                        Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google
                        Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out
                        page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at
                        <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a>.
                    </p>
                    <p>
                        <strong>• Retargeting/Advertising Cookies.</strong> Retargeting/Advertising Cookies collect data about your online
                        activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more
                        information about this, please see the section below titled “Information about Interest-Based Advertisements.”
                    </p>
                    <p>
                        You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an
                        option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as
                        (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a
                        variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may
                        have to manually adjust some preferences every time you visit our website and some of the Services and
                        functionalities may not work.
                    </p>
                    <p>
                        To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your
                        browser’s menu. To find out more information about Cookies, including information about how to manage and delete
                        Cookies, please visit http://www.allaboutcookies.org/.
                    </p>
                </section>

                <section class="block">
                    <h3>Information about Interest-Based Advertisements:</h3>
                    <p>
                        We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies,
                        ad technology vendors and research firms, to serve advertisements through the Services. These advertisements may be
                        targeted to users who fit certain general profile categories or display certain preferences or behaviors
                        (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data) may be provided to us by you,
                        or derived from the usage patterns of particular users on the Services and/or services of third parties. Such
                        information may be gathered through tracking users’ activities across time and unaffiliated properties, including
                        when you leave the Services. To accomplish this, we or our service providers may deliver Cookies, including a file
                        (known as a “web beacon”) from an ad network to you through the Services. Web beacons allow ad networks to provide
                        anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad
                        networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to
                        view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.
                    </p>
                    <p>
                        We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral
                        Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing associations
                        have developed an industry self-regulatory program to give consumers a better understanding of, and greater control
                        over, ads that are customized based a consumer’s online behavior across different websites and properties. To make
                        choices about Interest-Based Ads from participating third parties, including to opt-out of receiving behaviorally
                        targeted advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt-out pages,
                        which are located at http://www.networkadvertising.org/choices/ or www.aboutads.info/choices.
                    </p>
                </section>

                <section class="block">
                    <h2>Data Security and Retention</h2>
                    <p>
                        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
                        consent before they knowingly collect personally identifiable information online from children who are under 13
                        years of age. We do not knowingly collect or solicit personally identifiable information from a child under 13
                        without obtaining verifiable consent from that child’s parent or guardian (“Parental Consent”), except for the
                        limited amount of personally identifiable information we need to collect in order to obtain Parental Consent
                        (“Required Information”). Until we have received Parental Consent, we will only use Required Information for the
                        purpose of obtaining Parental Consent. If you are a child under 13 years of age, please do not attempt to send any
                        Personal Data about yourself to us before we obtain Parental Consent, except for the Required Information in the
                        context of the Parental Consent process. If you believe that a child under 13 years of age has provided us with
                        Personal Data (beyond the Required Information) without our obtaining Parental Consent, please contact us at
                        <a>info@lifo.ai</a>. We do not condition participation in our Services on disclosure of more Personal Data from a
                        child under 13 years of age than is reasonably necessary for that participation, and we do not retain Personal Data
                        from children under 13 years of age longer than is reasonably necessary in order to fulfill the purpose for which it
                        was disclosed.
                    </p>
                    <p>
                        If you are a parent or guardian of a user of our Services who is under 13 years of age, you may contact us at any
                        time to ask that (a) we stop collecting Personal Data from such user, (b) we delete any Personal Data already
                        collected from such user (although note that we may further retain information in an anonymous or aggregated form
                        where that information would not identify such user personally), or (c) we stop disclosing Personal Data collected
                        from such user to third parties, but continue to allow for collection and use of Personal Data collected from such
                        user in connection with the Services.
                    </p>
                </section>

                <section class="block">
                    <h2>California Resident Rights</h2>
                    <p>
                        If you are a California resident, you have the rights set forth in this section. Please see the “Exercising Your
                        Rights” section below for instructions regarding how to exercise these rights. Please note that we may process
                        Personal Data of our customers’ end users or employees in connection with our provision of certain services to our
                        customers. If we are processing your Personal Data as a service provider, you should contact the entity that
                        collected your Personal Data in the first instance to address your rights with respect to such data.
                    </p>
                    <p>
                        If there are any conflicts between this section and any other provision of this Privacy Policy and you are a
                        California resident, the portion that is more protective of Personal Data shall control to the extent of such
                        conflict. If you have any questions about this section or whether any of the following rights apply to you, please
                        contact us at <a>info@lifo.ai</a>.
                    </p>
                </section>

                <section class="block">
                    <h3>Access</h3>
                    <p>
                        You have the right to request certain information about our collection and use of your Personal Data over the past
                        12 months. In response, we will provide you with the following information:
                    </p>
                    <ul>
                        <li>The categories of Personal Data that we have collected about you.</li>
                        <li>The categories of sources from which that Personal Data was collected.</li>
                        <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
                        <li>The categories of third parties with whom we have shared your Personal Data.</li>
                        <li>The specific pieces of Personal Data that we have collected about you.</li>
                    </ul>
                    <p>
                        If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will
                        identify the categories of Personal Data shared with each category of third party recipient. If we have sold your
                        Personal Data over the past 12 months, we will identify the categories of Personal Data sold to each category of
                        third party recipient.
                    </p>
                </section>

                <section class="block">
                    <h3>Deletion</h3>
                    <p>
                        You have the right to request that we delete the Personal Data that we have collected about you. Under the CCPA,
                        this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you
                        with the Services or complete a transaction or other action you have requested. If your deletion request is subject
                        to one of these exceptions, we may deny your deletion request.
                    </p>
                </section>

                <section class="block">
                    <h3>Exercising Your Rights</h3>
                    <p>
                        To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request that (1)
                        provides sufficient information to allow us to verify that you are the person about whom we have collected Personal
                        Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate and respond to it.
                        Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests
                        that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify your identity
                        and complete your request. You do not need an account to submit a Valid Request.
                    </p>
                    <p>
                        We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a
                        Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that
                        your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your
                        request.
                    </p>
                    <p>You may submit a Valid Request using the following methods:</p>
                    <ul>
                        <li>Call us at: 833-800-LIFO</li>
                        <li>Email us at: <a>info@lifo.ai</a></li>
                        <li>Submit a form at this address: <a href="http://www.lifo.ai" target="_blank">http://www.lifo.ai</a></li>
                    </ul>
                    <p>
                        You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this, you must
                        provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a
                        copy of this written permission from your Authorized Agent when they make a request on your behalf.
                    </p>
                </section>

                <section class="block">
                    <h3>Personal Data Sales Opt-Out and Opt-In</h3>
                    <p>
                        We will not sell your Personal Data, and have not done so over the last 12 months. To our knowledge, we do not sell
                        the Personal Data of minors under 16 years of age.
                    </p>
                </section>

                <section class="block">
                    <h3>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</h3>
                    <p>
                        We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or
                        services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise
                        your rights under the CCPA. However, we may offer different tiers of our Services as allowed by applicable data
                        privacy laws (including the CCPA) with varying prices, rates or levels of quality of the goods or services you
                        receive related to the value of Personal Data that we receive from you.
                    </p>
                </section>

                <section class="block">
                    <h2>Other State Law Privacy Rights</h2>
                    <h3>California Resident Rights</h3>
                    <p>
                        Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent
                        disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit
                        such a request, please contact us at info@lifo.ai.
                    </p>
                    <h3>Nevada Resident Rights</h3>
                    <p>
                        If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties
                        who intend to license or sell that Personal Data. You can exercise this right by contacting us at
                        <a>info@lifo.ai</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email
                        address associated with your account. The company does not sell Personal Data.
                    </p>
                </section>

                <section class="block">
                    <h2>Changes to this Privacy Policy</h2>
                    <p>
                        We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but
                        we will alert you to any such changes by placing a notice on the Lifo website, by sending you an email and/or by
                        some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t
                        provided us with your email address), those legal notices will still govern your use of the Services, and you are
                        still responsible for reading and understanding them. If you use the Services after any changes to the Privacy
                        Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the
                        Privacy Policy in effect at the time such information is collected.
                    </p>
                </section>

                <section class="block">
                    <h2>Contact Information</h2>
                    <p>
                        If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal
                        Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:
                    </p>
                    <ul>
                        <li>Email: <a href="http://www.lifo.ai" target="_blank">http://www.lifo.ai</a></li>
                        <li>Phone: 833-800-LIFO</li>
                        <li>Address: 35640 Fremont Boulevard, #607, Fremont, CA, 94536</li>
                    </ul>
                </section>
            </section>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-of-use']"> Terms-of-Use </a>
        </nz-tab>
        <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['/terms-and-conditions']"> Terms and Conditions </a>
        </nz-tab>
    </nz-tabset>
    <section class="footer">v2.4 Ⓒ2022 by Lifo.ai</section>
</section>
