import { FirebaseEventsService, CUSTOM_ANALYTICS_EVENT } from '@services/firebase-event.service';
import { PaymentMethodEnum } from '@shared/components/shopping-cart/shopping-cart.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BalanceHistoryService, TransactionItem } from '.././balance-history.service';
import { BalanceHistoryTableComponent } from '../balance-history-table/balance-history-table.component';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-balance-history-list',
    templateUrl: './balance-history-list.component.html',
    styleUrls: ['./balance-history-list.component.less'],
})
export class BalanceHistoryListComponent implements OnInit {
    @ViewChild('pendingTable') pendingTable: BalanceHistoryTableComponent;
    @ViewChild('recentTable') recentTable: BalanceHistoryTableComponent;

    transactionList: TransactionItem[];
    pendingTransactionList: TransactionItem[];
    recentTransactionList: TransactionItem[];

    loadingData = false;

    constructor(
        private balanceHistoryService: BalanceHistoryService,
        private message: NzMessageService,
        private firebaseEventService: FirebaseEventsService
    ) {}

    async ngOnInit() {
        this.getTransactionHistory();
        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_BILLINGS);
    }

    getTransactionHistory() {
        this.loadingData = true;
        const transactionListPending = [];
        const transactionListRecent = [];
        this.balanceHistoryService
            .getTransactionHistory()
            .then(res => {
                this.transactionList = res;
                for (const item of this.transactionList) {
                    this.transactionTypeHandler(item);

                    if (item.type === 'Payment') {
                        const paymentMethods = item.meta_data?.paymentMethod || [];

                        const isSplitPayment = paymentMethods.filter(paymentItem => paymentItem.amount > 0).length > 1;
                        if (isSplitPayment) {
                            item.payMethod = 'Split Payment';
                        } else {
                            item.payMethod = paymentMethods.find(paymentItem => paymentItem.amount > 0)?.method;
                            item.bankNumber = item.meta_data?.paymentMethod?.find(
                                payment => payment.method === PaymentMethodEnum.BANK
                            )?.bankNumber;
                        }
                    }

                    if (item.status === 'PENDING') {
                        transactionListPending.push(item);
                    } else if (item.status === 'DONE' || item.status === 'FAILED') {
                        transactionListRecent.push(item);
                    }
                }

                this.pendingTransactionList = transactionListPending;
                this.recentTransactionList = transactionListRecent;
            })
            .catch(() => this.message.error('Get transaction list failed, please tray again later.'))
            .finally(() => (this.loadingData = false));
    }

    private transactionTypeHandler(item: TransactionItem) {
        switch (item.transaction_type) {
            case 'SHOP_COMMISSION':
                item.items = 'Lifo Shop Sales Commission';
                item.type = 'Earnings';
                break;
            case 'CASH_OUT':
                item.items = 'Balance Transfer To';
                item.type = 'Cash Out';
                break;
            case 'CAMPAIGN_PAY':
                item.items = 'Campaign Commission';
                item.type = 'Earnings';
                break;
            case 'SHOP_PAYMENT':
                item.items = 'Lifo Shop Product Sample';
                item.type = 'Payment';
                break;
            case 'RESTOCK':
                item.items = 'Restock Payment';
                item.type = 'Payment';
                break;
            case 'Restock Payment':
                item.items = 'Restock Payment';
                item.type = 'Payment';
                break;
            case 'AUTO_RESTOCK':
                item.items = 'Auto-Restock';
                item.type = 'Automatic Payment';
                break;
            case 'SHOP_EARNING':
                item.items = 'Lifo Shop Earnings';
                item.type = 'Earnings';
                break;
            case 'SHIPPING_CREDIT':
                item.items = 'Shipping Credit';
                item.type = 'Rewards';
                break;
            case 'Weekly Shipping Cost':
            case 'Monthly Shipping Cost':
                item.items = 'Payment for Shipping Cost';
                item.type = 'Payment';
                break;
            case 'Product Procurement Cost':
                item.items = 'Payment for Product Procurement Cost';
                item.type = 'Payment';
                break;
            case 'RAFFLE_CREDIT':
            case 'CREDIT':
            case 'AWARD_CREDIT':
                item.items = 'Event Rewards';
                item.type = 'Earnings';
                break;
            case 'Payment for Customized Order':
                item.items = 'Payment for Customized Product';
                item.type = 'Payment';
                break;
            case 'Warehouse Operation Cost':
                item.items = 'Payment for Warehouse Operation Cost';
                item.type = 'Payment';
                break;
            case 'Subscription Payment - Advanced Plan':
                item.items = item.transaction_type;
                item.type = 'Payment';
                break;
            default:
                item.items = item.transaction_type;
                item.type = 'Rewards';
                break;
        }
    }
}
