import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['../terms-of-use/terms-of-use.component.less'],
})
export class TermsAndConditionsComponent implements OnInit {
    index = 2;
    constructor() {}

    ngOnInit() {}
}
