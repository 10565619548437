import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { ProfileService } from '@services/profile.service';
import { ShopService } from '@services/shop.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    idTokenResult: firebase.auth.IdTokenResult;

    get isAccountManagerSignIn() {
        return this.idTokenResult?.claims?.account_manager;
    }

    constructor(
        private auth: AngularFireAuth,
        private profileService: ProfileService,
        private shopService: ShopService,
        private router: Router
    ) {}

    signOut() {
        this.auth.signOut().then(() => {
            this.router.navigate(['/', 'auth', 'sign-in']).then(() => {
                this.profileService.currentProfileSubject.next(null);
                this.shopService.shopProfile$.next(null);
            });
        });
    }
}
