import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import firebase from 'firebase/app';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent implements OnInit {
    validateForm!: FormGroup;
    subscriptions: Subscription[] = [];
    checked = true;
    submitting = false;
    forgotEmail = true;
    isSuccess = false;
    code: string;

    constructor(
        private fb: FormBuilder,
        private auth: AngularFireAuth,
        private router: Router,
        private message: NzMessageService,
        private activatedRoute: ActivatedRoute
    ) {}

    confirmValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { error: true, required: true };
        } else if (control.value !== this.validateForm.controls.password.value) {
            return { confirm: true, error: true };
        }
        return {};
    };

    ngOnInit(): void {
        this.code = this.activatedRoute.snapshot.queryParamMap.get('oobCode');
        if (this.code) {
            this.validateForm = this.fb.group({
                password: ['', [Validators.required, Validators.minLength(8)]],
                confirmPassword: ['', [Validators.required, this.confirmValidator]],
            });
        } else {
            this.validateForm = this.fb.group({
                email: ['', [Validators.required, Validators.email]],
            });
        }
    }

    validateConfirmPassword(): void {
        Promise.resolve().then(() => this.validateForm.controls.confirmPassword.updateValueAndValidity());
    }

    resetPassword() {
        this.submitting = true;
        if (!this.code) {
            this.auth
                .sendPasswordResetEmail(this.validateForm.get('email').value, {
                    handleCodeInApp: false,
                    url: `${window.location.origin}/auth/restore-password`,
                })
                .then(() => {
                    this.forgotEmail = false;
                    this.submitting = false;
                })
                .catch(e => {
                    console.log(e);
                    this.submitting = false;
                    this.message.error('Sending failed, please try again.');
                });
        } else {
            if (this.validateForm.get('password').value !== this.validateForm.get('confirmPassword').value) {
                return;
            }

            this.auth
                .confirmPasswordReset(this.code, this.validateForm.get('password').value)
                .then(resp => {
                    this.isSuccess = true;
                    this.submitting = false;
                    setTimeout(() => {
                        this.router.navigate(['/auth/sign-in']);
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.submitting = false;
                    this.message.error('Reset password failed, please try again.');
                });
        }
    }

    backToSignIn() {
        this.router.navigateByUrl('/auth/sign-in');
    }
}
