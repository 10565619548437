import { StorefrontService, StorefrontMediaEnum } from './../storefront.service';
import { MessageService } from '@services/message.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input } from '@angular/core';
import { StorefrontStateService, TextEditableEnum } from '../storefront-state.service';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.less'],
})
export class BannerComponent implements OnInit {
    @Input() shopDetail: ShopProfile;
    @Input() hasOngoingCampaign: boolean;
    @Input() siteView: 'mobile' | 'desktop';

    TextEditableEnum = TextEditableEnum;

    desktopImgUploading = false;
    mobileImgUploading = false;

    mobileBannerLayouts: any[] = ['center', 'top', 'bottom'];

    StorefrontMediaEnum = StorefrontMediaEnum;

    get scaleRatio() {
        return 1 / this.storefrontStateService.scaleRatio || 1;
    }

    constructor(
        private firebaseUploadService: FirebaseFileUploadService,
        private messageService: MessageService,
        public storefrontService: StorefrontService,
        public storefrontStateService: StorefrontStateService
    ) {}

    get originShopDetail() {
        return this.storefrontStateService.shopDetail;
    }

    get currentEditingTextSection() {
        return this.storefrontStateService.currentEditingTextSection;
    }

    get editableTextSectionDataMap() {
        return this.storefrontStateService.editableTextSectionDataMap;
    }

    get currentEditingTextSectionData() {
        return this.storefrontStateService.currentEditingTextSectionData;
    }

    get bannerHeadingColor() {
        return this.shopDetail?.theme_settings?.color5;
    }

    get bannerBodyColor() {
        return this.shopDetail?.theme_settings?.color2;
    }

    get bannerButtonBackground() {
        return this.shopDetail?.theme_settings?.color3;
    }

    get bannerButtonColor() {
        return this.shopDetail?.theme_settings?.color1;
    }

    ngOnInit(): void {}

    beforeUploadMobile = file => {
        this.uploadFile(file, 'mobileImg');
        return false;
    };

    beforeUploadDesktop = file => {
        this.uploadFile(file, 'desktopImg');
        return false;
    };

    uploadFile(file: any, imgStat: 'desktopImg' | 'mobileImg') {
        // Image Uploading
        let key: string;

        if (imgStat === 'desktopImg') {
            key = 'desktopImgUploading';
        }
        if (imgStat === 'mobileImg') {
            key = 'mobileImgUploading';
        }

        this[key] = true;

        this.onDrop(file, imgStat)
            .catch(() => this.messageService.error())
            .finally(() => (this[key] = false));
    }

    onDrop(file: any, imgStat: 'desktopImg' | 'mobileImg') {
        const { uploadTask } = this.firebaseUploadService.startUpload(file);
        return uploadTask.then(downloadURL => {
            if (imgStat === 'desktopImg') {
                this.shopDetail.theme_settings.banner.desktopImg = downloadURL;
            }
            if (imgStat === 'mobileImg') {
                this.shopDetail.theme_settings.banner.mobileImg = downloadURL;
            }

            this.storefrontService.handleBannerImage(this.shopDetail.theme_settings);
        });
    }

    removeMedia(type: 'desktopBanner' | 'mobileBanner') {
        if (type === 'desktopBanner') {
            this.shopDetail.theme_settings.banner.desktopImg = this.storefrontService.defaultThemeSettings.banner.desktopImg;
        }

        if (type === 'mobileBanner') {
            this.shopDetail.theme_settings.banner.mobileImg = this.storefrontService.defaultThemeSettings.banner.mobileImg;
        }
    }
}
