import { DowngradeConfirmComponent } from './modals/downgrade-confirm/downgrade-confirm.component';
import { SubscriptionContactUsComponent } from './modals/contact-us/subscription-contact-us.component';
import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { CARD_LIST, SubscribePlan, SubscribePlanNameEnum } from './subscription.data';
import { ShopService } from '@services/shop.service';
import { SubscribePlanService } from './subscribe-plan.service';
import { MessageService } from '@services/message.service';
import { PlaidService } from '@services/plaid.service';
import { ShowModalService } from '@services/show-modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

enum PaymentMethodEnum {
    BANK = 'Bank Account',
    CREDIT_CARD = 'Credit Card',
}
enum BillingIntervalEnum {
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
}

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.less'],
})
export class SubscriptionComponent implements OnInit {
    @Input() showSkipBtnInput = false;
    @Input() type: string;
    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    validateCoupon!: FormGroup;

    cardList = CARD_LIST;

    viewStatusControl = {
        loadingProfile: false,
        isCreditCartInputCompleted: false,
        showFullFeatureTable: false,
        payingByCredit: false,
        payingByBank: false,
        hideCreditCard: true,
        hideSubscriptionCard: false,
        connectingBank: false,
        freeTrialApplying: false,
    };

    bankAccount: string;

    shownCard: 'subscription' | 'payment' | 'subscribeSuccess' | 'subscribeSuccessNoStore' = 'subscription';
    shownCard$: BehaviorSubject<'subscription' | 'payment' | 'subscribeSuccess' | 'subscribeSuccessNoStore'> = new BehaviorSubject(
        'subscription'
    );

    stripeInfo = {
        firstName: '',
        lastName: '',
        zipCode: '',
    };

    recommendPlan: SubscribePlan = this.cardList.find(item => item.plan_text === SubscribePlanNameEnum.PRO);

    selectedPlan: SubscribePlan;

    PaymentMethodEnum = PaymentMethodEnum;
    selectedPaymentMethod: PaymentMethodEnum = PaymentMethodEnum.CREDIT_CARD;
    BillingIntervalEnum = BillingIntervalEnum;
    selectedBillingInterval = BillingIntervalEnum.YEARLY;
    couponCode;
    couponChecking = false;
    isApplied = false;
    subTotal = 288;
    discount = 0;

    faqTooltipVisible = false;

    get showSkipBtn() {
        return this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER;
    }

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get elementsOptions() {
        return this.shoppingCartService.elementsOptions;
    }

    get cardOptions() {
        return this.shoppingCartService.cardOptions;
    }

    get checkoutDisabled() {
        return (
            (this.selectedPaymentMethod === this.PaymentMethodEnum.CREDIT_CARD &&
                (!this.stripeInfo.firstName ||
                    !this.stripeInfo.lastName ||
                    !this.stripeInfo.zipCode ||
                    !this.viewStatusControl.isCreditCartInputCompleted)) ||
            (this.selectedPaymentMethod === this.PaymentMethodEnum.BANK && this.bankAccount === null)
        );
    }

    constructor(
        private fb: FormBuilder,
        public shoppingCartService: ShoppingCartService,
        private shopService: ShopService,
        private stripeService: StripeService,
        private subscribePlanService: SubscribePlanService,
        private messageService: MessageService,
        private modalService: NzModalService,
        private plaidService: PlaidService,
        private showModalService: ShowModalService
    ) {}

    ngOnInit() {
        this.validateCoupon = this.fb.group({
            couponCode: [null],
        });
        this.refreshData();
        this.getConnectedBankAccount();
    }

    chooseCardItem(data: SubscribePlan) {
        const modalName = this.subscribePlanService.showWhichModal(this.shopProfile.subscription_plan, data.plan_text);
        this.selectedPlan = data;

        if (
            data.plan_text === SubscribePlanNameEnum.ADVANCED &&
            !this.shopProfile.subscription.prev_sub_plan_id &&
            !this.shopProfile.subscription.trial_end_date
        ) {
            this.freeTrialConfirm();
            return false;
        }

        switch (modalName) {
            case 'contact':
                this.showContactUsModal();
                break;

            case 'downgrade':
                this.showDownGradeModal();
                break;

            case 'payment':
                this.shownCard = 'payment';
                this.shownCard$.next('payment');
                break;
        }
    }

    refreshData() {
        this.viewStatusControl.payingByBank = false;
        this.viewStatusControl.payingByCredit = false;
        this.viewStatusControl.loadingProfile = true;
        this.stripeInfo = {
            firstName: '',
            lastName: '',
            zipCode: '',
        };
        this.shownCard = 'subscription';
        this.shownCard$.next('subscription');
        this.shopService
            .getShopDetail()
            .then(() => (this.selectedPlan = this.cardList.find(item => item.plan_text === this.shopProfile.subscription_plan)))
            .finally(() => (this.viewStatusControl.loadingProfile = false));
    }

    zipCodeChange() {
        this.card.update({ value: { postalCode: this.stripeInfo.zipCode } });
    }

    subscriptionConfirm() {
        const data: any = {
            subscription_plan: this.selectedPlan.plan_text,
            payment_method: this.selectedPaymentMethod,
            coupon_id: this.isApplied ? this.couponCode : '',
            interval: this.selectedBillingInterval,
        };

        if (this.selectedPaymentMethod === this.PaymentMethodEnum.CREDIT_CARD) {
            this.viewStatusControl.payingByCredit = true;
            this.shopService
                .selectSubscriptionPlan(data as any)
                .then(res => this.payWithCredit(res))
                .catch(() => {
                    this.viewStatusControl.payingByCredit = false;
                    this.messageService.error();
                });
        }

        if (this.selectedPaymentMethod === this.PaymentMethodEnum.BANK) {
            this.viewStatusControl.payingByBank = true;
            this.shopService
                .selectSubscriptionPlan(data as any)
                .then((res: any) => {
                    if (res.domain) {
                        this.shownCard = 'subscribeSuccess';
                        this.shownCard$.next('subscribeSuccess');
                    } else {
                        this.shownCard = 'subscribeSuccessNoStore';
                        this.shownCard$.next('subscribeSuccessNoStore');
                    }
                })
                .catch(() => this.messageService.error())
                .finally(() => (this.viewStatusControl.payingByBank = false));
        }
    }

    freeTrialConfirm() {
        const data: any = {
            subscription_plan: this.selectedPlan.plan_text,
            payment_method: PaymentMethodEnum.BANK,
            coupon_id: '',
            interval: BillingIntervalEnum.MONTHLY,
        };
        this.viewStatusControl.freeTrialApplying = true;
        this.shopService
            .selectSubscriptionPlan(data as any)
            .then((res: any) => {
                if (res.domain) {
                    this.shownCard = 'subscribeSuccess';
                    this.shownCard$.next('subscribeSuccess');
                } else {
                    this.shownCard = 'subscribeSuccessNoStore';
                    this.shownCard$.next('subscribeSuccessNoStore');
                }
            })
            .catch(() => this.messageService.error())
            .finally(() => (this.viewStatusControl.freeTrialApplying = false));
    }

    cardInputChange(e: StripeCardElementChangeEvent) {
        this.viewStatusControl.isCreditCartInputCompleted = e.complete;
    }

    skipSubscription() {
        this.showModalService.hideSubscriptionModal();
        this.shopService.skipSubscriptionPlan().then(() => this.refreshData());
    }

    outChoseCard() {
        this.refreshData();
        this.showModalService.hideSubscriptionModal();
    }

    async payWithCredit(pi) {
        const generalError = 'Payment failed, please reconfirm the payment information';
        this.stripeService
            .confirmCardPayment(pi.client_secret, {
                payment_method: {
                    card: this.card.element,
                    billing_details: {
                        name: `${this.stripeInfo.firstName} ${this.stripeInfo.lastName}`,
                    },
                },
            })
            .subscribe(
                result => {
                    if (result?.paymentIntent?.status === 'succeeded') {
                        this.shopService.confirmSubscriptionPlan({ shop_subscription_id: pi.shop_subscription_id }).then(() => {
                            // Completed internal checkout process
                            if (pi.domain) {
                                this.shownCard = 'subscribeSuccess';
                                this.shownCard$.next('subscribeSuccess');
                            } else {
                                this.shownCard = 'subscribeSuccessNoStore';
                                this.shownCard$.next('subscribeSuccessNoStore');
                            }
                        });
                    } else {
                        // Stripe confirm failed with error message
                        this.messageService.error(result.error.message || generalError);
                    }
                },

                // Strip confirm failed with no reason
                () => this.messageService.error(generalError),

                // Finally
                () => (this.viewStatusControl.payingByCredit = false)
            );
    }

    connectPlaid() {
        this.viewStatusControl.connectingBank = true;
        this.plaidService
            .connectPlaid()
            .then(() => this.getConnectedBankAccount())
            .catch(() => this.messageService.error())
            .finally(() => (this.viewStatusControl.connectingBank = false));
    }

    getConnectedBankAccount() {
        this.plaidService.getPaymentMethod().then(data => {
            if (data.bank_account) {
                this.bankAccount = `${data.bank_account.account.name} ******* ${data.bank_account.account.mask}`;
            } else {
                this.bankAccount = null;
            }
        });
    }

    changeBillingInterval(val: BillingIntervalEnum) {
        this.selectedBillingInterval = val;
        this.subTotal = this.selectedBillingInterval === this.BillingIntervalEnum.YEARLY ? 288 : 29.99;
    }

    checkCoupon() {
        if (!this.couponCode) return;
        this.couponChecking = true;
        this.plaidService
            .couponCheck({ coupon_id: this.couponCode })
            .then((res: any) => {
                this.messageService.success('Successful.');
                this.discount = res.off_count;
                this.isApplied = true;
            })
            .catch(() => {
                this.validateCoupon.controls.couponCode.setErrors({ invalidCode: 'The coupon code is used. Try another.' });
            })
            .finally(() => (this.couponChecking = false));
    }

    private showContactUsModal() {
        this.modalService.create({
            nzContent: SubscriptionContactUsComponent,
            nzComponentParams: { selectedPlan: this.selectedPlan },
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWrapClassName: 'ant-modal-centered',
            nzOnOk: () => this.refreshData(),
        });
    }

    private showDownGradeModal() {
        this.modalService.create({
            nzContent: DowngradeConfirmComponent,
            nzComponentParams: { selectedPlan: this.selectedPlan },
            nzWrapClassName: 'ant-modal-centered',
            nzFooter: null,
            nzOnOk: () => this.refreshData(),
        });
    }
}
