import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';
import Client from 'shopify-buy';

@Injectable({
    providedIn: 'root',
})
export class ShopifyService {
    client;

    constructor(private requestService: RequestService) {
        this.initClient();
    }

    initClient() {
        // Initializing a client to return content in the store's primary language
        this.client = Client.buildClient({
            domain: 'lifohome.myshopify.com',
            storefrontAccessToken: '28b2c0d7fddf34886c333efe61bd440a',
        });
    }
    listShopItem() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: '/shared/list_product',
            api: 'data-shop',
        });
    }

    listShopItemByIds(ids: any[]) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/shared/list_product_by_ids',
            api: 'data-shop',
            data: { ids },
        });
    }

    fetchProductById(productId) {
        return this.client.product.fetch(productId);
    }

    updateProductList(product_list) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/shop-proudct-list',
            data: {
                product_list,
            },
            api: 'api-shop',
        });
    }

    getShopDetailInternal() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop-detail',
            api: 'api-shop',
        });
    }

    updateShopDetails(data): Promise<any> {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/shop',
            data,
        });
    }

    getShopifyIdFromBase64(base64Id: string): number {
        const decoded = atob(base64Id);
        const shopifyId = decoded.split('/').pop();
        return parseInt(shopifyId, 10);
    }

    encodingShopifyId(id: number): string {
        return btoa(`gid://shopify/Product/${id}`);
    }
}
