import { IStandardProductDetails, IStandardProductVariant } from '@modules/nidavellir/nidavellir.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-product-modal-tab',
    templateUrl: './order-product-modal-tab.component.html',
    styleUrls: ['./order-product-modal-tab.component.less'],
})
export class OrderProductModalTabComponent implements OnInit {
    @Input() productDetail: IStandardProductDetails;
    @Input() enableEditQty: boolean;

    sampleOrderSelectedVariants = new Set();
    variantAmountMap = new Map();
    submitting = false;

    get addToCartDisabled() {
        if (this.enableEditQty) {
            return Array.from(this.variantAmountMap.values()).every(amount => amount <= 0);
        } else {
            return this.sampleOrderSelectedVariants.size === 0;
        }
    }

    constructor() {}

    ngOnInit(): void {}

    formatterInteger = value => Math.floor(value) || 0;

    generateVariants(): IStandardProductVariant[] {
        if (this.enableEditQty) {
            return Array.from(this.variantAmountMap.keys()).filter(key => this.variantAmountMap.get(key) > 0);
        } else {
            return Array.from(this.sampleOrderSelectedVariants) as IStandardProductVariant[];
        }
    }

    selectVariant(variant: IStandardProductVariant) {
        if (!this.enableEditQty) {
            if (this.sampleOrderSelectedVariants.has(variant)) {
                this.sampleOrderSelectedVariants.delete(variant);
            } else {
                this.sampleOrderSelectedVariants.add(variant);
            }
        }
    }

    amountChange(item: IStandardProductVariant, value: number) {
        if (!value || value <= 0) {
            this.variantAmountMap.set(item, 0);
        } else {
            this.variantAmountMap.set(item, Math.floor(value));
        }
    }
}
