<div class="operates global-operators">
    <!-- Domain -->
    <div class="operate-item">
        <div class="operate-name">Domain</div>
        <div class="operate-content">
            <div *ngIf="shopDetail.shop_type === 'Shared'">
                <span>{{environment.domainConfig.prefix}} </span>
                <nz-input-group [nzSuffix]="suffixTemplateInfo" style="width: 110px">
                    <input
                        [formControl]="domainControl"
                        nz-input
                        nzSize="small"
                        nzType="primary"
                        placeholder="Domain"
                        type="text"
                        style="height: 40px; min-height: 40px"
                    />
                </nz-input-group>

                <ng-template #suffixTemplateInfo>
                    <i *ngIf="domainCheckingStatus === 'yes'" nz-icon nzTheme="outline" nzType="check-circle" style="color: green"></i>
                    <i *ngIf="domainCheckingStatus === 'no'" nz-icon nzTheme="outline" nzType="close-circle" style="color: red"></i>
                    <i *ngIf="domainCheckingStatus === 'checking'" nz-icon nzTheme="outline" nzType="loading"></i>
                </ng-template>
                <span> {{environment.domainConfig.suffix}}</span>
            </div>

            <div *ngIf="shopDetail.shop_type !== 'Shared'" class="link-btn" (click)="previewOnlineStore()">{{ shopDetail.domain }}</div>

            <ng-container *ngIf="domainControl.hasError('maxlength')">
                <div class="red">Max {{ domainControl.errors["maxlength"]["requiredLength"] }} characters</div>
            </ng-container>
            <ng-container *ngIf="domainControl.hasError('pattern')">
                <div class="red">Only alphabetical letters and numbers can be used.</div>
            </ng-container>
        </div>
    </div>

    <!-- Logo -->
    <div class="operate-item">
        <div class="operate-name">Logo</div>
        <div class="operate-content">
            <div [loading]="logoUploading" [maskVisible]="true" appLoading class="image-holder">
                <img [src]="shopDetail.theme_settings.logoImage" class="image-show" />
                <div class="add-logo isOpacity">
                    <div class="edit-button">
                        <img src="assets/imgs/swap.png" />
                        <p>Edit</p>
                    </div>
                    <input
                        (change)="checkoutImagePixel($event.target['files'])"
                        accept=".png,.jpg"
                        class="file-input"
                        id="inputFile"
                        type="file"
                    />
                </div>
            </div>

            <p class="fs-12 mt-10" style="color: #9f99a3">Height: 60px, max width: 120px</p>
        </div>
    </div>

    <!-- Current Templates -->
    <div class="operate-item">
        <div class="operate-name">Current Template</div>
        <div class="operate-content">
            <div class="template-list">
                <div
                    *ngFor="let item of templateList"
                    class="d-flex align-items-center cursor-pointer template-item"
                    (click)="changeTemplate(item)"
                >
                    <img
                        [src]="'assets/svg/checkbox-' + (shopDetail?.theme_settings?.themeStyle === item.value ? 'checked' : 'unchecked') + '.svg'"
                    />
                    <img [src]="item.icon" />
                    <span>{{item.name}}</span>
                </div>
            </div>

            <ng-container *ngIf="shopDetail?.theme_settings.themeStyle === 'custom'">
                <section class="color-selections mb-20">
                    <div class="sub-title">Colors Selections</div>

                    <div>
                        <div class="color-item-box" nz-tooltip [nzTooltipTitle]="shopDetail.theme_settings.color4">
                            <div class="color-item-edit">
                                <span class="item-name">Main Color</span>
                                <div [style.backgroundColor]="shopDetail.theme_settings.color4" class="color-block"></div>
                                <span class="overflow-ellipsis">{{shopDetail.theme_settings.color4}}</span>
                                <i class="fs-14 ml-10" style="width: 20px" nz-icon nzType="antd:arrow-down"></i>
                            </div>
                            <input
                                *ngIf="shopDetail.theme_settings"
                                (colorPickerChange)="accentColorChange($event)"
                                [(colorPicker)]="shopDetail.theme_settings.color4"
                                cpPosition="top"
                                class="color-picker"
                            />
                        </div>

                        <div class="color-item-box" nz-tooltip [nzTooltipTitle]="shopDetail.theme_settings.color3">
                            <div class="color-item-edit">
                                <span class="item-name">Button Color</span>
                                <div [style.backgroundColor]="shopDetail.theme_settings.color3" class="color-block"></div>
                                <span class="overflow-ellipsis">{{shopDetail.theme_settings.color3}}</span>
                                <i class="fs-14 ml-10" style="width: 20px" nz-icon nzType="antd:arrow-down"></i>
                            </div>
                            <input
                                *ngIf="shopDetail.theme_settings"
                                [(colorPicker)]="shopDetail.theme_settings.color3"
                                (colorPickerChange)="mainColorChange($event)"
                                cpPosition="top"
                                class="color-picker"
                            />
                        </div>
                    </div>
                </section>

                <section class="corner-style mb-20">
                    <div class="sub-title">Button Style</div>

                    <div (click)="cornerStyleChange('round')" class="corner-item-edit cursor-pointer">
                        <img
                            [src]="'assets/svg/checkbox-' + (shopDetail?.theme_settings.cornerStyle === 'round' ? 'checked' : 'unchecked') + '.svg'"
                        />
                        <span style="margin: 0 20px">Round Corner:</span>
                        <img src="assets/imgs/corner-round.png" width="38" />
                    </div>

                    <div (click)="cornerStyleChange('straight')" class="corner-item-edit cursor-pointer">
                        <img
                            [src]="'assets/svg/checkbox-' + (shopDetail?.theme_settings.cornerStyle === 'straight' ? 'checked' : 'unchecked') + '.svg'"
                        />
                        <span style="margin: 0 20px">Straight Corner:</span>
                        <img src="assets/imgs/corner-straight.png" width="38px" />
                    </div>
                </section>
            </ng-container>

            <div class="custom-font mb-20">
                <div class="custom-style-item">
                    <div style="color: 1b222e" class="label">Heading Font</div>

                    <nz-select
                        [(ngModel)]="shopDetail.theme_settings.fontTitle"
                        (ngModelChange)="fontChange($event,'title')"
                        [style.fontFamily]="shopDetail.theme_settings.fontTitle"
                        nzSuffixIcon="caret-down"
                    >
                        <nz-option *ngFor="let item of fontList" [nzLabel]="item.label" [nzValue]="item.value" [nzCustomContent]="true">
                            <span [ngStyle]="{'font-family':item.value}">{{item.label}}</span>
                        </nz-option>
                    </nz-select>
                </div>
                <div class="custom-style-item">
                    <div style="color: #1b222e" class="label">Text Font</div>

                    <nz-select
                        [(ngModel)]="shopDetail.theme_settings.fontBody"
                        (ngModelChange)="fontChange($event,'body')"
                        nzSuffixIcon="caret-down"
                        [style.fontFamily]="shopDetail.theme_settings.fontBody"
                    >
                        <nz-option *ngFor="let item of fontList" [nzLabel]="item.label" [nzValue]="item.value" [nzCustomContent]="true">
                            <span [ngStyle]="{'font-family':item.value}">{{item.label}}</span>
                        </nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
    </div>
</div>
