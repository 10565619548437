<section *ngIf="deckList?.length > 0" class="product-list-box" appLoading [loading]="loading">
    <h1>
        <img src="/assets/imgs/star-icon.png" width="20" class="mr-20" />
        <strong>Recommended For You</strong>
    </h1>
    <section class="deck-list">
        <div class="decks-box d-flex justify-content-start align-items-start" style="overflow-x: auto">
            <div class="deck-item" *ngFor="let deckItem of deckList" [routerLink]="['/','sourcing-request',deckItem.id]">
                <app-gallery-images-display [images]="deckItem.images"></app-gallery-images-display>

                <div class="mt-10 mb-10 overflow-ellipsis-2">{{deckItem.name}}</div>
                <div class="tag mb-10" *ngFor="let category of deckItem.categories">{{ category }}</div>
                <div style="color: #9f99a3">{{deckItem.created_at | localtime}}</div>
            </div>
        </div>
    </section>
</section>
