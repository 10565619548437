<!-- Crowd Funding -->
<section class="product-description mt-20" *ngIf="crowdFundingDetail">
    <div class="description-title">Crowdfunding</div>
    <div class="operation-box">
        <ng-container *ngIf="!isCrowdfundingEnd">
            <button nz-button class="operation-item" (click)="editCrowdFunding()">
                <i nz-icon nzType="edit"></i>
                Edit
            </button>

            <button nz-button class="operation-item" (click)="endCrowdfunding()">
                <i nz-icon nzType="poweroff"></i>
                End
            </button>
        </ng-container>

        <div *ngIf="isCrowdfundingEnd">
            <span class="mr-10">Show on product page</span>
            <nz-switch
                [nzLoading]="switching"
                [ngModel]="!crowdFundingDetail.hide_from_store"
                (ngModelChange)="toggleVisibleInStore($event)"
            ></nz-switch>
        </div>
    </div>
    <div class="crowd-funding-box">
        <div class="mt-20 mb-20">
            <span style="margin-right: 50px">
                <span style="color: #f39969; font-size: 20px">{{crowdFundingDetail.item_sold || 0}} </span>
                <span>sold / {{crowdFundingDetail.minimal_quantity}}</span>
            </span>

            <span>
                <span style="font-size: 16px; font-weight: bold; color: #1b222e">
                    <ng-container *ngIf="isCrowdfundingEnd"> Ended </ng-container>
                    <ng-container *ngIf="!isCrowdfundingEnd"> {{remainingDate}} </ng-container>
                </span>
                <span>
                    <ng-container *ngIf="isCrowdfundingEnd">
                        <span>{{dateTimeService.replaceCurrentTzWithPST(crowdFundingDetail.start_date,'YYYY-MM-DD HH:mm PST')}}</span>
                        <span> —— </span>
                        <span>{{dateTimeService.replaceCurrentTzWithPST(crowdFundingDetail.end_date,'YYYY-MM-DD HH:mm PST')}}</span>
                    </ng-container>
                    <ng-container *ngIf="!isCrowdfundingEnd"> remaining </ng-container>
                </span>
            </span>
        </div>

        <div class="width-100p d-flex justify-content-start align-items-center">
            <div class="progress-box" style="height: 14px; width: 660px; background-color: #ebeaed; border-radius: 8px">
                <div
                    class="real-progress"
                    [style.width]="percentRate>100?100:percentRate + '%'"
                    [ngClass]="{
                        'crowd-funding-in-progress':percentRate < 100,
                        'crowd-funding-success':percentRate >= 100
                    }"
                ></div>
            </div>

            <span class="ml-20">
                <span style="color: #f39969; font-weight: 600; font-size: 16px">{{percentRate}}%</span>
                <span> funded</span>
            </span>
        </div>
    </div>
</section>
