import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ShopifyService } from '../shopify.service';

@Component({
    selector: 'app-select-product',
    templateUrl: './select-product.component.html',
    styleUrls: ['./select-product.component.less'],
})
export class SelectProductComponent implements OnInit {
    productList = [];

    shopProductList = [];
    remainingProductList = [];

    restProductLoading = true;
    selectionProductLoading = true;

    showProductDetail = false;
    selectedProduct;
    finalProductVariant;
    selectedProductImages;

    isAddingToSelection = false;
    isProductSelected = false;

    shopDetail;

    maxSelection = 15;

    constructor(private message: NzMessageService, private shopifyService: ShopifyService) {}

    ngOnInit(): void {
        Promise.all([this.shopifyService.getShopDetailInternal(), this.shopifyService.listShopItem()]).then(result => {
            this.shopDetail = result[0];
            this.productList = result[1].items;

            const shopProductList = [];
            const remainingProductList = [];
            for (const product of this.productList) {
                if (this.shopDetail.product_list.indexOf(this.shopifyService.encodingShopifyId(product.product_id)) >= 0) {
                    shopProductList.push(product);
                } else {
                    remainingProductList.push(product);
                }
            }
            this.shopProductList = shopProductList;
            this.remainingProductList = remainingProductList;

            this.restProductLoading = false;
            this.selectionProductLoading = false;
        });
    }

    fetchLifoProductList() {}

    addProductToShop(product: { loading: boolean; product_id: number; promoted: any }, index: number) {
        if (product.loading) {
            return;
        }
        if (this.shopProductList.length >= this.maxSelection) {
            this.message.error(`You can only select up to ${this.maxSelection} items.`, { nzDuration: 3000 });
            return;
        }
        product.loading = true;
        const product_list = this.shopProductList.map(result => this.shopifyService.encodingShopifyId(result.product_id));
        product_list.push(this.shopifyService.encodingShopifyId(product.product_id));
        this.shopifyService.updateProductList(product_list).then(() => {
            this.remainingProductList.splice(index, 1);
            this.shopProductList.push(product);

            product.loading = false;
            this.message.success('The selected item has been added to your shop.', { nzDuration: 3000 });
        });
    }

    removeProductFromShop(product: { loading: boolean; product_id: number; promoted: any }) {
        if (product.loading) {
            return;
        }
        product.loading = true;

        const product_list = this.shopProductList.map(result => this.shopifyService.encodingShopifyId(result.product_id));
        const index = product_list.indexOf(this.shopifyService.encodingShopifyId(product.product_id));
        product_list.splice(index, 1);

        this.shopifyService.updateProductList(product_list).then(result => {
            this.remainingProductList.push(product);
            this.shopProductList.splice(index, 1);
            product.loading = false;
            this.message.success('The selected item has been removed from your shop.', { nzDuration: 3000 });
        });
    }

    showStoreTutorial() {
        // this.modalService.create({
        //     nzContent: StoreTutorialComponent,
        //     nzClosable: false,
        //     nzFooter: null,
        //     nzCloseOnNavigation: true,
        //     nzBodyStyle: {
        //         borderRadius: '20px',
        //     },
        // });
    }

    videProductDetail(product) {
        // this.shopifyService.fetchProductById(this.shopifyService.encodingShopifyId(product.product_id)).then(result => {
        //     this.selectedProduct = result;
        //     this.finalProductVariant = this.selectedProduct.variants[0];
        //     this.showProductDetail = true;
        //     this.selectedProductImages = result.images.map(item => item.src);
        // });
    }

    changeSelection() {}
}
