<div class="loading-overlay" *ngIf="isOverlayDisplayed" appLoading [loading]="isOverlayDisplayed"></div>

<section class="profile-container">
    <div class="password-changed-message" *ngIf="isPasswordChangedMessageDisplayed" style="padding-right: 22px">
        <img src="assets/svg/check_icon.svg" style="margin-right: 22px" />
        <label>Password updated!</label>
        <a class="close" (click)="isPasswordChangedMessageDisplayed = false"><img src="assets/svg/close_icon_white.svg" /></a>
    </div>

    <h1>Profile</h1>

    <div class="d-flex justify-content-between align-items-start" style="margin-top: 50px">
        <div class="flex-1">
            <section class="account">
                <h2>Account</h2>

                <div class="account-view-mode">
                    <div class="block">
                        <label class="title">Email</label>
                        <label class="value">{{profileService.currentProfile?.email}}</label>
                    </div>
                    <div class="block" *ngIf="!isChangePasswordFormDisplayed">
                        <label class="title">Password</label>
                        <a (click)="isChangePasswordFormDisplayed = true">Change Password <img src="assets/svg/arrow_down.svg" /></a>
                    </div>
                </div>
            </section>

            <section class="change-password" *ngIf="isChangePasswordFormDisplayed">
                <form [formGroup]="changePasswordForm">
                    <h2>Change password</h2>
                    <div class="block">
                        <label class="title">current password</label>
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="currPasswordEmptyErrorTpl">
                                <input
                                    type="password"
                                    nz-input
                                    formControlName="currentPassword"
                                    placeholder="Enter your current password"
                                    required
                                />
                                <ng-template #currPasswordEmptyErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('wrongPassword')">
                                        <span class="input-tip">The password is invalid or the user does not have a password.</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="block">
                        <label class="title">New password</label>
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="passwordEmptyErrorTpl">
                                <input
                                    type="password"
                                    nz-input
                                    formControlName="newPassword"
                                    placeholder="Password 8+characters"
                                    required
                                />
                                <ng-template #passwordEmptyErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('minlength')">
                                        <span class="input-tip">Short password!</span>
                                    </ng-container>
                                    <ng-container *ngIf="control.hasError('required')">
                                        <span class="input-tip">Please input your password!</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="block">
                        <label class="title">confirm new password</label>
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="confirmPasswordErrorTpl">
                                <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirm password" required />
                                <ng-template #confirmPasswordErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('notSame')">
                                        <span class="input-tip">New password and confirm password do not match!</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="buttons-row">
                        <button nz-button nzType="default" (click)="closeUpdatePasswordForm()" style="margin-right: 30px">Cancel</button>
                        <button nz-button nzType="primary" [disabled]="!changePasswordForm.valid" (click)="updatePassword()">Update</button>
                    </div>

                    <div class="message error-message" *ngIf="errorMessage.isDisplayed">
                        <img src="assets/svg/close_red.svg" />
                        {{errorMessage.text}}
                    </div>
                </form>
            </section>
        </div>

        <section class="payment-method flex-1">
            <h2>Payment Method</h2>

            <div class="block">
                <a nz-tooltip (click)="showPaymentMethodModal()" class="connect" *ngIf="!paymentMethod">+ Connect Payment Method</a>

                <ng-container *ngIf="paymentMethod === 'bank_account'">
                    <label class="title"> Bank Account </label>
                    <div class="selected-payment-method">
                        <span>{{ connectedBankName }}</span>
                        <a class="reconnect-paypal" (click)="connectPlaid()"> <img src="assets/svg/edit_icon.svg" /></a>
                    </div>
                </ng-container>

                <ng-container *ngIf="paymentMethod === 'paypal'">
                    <div class="selected-payment-method">
                        <span>PayPal ({{ paypalAccount | emailAsterisk }})</span>
                        <div class="cursor-pointer" style="color: #f3745e; margin-top: 10px" (click)="connectWithPaypal()">
                            Change PayPal Account
                        </div>
                    </div>
                </ng-container>
            </div>
        </section>
    </div>

    <nz-divider></nz-divider>

    <section class="subscription">
        <h2>
            Subscription
            <div class="ml-20 discount-desc" *ngIf="freeTrialInUse && !isBindCard">
                Set up subscription for the first month discount of $1.99.
                <u class="ml-20 cursor-pointer" (click)="addPaymentMethod()">Add payment method</u>
            </div>

            <div class="ml-20 discount-desc" *ngIf="isFreePlanAndTrialEnded">
                You are in the 'Free' plan. Subscribe below for more advanced features!
            </div>

            <div class="ml-20 discount-desc" *ngIf="isFreePlanAndNoTrial">
                You are in the 'Free' plan. Start enjoying your FREE 90 DAY TRIAL now!
            </div>
        </h2>
        <app-subscription #subscriptionCard></app-subscription>
    </section>

    <a class="terms-of-use" [routerLink]="['/', 'terms-of-use']" target="_blank">Terms of Use <img src="assets/svg/arrow_right.svg" /> </a>
</section>

<nz-modal
    [nzClassName]="'add-payment-method-modal'"
    [(nzVisible)]="addPaymentMethodVisible"
    [nzFooter]="null"
    [nzClosable]="false"
    nzWidth="600px"
>
    <app-add-payment-method
        (closeModal)="addPaymentMethodVisible = false"
        (saveSuccess)="addPaymentMethodSuccess()"
    ></app-add-payment-method>
</nz-modal>
