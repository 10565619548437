import { SubscribePlanNameEnum } from '@shared/components/subscription/subscription.data';
import { Subscription } from 'rxjs';
import { first, filter } from 'rxjs/operators';

import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ShopService, ReferralCampaign } from '@services/shop.service';
import { ProfileService } from '@services/profile.service';
import { LoadingService } from '@services/loading.service';
import { ShowModalService } from '@services/show-modal.service';
import { SubscriptionComponent } from './../subscription/subscription.component';
import dayjs from 'dayjs';

@Component({
    selector: 'app-navigation-wrapper',
    templateUrl: './navigation-wrapper.component.html',
    styleUrls: ['./navigation-wrapper.component.less'],
})
export class NavigationWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('subscriptionCard') subscriptionCard: SubscriptionComponent;
    globalLoadingStatus: boolean;
    subscriptions = new Subscription();
    isShowClosable = true;
    showSubscriptionTitle = true;
    email: string;
    SubscribePlanNameEnum = SubscribePlanNameEnum;

    subscribeOfSubscriptionModal: Subscription;

    subscriptionVisible: boolean;
    storePreparingVisible: boolean;
    shopStatus: 'not_exist' | 'preparing' | 'expired';

    referralCampaign: ReferralCampaign;

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get getSubscriptionTitle() {
        if (this.shopProfile.subscription_plan === SubscribePlanNameEnum.PREMIUM) {
            return 'Subscription';
        } else if (
            !this.shopProfile.subscription?.trial_end_date &&
            !this.shopProfile.subscription?.prev_sub_plan_id &&
            this.shopProfile.subscription_plan === SubscribePlanNameEnum.STARTER
        ) {
            return '90-day Free Trial Is Available For Everyone Now!';
        } else {
            return 'Upgrade your plan to kick off your business!';
        }
    }

    get getShopClosedDate() {
        return this.shopProfile.subscription?.trial_end_date
            ? dayjs(this.shopProfile.subscription?.trial_end_date).add(1, 'month').valueOf()
            : null;
    }

    // Free starter user doesn't have access to storefront
    // Shopify user doesn't have access to storefront
    get accessToStorefront() {
        if (this.shopProfile.subscription_plan === SubscribePlanNameEnum.PREMIUM) {
            return this.shopProfile.theme_id;
        }
        return this.shopProfile.subscription_plan !== SubscribePlanNameEnum.STARTER && this.shopProfile.shop_type !== 'Shopify';
    }

    constructor(
        public authService: AuthService,
        private router: Router,
        private shopService: ShopService,
        private profileService: ProfileService,
        private loadingService: LoadingService,
        private cdr: ChangeDetectorRef,
        public showModalService: ShowModalService
    ) {
        this.handleModals();
    }

    ngOnInit(): void {
        this.globalLoadingHandle();
        this.subscribeShopDetail();
        this.getReferralCampaign();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    signOut() {
        this.authService.signOut();
    }

    subscribeShopDetail() {
        this.shopService.shopProfile$
            .pipe(
                filter(value => !!value),
                first()
            )
            .subscribe(shopDetail => {
                if (shopDetail.subscription_plan === SubscribePlanNameEnum.STARTER) {
                    this.showModalService.showSubscriptionModal();
                }
            });
    }

    globalLoadingHandle() {
        const sub = this.loadingService.loadingStatus$.subscribe(status => {
            this.globalLoadingStatus = status;
            this.cdr.detectChanges();
        });
        this.subscriptions.add(sub);
    }

    getReferralCampaign() {
        this.shopService.getReferralCampaign().then(res => {
            this.referralCampaign = res;
        });
    }

    visitOnlineStore() {
        if (this.shopProfile.subscription_plan !== SubscribePlanNameEnum.STARTER && !this.shopProfile.domain) {
            this.shopStatus = 'preparing';
            this.storePreparingVisible = true;
            return false;
        }
        if (this.shopProfile.subscription_plan === SubscribePlanNameEnum.STARTER) {
            if (this.getShopClosedDate && this.getShopClosedDate < dayjs().valueOf()) {
                this.shopStatus = 'expired';
            } else {
                this.shopStatus = 'not_exist';
            }
            this.storePreparingVisible = true;
            return false;
        }
        if (this.shopProfile.domain.indexOf('http') !== 0) {
            window.open(`http://${this.shopProfile.domain}`, '_blank');
        } else {
            window.open(this.shopProfile.domain, '_blank');
        }
    }

    showSubscriptionModal() {
        this.storePreparingVisible = false;
        this.showModalService.showSubscriptionModal();
    }

    private handleModals() {
        const subscriptionSub = this.showModalService.subscriptionModalVisible$.subscribe(res => {
            this.subscriptionVisible = res;
            if (this.subscriptionVisible) {
                this.subscribeOfSubscriptionModal = this.subscriptionCard.shownCard$.subscribe(shownPage => {
                    if (shownPage === 'payment' || shownPage === 'subscribeSuccess') {
                        this.showSubscriptionTitle = false;
                    } else {
                        this.showSubscriptionTitle = true;
                    }
                });
            } else {
                this.subscribeOfSubscriptionModal.unsubscribe();
            }
        });
        this.subscriptions.add(subscriptionSub);

        const shopPreparationSub = this.showModalService.shopPreparationModalVisible$.subscribe(res => {
            this.shopStatus = 'preparing';
            this.storePreparingVisible = res;
        });
        this.subscriptions.add(shopPreparationSub);
    }
}
