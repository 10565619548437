import { environment } from '@src/environments/environment';
import { SubscribePlanNameEnum } from './../shared/components/subscription/subscription.data';
import { ShopService } from './shop.service';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

export interface CustomFirebaseEvent {
    name: CUSTOM_ANALYTICS_EVENT;
    properties?: any;
}

export enum CUSTOM_ANALYTICS_EVENT {
    VIEW_DASHBOARD = 'view_dashboard',
    VIEW_AC_PRODUCT_LIST = 'view_ac_product_list',
    VIEW_POD_PRODUCT_LIST = 'view_pod_product_list',
    POD_PRODUCT_VIEW = 'pod_product_view',
    CREATE_AC_SAMPLE = 'create_ac_sample',
    CREATE_POD_SAMPLE = 'create_pod_sample',
    VIEW_SHOPPING_CART = 'view_shopping_cart',
    PURCHASE = 'purchase',
    VIEW_LISTING = 'view_listing',
    VIEW_LISTING_PRODUCT = 'view_listing_product',
    SAVE_EDIT_LISTING_PRODUCT = 'save_edit_listing_product',
    VIEW_STOREFRONT = 'view_storefront',
    SAVE_EDIT_STOREFRONT = 'save_edit_storefront',
    VIEW_PURCHASE_ORDER = 'view_purchase_order',
    VIEW_BILLINGS = 'view_billings',
    VIEW_PROFILE = 'view_profile',
    VIEW_FAQ = 'view_faq',
    VIEW_ALL_STARS = 'view_all_stars',
}

@Injectable({
    providedIn: 'root',
})
export class FirebaseEventsService {
    constructor(private analytics: AngularFireAnalytics, private shopService: ShopService) {
        this.analytics.setAnalyticsCollectionEnabled(environment.production);
    }

    logEvent(event: CustomFirebaseEvent | string) {
        if (typeof event === 'string') {
            this.analytics.logEvent(event, { shop_id: this.shopService.shopProfile?.id });
        } else {
            this.analytics.logEvent(event.name, { shop_id: this.shopService.shopProfile.id, ...(event.properties || {}) });
        }
        // eslint-disable-next-line no-console
        console.debug('event_sent:', event);
    }

    setupFirebaseEvent(params: { uid?: any; userType?: SubscribePlanNameEnum }) {
        if (params.uid) {
            this.analytics.setUserId(params.uid);
        }

        if (params.userType) {
            this.analytics.setUserProperties({ user_type: params.userType });
        }
    }

    addShopIdForDefaultEvent(shopId: any) {
        this.analytics.updateConfig({ shop_id: shopId });
    }
}
