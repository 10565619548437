import { cloneDeep } from 'lodash';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { StorefrontStateService } from './../storefront-state.service';
import { ProductListItem } from '@modules/product-management/product-management.service';
import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-choose-product-modal',
    templateUrl: './choose-product-modal.component.html',
    styleUrls: ['./choose-product-modal.component.less'],
})
export class ChooseProductModalComponent implements OnInit, OnDestroy {
    @Input() shopDetail: ShopProfile;

    productList: ProductListItem[];
    selectedProducts: ProductListItem[];
    unselectedProducts: ProductListItem[];

    subscriptions = new Subscription();

    constructor(public modalRef: NzModalRef, private storefrontStateService: StorefrontStateService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.storefrontStateService.currentSelectedProducts$.pipe(filter(data => !!data)).subscribe(data => {
                this.selectedProducts = cloneDeep(data);
                this.handleUnSelectedData();
            })
        );

        this.subscriptions.add(
            this.storefrontStateService.productList$.pipe(filter(data => !!data)).subscribe(data => {
                this.productList = cloneDeep(data);
                this.handleUnSelectedData();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    handleUnSelectedData() {
        if (this.productList && this.selectedProducts) {
            this.unselectedProducts = this.productList.filter(
                product => !this.selectedProducts.some(item => item.product_id === product.product_id)
            );
        }
    }

    ok() {
        this.storefrontStateService.currentSelectedProducts = this.selectedProducts;
        this.storefrontStateService.currentSelectedProducts$.next(this.selectedProducts);
        this.modalRef.triggerOk();
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    drop(event: CdkDragDrop<ProductListItem[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }
}
