import { ShopProfileGuard } from './services/shop-profile.guard';
import { SourcingRequestComponent } from './modules/pages-no-sub-wrapper/sourcing-request/sourcing-request.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

// Home
import { HomeComponent } from '@modules/home/home.component';
import { HomeDashboardComponent } from '@modules/home/home-dashboard/home-dashboard.component';
import { OrderHistoryComponent } from '@modules/home/order-history/order-history.component';

import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { NavigationWrapperComponent } from '@shared/components/navigation-wrapper/navigation-wrapper.component';
import { LoginComponent } from '@src/app/modules/auth/login/login.component';
import { ConnectPaypalComponent } from '@shared/components/paypal-redirection-page/paypal-redirection-page.component';
import { ForgotPasswordComponent } from '@src/app/modules/auth/forgot-password/forgot-password.component';
import { OrderDetailComponent } from '@modules/home/order-detail/order-detail.component';
import { BalanceHistoryComponent } from '@modules/balance-history/balance-history.component';
import { BalanceHistoryListComponent } from '@modules/balance-history/balance-history-list/balance-history-list.component';
import { ProfileComponent } from '@modules/profile/profile.component';
import { FaqComponent } from '@modules/faq/faq.component';
import { ReferralComponent } from '@modules/referral/referral.component';
import { TermsOfUseComponent } from '@shared/components/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from '@shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '@shared/components/terms-and-conditions/terms-and-conditions.component';
import { SignUpComponent } from '@modules/auth/sign-up/sign-up.component';
import { AuthComponent } from '@modules/auth/auth.component';
import { StorefrontComponent } from '@modules/storefront/storefront.component';
import { FundsComponent } from '@modules/funds/funds.component';
import { CustomSignInComponent } from '@modules/auth/custom-sign-in/custom-sign-in.component';
import { PlansComponent } from './modules/plans/plans.component';
// eslint-disable-next-line max-len
import { SignInConditionContractComponent } from '@modules/pages-no-sub-wrapper/sign-in-condition-contract/sign-in-condition-contract.component';

import { ShopComponent } from './modules/shop/shop.component';
import { CanDeactivateGuard } from '@shared/guards/can-deactivate.guard';

const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
    const originPath = route.url?.map(item => item.path);
    const path = originPath ? `/${originPath.join('/')}` : '';

    return redirectUnauthorizedTo(`/auth/sign-in?redirectTo=${path}`);
};

const redirectAuthorizedToHome = () => redirectLoggedInTo(['/home']);

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/home/dashboard' },
    {
        path: 'custom-sign-in',
        component: CustomSignInComponent,
    },
    {
        path: 'products',
        loadChildren: () => import('@modules/external/products/products.module').then(m => m.ProductsModule),
    },
    {
        path: 'all-stars',
        loadChildren: () => import('@modules/external/shops/shops.module').then(m => m.ShopsModule),
    },
    {
        path: 'auth',
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
            {
                path: '',
                component: AuthComponent,
                children: [
                    {
                        path: 'sign-in',
                        canActivate: [AngularFireAuthGuard],
                        data: { authGuardPipe: redirectAuthorizedToHome },
                        component: LoginComponent,
                    },
                    {
                        path: 'sign-up',
                        canActivate: [AngularFireAuthGuard],
                        data: { authGuardPipe: redirectAuthorizedToHome },
                        component: SignUpComponent,
                    },
                ],
            },
            {
                path: 'restore-password',
                component: ForgotPasswordComponent,
            },
        ],
    },
    {
        path: 'plans',
        component: PlansComponent,
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
    },
    { path: 'page-not-found', component: PageNotFoundComponent },

    {
        path: 'sourcing-request/:deckId',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        component: SourcingRequestComponent,
    },
    {
        path: 'sign-in-condition',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        component: SignInConditionContractComponent,
    },
    {
        path: '',
        component: NavigationWrapperComponent,
        canActivate: [ShopProfileGuard],
        children: [
            {
                path: 'home',
                component: HomeComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                children: [
                    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
                    { path: 'dashboard', component: HomeDashboardComponent },
                    { path: 'order-history', component: OrderHistoryComponent },
                    { path: 'order-detail/:orderId', component: OrderDetailComponent },
                ],
            },
            {
                path: 'product-management',
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                loadChildren: () => import('./modules/product-management/product-management.module').then(m => m.ProductManagementModule),
            },
            {
                path: 'balance-history',
                component: BalanceHistoryComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                children: [
                    { path: '', pathMatch: 'full', redirectTo: 'balance-history-list' },
                    { path: 'balance-history-list', component: BalanceHistoryListComponent },
                ],
            },
            {
                path: 'funds',
                component: FundsComponent,
            },
            {
                path: 'connect-paypal',
                component: ConnectPaypalComponent,
            },
            {
                path: 'profile',
                component: ProfileComponent,
            },
            {
                path: 'faq',
                component: FaqComponent,
            },
            {
                path: 'referral',
                component: ReferralComponent,
            },
            {
                path: 'nidavellir',
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                loadChildren: () => import('./modules/nidavellir/nidavellir.module').then(m => m.NidavellirModule),
            },
            {
                path: 'site-traffic',
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                loadChildren: () => import('./modules/site-traffic/site-traffic.module').then(m => m.SiteTrafficModule),
            },
            {
                path: 'storefront',
                component: StorefrontComponent,
                canActivate: [AngularFireAuthGuard],
                canDeactivate: [CanDeactivateGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
            },
            {
                path: 'co-sell',
                component: ShopComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
            },
            {
                path: 'campaign',
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule),
            },
            {
                path: 'my-purchase',
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectUnauthorizedToLogin },
                loadChildren: () => import('./modules/my-purchase/my-purchase.module').then(m => m.MyPurchaseModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'page-not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
