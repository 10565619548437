<section class="revenue-detail" *ngIf="revenueDetail">
    <section *ngIf="revenueDetail.report_summary?.weekly_revenue !== undefined" class="revenue-item mb-20">
        <span class="name">Revenue</span>
        <span class="count">{{ revenueDetail.report_summary.weekly_revenue | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.monthly_revenue !== undefined" class="revenue-item mb-20">
        <span class="name">Revenue</span>
        <span class="count">{{ revenueDetail.report_summary.monthly_revenue | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.service_fee" class="revenue-item mb-20">
        <span class="name">Service Fee</span>
        <span class="count">{{ revenueDetail.report_summary.service_fee | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.return_items !== undefined" class="revenue-item mb-20">
        <span class="name">Returned Items</span>
        <span class="count">{{ revenueDetail.report_summary.return_items | currency }}</span>
    </section>

    <section *ngIf="revenueDetail.report_summary?.return_service_fee !== undefined" class="revenue-item">
        <span class="name">Service Fee Refund</span>
        <span class="count">{{ revenueDetail.report_summary?.return_service_fee | currency }}</span>
    </section>

    <nz-divider></nz-divider>

    <section class="revenue-item">
        <span class="name">Net Revenue</span>
        <span class="count">{{ revenueDetail.amount | currency }}</span>
    </section>
</section>

<section class="revenue-detail" *ngIf="paymentDetail">
    <section class="revenue-item mb-20">
        <span class="name">Payment Amount</span>
        <span class="count">{{ paymentDetail.amount | currency }}</span>
    </section>
    <nz-divider></nz-divider>
    <section *ngIf="paymentDetail.balance" class="revenue-item mb-20">
        <span class="name">Balance</span>
        <span class="count">{{ paymentDetail.balance | currency }}</span>
    </section>
    <section *ngIf="paymentDetail.lifoCredit" class="revenue-item mb-20">
        <span class="name">Lifo Credit</span>
        <span class="count">{{ paymentDetail.lifoCredit | currency }}</span>
    </section>
    <section *ngIf="paymentDetail.bank" class="revenue-item mb-20">
        <span class="name">
            <div class="bank">Bank Account</div>
            <div class="bank-account">{{ paymentDetail.bankName }}</div>
        </span>
        <span class="count">{{ paymentDetail.bank | currency }}</span>
    </section>
    <section *ngIf="paymentDetail.creditCard" class="revenue-item mb-20">
        <span class="name">Credit Card</span>
        <span class="count">{{ paymentDetail.creditCard | currency }}</span>
    </section>
    <section *ngIf="paymentDetail.paypal" class="revenue-item mb-20">
        <span class="name">PayPal</span>
        <span class="count">{{ paymentDetail.paypal | currency }}</span>
    </section>
    <section *ngIf="paymentDetail.installment" class="revenue-item mb-20">
        <span class="name">Installment</span>
        <span class="count">{{ paymentDetail.installment | currency }}</span>
    </section>
</section>
