import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appImgLoadSmooth]',
})
export class ImgLoadTransitionDirective implements OnInit {
    get element(): HTMLImageElement {
        return this.ele.nativeElement;
    }

    constructor(private ele: ElementRef) {}

    ngOnInit() {
        this.element.style.opacity = '0';
        this.element.style.transition = 'opacity linear 0.3s';
        this.element.onload = () => {
            this.element.style.opacity = '1';
        };
        this.element.onerror = () => {
            this.element.src = '/assets/svg/no_pic.svg';
        };
    }
}
