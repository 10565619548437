import { Pipe, PipeTransform } from '@angular/core';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
    name: 'localtime',
})
export class LocaltimePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        const localTimeZone = dayjs.tz.guess();
        return dayjs(value).tz(localTimeZone, true).format('YYYY-MM-DD HH:mm');
    }
}
