import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root',
})
export class ShowModalService {
    subscriptionModalVisible$: Subject<boolean> = new Subject();
    shopPreparationModalVisible$: Subject<boolean> = new Subject();

    constructor(private modalService: NzModalService) {}

    showSubscriptionModal() {
        this.subscriptionModalVisible$.next(true);
    }
    hideSubscriptionModal() {
        this.subscriptionModalVisible$.next(false);
    }

    showShopPreparationModal() {
        this.shopPreparationModalVisible$.next(true);
    }
    hideShopPreparationModal() {
        this.shopPreparationModalVisible$.next(false);
    }
}
