import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slice',
})
export class SlicePipe implements PipeTransform {
    transform<T>(value: T[], count: number): T[] {
        if (!value || !count) {
            return value;
        }

        if (count < 0) {
            return value.slice(count);
        }

        return value.slice(0, count);
    }
}
