import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    constructor() {}

    replaceCurrentTzWithPST(date: string, format: string = null): string {
        return date ? dayjs.tz(dayjs(date).format('YYYY-MM-DD HH:mm:ss'), 'PST').format(format) : '';
    }

    formateDateWithTimezone(date: string, format = 'YYYY-MM-DD HH:mm:ss', timezone = 'PST') {
        return date ? dayjs(date).tz(timezone).format(format) : '';
    }

    parseDateWithTimeZone(date: string, format = 'YYYY-MM-DD HH:mm:ss', timezone = 'PST') {
        return date ? dayjs(dayjs(date).tz(timezone).format(format)).toString() : '';
    }
}
