import { IPayPalConfig } from 'ngx-paypal';
import { environment } from '@src/environments/environment';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HomeService } from '@modules/home/home.service';
import { PlaidService } from '@services/plaid.service';
import { ShoppingCartService } from '@shared/components/shopping-cart/shopping-cart.service';
import { AccountBalance } from '@typings/earnings.typings';
import { PaymentMethodEnum } from './../shopping-cart/shopping-cart.service';
import { ProfileService } from '@services/profile.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MessageService } from '@src/app/services/message.service';

export interface GeneralPaymentData {
    itemList: { name: string; amount: number }[];
    availableLifoCreditRate?: number; // 0 - 1
    disabledPaymentMethod?: Set<PaymentMethodEnum>;
    paymentId: any;
}

type GeneralPaymentMethod = PaymentMethodEnum.CREDIT_CARD | PaymentMethodEnum.BANK | PaymentMethodEnum.PAYPAL;

@Component({
    selector: 'app-general-payment',
    templateUrl: './general-payment.component.html',
    styleUrls: ['./general-payment.component.less'],
})
export class GeneralPaymentComponent implements OnInit {
    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    @Input() data: GeneralPaymentData = {
        itemList: [],
        availableLifoCreditRate: 0.4,
        paymentId: null,
    };

    paypalConfig: IPayPalConfig;

    PaymentMethodEnum = PaymentMethodEnum;

    accountBalance: AccountBalance;

    viewStatusControl = {
        useLifoCredit: false,
        useBalancePay: false,
        connectingBank: false,
        isCreditCartInputCompleted: false,
        submitting: false,
    };

    selectedPaymentMethod: GeneralPaymentMethod = PaymentMethodEnum.CREDIT_CARD;

    connectedBankName: string;
    connectedCreditCard: string;

    amountDetail = {
        subTotal: 0,
        creditUsedAmount: 0,
        balanceUsedAmount: 0,
        total: 0,
    };

    stripeInfo = {
        firstName: '',
        lastName: '',
        zipCode: '',
    };

    isCreditCardSetAsSubscriptionPayment = false;

    get isNoSubscriptionPaymentMethod() {
        return !this.connectedBankName && !this.connectedCreditCard;
    }

    get availableLifoCreditRate() {
        return this.data.availableLifoCreditRate || 0.4;
    }

    get disabledPaymentMethod() {
        return this.data.disabledPaymentMethod || this.defaultDisabledPaymentMethod;
    }

    get confirmDisabled() {
        if (this.amountDetail.total <= 0) {
            return false;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
            return !this.connectedBankName;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
            return (
                !this.stripeInfo.firstName ||
                !this.stripeInfo.lastName ||
                !this.stripeInfo.zipCode ||
                !this.viewStatusControl.isCreditCartInputCompleted ||
                (this.isNoSubscriptionPaymentMethod && !this.isCreditCardSetAsSubscriptionPayment)
            );
        }
    }

    private defaultDisabledPaymentMethod = new Set();

    constructor(
        private homeService: HomeService,
        private plaidService: PlaidService,
        private profileService: ProfileService,
        private messageService: MessageService,
        private stripeService: StripeService,
        public modalRef: NzModalRef,
        public shoppingCartService: ShoppingCartService
    ) {}

    ngOnInit(): void {
        this.homeService.getAccountBalance().then(data => {
            this.accountBalance = data;
            this.calculateAmount();
        });

        this.getSelectedBankAccount();
        this.initConfig();
    }

    initConfig() {
        this.paypalConfig = {
            currency: 'USD',
            clientId: environment.paypal.clientId,
            style: {
                label: 'checkout',
                layout: 'horizontal',
                color: 'blue',
                size: 'medium',
                height: 54,
                tagline: false,
                fundingicons: false,
            },
            createOrderOnServer: this.payWithPayPal,
            onApprove: this.completeRequestFunc,
            onError: this.errorFunc,
            onCancel: () => (this.viewStatusControl.submitting = false),
        };
    }

    calculateAmount() {
        let creditUsedAmount = 0;
        let balanceUsedAmount = 0;
        let total = 0;
        const tempTotal = this.data.itemList.reduce((prev, curr) => prev + curr.amount, 0);

        if (this.viewStatusControl.useLifoCredit) {
            const neededCreditAmount = this.amountDetail.subTotal * this.availableLifoCreditRate;
            const restCredit = this.accountBalance.lifo_credit;
            creditUsedAmount = Number(Math.min(neededCreditAmount, restCredit, tempTotal).toFixed(2));
        }

        if (this.viewStatusControl.useBalancePay) {
            const totalExceptUseBalance = tempTotal - creditUsedAmount;
            balanceUsedAmount = Number(Math.min(this.accountBalance.account_balance, totalExceptUseBalance).toFixed(2));
        }

        total = tempTotal - creditUsedAmount - balanceUsedAmount;

        this.amountDetail.balanceUsedAmount = balanceUsedAmount;
        this.amountDetail.creditUsedAmount = creditUsedAmount;
        this.amountDetail.subTotal = tempTotal;
        this.amountDetail.total = Number(total.toFixed(2));
    }

    zipCodeChange() {
        this.card.update({ value: { postalCode: this.stripeInfo.zipCode } });
    }

    cardInputChange(e: StripeCardElementChangeEvent) {
        this.viewStatusControl.isCreditCartInputCompleted = e.complete;
    }

    selectPaymentMethod(paymentMethod: GeneralPaymentMethod) {
        if (paymentMethod === PaymentMethodEnum.BANK && this.connectedBankName === undefined) {
            return;
        }

        this.selectedPaymentMethod = paymentMethod;
    }

    async connectPlaid() {
        this.viewStatusControl.connectingBank = true;

        try {
            const linkToken = await this.plaidService.fetchLinkToken();
            const publicToken = await this.plaidService.getPublicToken(linkToken.link_token);
            if (publicToken) {
                await this.plaidService.exchangePublicToken(publicToken);
                this.profileService.currentProfile = await this.profileService.getCurrentProfile();
                await this.getSelectedBankAccount();
            }
            this.viewStatusControl.connectingBank = false;
        } catch (e) {
            this.viewStatusControl.connectingBank = false;
        }
    }

    completeRequestFunc = () => {
        this.homeService
            .completePaymentRequest(this.data.paymentId)
            .then(() => this.modalRef.triggerOk())
            .catch(() => this.errorFunc())
            .finally(() => (this.viewStatusControl.submitting = false));
    };

    errorFunc = (err?: any) => {
        this.messageService.error(err?.message, { nzDuration: 5000 });
        this.viewStatusControl.submitting = false;
    };

    payWithPayPal = () => {
        this.viewStatusControl.submitting = true;

        const paymentMethods = this.generatePaymentMethods();

        paymentMethods.push({
            method: PaymentMethodEnum.PAYPAL,
            amount: this.amountDetail.total,
        });

        return this.homeService
            .approvePaymentRequest({
                payment_methods: paymentMethods,
                payment_request_id: this.data.paymentId,
            })
            .then((res: any) => res.id);
    };

    pay() {
        const paymentMethods = this.generatePaymentMethods();
        this.viewStatusControl.submitting = true;

        this.homeService
            .approvePaymentRequest({
                payment_methods: paymentMethods,
                payment_request_id: this.data.paymentId,
            })
            .then((result: any) => {
                if (this.amountDetail.total > 0 && this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
                    this.stripeService
                        .confirmCardPayment(result.client_secret, {
                            payment_method: {
                                card: this.card.element,
                                billing_details: {
                                    name: `${this.stripeInfo.firstName} ${this.stripeInfo.lastName}`,
                                },
                            },
                        })
                        .subscribe(
                            paymentRes => {
                                if (paymentRes?.paymentIntent?.status === 'succeeded') {
                                    this.completeRequestFunc();
                                } else {
                                    this.errorFunc();
                                }
                            },
                            // Strip confirm failed with no reason
                            this.errorFunc
                        );
                } else {
                    this.viewStatusControl.submitting = false;
                    this.modalRef.triggerOk();
                }
            })
            .catch(this.errorFunc);
    }

    private getSelectedBankAccount() {
        return this.plaidService.getPaymentMethod().then(data => {
            if (data.bank_account) {
                this.connectedBankName = `${data.bank_account.account.name} ******* ${data.bank_account.account.mask}`;
            } else {
                this.connectedBankName = null;
            }

            this.connectedCreditCard = data.credit_card;
        });
    }

    private generatePaymentMethods() {
        const paymentMethods = [];

        if (this.viewStatusControl.useBalancePay) {
            paymentMethods.push({
                method: PaymentMethodEnum.BALANCE,
                amount: this.amountDetail.balanceUsedAmount,
            });
        }

        if (this.viewStatusControl.useLifoCredit) {
            paymentMethods.push({
                method: PaymentMethodEnum.LIFO_CREDIT,
                amount: this.amountDetail.creditUsedAmount,
            });
        }

        if (this.amountDetail.total > 0) {
            if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
                paymentMethods.push({
                    method: PaymentMethodEnum.BANK,
                    amount: this.amountDetail.total,
                });
            }

            if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
                paymentMethods.push({
                    method: PaymentMethodEnum.CREDIT_CARD,
                    amount: this.amountDetail.total,
                });
            }
        }

        return paymentMethods;
    }
}
