import { PaypalService } from '@services/paypal.service';
import { FirebaseEventsService } from '@services/firebase-event.service';
import { ProfileService } from '@services/profile.service';
import { PlaidService } from '@services/plaid.service';
import { PaymentMethodEnum } from '@shared/components/shopping-cart/shopping-cart.service';
import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ShopService } from '@services/shop.service';
import { SubscribePlanNameEnum } from '@src/app/shared/components/subscription/subscription.data';

@Component({
    selector: 'app-connect-payment',
    templateUrl: './connect-payment.component.html',
    styleUrls: ['./connect-payment.component.less'],
})
export class ConnectPaymentComponent implements OnInit {
    PaymentMethodEnum = PaymentMethodEnum;

    selectedPaymentMethod: PaymentMethodEnum = PaymentMethodEnum.BANK;

    connectingBank = false;

    subscribePlanNameEnum = SubscribePlanNameEnum;

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    constructor(
        private plaidService: PlaidService,
        private profileService: ProfileService,
        private modalRef: NzModalRef,
        private paypalService: PaypalService,
        private shopService: ShopService
    ) {}

    ngOnInit(): void {}

    selectPaymentMethod(paymentMethod: PaymentMethodEnum) {
        this.selectedPaymentMethod = paymentMethod;
    }

    async connectPlaid() {
        this.connectingBank = true;

        try {
            const linkToken = await this.plaidService.fetchLinkToken();
            const publicToken = await this.plaidService.getPublicToken(linkToken.link_token);
            if (publicToken) {
                await this.plaidService.exchangePublicToken(publicToken);
                this.profileService.currentProfile = await this.profileService.getCurrentProfile();
                this.modalRef.triggerOk();
            }
            this.connectingBank = false;
        } catch (e) {
            this.connectingBank = false;
        }
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    confirm() {
        if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
            this.connectPlaid();
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.PAYPAL) {
            window.location.href = this.paypalService.getPaypalLink();
        }
    }
}
