import { ActiveOrder } from './../my-purchase/orders/orders.type';
import { PaymentMethodEnum } from './../../shared/components/shopping-cart/shopping-cart.service';
import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

import {
    IStandardProductListItem,
    IMerchProductListItem,
    IProductPackaging,
    IStandardProductDetails,
    IMerchProductDetails,
    ICreateProduct,
    ProductInCartFromAPI,
    ProductInCart,
    CrowdFundingDetail,
    IPrintOnDemand,
} from './nidavellir.type';

export * from './nidavellir.type';

export const sellingTagMap = {
    high_sell_through: {
        title: 'High Sell-Through',
        color: 'rgba(210, 159, 59, 1)',
        bgColor: 'rgba(210, 159, 59, 0.1)',
    },
    new: {
        title: 'New',
        color: 'rgba(34, 188, 185, 1)',
        bgColor: 'rgba(34, 188, 185, 0.1)',
    },
    popular: {
        title: 'Popular',
        color: 'rgba(243, 116, 94, 1)',
        bgColor: 'rgba(243, 116, 94, 0.1)',
    },
    high_profit: {
        title: 'High Profit',
        color: 'rgba(87, 51, 87, 1)',
        bgColor: 'rgba(87, 51, 87, 0.1)',
    },
};

export const productAttrMap = {
    eco_friendly: {
        title: 'Eco-friendly',
        iconName: 'eco-friendly',
    },
    customizable: {
        title: 'Customizable',
        iconName: 'customizable',
    },
    customizable_packaging: {
        title: 'Customizable Packaging',
        iconName: 'customizable-packaging',
    },
    not_on_amazon: {
        title: 'Not on Amazon',
        iconName: 'not-amazon',
    },
    social_good: {
        title: 'Social Good',
        iconName: 'social-good',
    },
    handmade: {
        title: 'Handmade',
        iconName: 'handmade',
    },
    designer_product: {
        title: 'Designer Product',
        iconName: 'designer-product',
    },
    small_batch: {
        title: 'Small Batch',
        iconName: 'small-batch',
    },
};

@Injectable({
    providedIn: 'root',
})
export class NidavellirService {
    constructor(private requestService: RequestService) {}

    getProductList(): Promise<IStandardProductListItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/standard_product',
            api: 'data-shop',
        });
    }

    getRecommendProductList(refresh = false): Promise<IStandardProductListItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/standard_product/recommend${refresh ? '?refresh=1' : ''}`,
            api: 'data-shop',
        });
    }

    getEcoFriendlyProductList(text: string): Promise<IStandardProductListItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/standard_product/attribute?attribute=${text}`,
            api: 'data-shop',
        });
    }

    getMerchProductList(): Promise<IMerchProductListItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/merch_product',
            api: 'data-shop',
        });
    }

    getProductDetailByProductId(id: string): Promise<IStandardProductDetails> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/standard_product_detail?product_id=${id}`,
            api: 'data-shop',
        });
    }

    getMerchProductDetailbyProductId(id: string): Promise<IMerchProductDetails> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/merch_product_detail?product_id=${id}`,
            api: 'data-shop',
        });
    }

    createProductPackaging(product: IProductPackaging): Promise<{ product_id: string }> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/shop_management/order_standard_product',
            api: 'data-shop',
            data: product,
        });
    }

    createPrintOnDemand(product: IPrintOnDemand): Promise<{ product_id: string }> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/print_on_demand/start_selling',
            api: 'data-shop',
            data: product,
        });
    }

    createProduct(createProduct: ICreateProduct) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/create_diy_product',
            data: createProduct,
        });
    }

    getMerchColorOptions(): Promise<{ id: number; is_display: boolean; name: string; value: string }[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/shop_management/merch_color_options',
        });
    }

    updateServiceRequest(data: any): Promise<any> {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/service_request',
            data,
        });
    }

    orderPodSampleToCart(data: ProductInCart) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                api: 'data-shop',
                url: '/influencer/print_on_demand/order_sample',
                data,
            },
            'object'
        );
    }

    // Shopping cart
    addToCart(data: ProductInCart): Promise<void> {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                api: 'data-shop',
                url: '/influencer/shopping_cart',
                data,
            },
            'object'
        );
    }

    getProductsInCart(uid = ''): Promise<ProductInCartFromAPI[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: uid ? `/influencer/shopping_cart?uid=${uid}` : '/influencer/shopping_cart',
        });
    }

    getAdvancedCustomizationCartOrder(data: { id: number; uid: string }): Promise<ProductInCartFromAPI> {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/shopping_cart/${data.id}${data.uid ? `?uid=${data.uid}` : ''}`,
        });
    }

    removeFromCart(data: { id: any; uid: string }): Promise<void> {
        return this.requestService.sendRequest({
            method: 'DELETE',
            api: 'data-shop',
            url: `/influencer/shopping_cart?id=${data.id}${data.uid ? `&uid=${data.uid}` : ''}`,
        });
    }

    startCheckout(data): Promise<any> {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                api: 'data-shop',
                url: '/influencer/start_checkout',
                data,
            },
            'object'
        );
    }

    placeKlarnaOrder(checkout_id: any, authorization_token: string) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/process_checkout',
            data: {
                checkout_id,
                authorization_token,
            },
        });
    }

    completeCheckout(checkout_id: number) {
        return this.requestService.sendRequest({
            method: 'PUT',
            api: 'data-shop',
            url: '/influencer/complete_checkout',
            data: { checkout_id },
        });
    }

    generateCombinedImage(data) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/combine_images',
            data,
        });
    }

    generateAdvancedCombinedImage(data) {
        return this.requestService.sendRequest<{ src: string; path: string }[]>({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/combine_images_advanced_v2',
            data,
        });
    }

    getProductsByCheckoutId(checkoutId: any) {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/checkout_detail?id=${checkoutId}`,
        });
    }

    getUnpaidOrderList() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/unpaid_orders',
            api: 'data-shop',
        });
    }

    getActiveOrderList() {
        return this.requestService.sendRequest<ActiveOrder[]>({
            method: 'GET',
            url: '/influencer/active_orders',
            api: 'data-shop',
        });
    }

    productSourcingRequest(data) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/product_sourcing_request',
            data,
        });
    }

    getAllCategories() {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/product_label?type=product',
        });
    }

    getAdvancedCustomizationCategory(id: number) {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/get_advanced_customization_category?category_id=${id}`,
        });
    }

    getAdvancedCustomization() {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/get_all_advanced_customization_category',
        });
    }

    getAdvancedCustomizationAllTemplate() {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/advanced_customization/all_template',
        });
    }

    getPrintOnDemandProduct() {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/get_all_print_on_demand_product',
        });
    }

    getAdvancedCustomizationOrder(id: number) {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/advanced_customization_order?order_id=${id}`,
        });
    }

    updateAdvancedCustomizationOrder(data: ProductInCart): Promise<void> {
        return this.requestService.sendRequest({
            method: 'PUT',
            api: 'data-shop',
            url: '/influencer/advanced_customization_order',
            data,
        });
    }

    getAdvancedCustomizationOrderDetail(orderId: any) {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/advanced_customization_order?order_id=${orderId}`,
        });
    }

    removeSampleOrderProject(orderId: any) {
        return this.requestService.sendRequest<any>({
            method: 'DELETE',
            api: 'data-shop',
            url: `/influencer/advanced_customization_order?order_id=${orderId}`,
        });
    }

    getAdvancedCustomizationTemplate(id: number, template_id: number) {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: template_id
                ? `/influencer/advanced_customization/template?category_id=${id}&template_id=${template_id}`
                : `/influencer/advanced_customization/template?category_id=${id}`,
        });
    }

    sendModelToLifo(data: { order_id: any; carrier: string; tracking_number: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/advanced_customization_order_model',
            data,
        });
    }

    startCrowdFunding(order_id: any): Promise<CrowdFundingDetail> {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/advanced_customization/crowd_funding',
            data: { order_id },
        });
    }

    startCrowdfunding(data: { product_id: any; end_date: string; minimal_quantity: number }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/advanced_customization/crowd_funding',
            api: 'data-shop',
            data,
        });
    }

    endCrowdfunding(productId: any) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/advanced_customization/crowd_funding/terminate',
            api: 'data-shop',
            data: { product_id: productId },
        });
    }

    startSelling(productId: any) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/active_orders/start_selling',
            api: 'data-shop',
            data: { product_id: productId },
        });
    }

    updateCrowdFundingInfo(data: { product_id: any; minimal_quantity: number; end_date: string }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/advanced_customization/crowd_funding',
            api: 'data-shop',
            data,
        });
    }

    toggleVisibleOfCrowdfundingInStore(data: { product_id: any; hide_from_store: boolean }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/advanced_customization/crowd_funding',
            api: 'data-shop',
            data,
        });
    }

    getCrowdFundingDetailByProductId(product_id: string): Promise<CrowdFundingDetail> {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/advanced_customization/crowd_funding?product_id=${product_id}`,
        });
    }

    getCrowdFundingDetailByOrderId(order_id: any): Promise<CrowdFundingDetail> {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: `/influencer/advanced_customization/crowd_funding?order_id=${order_id}`,
        });
    }

    evaluateStandardProduct(product_id: number, thumb_status: string): Promise<CrowdFundingDetail> {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'shop-api',
            url: '/influencer/standard_product/evaluate',
            data: { product_id, thumb_status },
        });
    }

    getStandardProducts(data: {
        category: string;
        product_name: string;
        selling_tag: string;
        product_attribute: string;
        page_size: number;
        page_index: number;
        start_time?: string;
        end_time?: string;
    }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/standard_product_pagination',
            data,
        });
    }

    getCategoryListByParent(parent: string = null) {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/label/label?type=product&parent=${parent}`,
        });
    }

    getTimeSlot() {
        return this.requestService.sendRequest({
            method: 'GET',
            api: 'data-shop',
            url: '/influencer/video_walkthrough/time_slot',
        });
    }

    requestVideoWalkthrough(data: { order_id: any; time_slot_ids: any[] }) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/video_walkthrough',
            data,
        });
    }

    approveQc(orderId: any) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/approve_advanced_customization_order',
            data: { order_id: orderId },
        });
    }
}
