import { SourcingRequest } from '@modules/pages-no-sub-wrapper/sourcing-request/sourcing-request.type';
import { SourcingRequestDeck, SourcingRequestDeckProductComment } from './sourcing-request.type';
import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { ProductDetail } from '@modules/product-management/product-management.service';

@Injectable()
export class SourcingRequestService {
    constructor(private requestService: RequestService) {}

    getSourcingRequestList(): Promise<SourcingRequest[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/product_sourcing_request',
            api: 'data-shop',
        });
    }

    getDeckList(): Promise<SourcingRequestDeck[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/all_sourcing_deck',
            api: 'data-shop',
        });
    }

    getDeckDetailById(id: any): Promise<SourcingRequestDeck> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/sourcing_deck?id=${id}`,
            api: 'data-shop',
        });
    }

    addComment(data: {
        product_id: any;
        content: string;
        image_id?: string;
    }): Promise<{
        created_at: string;
        created_by: string;
        comment_id: any;
        content: any;
        is_self: boolean;
        replies?: SourcingRequestDeckProductComment[];
    }> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/sourcing_deck_comment',
            api: 'data-shop',
            data,
        });
    }
    replyComment(data: {
        product_id: any;
        content: string;
        reply_to_id: any;
    }): Promise<{
        created_at: string;
        created_by: string;
        comment_id: any;
        content: any;
        is_self: boolean;
        replies?: SourcingRequestDeckProductComment[];
    }> {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/influencer/sourcing_deck_comment',
                api: 'data-shop',
                data,
            },
            'object'
        );
    }

    editComment(data: { comment_id: any; content: string }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/sourcing_deck_comment',
            api: 'data-shop',
            data,
        });
    }

    deleteComment(commentId: any) {
        return this.requestService.sendRequest(
            {
                method: 'DELETE',
                url: `/influencer/sourcing_deck_comment?id=${commentId}`,
                api: 'data-shop',
            },
            'object'
        );
    }

    getProductDetailByProductId(id: string): Promise<ProductDetail> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/product_detail?product_id=${id}`,
            api: 'data-shop',
        });
    }

    deleteSourcingRequest(requestId: any) {
        return this.requestService.sendRequest({
            method: 'DELETE',
            url: `/influencer/shop_management/product_sourcing_request?id=${requestId}`,
            api: 'data-shop',
        });
    }
}
