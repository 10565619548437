import { FirebaseEventsService, CUSTOM_ANALYTICS_EVENT } from '@services/firebase-event.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShopService } from '@services/shop.service';
import { MessageService } from '@services/message.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.less'],
})
export class FaqComponent implements OnInit {
    selectDefault = 'General';
    subscription: Subscription[] = [];
    filterList = [];
    radioData = [];
    buildRadioData = true;
    faqList = [];
    faqsResult = [];
    faqListMap = new Map();
    fetchingFaqList = false;
    videoTutorialVisible = false;
    videoTutorialUrl;
    videoTutorials = [
        { src: 'https://player.vimeo.com/video/684841563?h=19856892cd', title: '01. Lifo Creator Hub Home Tutorial' },
        { src: 'https://player.vimeo.com/video/685754963?h=cf779e3ccc', title: '02. Lifo Creator Hub Advanced Customization Tutorial 1' },
        { src: 'https://player.vimeo.com/video/686869216?h=6069aa97a2', title: '03. Creator Hub Print On Demand Tutorial' },
    ];

    constructor(
        private messageService: MessageService,
        private firebaseEventService: FirebaseEventsService,
        public shopService: ShopService,
        public router: Router
    ) {}

    ngOnInit() {
        this.getDefaultFaqs(this.selectDefault);

        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_FAQ);
    }

    getDefaultFaqs(key) {
        this.fetchingFaqList = true;
        this.shopService
            .getFaqAll()
            .then(res => {
                this.faqsResult = res;
                this.getFaqAlls(key);
            })
            .catch(() => this.messageService.error())
            .finally(() => (this.fetchingFaqList = false));
    }

    getFaqAlls(key) {
        const faqList = [];
        const categorySet = new Set();
        for (const i of this.faqsResult) {
            if (i.category === key) {
                faqList.push(i);
            }
        }
        this.faqListMap.set(key, faqList);
        if (this.buildRadioData) {
            for (const i of this.faqsResult) {
                if (i.category && !categorySet.has(i.category)) {
                    this.radioData.push({
                        name: i.category,
                        value: i.category,
                        checked: i.category === this.selectDefault,
                    });
                    categorySet.add(i.category);
                }
            }
            this.radioData.sort((a, b) => a.name.localeCompare(b.name));
            this.buildRadioData = false;
        }
        faqList.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        this.faqList = faqList;
    }

    selectClick(val) {
        for (const i of this.radioData) {
            if (i.name === val.name) {
                i.checked = true;
            } else {
                i.checked = false;
            }
        }
        if (!this.faqListMap.get(val.name)) {
            this.getFaqAlls(val.name);
        } else {
            this.faqList = this.faqListMap.get(val.name);
        }
    }

    onLeftClick() {
        this.router.navigate(['/account-info']);
    }

    playVideo(src) {
        this.videoTutorialUrl = `${src}?rel=0&amp;autoplay=1`;
        this.videoTutorialVisible = true;
    }
}
