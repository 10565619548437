import { ShopProfile } from '@typings/profile.typing';
import { FirebaseEventsService } from '@services/firebase-event.service';
import { AuthService } from '@services/auth.service';
import { ShopService } from '@services/shop.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubscribePlanNameEnum } from '../shared/components/subscription/subscription.data';

@Injectable({
    providedIn: 'root',
})
export class ShopProfileGuard implements CanActivate {
    constructor(
        private shopService: ShopService,
        private authService: AuthService,
        private router: Router,
        private firebaseEventService: FirebaseEventsService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.shopService.shopProfile) {
            return this.handleRedirect(this.shopService.shopProfile);
        }

        return this.shopService
            .getShopDetail()
            .then(shopProfile => this.handleRedirect(shopProfile))
            .catch(() => {
                this.authService.signOut();
                return false;
            });
    }

    private handleRedirect(shopProfile: ShopProfile) {
        this.firebaseEventService.setupFirebaseEvent({ userType: shopProfile.subscription_plan || SubscribePlanNameEnum.STARTER });
        this.firebaseEventService.addShopIdForDefaultEvent(shopProfile.id);

        if (!shopProfile.setup_complete && !this.authService.isAccountManagerSignIn) {
            this.router.navigateByUrl('/sign-in-condition');
            return false;
        } else {
            return true;
        }
    }
}
