import { Directive, Input, AfterViewInit, ElementRef, OnChanges, SimpleChanges, HostListener } from '@angular/core';

import * as echarts from 'echarts/core';
import { GridComponent, GridComponentOption, TooltipComponent, TitleComponent, TitleComponentOption } from 'echarts/components';
import { LineChart, LineSeriesOption, PieChart, PieSeriesOption } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([GridComponent, LineChart, CanvasRenderer, TooltipComponent, PieChart, TitleComponent]);

export type EchartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | PieSeriesOption | TitleComponentOption>;
export { echarts };

@Directive({
    selector: '[appEcharts]',
})
export class EchartsDirective implements AfterViewInit, OnChanges {
    @Input() initialOption: EchartsOption;
    @Input() optionToBeUpdate: EchartsOption;

    private charts: echarts.EChartsType;
    private chartInitialized = false;

    constructor(private el: ElementRef) {}

    @HostListener('window:resize')
    resizeChart() {
        this.charts?.resize();
    }

    ngOnChanges(change: SimpleChanges) {
        if (!change.optionToBeUpdate?.currentValue) return;

        if (this.chartInitialized) {
            this.charts.setOption(this.optionToBeUpdate);
        } else {
            const intervalId = setInterval(() => {
                if (this.charts) {
                    this.charts.setOption(this.optionToBeUpdate);
                    clearInterval(intervalId);
                }
            }, 10);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.charts = echarts.init(this.el.nativeElement);
            this.charts.setOption(this.initialOption);
            this.chartInitialized = true;
        }, 0);
    }

    private showLoading(
        config: { maskColor?: string; text?: string; color?: string; textColor?: string; zlevel?: number } = {
            maskColor: 'transparent',
            text: '',
        }
    ) {
        this.charts.showLoading(config);
    }

    private hideLoading() {
        this.charts.hideLoading();
    }
}
