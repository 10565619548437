import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UploadedFile } from '@shared/directives/file-preview.directive';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.less'],
})
export class ImageGalleryComponent implements OnInit {
    @Input() imagesList: UploadedFile[] = [];
    @Input() selectedImage: UploadedFile;
    @Input() readonly isOpened: boolean = false;
    @Output() closeGallery: EventEmitter<void> = new EventEmitter();

    get isOnlyOneImage() {
        return this.imagesList.length === 1;
    }

    constructor() {}

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.isOpened) {
            switch (event.key) {
                case 'ArrowLeft':
                    this.changeImage('prev');
                    break;
                case 'ArrowRight':
                    this.changeImage('next');
                    break;
            }
        }
    }

    ngOnInit(): void {}

    get next_index() {
        const currentIndex = this.imagesList.findIndex(img => img.url === this.selectedImage?.url);
        if (currentIndex >= this.imagesList.length - 1) {
            return null;
        }
        return currentIndex + 1;
    }

    get prev_index() {
        const currentIndex = this.imagesList.findIndex(img => img.url === this.selectedImage?.url);
        if (currentIndex <= 0) {
            return null;
        }
        return currentIndex - 1;
    }

    changeImage(destination: 'next' | 'prev') {
        if ((destination === 'next' && this.next_index === null) || (destination === 'prev' && this.prev_index === null)) {
            return;
        }
        let index = 0;
        switch (destination) {
            case 'next':
                index = this.next_index;
                break;
            case 'prev':
                index = this.prev_index;
                break;
        }
        this.selectedImage = this.imagesList[index];
    }
}
