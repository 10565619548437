<div *ngIf="productDetail">
    <div class="border-radius-12 sample-variant-items">
        <div
            *ngFor="let item of productDetail.variants"
            class="sample-variant-item d-flex justify-content-start align-items-center pl-20 pr-20"
            style="height: 50px"
            (click)="selectVariant(item)"
        >
            <div *ngIf="!enableEditQty" style="width: 40px">
                <label nz-checkbox [nzChecked]="sampleOrderSelectedVariants.has(item)" (nzCheckedChange)="selectVariant(item)"></label>
            </div>
            <img
                class="mr-20 border-radius-4 object-fit-cover"
                width="32"
                height="32"
                [src]="item.image?.src || 'assets/imgs/img_default@2x.png'"
            />
            <div class="flex-1">{{item.title}}</div>
            <div *ngIf="!enableEditQty" class="flex-1">x1</div>
            <div *ngIf="enableEditQty" class="flex-1" (click)="$event.preventDefault()">
                x
                <nz-input-number
                    style="width: 100px"
                    [ngModel]="variantAmountMap.get(item)"
                    (ngModelChange)="amountChange(item,$event)"
                    [nzFormatter]="formatterInteger"
                    [nzMin]="0"
                    [nzStep]="1"
                    [nzPlaceHolder]="'Quantity'"
                ></nz-input-number>
            </div>
            <div style="width: 80px">{{item.unit_cost | currency}}</div>
        </div>
    </div>
</div>
