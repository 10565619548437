import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { UserProfile } from '@typings/profile.typing';
import { BehaviorSubject } from 'rxjs';
import { FirebaseEventsService } from '@services/firebase-event.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    currentProfileSubject = new BehaviorSubject<UserProfile>(this.currentProfile);

    get currentProfile() {
        return this.pCurrentProfile;
    }

    set currentProfile(val) {
        this.pCurrentProfile = val;
        this.currentProfileSubject.next(val);
        if (val) {
            this.firebaseEventService.setupFirebaseEvent({ uid: val.uid });
        }
    }

    get currentProfileInValid() {
        return (
            !this.pCurrentProfile?.address_line_1 ||
            !this.pCurrentProfile?.city ||
            !this.pCurrentProfile?.state ||
            !this.pCurrentProfile?.country ||
            !this.pCurrentProfile?.zip ||
            !this.pCurrentProfile?.first_name ||
            !this.pCurrentProfile?.last_name
        );
    }

    private pCurrentProfile: UserProfile;

    constructor(private requestService: RequestService, private firebaseEventService: FirebaseEventsService) {}

    getCurrentProfile(): Promise<UserProfile> {
        return this.requestService.sendRequest<UserProfile>({
            method: 'GET',
            url: '/influencer/current-profile',
            api: 'shop-api',
        });
    }

    isProfileInValid(info) {
        return (
            !info?.address_line_1 || !info?.city || !info?.state || !info?.country || !info?.zip || !info?.first_name || !info?.last_name
        );
    }

    updateCurrentProfile(userData: UserProfile): Promise<UserProfile> {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/current-profile',
            data: userData,
            api: 'shop-api',
        });
    }

    checkInvitationCode(data: { invitation_code: string }): Promise<any> {
        return this.requestService.sendUnauthorizedRequest(
            {
                method: 'POST',
                url: '/shared/validate_invitation_code',
                data,
                api: 'shop-api',
            },
            'object'
        );
    }

    sendInvitationCode<T extends { invitation_code: string }>(data: T): Promise<any> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/shop_management/accept_invitation_code',
            data,
            api: 'shop-api',
        });
    }

    receiveInvited(data: { currentEmail: string; invitationCode: string; inviteState?: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/referral/receive_invited',
            data,
            api: 'data-api',
        });
    }

    /**
     * @param file File
     * @param limitations width:px height:px size:KB
     */
    checkIfImagePixelValid(file: File, limitations: { width: number; height: number; size: number }) {
        return new Promise<void>((resolve, reject) => {
            const URL = window.URL || window.webkitURL;
            const img = new Image();
            img.onload = () => {
                const valid = img.width <= limitations.width && img.height <= limitations.height && file.size <= limitations.size * 1024;
                if (valid) {
                    resolve();
                } else {
                    reject();
                }
            };
            img.src = URL.createObjectURL(file);
        });
    }
}
