<ng-template #titleTemplate>
    <app-toolbar
        (menuClicked)="storefrontStateService.toolbarMenuChange($event)"
        *ngIf="currentEditingTextSectionData"
        [color]="currentEditingTextSectionData.styles.color"
        [selectedToolbars]="currentEditingTextSectionData.selectedToolBars"
        [textAlign]="currentEditingTextSectionData.styles['text-align']"
        [textDecoration]="currentEditingTextSectionData.styles['text-decoration']"
    ></app-toolbar>
</ng-template>

<div class="about-box" *ngIf="shopDetail">
    <!-- Mobile -->
    <div [hidden]="siteView === 'desktop'" class="about-mobile">
        <div
            [ngStyle]="{
                backgroundColor: aboutBackground
            }"
            class="d-flex align-items-center about-content relative"
        >
            <!-- Video / Image -->
            <div *ngIf="aboutSectionMediaType === 'video'" class="about-video">
                <div
                    [ngStyle]="{padding:(shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained') ? '20px' : '0'}"
                    class="width-100p height-100p"
                    style="position: absolute; top: 0; left: 0"
                >
                    <iframe
                        *ngIf="shopDetail?.theme_settings.aboutMe.youtubeVideoUrl"
                        [src]="embedYoutubeVideoUrl | safeUrl"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        frameborder="0"
                        height="100%"
                        title="YouTube video player"
                        width="100%"
                    ></iframe>

                    <img
                        *ngIf="!shopDetail?.theme_settings.aboutMe.youtubeVideoUrl"
                        [src]="storefrontService.defaultThemeSettings.aboutMe.portraitImage"
                        class="width-100p height-100p object-fit-cover"
                    />
                </div>

                <div
                    [ngStyle]="{'top': (shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained') ? '20px' : '0', 'left': shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained' ? '20px' : '0' }"
                    class="add-media about-upload"
                >
                    <div (click)="showAddYoutubeVideoModal()" class="banner-upload-icon-text">
                        <img src="assets/antd/banner-upload.svg" />
                        <span class="text">Add Video</span>
                    </div>
                </div>
            </div>

            <div
                *ngIf="aboutSectionMediaType === 'image'"
                [loading]="portraitImgUploading"
                [maskVisible]="true"
                appLoading
                class="about-img"
            >
                <div
                    [ngStyle]="{padding: shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained' ? '20px' : '0'}"
                    class="width-100p height-100p"
                    style="position: absolute; top: 0; left: 0"
                >
                    <img
                        [src]="shopDetail?.theme_settings?.aboutMe?.portraitImage || storefrontService.defaultThemeSettings.aboutMe.portraitImage"
                        class="img"
                    />
                </div>

                <div
                    class="add-media about-upload"
                    [ngStyle]="{'top': (shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained') ? '20px' : '0', 'left': shopDetail.theme_settings.aboutMe.mobilePortraitImageStyle === 'contained' ? '20px' : '0' }"
                >
                    <nz-upload
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="uploadPortrait"
                        [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                    >
                        <div class="banner-upload-icon-text">
                            <img src="assets/antd/banner-upload.svg" />
                            <div>
                                <div class="text">Add Image</div>
                                <div class="fs-10">640 * 640px</div>
                            </div>
                        </div>
                    </nz-upload>
                </div>

                <div
                    *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.ABOUT_PORTRAIT, shopDetail.theme_settings.aboutMe.portraitImage)"
                    class="remove-media"
                    (click)="removeMedia('portrait')"
                >
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </div>
            </div>

            <!-- Text Section -->
            <div
                [loading]="mobileBackgroundImageUploading"
                [maskVisible]="true"
                [style.backgroundImage]="'url('+ shopDetail.theme_settings.aboutMe.mobileBackgroundImage +')'"
                style="text-align: center; min-height: 320px"
                appLoading
                class="about-text relative"
            >
                <div class="add-media">
                    <nz-upload
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="uploadMobileBg"
                        [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                    >
                        <div class="banner-upload-icon-text">
                            <img src="assets/antd/banner-upload.svg" />
                            <div>
                                <div class="text">Add Image</div>
                                <div class="fs-10">640 * 640px</div>
                            </div>
                        </div>
                    </nz-upload>
                </div>

                <div
                    *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.ABOUT_MOBILE_TEXT_BG, shopDetail.theme_settings.aboutMe.mobileBackgroundImage)"
                    class="remove-media"
                    (click)="removeMedia('mobileBg')"
                >
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </div>

                <h4 class="content-title" [style.color]="shopDetail.theme_settings.color1">
                    <div
                        class="edit-input"
                        nz-popover
                        nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                        nzPopoverPlacement="top"
                        [nzPopoverContent]="titleTemplate"
                        [nzPopoverTrigger]="'click'"
                        [style.fontFamily]="shopDetail.theme_settings?.fontTitle"
                        [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.ABOUT_TITLE)?.styles"
                        (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.ABOUT_TITLE)"
                        appContentEditableModel
                        [(contentEditableModel)]="shopDetail.theme_settings.aboutMe.title"
                    ></div>
                </h4>
                <div class="content-text" [style.color]="shopDetail.theme_settings.color1">
                    <div
                        class="edit-input"
                        nz-popover
                        nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                        nzPopoverPlacement="top"
                        [nzPopoverContent]="titleTemplate"
                        [nzPopoverTrigger]="'click'"
                        [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                        [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.ABOUT_CONTENT)?.styles"
                        (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.ABOUT_CONTENT)"
                        appContentEditableModel
                        [(contentEditableModel)]="shopDetail.theme_settings.aboutMe.content"
                    ></div>
                </div>
            </div>
        </div>
    </div>

    <!-- Desktop -->
    <div [hidden]="siteView === 'mobile'" class="about-desktop">
        <div
            [ngClass]="{'about-content-reverse': shopDetail?.theme_settings?.aboutMe?.direction === 'basic'}"
            [ngStyle]="{backgroundColor: aboutBackground}"
            class="d-flex align-items-stretch about-content relative"
        >
            <!-- Text Section -->
            <div
                [loading]="desktopBackgroundImageUploading"
                [maskVisible]="true"
                [style.backgroundImage]="'url('+ shopDetail?.theme_settings.aboutMe.desktopBackgroundImage + ')'"
                appLoading
                class="about-text relative"
            >
                <div class="add-media" [style.transform]="'scale(' + scaleRatio + ')'" style="transform-origin: top left">
                    <nz-upload
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="uploadDesktopBg"
                        [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                    >
                        <div class="banner-upload-icon-text">
                            <img src="assets/antd/banner-upload.svg" />
                            <div>
                                <div class="text">Add Image</div>
                                <div class="fs-10">720 * 720px</div>
                            </div>
                        </div>
                    </nz-upload>
                </div>

                <div
                    *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.ABOUT_DESKTOP_TEXT_BG, shopDetail.theme_settings.aboutMe.desktopBackgroundImage)"
                    class="remove-media"
                    (click)="removeMedia('desktopBg')"
                    [style.transform]="'scale(' + scaleRatio + ')'"
                    style="transform-origin: top right"
                >
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </div>

                <h4 class="content-title" [style.color]="shopDetail.theme_settings.color1">
                    <div
                        class="edit-input"
                        nz-popover
                        nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                        nzPopoverPlacement="top"
                        [nzPopoverContent]="titleTemplate"
                        [nzPopoverTrigger]="'click'"
                        [style.fontFamily]="shopDetail.theme_settings?.fontTitle"
                        [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.ABOUT_TITLE)?.styles"
                        (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.ABOUT_TITLE)"
                        appContentEditableModel
                        [(contentEditableModel)]="shopDetail.theme_settings.aboutMe.title"
                    ></div>
                </h4>
                <div class="content-text" [style.color]="shopDetail.theme_settings.color1">
                    <div
                        class="edit-input"
                        nz-popover
                        nzPopoverOverlayClassName="no-bg no-padding transparent-arrow"
                        nzPopoverPlacement="top"
                        [nzPopoverContent]="titleTemplate"
                        [nzPopoverTrigger]="'click'"
                        [style.fontFamily]="shopDetail?.theme_settings?.fontBody"
                        [ngStyle]="editableTextSectionDataMap.get(TextEditableEnum.ABOUT_CONTENT)?.styles"
                        (click)="storefrontStateService.selectEditableTextSection(TextEditableEnum.ABOUT_CONTENT)"
                        appContentEditableModel
                        [(contentEditableModel)]="shopDetail.theme_settings.aboutMe.content"
                    ></div>
                </div>
            </div>

            <!-- Video / Image -->
            <div *ngIf="aboutSectionMediaType === 'video'" class="about-video">
                <div class="width-100p height-100p" style="position: absolute; top: 0; left: 0">
                    <iframe
                        *ngIf="shopDetail?.theme_settings.aboutMe.youtubeVideoUrl"
                        [src]="embedYoutubeVideoUrl | safeUrl"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        frameborder="0"
                        height="100%"
                        style="position: absolute; right: 0; top: 0"
                        title="YouTube video player"
                        width="100%"
                    ></iframe>

                    <img
                        *ngIf="!shopDetail?.theme_settings.aboutMe.youtubeVideoUrl"
                        [src]="storefrontService.defaultThemeSettings.aboutMe.portraitImage"
                        class="width-100p height-100p object-fit-cover"
                        style="position: absolute; left: 0; top: 0"
                    />
                </div>

                <div class="add-media about-upload" [style.transform]="'scale(' + scaleRatio + ')'" style="transform-origin: top left">
                    <div (click)="showAddYoutubeVideoModal()" class="banner-upload-icon-text">
                        <img src="assets/antd/banner-upload.svg" />
                        <span class="text">Add Video</span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="aboutSectionMediaType === 'image'"
                [loading]="portraitImgUploading"
                [maskVisible]="true"
                appLoading
                class="about-img"
            >
                <div class="width-100p height-100p" style="position: absolute; top: 0; left: 0">
                    <img
                        [src]="shopDetail?.theme_settings?.aboutMe?.portraitImage || storefrontService.defaultThemeSettings.aboutMe.portraitImage"
                        class="img"
                    />
                </div>

                <div class="add-media about-upload" [style.transform]="'scale(' + scaleRatio + ')'" style="transform-origin: top left">
                    <nz-upload
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="uploadPortrait"
                        [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                    >
                        <div class="banner-upload-icon-text">
                            <img src="assets/antd/banner-upload.svg" />
                            <div>
                                <div class="text">Add Image</div>
                                <div class="fs-10">720 * 720px</div>
                            </div>
                        </div>
                    </nz-upload>
                </div>

                <div
                    *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.ABOUT_PORTRAIT, shopDetail.theme_settings.aboutMe.portraitImage)"
                    class="remove-media"
                    (click)="removeMedia('portrait')"
                    [style.transform]="'scale(' + scaleRatio + ')'"
                    style="transform-origin: top right"
                >
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </div>
            </div>
        </div>
    </div>
</div>
