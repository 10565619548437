import { CrowdfundingInProgressModalComponent } from '../crowdfunding-in-progress-modal/crowdfunding-in-progress-modal.component';
import { StartCrowdfundingModalComponent } from './../start-crowdfunding-modal/start-crowdfunding-modal.component';
import { CrowdFundingDetail } from '@modules/nidavellir/nidavellir.type';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NidavellirService } from '@modules/nidavellir/nidavellir.service';
import { DateTimeService } from '@services/date-time.service';
import { MessageService } from '@services/message.service';

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export type CrowdfundingStatus = 'notStart' | 'inProgress' | 'end';

@Component({
    selector: 'app-crowdfunding-overview',
    templateUrl: './crowdfunding-overview.component.html',
    styleUrls: ['./crowdfunding-overview.component.less'],
})
export class CrowdfundingOverviewComponent implements OnInit {
    @Input() productData: { [key: string]: any; product_id: any };
    @Input() disableStartCrowdfunding = false;

    @Output() getCrowdfundingDone = new EventEmitter<{ productId: any; status: CrowdfundingStatus }>();

    crowdfundingDetail: CrowdFundingDetail;

    percentRate: number;

    fetchingCrowdfundingDetail = false;

    remainingDate: string;

    isCrowdfundingEnd = false;

    constructor(
        private modalService: NzModalService,
        private nidavellirService: NidavellirService,
        private dateTimeService: DateTimeService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.fetchingCrowdfundingDetail = true;
        this.nidavellirService
            .getCrowdFundingDetailByProductId(this.productData.product_id)
            .then((res: CrowdFundingDetail) => {
                let status: CrowdfundingStatus = 'notStart';

                if (res) {
                    this.setLocalDataByDetail(res);
                    if (this.isCrowdfundingEnd) {
                        status = 'end';
                    } else {
                        status = 'inProgress';
                    }
                }

                this.getCrowdfundingDone.emit({
                    productId: this.productData.product_id,
                    status,
                });
            })
            .finally(() => (this.fetchingCrowdfundingDetail = false));
    }

    getFulfilledProgress(data: CrowdFundingDetail) {
        const percentage = Math.floor((data.item_sold / data.minimal_quantity) * 100);
        return percentage > 100 ? 100 : percentage;
    }

    startCrowdFunding() {
        if (this.disableStartCrowdfunding) {
            return;
        }

        this.modalService.create({
            nzContent: StartCrowdfundingModalComponent,
            nzFooter: null,
            nzMaskClosable: false,
            nzComponentParams: { moq: this.productData.moq, quantity: this.productData.moq || 1 },
            nzOnOk: comp => {
                const data = {
                    product_id: this.productData.product_id,
                    end_date: this.dateTimeService.replaceCurrentTzWithPST(comp.endTime),
                    minimal_quantity: comp.quantity,
                };
                comp.submitting = true;

                return this.nidavellirService
                    .startCrowdfunding(data)
                    .then((res: CrowdFundingDetail) => {
                        this.getCrowdfundingDone.emit({
                            productId: this.productData.product_id,
                            status: 'inProgress',
                        });

                        this.setLocalDataByDetail(res);
                        this.productData.product_id = res.product_id;
                        this.productData.link = res.link;
                        this.productData.active = true;

                        this.modalService.create({
                            nzContent: CrowdfundingInProgressModalComponent,
                            nzFooter: null,
                            nzComponentParams: {
                                crowdFundingDetail: this.crowdfundingDetail,
                                productItem: this.productData,
                            },
                        });
                    })
                    .catch(() => this.messageService.error())
                    .finally(() => (comp.submitting = false));
            },
        });
    }

    editCrowdFunding() {
        this.modalService.create({
            nzContent: StartCrowdfundingModalComponent,
            nzFooter: null,
            nzMaskClosable: false,
            nzComponentParams: {
                moq: this.productData.moq,
                quantity: this.crowdfundingDetail.minimal_quantity,
                endTime: this.dateTimeService.parseDateWithTimeZone(this.crowdfundingDetail.end_date),
                isEditMode: true,
            },
            nzOnOk: comp => {
                const data = {
                    product_id: this.productData.product_id,
                    end_date: this.dateTimeService.replaceCurrentTzWithPST(comp.endTime),
                    minimal_quantity: comp.quantity,
                };
                comp.submitting = true;

                return this.nidavellirService
                    .updateCrowdFundingInfo(data)
                    .then((res: CrowdFundingDetail) => {
                        if (res) {
                            this.setLocalDataByDetail(res);
                        }
                    })
                    .catch(() => this.messageService.error())
                    .finally(() => (comp.submitting = false));
            },
        });
    }

    private setLocalDataByDetail(data) {
        this.crowdfundingDetail = data;
        this.percentRate = this.getFulfilledProgress(this.crowdfundingDetail);
        this.remainingDate = this.getRemainingTimes(this.crowdfundingDetail.end_date);
        this.isCrowdfundingEnd = dayjs(this.crowdfundingDetail.end_date).isSameOrBefore(dayjs());
    }

    private getRemainingTimes(date: string) {
        const diffTime = dayjs.duration(dayjs(date).diff(dayjs()), 'millisecond');

        const day = Math.floor(diffTime.asDays());

        if (day >= 1) {
            return day > 1 ? `${day} days` : `${day} day`;
        }

        const hours = diffTime.hours();
        const minutes = String(diffTime.minutes()).padStart(2, '0');

        return `${hours}:${minutes}`;
    }
}
