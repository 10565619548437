<section>
    <h2>Crowdfunding</h2>

    <p class="mt-20 mb-20">
        Crowdfunding can help you start selling the product before mass production, and you can produce according to the funding result. If
        the minimum goal is not reached, Lifo will refund the customers.
    </p>

    <div class="form d-flex justify-content-between align-items-center">
        <div class="form-item">
            <span>Min. Goal <span class="red">*</span></span>
            <div class="mt-10">
                <nz-input-number [nzMin]="moq || 1" [nzStep]="1" [(ngModel)]="quantity"></nz-input-number>
                <span class="pl-10">Units Sold</span>
            </div>
        </div>

        <div class="form-item" style="width: 50%">
            <span>End Time (PST) <span class="red">*</span></span>

            <nz-date-picker
                class="width-100p mt-10"
                nzFormat="yyyy-MM-dd HH:mm"
                nzSize="large"
                style="border-radius: 8px"
                [nzDropdownClassName]="'no-now'"
                [nzAllowClear]="true"
                [nzDisabledDate]="disabledDate"
                [nzDisabledTime]="disabledDateTime"
                [nzShowTime]="timeOptions"
                [(ngModel)]="endTime"
            ></nz-date-picker>
        </div>
    </div>

    <p *ngIf="moq" class="mt-10 fs-12" style="color: #9f99a3">MOQ required for mass production: {{moq}} Units"</p>

    <div class="d-flex justify-content-around align-items-center mt-30">
        <button nz-button style="width: 40%" (click)="cancel()">Close</button>
        <button nz-button nzType="primary" style="width: 40%" [disabled]="confirmDisabled" [nzLoading]="submitting" (click)="ok()">
            {{isEditMode ? 'Confirm' : 'Start'}}
        </button>
    </div>
</section>
