import { PaymentMethodEnum } from './../../shared/components/shopping-cart/shopping-cart.service';
import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AccountBalance } from '@typings/earnings.typings';
import { OrderItem, OrderDetail } from '@typings/order.typings';
import { IPaymentRecord } from '@typings/profile.typing';

export enum ReportType {
    COST = 'cost',
    REVENUE = 'revenue',
}

export interface ReportItem {
    created_at: string;
    amount: number;
    report_date: string;
    report_end_date: string;
    report_frequency: string;
    report_start_date: string;
    report_summary: {
        return_items: number;
        return_service_fee: number;
        service_fee: number;
        weekly_revenue: number;
        monthly_revenue: number;
    };
    report_name: string;
    report_type: ReportType;
}

@Injectable()
export class HomeService {
    constructor(private requestService: RequestService) {}

    getOrders(): Promise<OrderItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/orders',
            api: 'data-shop',
        });
    }

    getRevenueReport() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/revenue_report',
            api: 'data-shop',
        });
    }

    getCostReport() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/cost_report',
            api: 'data-shop',
        });
    }

    /**
     * @param timeStamp Unix timestamp(seconds)
     */
    getSalesInfoByDate(timeStamp: number) {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/sales?since=${timeStamp}`,
            api: 'data-shop',
        });
    }

    getAccountBalance() {
        return this.requestService.sendRequest<AccountBalance>({
            method: 'GET',
            url: '/influencer/account_balance',
            api: 'data-api',
        });
    }

    getOrderDetailById(orderId: string): Promise<OrderDetail> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/order_detail?order_id=${orderId}`,
            api: 'data-shop',
        });
    }

    getAnalytics() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/influencer/report',
                api: 'data-api',
            },
            'object'
        );
    }

    getPaymentRecords(): Promise<IPaymentRecord[]> {
        return this.requestService.sendRequest<IPaymentRecord[]>({
            method: 'GET',
            url: '/influencer/payment/records',
            api: 'data-api',
        });
    }

    approvePayment(paymentId: number, paymentData: { amountFromBalance: number; amountToCharge: number }): Promise<any> {
        return this.requestService.sendRequest<any>({
            method: 'PUT',
            url: '/influencer/payment/approve',
            data: {
                payment_id: paymentId,
                ...paymentData,
            },
            api: 'shop-api',
        });
    }

    approvePaymentRequest(data: { payment_request_id: any; payment_methods: { method: PaymentMethodEnum; amount: number }[] }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            api: 'shop-api',
            url: '/influencer/approve_payment_request',
            data,
        });
    }

    completePaymentRequest(payment_request_id: any) {
        return this.requestService.sendRequest({
            method: 'PUT',
            api: 'shop-api',
            url: '/influencer/complete_payment_request',
            data: { payment_request_id },
        });
    }

    getInCompleteCheckout() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            api: 'shop-api',
            url: '/influencer/incomplete_checkout',
        });
    }

    getNotifications(): Promise<
        {
            type: string;
            meta_data: { [key: string]: any; order_id?: any };
        }[]
    > {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            api: 'shop-api',
            url: '/influencer/shop_notification',
        });
    }

    markNotificationAsRead(id: any) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            api: 'shop-api',
            url: '/influencer/shop_notification/read',
            data: { id },
        });
    }

    skipGuidance() {
        return this.requestService.sendRequest<any>({
            method: 'PUT',
            api: 'shop-api',
            url: '/influencer/shop_management/skip_user_guide',
        });
    }
}
