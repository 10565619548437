import { environment } from './../../../../environments/environment';
import { KlarnaInitInfo } from './klarna-modal/klarna-modal.component';
import { Injectable } from '@angular/core';

import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import * as dayjs from 'dayjs';

import { ProductInCartFromAPI } from '@modules/nidavellir/nidavellir.service';
import { ShopService } from '@services/shop.service';
import { IPayPalConfig } from 'ngx-paypal';

declare const Klarna: any;

export enum PaymentMethodEnum {
    BANK = 'Bank Account',
    CREDIT_CARD = 'Credit Card',
    PAYPAL = 'Paypal',
    KLARNA = 'Installment', // To be deprecate
    INSTALLMENT = 'Installment',
    BALANCE = 'Balance',
    LIFO_CREDIT = 'Lifo Credit',
}

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService {
    elementsOptions: StripeElementsOptions = {
        locale: 'en',
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
            },
        ],
    };

    cardOptions: StripeCardElementOptions = {
        hidePostalCode: true,
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#0D053C',
                color: '#31325F',
                fontWeight: '400',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#9F99A3',
                },
            },
            invalid: {
                iconColor: '#EB5757',
                color: '#EB5757',
            },
        },
        classes: {
            base: 'credit-card-box',
        },
    };

    paypalConfig: IPayPalConfig;

    get availableLifoCreditRate() {
        return this.shopService.shopProfile.available_credit_rate || 0.4;
    }

    constructor(private shopService: ShopService) {}

    initConfig(onApprove, createOrderOnServer, onError, onCancel) {
        this.paypalConfig = {
            currency: 'USD',
            clientId: environment.paypal.clientId,
            style: {
                label: 'checkout',
                layout: 'horizontal',
                color: 'blue',
                size: 'medium',
                height: 54,
                tagline: false,
                fundingicons: false,
            },
            createOrderOnServer,
            // onClientAuthorization: onApprove,
            onApprove,
            onError,
            onCancel,
        };
    }

    calculateAmount(
        products: ProductInCartFromAPI[],
        viewStatusControl: { [key: string]: boolean },
        calculateType: 'internal' | 'creator',
        extraInfo?: {
            lifo_credit: number;
            account_balance: number;
            discount: number;
        }
    ) {
        let creditUsedAmount = 0;
        let balanceUsedAmount = 0;
        let total = 0;
        let discount = extraInfo.discount || 0;
        const tax = 0;

        const { subTotal, packageOperationFee, shipping } = this.calculateSubtotalShippingAndOperationFee(products);
        const tempTotal = subTotal + packageOperationFee + shipping;

        // Internal Create Order
        if (calculateType === 'internal') {
            if (discount > tempTotal) {
                discount = tempTotal;
            }

            total = tempTotal - discount;
        }

        // Creator Checkout directly
        if (calculateType === 'creator') {
            if (viewStatusControl.useCreditPay) {
                const neededCreditAmount = (subTotal + packageOperationFee) * this.availableLifoCreditRate;
                const restCredit = extraInfo.lifo_credit;
                const restAmount = tempTotal - discount;
                creditUsedAmount = Math.min(neededCreditAmount, restCredit, restAmount);
            }

            // TODO: temp remove, we do have no tax now
            // const tax = (subTotal + packageOperationFee + creditUsedAmount) * 0.1;

            if (viewStatusControl.useBalancePay) {
                const totalExceptUseBalance = tempTotal - creditUsedAmount + tax - discount;
                balanceUsedAmount = Math.min(extraInfo.account_balance, totalExceptUseBalance);
            }

            total = tempTotal - creditUsedAmount - balanceUsedAmount - discount;
        }

        return {
            amount: {
                subTotal: Number(subTotal.toFixed(2)),
                packageOperationFee: Number(packageOperationFee.toFixed(2)),
                tax: Number(tax.toFixed(2)),
                creditUsedAmount: Number(creditUsedAmount.toFixed(2)),
                balanceUsedAmount: Number(balanceUsedAmount.toFixed(2)),
                total: Number(total.toFixed(2)),
                shipping: Number(shipping.toFixed(2)),
            },
            discount: Number(discount.toFixed(2)),
        };
    }

    calculateDeliverDate(product: ProductInCartFromAPI) {
        const dateWithLogistics = dayjs().add(product.cart_json.est_ordering_days, 'day');
        return `${dateWithLogistics.format('MMM D')} - ${dateWithLogistics.add(3, 'day').format('MMM D')}`;
    }

    async initializeKlarna(selector: string, data: KlarnaInitInfo, cb) {
        try {
            Klarna.Payments.init({ client_token: data.client_token });

            Klarna.Payments.load(
                { container: selector, payment_method_category: data.payment_method_categories[0].identifier },
                {},
                (authorizeRes: { show_form: boolean }) => cb()
            );
        } catch (e) {
            cb();
            console.error(e);
        }
    }

    calculateSubtotalShippingAndOperationFee(products) {
        let subTotal = 0;
        let packageOperationFee = 0;
        let shipping = 0;
        products.forEach(product => {
            product.cart_json.variants.forEach(variant => {
                subTotal += variant.quantity * variant.unit_cost;
                if (product.cart_json.packaging?.length > 0) {
                    packageOperationFee += variant.quantity;
                }

                if (product.cart_json.is_sample) {
                    shipping = 3.99;
                }
            });
        });

        return { subTotal, packageOperationFee, shipping };
    }
}
