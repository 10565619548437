import { CUSTOM_ANALYTICS_EVENT } from './../../services/firebase-event.service';
import { FirebaseEventsService } from '@services/firebase-event.service';
import { ProductTypeEnum } from '@shared/typings/order.typings';
import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';

export interface ProductDetail {
    // 上架状态
    active: boolean;

    listing_status: string;

    // unpublished: 新产品，未上架过，只要上架过一次，就永远不会再变回unpublished
    status: 'unpublished' | 'draft' | 'active';
    body_html: string;
    image: string;
    images: any[];
    product_id: any;
    product_json: any;
    product_type: ProductTypeEnum;
    sku_list: any[];
    title: string;
    vendor: string;
    packaging: {
        image_url: string;
        type: string;
        sku: string;
    }[];
    moq?: number;
}

export enum SellingTagEnum {
    BEST_SELLER = 'best_seller',
    HIGH_MARGIN = 'high_margin',
    HIGH_SELL_THROUGH = 'high_sell_through',
}

export interface ProductListItem {
    id: any;
    image: string;
    product_id: any;
    product_type: any;
    sku_list: string[];
    title: string;
    total_inventory: number;
    vendor: string;
    warehouse_list: string[];
    active: boolean;
    link: string;
    est_profit: number;
    units_sold: number;
    est_shipping_cost: number;
    gross_margin: number;
    tags: SellingTagEnum[];
    price: number;
    collections: { name: string; id: any }[];
    status: string;
}

export interface CreateProduct {
    brand_name: string;
    product_name: string;
    product_detail: string;
    logo: string;
    background_image: string;
}

export interface Collection {
    created_at: string;
    created_by: string;
    id: number;
    name: string;
    product_count: number;
    product_list: ProductListItem[];
    shop_id: number;
    shopify_collection_id: any;
    discount: number;
}

@Injectable({
    providedIn: 'root',
})
export class ProductManagementService {
    constructor(private requestService: RequestService, private firebaseEventService: FirebaseEventsService) {}

    getProductList(): Promise<ProductListItem[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/shop_management/product',
            api: 'data-shop',
        });
    }

    getProductDetailByProductId(id: string): Promise<ProductDetail> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/product_detail?product_id=${id}`,
            api: 'data-shop',
        });
    }

    getProductImage() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/system-settings',
            api: 'campaign-test',
        });
    }

    createProduct(createProduct: CreateProduct) {
        return this.requestService.sendRequest({
            method: 'POST',
            api: 'data-shop',
            url: '/influencer/shop_management/create_diy_product',
            data: createProduct,
        });
    }

    updateProductInfo(productDetail: ProductDetail) {
        return this.requestService
            .sendRequest(
                {
                    method: 'PUT',
                    url: '/influencer/shop_management/product_detail',
                    api: 'data-shop',
                    data: productDetail,
                },
                'object'
            )
            .then((data: any) => {
                this.firebaseEventService.logEvent({
                    name: CUSTOM_ANALYTICS_EVENT.SAVE_EDIT_LISTING_PRODUCT,
                    properties: { product_id: data.product_id },
                });

                return data;
            });
    }

    getCollectionList(): Promise<Collection[]> {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/influencer/collections',
            api: 'data-shop',
        });
    }

    createCollection(name: string): Promise<Collection> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/collections',
            api: 'data-shop',
            data: { name },
        });
    }

    addCollectionForProduct(data: { product_id: any; collection_id_list: any[] }) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/influencer/product_collection',
            api: 'data-shop',
            data,
        });
    }

    deleteProduct(productId: any) {
        return this.requestService.sendRequest({
            method: 'DELETE',
            url: `/influencer/shop_management/product_detail?product_id=${productId}`,
            api: 'data-shop',
        });
    }

    priceFormatter(value: number | string) {
        return `$${Number(value).toFixed(2) || '0.00'}`;
    }

    beforeUnloadEvent(e: BeforeUnloadEvent) {
        e.returnValue = '';
        return '';
    }
}
