<div
    [hidden]="siteView === 'mobile'"
    class="social-media social-media-desktop relative"
    appLoading
    [loading]="desktopImgUploading"
    [maskVisible]="true"
    [ngStyle]="{
        'background-color': shopDetail?.theme_settings?.color6,
        'background-image':'url('+ shopDetail?.theme_settings?.socialMediaSection.desktopBackgroundImage +')'
    }"
>
    <div class="add-media" [style.transform]="'scale(' + scaleRatio + ')'" style="transform-origin: top left">
        <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="uploadDesktop" [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'">
            <div class="banner-upload-icon-text">
                <img src="assets/antd/banner-upload.svg" />
                <div>
                    <div class="text">Add Image</div>
                    <div class="fs-10">1440 * 320px</div>
                </div>
            </div>
        </nz-upload>
    </div>

    <div
        *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.SOCIAL_DESKTOP_BG, shopDetail.theme_settings.socialMediaSection.desktopBackgroundImage)"
        class="remove-media"
        (click)="removeMedia('desktopImg')"
        [style.transform]="'scale(' + scaleRatio + ')'"
        style="transform-origin: top right"
    >
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>

    <h4 class="content-title" [ngStyle]="{color: shopDetail?.theme_settings?.color4, 'font-family': shopDetail?.theme_settings?.fontTitle}">
        Social Media
    </h4>
    <p class="content-text" [ngStyle]="{color: shopDetail?.theme_settings?.color4, 'font-family': shopDetail?.theme_settings?.fontBody}">
        Let's Connect
    </p>
    <div class="d-flex flex-wrap justify-content-center social-media-content">
        <div *ngFor="let platform of addedSocial" class="img-box">
            <i
                nz-icon
                [nzType]="getNewSocialIcon(platform)"
                nzTheme="outline"
                class="img"
                [ngStyle]="{color: shopDetail?.theme_settings?.color4}"
            ></i>
            <i class="cursor-pointer remove" nz-icon nzType="close" nzTheme="outline" (click)="removeSocialMedia(platform)"></i>
        </div>
        <div [hidden]="nonAddedSocial?.length === 0" class="cursor-pointer add-social" (click)="showAddSocialModal()">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
        </div>
    </div>
</div>

<div
    class="social-media social-media-mobile relative"
    appLoading
    [loading]="mobileImgUploading"
    [maskVisible]="true"
    [hidden]="siteView === 'desktop'"
    [ngStyle]="{
        'background-color': shopDetail?.theme_settings?.color6,
        'background-image':'url(' + shopDetail?.theme_settings?.socialMediaSection.mobileBackgroundImage + ')'
    }"
>
    <div class="add-media">
        <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="uploadMobile" [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'">
            <div class="banner-upload-icon-text">
                <img src="assets/antd/banner-upload.svg" />
                <div>
                    <div class="text">Add Image</div>
                    <div class="fs-10">640 * 800px</div>
                </div>
            </div>
        </nz-upload>
    </div>

    <div
        *ngIf="!storefrontService.isDefaultImage(StorefrontMediaEnum.SOCIAL_MOBILE_BG, shopDetail.theme_settings.socialMediaSection.mobileBackgroundImage)"
        class="remove-media"
        (click)="removeMedia('mobileImg')"
    >
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>

    <h4 class="content-title" [ngStyle]="{color: shopDetail?.theme_settings?.color4, 'font-family': shopDetail?.theme_settings?.fontTitle}">
        Social Media
    </h4>

    <p class="content-text" [ngStyle]="{color: shopDetail?.theme_settings?.color4, 'font-family': shopDetail?.theme_settings?.fontBody}">
        Let's Connect
    </p>

    <div class="d-flex flex-wrap justify-content-center social-media-content">
        <div *ngFor="let platform of addedSocial" class="img-box">
            <i
                style="font-size: 80px; margin: 20px 0"
                nz-icon
                [nzType]="getNewSocialIcon(platform)"
                nzTheme="outline"
                class="img"
                [ngStyle]="{color: shopDetail?.theme_settings?.color4}"
            ></i>
            <i class="cursor-pointer remove" nz-icon nzType="close" nzTheme="outline" (click)="removeSocialMedia(platform)"></i>
        </div>
        <div
            [hidden]="nonAddedSocial?.length===0"
            class="cursor-pointer add-social"
            style="margin: 30px 15px"
            (click)="showAddSocialModal()"
        >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
        </div>
    </div>
</div>
