import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@src/environments/environment';

interface IRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    api?: string;
    data?: any;
    params?: HttpParams;
}

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient, private auth: AngularFireAuth) {}

    getHost(apiString) {
        switch (apiString) {
            case 'api-shop':
                return 'https://campaign-shop.lifo.ai';
            case 'data-api':
                return environment.dataApiUrl;
            case 'data-shop':
            case 'discover':
            case 'shop-api':
                return environment.shopApiService;
            default:
                return environment.campaignService;
        }
    }

    sendRequest$<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'string'): Observable<T> {
        const host = this.getHost(requestOptions.api);
        return this.auth.idToken.pipe(
            mergeMap(token => {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: token,
                    }),
                };

                const url = `${host ?? environment.campaignService}${requestOptions.url}`;

                switch (requestOptions.method) {
                    case 'GET':
                        return this.http.get<T>(url, httpOptions);
                    case 'DELETE':
                        return this.http.delete<T>(url, httpOptions);
                    case 'POST':
                        return this.http.post<T>(url, requestOptions.data, httpOptions);
                    case 'PUT':
                        return this.http.put<T>(url, requestOptions.data, httpOptions);
                }
            }),
            catchError(error => this.handleError(error, errorHandlerType))
        );
    }

    sendUnauthorizedRequest$<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'string'): Observable<T> {
        const host = this.getHost(requestOptions.api);

        const url = `${host ?? environment.campaignService}${requestOptions.url}`;

        return this.http
            .request<T>(requestOptions.method, url, {
                body: requestOptions.data ? requestOptions.data : null,
                params: requestOptions.params ? requestOptions.params : null,
            })
            .pipe(catchError(error => this.handleError(error, errorHandlerType)));
    }

    sendRequest<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'object'): Promise<T> {
        return this.sendRequest$<T>(requestOptions, errorHandlerType).pipe(take(1)).toPromise();
    }

    sendUnauthorizedRequest<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'string'): Promise<T> {
        return this.sendUnauthorizedRequest$<T>(requestOptions, errorHandlerType).pipe(take(1)).toPromise();
    }

    private handleError(error: HttpErrorResponse, errorHandlerType: 'string' | 'object' = 'string') {
        if (errorHandlerType === 'object') {
            return throwError({
                status: error.status,
                body: error.status < 500 ? error.error : '  Oops! Something went wrong. Please try again.',
            });
        }
        if (errorHandlerType === 'string' || !errorHandlerType) {
            let error_message = '';
            // if (error.error instanceof ErrorEvent) {
            //     // A client-side or network error occurred. Handle it accordingly.
            //     error_message = `An error occurred:', ${error.error.message}`;
            // } else {
            //     // The backend returned an unsuccessful response code.
            //     // The response body may contain clues as to what went wrong,
            //     error_message = 'Oops! Something went wrong. Please try again.';
            // }

            error_message = 'Oops! Something went wrong. Please try again.';

            // return an observable with a user-facing error message
            return throwError(error_message);
        }
    }
}
