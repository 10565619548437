import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { random } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class FirebaseFileUploadService {
    constructor(private storage: AngularFireStorage) {}

    startUpload(
        file: File,
        originUploadPath = '/public/images'
    ): { file: File; uploadTask: Promise<string>; taskPercentage$: Observable<number>; path: string } {
        const path = `${originUploadPath}/${Date.now()}/${random(0, 100000)}/${random(0, 1000)}.${file.name?.split('.').pop() || 'png'}`;

        // Reference to storage bucket
        const ref = this.storage.ref(path);

        // The main task
        const task$ = this.storage.upload(path, file);
        const uploadTask = new Promise<string>(resolve => {
            task$
                .snapshotChanges()
                .pipe(
                    finalize(async () => {
                        const downloadUrl: string = await ref.getDownloadURL().toPromise();
                        resolve(downloadUrl);
                    })
                )
                .subscribe();
        });
        const taskPercentage$ = task$.percentageChanges();

        return {
            file,
            uploadTask,
            taskPercentage$,
            path,
        };
    }
}
