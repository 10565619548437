import { NgModule } from '@angular/core';
import { ImgVideoPreviewDirective } from '@shared/directives/file-preview.directive';
import { EchartsDirective } from '@shared/directives/echarts.directive';
import { LoadingDirective } from '@shared/directives/loading.directive';
import { StatusBtnDirective } from '@shared/directives/status-btn.directive';
import { ImgLoadTransitionDirective } from './img-loading.directive';
import { ContentEditableModelDirective } from './content-editable-model.directive';

@NgModule({
    declarations: [
        ImgVideoPreviewDirective,
        EchartsDirective,
        LoadingDirective,
        StatusBtnDirective,
        ImgLoadTransitionDirective,
        ContentEditableModelDirective,
    ],
    imports: [],
    exports: [
        ImgVideoPreviewDirective,
        EchartsDirective,
        LoadingDirective,
        StatusBtnDirective,
        ImgLoadTransitionDirective,
        ContentEditableModelDirective,
    ],
})
export class DirectivesModule {}
