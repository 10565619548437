import { ProductTypeEnum } from '@shared/typings/order.typings';
import { NidavellirService, ProductInCart } from '@modules/nidavellir/nidavellir.service';
import { ProductDetail, ProductManagementService } from '@modules/product-management/product-management.service';
import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MessageService } from '@services/message.service';
import { UtilService } from '@services/util.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { expandOnEnterAnimation, collapseOnLeaveAnimation } from 'angular-animations';

@Component({
    selector: 'app-mass-product-modal',
    templateUrl: './mass-product-modal.component.html',
    styleUrls: ['./mass-product-modal.component.less'],
    providers: [ProductManagementService],
    animations: [
        collapseOnLeaveAnimation({ anchor: 'collapse', duration: 300 }),
        expandOnEnterAnimation({ anchor: 'expand', duration: 300 }),
    ],
})
export class MassProductModalComponent implements OnInit {
    @Input() productId: any;
    @Input() title = 'Bulk Order';
    @Input() isOrderSample = false;

    ProductTypeEnum = ProductTypeEnum;
    MAX_ORDER_PRODUCT = 200;

    productDetail: ProductDetail;
    variantAmountMap = new Map<any, number>();
    submitting = false;

    addedProducts: any;
    infinity = Infinity;

    stickerSectionVisible = false;

    stickers: { url: string; product_id?: any }[];
    selectedSticker: { url: string; product_id?: any };

    expanded = false;

    get subTotal() {
        if (!this.productDetail) return 0;

        return this.productDetail.sku_list.reduce((sum, curr) => {
            const realUnitCost = this.isOrderSample ? curr.unit_cost + (curr.fulfillment_cost || 0) : curr.unit_cost;
            return sum + (this.variantAmountMap.get(curr) || 0) * realUnitCost;
        }, 0);
    }

    get totalCount() {
        let result = 0;
        const values = this.variantAmountMap.values();
        for (const item of values) {
            result += item;
        }

        return result || 0;
    }

    get total() {
        return this.subTotal + (this.selectedSticker ? this.totalCount * 1 : 0);
    }

    constructor(
        private productManagementService: ProductManagementService,
        private modalRef: NzModalRef,
        private nidavellirService: NidavellirService,
        private messageService: MessageService,
        private nzMessageService: NzMessageService,
        private utilService: UtilService,
        private firebaseUpload: FirebaseFileUploadService
    ) {}

    ngOnInit(): void {
        this.productManagementService.getProductDetailByProductId(this.productId).then(res => (this.productDetail = res));
        this.getStickers();
    }

    beforeUpload = (file: any) => {
        this.utilService
            .checkIfImagePixelGreater(file, { width: 975, height: 975 })
            .then(() => {
                const stickerObj = { url: null };
                this.stickers.push(stickerObj);
                const { uploadTask } = this.firebaseUpload.startUpload(file, 'public/sticker-imgs/');
                uploadTask
                    .then(downloadUrl => {
                        stickerObj.url = downloadUrl;
                        this.selectedSticker = stickerObj;
                    })
                    .catch(() => {
                        this.stickers = this.stickers.filter(item => item !== stickerObj);
                        this.messageService.error('Upload image failed, please try again later');
                    });
            })
            .catch(() => this.messageService.error('The image size should be greater than 975 * 975 pixels'));

        return false;
    };

    formatterInteger = value => Math.floor(value) || 0;

    getStickers() {
        this.productManagementService.getProductList().then(res => {
            const stickers = res.filter(item => item.product_type === ProductTypeEnum.STICKER);
            this.stickers = stickers.map(item => ({ url: item.image }));
        });
    }

    generateVariants(): { id: number; image: string; sku: string; unit_cost: number; fulfillment_cost: number; variant_json: any }[] {
        return Array.from(this.variantAmountMap.keys()).filter(key => this.variantAmountMap.get(key) > 0);
    }

    amountChange(item, value: number) {
        if (!value || value <= 0) {
            this.variantAmountMap.set(item, 0);
        } else {
            this.variantAmountMap.set(item, Math.floor(value));
        }
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    async addToCart() {
        const orderedCount = Array.from(this.variantAmountMap.values()).reduce((prev, curr) => prev + curr, 0);

        if (this.productDetail.product_type === ProductTypeEnum.STANDARD_PRODUCT) {
            // Standard Product maximum order quantity is MAX_ORDER_PRODUCT
            const orderQuantityPerVariant: number[] = Array.from(this.variantAmountMap.values()).filter(val => val);
            if (orderQuantityPerVariant.some(val => val > this.MAX_ORDER_PRODUCT)) {
                return this.nzMessageService.error(`Maximum order quantity for each variant: ${this.MAX_ORDER_PRODUCT}`);
            }
        }

        if (this.isOrderSample) {
            if (orderedCount <= 0) {
                return this.nzMessageService.error('The number of products ordered less than 1');
            }
        } else {
            if (orderedCount < (this.productDetail.moq || 1)) {
                // When product has moq
                return this.nzMessageService.error(`The number of products ordered less than ${this.productDetail.moq || 1}(MOQ)`);
            }
        }

        this.submitting = true;

        const packaging = [];
        if (this.stickerSectionVisible && this.selectedSticker) {
            packaging.push({
                type: ProductTypeEnum.STICKER,
                image_url: this.selectedSticker.url,
                product_id: this.selectedSticker.product_id || null,
            });
        }

        const variants = this.generateVariants();
        console.log(variants);

        const data = {
            product_id: this.productDetail.product_id,
            variants: variants.map(variant => ({
                id: variant.sku as any,
                amount: this.variantAmountMap.get(variant) || 1,
                price: null,
            })),
            packaging,
        };
        const productsToBeAdd: ProductInCart = {
            product_id: this.productDetail.product_id,
            image_url: this.productDetail.image,
            title: this.productDetail.title,
            est_ordering_days: 15,
            type: 'mass_production',
            is_sample: false,
            variants: variants.map(item => ({
                variant_id: item.sku,
                quantity: this.variantAmountMap.get(item) || 1,
                unit_cost: item.unit_cost + (this.isOrderSample ? item.fulfillment_cost || 0 : 0),
                option: item.variant_json.title,
            })),
            packaging: data.packaging,
            data,
        };

        try {
            if (this.isOrderSample) {
                this.addedProducts = await this.nidavellirService.orderPodSampleToCart(productsToBeAdd);
            } else {
                this.addedProducts = await this.nidavellirService.addToCart(productsToBeAdd);
            }

            this.addedProducts = [productsToBeAdd];
            this.modalRef.triggerOk();
        } catch (err) {
            if (err.status !== 500) {
                this.messageService.error(err.body.error);
            } else {
                this.messageService.error();
            }
        } finally {
            this.submitting = false;
        }
    }
}
