<section>
    <div class="fs-20 fw-bold">Update Plan</div>

    <div class="fs-14 mt-30">
        <div class="mt-20">
            <span class="fw-bold">Current plan: </span>
            <span>
                <span><{{shopService.shopProfile?.subscription.sub_plan_id | titlecase}}> </span>
                <span>{{getFeeByPlanName(shopService.shopProfile?.subscription.sub_plan_id) | currency}}</span>
                <span>/month</span>
            </span>
        </div>
        <div class="mt-20">
            <span class="fw-bold">Upcoming plan: </span>
            <span><{{selectedPlan.plan_text | titlecase}}> {{selectedPlan.monthly_fee | currency}}/month</span>
        </div>
        <div class="mt-20">
            <span class="fw-bold">Effective date: </span>
            <span>{{renewDate}}</span>
        </div>
    </div>

    <div class="width-100p d-flex justify-content-between align-items-center mt-30">
        <button style="width: 200px" nz-button [disabled]="loading" (click)="modalRef.triggerCancel()">Cancel</button>
        <button style="width: 200px" nz-button nzType="primary" [nzLoading]="loading" (click)="confirmDownGrade()">Confirm</button>
    </div>
</section>
