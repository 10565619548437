import { OrderProductModalTabComponent } from './order-product-modal-tab/order-product-modal-tab.component';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { IStandardProductDetails, NidavellirService, ProductInCart } from '@modules/nidavellir/nidavellir.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MessageService } from '@services/message.service';

@Component({
    selector: 'app-order-product-modal',
    templateUrl: './order-product-modal.component.html',
    styleUrls: ['./order-product-modal.component.less'],
})
export class OrderProductModalComponent implements OnInit {
    @Input() productId: string;
    @ViewChild('sampleTabComp') sampleTabComp: OrderProductModalTabComponent;
    @ViewChild('massTabComp') massTabComp: OrderProductModalTabComponent;

    productDetail: IStandardProductDetails;
    submitting = false;
    loading = false;

    addedProducts: any[];

    tabIndex = 0;

    get addToCartDisabled() {
        if (this.tabIndex === 0) {
            if (this.isAccountManagerView) {
                return Array.from(this.sampleTabComp?.variantAmountMap.values() || []).every(amount => amount <= 0);
            } else {
                return this.sampleTabComp?.sampleOrderSelectedVariants.size === 0;
            }
        }

        if (this.tabIndex === 1) {
            return Array.from(this.massTabComp?.variantAmountMap.values() || []).every(amount => amount <= 0);
        }
    }

    get isAccountManagerView() {
        return this.authService.isAccountManagerSignIn;
    }

    constructor(
        private nidavellirService: NidavellirService,
        private authService: AuthService,
        private modalRef: NzModalRef,
        private messageService: MessageService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.getProductDetailById();
    }

    getProductDetailById() {
        this.loading = true;
        this.nidavellirService
            .getProductDetailByProductId(this.productId)
            .then(res => (this.productDetail = res))
            .catch(() => this.messageService.error())
            .finally(() => (this.loading = false));
    }

    ok() {
        this.modalRef.triggerOk();
    }

    cancel() {
        this.modalRef.triggerCancel();
    }

    addToCart() {
        this.submitting = true;

        let variants;
        let variantAmountMap;
        if (this.tabIndex === 0) {
            variants = this.sampleTabComp.generateVariants();
            variantAmountMap = this.sampleTabComp.variantAmountMap;
        }
        if (this.tabIndex === 1) {
            variants = this.massTabComp.generateVariants();
            variantAmountMap = this.massTabComp.variantAmountMap;
        }

        const data = {
            product_id: this.productDetail.id,
            variants: variants.map(variant => ({
                id: variant.id,
                amount: variantAmountMap.get(variant) || 1,
                price: null,
            })),
            packaging: [],
        };
        const productsToBeAdd: ProductInCart = {
            product_id: this.productDetail.id,
            image_url: this.productDetail.images[0].src,
            title: this.productDetail.product_name,
            est_ordering_days: this.productDetail.est_ordering_days,
            type: 'standard_product',
            is_sample: this.tabIndex === 0,
            variants: variants.map(item => ({
                variant_id: item.id,
                quantity: variantAmountMap.get(item) || 1,
                unit_cost: item.unit_cost,
                option: item.title,
            })),
            packaging: data.packaging,
            data,
        };

        return this.nidavellirService
            .addToCart(productsToBeAdd)
            .then(() => {
                this.addedProducts = [productsToBeAdd];
                this.modalRef.triggerOk();
            })
            .catch(err => {
                if (err.status !== 500) {
                    this.messageService.error(err.body.error);
                } else {
                    this.messageService.error();
                }
            })
            .finally(() => (this.submitting = false));
    }
}
