import { Component, Input, OnInit } from '@angular/core';
import { ReportItem } from '../../home.service';

@Component({
    selector: 'app-revenue-detail-popup',
    templateUrl: './revenue-detail-popup.component.html',
    styleUrls: ['./revenue-detail-popup.component.less'],
})
export class RevenueDetailPopupComponent implements OnInit {
    @Input() revenueDetail: ReportItem;

    constructor() {}

    ngOnInit(): void {}
}
