import { FirebaseEventsService, CUSTOM_ANALYTICS_EVENT } from '@services/firebase-event.service';
import { PaypalService } from '@services/paypal.service';
import { AdvancedCustomization } from '@modules/nidavellir/nidavellir.type';
import { ShopService } from '@services/shop.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StripeService, StripeCardComponent } from 'ngx-stripe';

import { AddressFormComponent } from './address-form/address-form.component';
import { KlarnaModalComponent, KlarnaInitInfo } from './klarna-modal/klarna-modal.component';
import { GeneralDialogComponent } from '@shared/components/general-dialog/general-dialog.component';
import { PaymentSuccessfulComponent, PaymentResultEnum } from './payment-successful/payment-successful.component';
import { HomeService } from '@modules/home/home.service';
import { ShoppingCartService, PaymentMethodEnum } from './shopping-cart.service';
import { AccountBalance } from '@typings/earnings.typings';
import { NidavellirService, ProductInCart, ProductInCartFromAPI } from '@modules/nidavellir/nidavellir.service';
import { PlaidService } from '@services/plaid.service';
import { ProfileService } from '@services/profile.service';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { LoadingService } from '@services/loading.service';
import { AuthService } from '@services/auth.service';
import { MessageService } from '@services/message.service';
import { SubscribePlanNameEnum } from '../subscription/subscription.data';
import { ShowModalService } from '@services/show-modal.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { getObjectURL } from '../../utils/draw';
import { parsePhoneNumber } from 'libphonenumber-js';

enum PaymentErrorEnum {
    INVALID_SHOPPING_CART = 'Invalid shopping cart',
    INACTIVE_ITEMS_IN_CART = 'Inactive item(s) in shopping cart',
    INVALID_PAYMENT_AMOUNT = 'Invalid payment amount',
}

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.less'],
})
export class ShoppingCartComponent implements OnInit, AfterViewInit {
    @ViewChild(StripeCardComponent) card: StripeCardComponent;
    @ViewChild('addressFormTpl') addressFormTpl: AddressFormComponent;

    @Input() paymentRequestItem: any;
    @Input() pageType: 'shoppingCart' | 'requestOrderPayment' | 'orders' = 'shoppingCart';
    @Output() paymentSuccess = new EventEmitter();

    orderDetail: any;

    PaymentMethodEnum = PaymentMethodEnum;
    OPERATION_FEE_PER_PIECE = 1;
    products: ProductInCartFromAPI[];
    paymentStatusMap = new Map([
        ['paid', 'Paid'],
        ['pending', 'Pending'],
        ['unpaid', 'Unpaid'],
    ]);

    accountBalance: AccountBalance;
    subscription = new Subscription();

    amount = {
        subTotal: null,
        packageOperationFee: null,
        creditUsedAmount: null,
        tax: null,
        balanceUsedAmount: null,
        total: null,
        shipping: null,
    };
    discount = 0;

    selectedPaymentMethod: PaymentMethodEnum = PaymentMethodEnum.CREDIT_CARD;
    connectedBankName: string;

    stripeInfo = {
        firstName: '',
        lastName: '',
        zipCode: '',
    };
    klarnaInitInfo: KlarnaInitInfo;

    addNewProductInfo = {
        productName: null,
        quantity: null,
        unit_cost: 0,
        is_sample: null,
        instruction: null,
    };
    uploadingImageSet = new Set<{ name: string; src: string; path: string }>();
    imagesOfNewAddedProduct: { name: string; src: string; path: string }[] = [];

    addressInfo: {
        first_name: string;
        last_name: string;
        address_line_1: string;
        address_line_2: string;
        city: string;
        state: string;
        country: string;
        zip: string;
        phone_number: string;
    };

    // Some show/hidden flag control
    viewStatusControl = {
        isFetchDataFailed: false,
        useCreditPay: false,
        useBalancePay: false,
        connectingBank: false,
        checkingOut: false,
        skipPaymentCheckingOut: false,
        isCreditCartInputCompleted: false,
        klarnaInitialized: false,
        showKlarnaForm: true,
        editingAddress: false,
        submittingAddressInfo: false,
        showAddNewProduct: false,
        addingNewProduct: false,
        loadingCheckDetailData: false,
        discountInputVisible: false,
    };
    errorMessage = '';

    paymentMethodEnum = [];
    paidAt: string;
    currentCart = 'user';
    actualOrderCreate = false;

    requireOnlinePictureProducts = new Set<ProductInCartFromAPI>();

    usedPaymentMethods: any[];

    paypalCheckoutId: number;

    get checkoutId() {
        return this.paymentRequestItem?.id;
    }

    get isRequestOrderView() {
        return this.pageType === 'requestOrderPayment';
    }
    get isShoppingCartView() {
        return this.pageType === 'shoppingCart';
    }
    get isOrderDetailView() {
        return this.pageType === 'orders';
    }
    get isPaidOrderDetailView() {
        const isPaidOrder = this.orderDetail?.payment_status && this.orderDetail.payment_status !== 'unpaid';
        return this.isOrderDetailView && isPaidOrder;
    }
    get isUnPaidOrderDetailView() {
        const isPaidOrder = this.orderDetail?.payment_status && this.orderDetail.payment_status !== 'unpaid';
        return this.isOrderDetailView && !isPaidOrder;
    }

    get isAccountManagerView() {
        return this.authService.isAccountManagerSignIn;
    }

    get disableAddNewProductBtn() {
        return (
            !this.addNewProductInfo.productName ||
            !this.addNewProductInfo.quantity ||
            !(this.addNewProductInfo.unit_cost >= 0) ||
            this.addNewProductInfo.is_sample === null ||
            this.uploadingImageSet.size > 0 ||
            this.imagesOfNewAddedProduct.length === 0
        );
    }

    get sampleProducts() {
        return this.products?.filter(item => item.cart_json.is_sample);
    }

    get otherProducts() {
        return this.products?.filter(item => !item.cart_json.is_sample);
    }

    get elementsOptions() {
        return this.shoppingCartService.elementsOptions;
    }

    get cardOptions() {
        return this.shoppingCartService.cardOptions;
    }

    get checkoutDisabled() {
        if (this.sampleProducts.length > 0 && this.profileService.isProfileInValid(this.addressInfo)) {
            return true;
        }

        if (!this.products?.length) {
            return true;
        }

        if (this.amount.total <= 0) {
            return false;
        }

        const generalInValid =
            this.viewStatusControl.isFetchDataFailed ||
            this.viewStatusControl.submittingAddressInfo ||
            !this.profileService.currentProfile ||
            !this.products?.length;

        if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
            return generalInValid || !this.connectedBankName;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
            return (
                generalInValid ||
                !this.stripeInfo.firstName ||
                !this.stripeInfo.lastName ||
                !this.stripeInfo.zipCode ||
                !this.viewStatusControl.isCreditCartInputCompleted
            );
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.KLARNA) {
            return generalInValid;
        }
    }

    get disableCreateOrderBtn() {
        return !this.products.length || this.viewStatusControl.discountInputVisible;
    }

    get address() {
        const arr = ['address_line_1', 'address_line_2', 'city', 'state', 'country', 'zip'];
        const profile = this.addressInfo || {};

        return arr
            .filter(key => profile[key])
            .map(key => profile[key])
            .join(', ');
    }

    get isFreePlan() {
        return this.shopService.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER;
    }

    get availableLifoCreditRate() {
        return this.shopService.shopProfile.available_credit_rate || 0.4;
    }

    get paypalConfig() {
        return this.shoppingCartService.paypalConfig;
    }

    constructor(
        public paypalService: PaypalService,
        public shoppingCartService: ShoppingCartService,
        public profileService: ProfileService,
        public authService: AuthService,
        private modalService: NzModalService,
        private homeService: HomeService,
        private nidavellirService: NidavellirService,
        private plaidService: PlaidService,
        private stripeService: StripeService,
        private activatedRoute: ActivatedRoute,
        private loadingService: LoadingService,
        private router: Router,
        private message: MessageService,
        private shopService: ShopService,
        private showModalService: ShowModalService,
        private firebaseUpload: FirebaseFileUploadService,
        private firebaseEventService: FirebaseEventsService
    ) {}

    ngOnInit(): void {
        if (this.checkoutId) {
            this.getCheckoutAndBalance();
        } else {
            const checkoutId = this.activatedRoute.snapshot.queryParamMap.get('checkout_id');
            if (checkoutId) {
                this.completeCheckout(Number(checkoutId));
            } else {
                this.currentCart = this.activatedRoute.snapshot.queryParamMap.get('currentCart') || 'user';
                this.getProductsAndBalance(this.currentCart === 'creator' ? this.shopService.shopProfile.user_id : '');
                this.profileService.currentProfileSubject.pipe(filter(value => !!value)).subscribe(info => this.setAddressInfo(info));
            }
        }

        this.getSelectedBankAccount();

        if (this.pageType === 'shoppingCart') {
            this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_SHOPPING_CART);
        }
    }

    ngAfterViewInit() {
        this.shoppingCartService.initConfig(this.onApprove, this.checkoutWithPaypal, this.paymentFailedErrorHandler, this.onCancel);
    }

    setAddressInfo(currentProfile) {
        this.addressInfo = {
            first_name: currentProfile.first_name,
            last_name: currentProfile.last_name,
            address_line_1: currentProfile.address_line_1,
            address_line_2: currentProfile.address_line_2,
            city: currentProfile.city,
            state: currentProfile.state,
            country: currentProfile.country,
            zip: currentProfile.zip,
            phone_number: currentProfile.phone_number,
        };
    }

    completeCheckout(checkoutId: number) {
        this.loadingService.showLoading();
        this.nidavellirService
            .completeCheckout(checkoutId)
            .then(() => this.showPaymentResultModal(PaymentResultEnum.SUCCESS))
            .catch(() => this.showPaymentResultModal(PaymentResultEnum.FAILED))
            .finally(() => {
                this.router.navigateByUrl('/nidavellir/shopping-cart');
                this.loadingService.hideLoading();
            });
    }

    cardInputChange(e: StripeCardElementChangeEvent) {
        this.viewStatusControl.isCreditCartInputCompleted = e.complete;
    }

    removeProduct(id: number) {
        this.modalService.create({
            nzContent: GeneralDialogComponent,
            nzFooter: null,
            nzClassName: 'no-padding no-bg',
            nzComponentParams: {
                message: 'Remove this product?',
                confirmText: 'Remove',
                cancelText: 'Cancel',
            },
            nzOnOk: comp => {
                comp.submitting = true;

                return this.nidavellirService
                    .removeFromCart({ id, uid: this.currentCart === 'creator' ? this.shopService.shopProfile.user_id : '' })
                    .then(() => {
                        this.products = this.products.filter(item => item.id !== id);
                        this.calculateAmount();
                    });
            },
        });
    }

    getProductsAndBalance(uid = '') {
        return Promise.allSettled([this.getAccountBalance(), this.getProductsInCart(uid)]).then(() => this.calculateAmount());
    }

    getCheckoutAndBalance() {
        return Promise.allSettled([this.getProductsByCheckoutId(), this.getAccountBalance()]).then(() => {
            if (this.isOrderDetailView) {
                this.showAmount();
            } else {
                this.calculateAmount();
            }
        });
    }

    getProductsInCart(uid = '') {
        this.loadingService.showLoading();
        this.viewStatusControl.isFetchDataFailed = false;

        return this.nidavellirService
            .getProductsInCart(uid)
            .then(data => (this.products = data))
            .catch(() => {
                this.viewStatusControl.isFetchDataFailed = true;
                this.message.error('Get data failed, please try again later');
            })
            .finally(() => this.loadingService.hideLoading());
    }

    getAccountBalance() {
        return this.homeService
            .getAccountBalance()
            .then(data => (this.accountBalance = data))
            .catch(() => this.message.error('Get account balance failed, please try again later.'));
    }

    getProductsByCheckoutId() {
        this.viewStatusControl.loadingCheckDetailData = true;
        return this.nidavellirService
            .getProductsByCheckoutId(this.checkoutId)
            .then((res: any) => {
                this.orderDetail = res;
                this.products = res.shopping_cart;
                this.discount = res.discount;
                this.setAddressInfo(res.address_detail);
                this.paymentMethodEnum = [];
                res.payment_method?.forEach(item => {
                    if (item.method === 'Credit Card' || item.method === 'Bank Account') {
                        this.paymentMethodEnum.push(item);
                    }
                    if (item.method === 'Lifo Credit') {
                        this.amount.creditUsedAmount = item.amount;
                    }
                    if (item.method === 'Balance') {
                        this.amount.balanceUsedAmount = item.amount;
                    }
                });
                this.paidAt = res.paid_at;
            })
            .finally(() => (this.viewStatusControl.loadingCheckDetailData = false));
    }

    calculateSingleProductAmount(product: ProductInCart) {
        let productAmount = 0;
        let quantity = 0;
        product.variants.forEach(variantItem => {
            productAmount += variantItem.quantity * variantItem.unit_cost;
            quantity += variantItem.quantity;
        });

        return { quantity, productAmount };
    }

    getSelectedBankAccount() {
        return this.plaidService.getPaymentMethod().then(data => {
            if (data.bank_account) {
                this.connectedBankName = `${data.bank_account.account.name} ******* ${data.bank_account.account.mask}`;
            } else {
                this.connectedBankName = null;
            }
        });
    }

    selectPaymentMethod(paymentMethod: PaymentMethodEnum) {
        /*
            this.connectedBankName === undefined
            Means we are fetching bank info
        */
        if (paymentMethod === PaymentMethodEnum.BANK && this.connectedBankName === undefined) {
            return;
        }

        this.selectedPaymentMethod = paymentMethod;
    }

    zipCodeChange() {
        this.card.update({ value: { postalCode: this.stripeInfo.zipCode } });
    }

    calculateAmount() {
        const data = {
            lifo_credit: this.accountBalance.lifo_credit,
            account_balance: this.accountBalance.account_balance,
            discount: this.discount,
        };

        const { amount, discount } = this.shoppingCartService.calculateAmount(
            this.products,
            this.viewStatusControl,
            this.isAccountManagerView ? 'internal' : 'creator',
            data
        );
        this.discount = Number(discount);
        this.amount = amount;
    }

    showAmount() {
        const { subTotal, packageOperationFee, shipping } = this.shoppingCartService.calculateSubtotalShippingAndOperationFee(
            this.products
        );
        const tempTotal = subTotal + packageOperationFee + shipping;
        let total = tempTotal - this.discount;
        if (this.amount.creditUsedAmount) {
            total = total - this.amount.creditUsedAmount;
            this.viewStatusControl.useCreditPay = true;
        }
        if (this.amount.balanceUsedAmount) {
            total = total - this.amount.balanceUsedAmount;
            this.viewStatusControl.useBalancePay = true;
        }
        this.amount.subTotal = subTotal.toFixed(2);
        this.amount.packageOperationFee = packageOperationFee.toFixed(2);
        this.amount.shipping = shipping.toFixed(2);
        this.amount.total = total.toFixed(2);
    }

    async connectPlaid() {
        this.viewStatusControl.connectingBank = true;

        try {
            const linkToken = await this.plaidService.fetchLinkToken();
            const publicToken = await this.plaidService.getPublicToken(linkToken.link_token);
            if (publicToken) {
                await this.plaidService.exchangePublicToken(publicToken);
                this.profileService.currentProfile = await this.profileService.getCurrentProfile();
                await this.getSelectedBankAccount();
            }
            this.viewStatusControl.connectingBank = false;
        } catch (e) {
            this.viewStatusControl.connectingBank = false;
        }
    }

    // Paypal Start
    onApprove = () => {
        this.nidavellirService
            .completeCheckout(this.paypalCheckoutId)
            .then(() => {
                this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.PURCHASE);
                this.showPaymentResultModal(PaymentResultEnum.SUCCESS);
            })
            .catch(() => this.message.error())
            .finally(() => (this.viewStatusControl.checkingOut = false));
    };

    onCancel = () => (this.viewStatusControl.checkingOut = false);

    checkoutWithPaypal = async () => {
        if (this.verifyIfCheckoutInValid()) {
            return Promise.reject();
        }

        this.viewStatusControl.checkingOut = true;

        const data = this.getCheckoutData(true, false);

        data.payment_method.push({
            method: PaymentMethodEnum.PAYPAL,
            amount: Number(this.amount.total),
        });

        return this.nidavellirService.startCheckout(data).then(res => {
            this.usedPaymentMethods = data.payment_method;
            this.paypalCheckoutId = res.checkout_id;
            return res.id;
        });
    };
    // PayPal End

    getCheckoutData(needPaymentRequest: boolean, allow_inactive_item: boolean) {
        const data: any = {
            shopping_cart: this.products.map(item => {
                if (this.requireOnlinePictureProducts.has(item)) {
                    item.cart_json.photo_shoot_required = true;
                }

                return item;
            }),
            payment_method: [],
            skip_payment: this.authService.isAccountManagerSignIn ? needPaymentRequest : false,
            cart_uid: this.authService.isAccountManagerSignIn && this.currentCart === 'creator' ? this.shopService.shopProfile.user_id : '',
            address_detail: this.addressInfo,
            shipping: Number(this.amount.shipping),
            discount: this.discount,
            allow_inactive_item,
            create_order_beforehand: this.actualOrderCreate,
        };

        if (this.checkoutId) {
            data.checkout_id = this.checkoutId;
        }

        if (Math.abs(this.amount.balanceUsedAmount) > 0) {
            data.payment_method.push({
                method: PaymentMethodEnum.BALANCE,
                amount: Math.abs(this.amount.balanceUsedAmount),
            });
        }

        if (Math.abs(this.amount.creditUsedAmount) > 0) {
            data.payment_method.push({
                method: PaymentMethodEnum.LIFO_CREDIT,
                amount: Math.abs(this.amount.creditUsedAmount),
            });
        }

        return data;
    }

    async checkout(needPaymentRequest = true, allow_inactive_item = false) {
        if (this.verifyIfCheckoutInValid()) {
            return;
        }

        if (!needPaymentRequest) {
            this.viewStatusControl.skipPaymentCheckingOut = true;
        } else {
            this.viewStatusControl.checkingOut = true;
        }

        const data = this.getCheckoutData(needPaymentRequest, allow_inactive_item);

        if (this.amount.total > 0 && !this.authService.isAccountManagerSignIn) {
            data.payment_method.push({
                method: this.selectedPaymentMethod,
                amount: Number(this.amount.total),
            });

            try {
                const pi = await this.nidavellirService.startCheckout(data);

                if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
                    this.payWithBank(pi);
                }

                if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
                    this.payWithCredit(pi);
                }
            } catch (err) {
                this.paymentFailedErrorHandler(err);

                if (!needPaymentRequest) {
                    this.viewStatusControl.skipPaymentCheckingOut = false;
                } else {
                    this.viewStatusControl.checkingOut = false;
                }
            }
        } else {
            // Only user balance & lifo_credit
            this.nidavellirService
                .startCheckout(data)
                .then(() => {
                    this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.PURCHASE);

                    return this.showPaymentResultModal(
                        this.isAccountManagerView ? PaymentResultEnum.CREATE_ORDER_SUCCESS : PaymentResultEnum.SUCCESS
                    );
                })
                .catch(err => this.paymentFailedErrorHandler(err))
                .finally(() => {
                    if (!needPaymentRequest) {
                        this.viewStatusControl.skipPaymentCheckingOut = false;
                    } else {
                        this.viewStatusControl.checkingOut = false;
                    }
                });
        }

        this.usedPaymentMethods = data.payment_method;
    }

    paymentFailedErrorHandler = err => {
        if (err.body?.error === PaymentErrorEnum.INVALID_PAYMENT_AMOUNT) {
            return this.message.error(PaymentErrorEnum.INVALID_PAYMENT_AMOUNT);
        }

        if (err.body?.error === PaymentErrorEnum.INACTIVE_ITEMS_IN_CART) {
            return this.showPaymentFailedDueInactiveItems();
        }

        if (err.body?.error === PaymentErrorEnum.INVALID_SHOPPING_CART) {
            return this.message.error(PaymentErrorEnum.INVALID_SHOPPING_CART);
        }

        this.viewStatusControl.checkingOut = false;

        return this.message.error(typeof err.message === 'string' ? err.message : null, { nzDuration: 5000 });
    };

    payWithBank(data: { status: string }) {
        this.viewStatusControl.checkingOut = false;
        if (data.status === 'PENDING') {
            this.showPaymentResultModal(PaymentResultEnum.PENDING);
        } else {
            this.showPaymentResultModal(PaymentResultEnum.SUCCESS);
        }

        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.PURCHASE);
    }

    async payWithCredit(pi: { client_secret: string; checkout_id: number }) {
        this.stripeService
            .confirmCardPayment(pi.client_secret, {
                payment_method: {
                    card: this.card.element,
                    billing_details: {
                        name: `${this.stripeInfo.firstName} ${this.stripeInfo.lastName}`,
                    },
                },
            })
            .subscribe(
                result => {
                    if (result?.paymentIntent?.status === 'succeeded') {
                        // Completed internal checkout process
                        this.nidavellirService
                            .completeCheckout(pi.checkout_id)
                            .then(() => {
                                this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.PURCHASE);
                                this.showPaymentResultModal(PaymentResultEnum.SUCCESS);
                            })
                            .catch(() => this.message.error())
                            .finally(() => (this.viewStatusControl.checkingOut = false));
                    } else {
                        // Stripe confirm failed with error message
                        this.viewStatusControl.checkingOut = false;
                        this.message.error(result.error.message);
                    }
                },
                // Strip confirm failed with no reason
                () => (this.viewStatusControl.checkingOut = false)
            );
    }

    saveNewAddress() {
        if (this.addressFormTpl.addressForm.invalid) {
            this.addressFormTpl.saveBtnActivated = true;
            return false;
        }

        const {
            address_line_1,
            address_line_2,
            city,
            country,
            last_name,
            first_name,
            phone_number,
            state,
            zip,
        } = this.addressFormTpl.addressForm.getRawValue();

        const data = {
            first_name,
            address_line_1,
            address_line_2,
            last_name,
            city,
            country,
            state,
            zip,
            phone_number,
        };

        if (this.addressFormTpl.isSetAsDefault) {
            this.viewStatusControl.submittingAddressInfo = true;
            this.profileService
                .updateCurrentProfile(data)
                .then(res => {
                    this.profileService.currentProfile = res;
                    this.viewStatusControl.editingAddress = false;
                    this.message.success('Save address success!');
                })
                .catch(() => this.message.error())
                .finally(() => (this.viewStatusControl.submittingAddressInfo = false));
        } else {
            this.setAddressInfo(data);
            this.viewStatusControl.editingAddress = false;
        }
    }

    addToCart() {
        this.viewStatusControl.addingNewProduct = true;
        const body: ProductInCart = {
            product_id: null,
            image_url: this.imagesOfNewAddedProduct[0].src,
            packaging: null,
            data: {
                images: this.imagesOfNewAddedProduct,
                logo_images: [],
                options: [],
                customization_detail: {},
                description: this.addNewProductInfo.instruction,
            } as AdvancedCustomization,
            title: this.addNewProductInfo.productName,
            est_ordering_days: 15, // This is actually for new product
            type: 'advanced_customization_product',
            is_sample: this.addNewProductInfo.is_sample,
            variants: [
                {
                    variant_id: null,
                    quantity: this.addNewProductInfo.quantity,
                    unit_cost: this.addNewProductInfo.unit_cost,
                    option: '--',
                },
            ],
        };

        this.nidavellirService
            .addToCart(body)
            .then(() => {
                this.getProductsAndBalance();
                this.viewStatusControl.showAddNewProduct = false;
                this.addNewProductInfo = {
                    productName: null,
                    quantity: null,
                    unit_cost: null,
                    is_sample: null,
                    instruction: null,
                };
                this.imagesOfNewAddedProduct.length = 0;
            })
            .catch(() => this.message.error())
            .finally(() => (this.viewStatusControl.addingNewProduct = false));
    }

    closeModal() {
        this.modalService.closeAll();
    }

    saveDiscountAmount(amount: any) {
        this.discount = Number(amount);
        this.viewStatusControl.discountInputVisible = false;
        this.calculateAmount();
    }

    getShippedByFulfillmentStatus(status) {
        if (status === 'partially_fulfilled') {
            return {
                name: 'Shipped',
                src: '/assets/imgs/shipped_icon.png',
            };
        } else if (status === 'fulfilled') {
            return {
                name: 'Delivered',
                src: '/assets/imgs/delivered_icon.png',
            };
        } else {
            return {
                name: 'To Be Shipped',
                src: '/assets/imgs/to_be_shipped_icon.png',
            };
        }
    }

    upgradePlan() {
        this.showModalService.showSubscriptionModal();
    }

    switchCart() {
        if (this.currentCart === 'user') {
            const uid = this.shopService.shopProfile.user_id;
            this.getProductsAndBalance(uid);
            this.currentCart = 'creator';
        } else {
            this.getProductsAndBalance();
            this.currentCart = 'user';
        }
        this.router.navigate(['/', 'nidavellir', 'shopping-cart'], {
            queryParams: {
                currentCart: this.currentCart,
            },
            replaceUrl: true,
        });
    }

    removeImage(imageItem: any) {
        this.imagesOfNewAddedProduct = this.imagesOfNewAddedProduct.filter(item => item !== imageItem);
    }

    beforeUpload = (file: any) => {
        this.onDrop(file);
        return false;
    };

    onDrop(file: File) {
        const { path, uploadTask } = this.firebaseUpload.startUpload(file, 'public/advanced-customization');
        const imageObj: { path: string; src: string; name: string } = {
            path,
            src: getObjectURL(file),
            name: file.name,
        };
        this.uploadingImageSet.add(imageObj);
        this.imagesOfNewAddedProduct.push(imageObj);
        uploadTask
            .then(downloadUrl => (imageObj.src = downloadUrl))
            .catch(() => this.message.error('Upload file failed, please try again later'))
            .finally(() => this.uploadingImageSet.delete(imageObj));
    }

    requireOnlinePictureProductChange(product: ProductInCartFromAPI) {
        if (this.requireOnlinePictureProducts.has(product)) {
            this.requireOnlinePictureProducts.delete(product);
        } else {
            this.requireOnlinePictureProducts.add(product);
        }
    }

    shouldShowRequireOnlinePictureForm(product: ProductInCartFromAPI) {
        return product.cart_json.is_sample;
    }

    goAdvancedCustomEditPage(product: ProductInCartFromAPI) {
        if (product.cart_json.type === 'advanced_customization_product' || product.cart_json.type === 'pod_product') {
            this.router.navigate(['/', 'nidavellir', 'advanced-customization', product.id], {
                queryParams: { type: 'cart_edit', currentCart: this.currentCart },
            });
        }
    }

    private showPaymentResultModal(type: PaymentResultEnum) {
        this.modalService.create({
            nzContent: PaymentSuccessfulComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWrapClassName: 'vertical-center-modal',
            nzComponentParams: { type },
            nzOnOk: () => {
                // Success
                if (this.isRequestOrderView) {
                    this.paymentSuccess.emit();
                }
                if (this.isShoppingCartView) {
                    switch (type) {
                        case PaymentResultEnum.SUCCESS:
                        case PaymentResultEnum.PENDING:
                            this.router.navigate(['/', 'my-purchase', 'orders']);
                            break;
                        default:
                            if (this.authService.isAccountManagerSignIn && this.currentCart === 'creator') {
                                this.getProductsAndBalance(this.shopService.shopProfile.user_id);
                            } else {
                                this.getProductsAndBalance();
                            }
                            break;
                    }
                }
                if (this.isOrderDetailView) {
                    this.getCheckoutAndBalance();
                }
            },
            nzOnCancel: () => {
                // Failed
                if (this.isRequestOrderView || this.isOrderDetailView) {
                    this.getCheckoutAndBalance();
                }
                if (this.isShoppingCartView) {
                    if (this.authService.isAccountManagerSignIn && this.currentCart === 'creator') {
                        this.getProductsAndBalance(this.shopService.shopProfile.user_id);
                    } else {
                        this.getProductsAndBalance();
                    }
                }
            },
        });
    }

    private showPaymentFailedDueInactiveItems() {
        this.modalService.create({
            nzContent: PaymentSuccessfulComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzWrapClassName: 'vertical-center-modal',
            nzComponentParams: { type: PaymentResultEnum.INACTIVE_ITEMS },
            nzOnOk: comp => {
                comp.loading = true;

                return new Promise(async (resolve, reject) => {
                    try {
                        await this.checkout(true, true);
                        comp.loading = false;
                        resolve();
                    } catch (err) {
                        comp.loading = false;
                        reject();
                    }
                });
            },
        });
    }

    private showKlarnaModal() {
        this.modalService.create({
            nzContent: KlarnaModalComponent,
            nzWrapClassName: 'vertical-center-modal',
            nzComponentParams: { klarnaData: this.klarnaInitInfo },
            nzFooter: null,
        });
    }

    private verifyIfCheckoutInValid() {
        if (this.sampleProducts.length > 0 && this.profileService.isProfileInValid(this.addressInfo)) {
            this.message.error('A valid shipping address is required for sample order');
            return true;
        }

        if (this.viewStatusControl.editingAddress) {
            this.message.error('You are editing shipping address');
            return true;
        }

        if (!this.products?.length) {
            this.message.error('Your shopping cart is empty!');
            return true;
        }

        if (this.amount.total <= 0) {
            return false;
        }

        const generalInValid =
            this.viewStatusControl.isFetchDataFailed ||
            this.viewStatusControl.submittingAddressInfo ||
            !this.profileService.currentProfile ||
            !this.products?.length;

        if (generalInValid) {
            this.message.error();
            return generalInValid;
        }

        if (this.isAccountManagerView) {
            return false;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.BANK) {
            const isInvalid = generalInValid || !this.connectedBankName;
            if (isInvalid) {
                this.message.error('Please connect your bank account');
            }
            return isInvalid;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
            const isInvalid =
                generalInValid ||
                !this.stripeInfo.firstName ||
                !this.stripeInfo.lastName ||
                !this.stripeInfo.zipCode ||
                !this.viewStatusControl.isCreditCartInputCompleted;

            if (isInvalid) {
                this.message.error('Please fill in your credit card information');
            }

            return isInvalid;
        }

        if (this.selectedPaymentMethod === PaymentMethodEnum.KLARNA) {
            return generalInValid;
        }
    }
}
