import { echarts, EchartsOption } from '@shared/directives/echarts.directive';

export const initialVisitsChartOption: EchartsOption = {
    xAxis: {
        show: false,
        type: 'category',
        data: null,
        boundaryGap: false,
    },
    yAxis: {
        show: false,
        type: 'value',
    },
    grid: {
        left: 4,
        right: 4,
        top: 4,
        bottom: 4,
    },
    series: [
        {
            data: null,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
                color: '#F3745E',
            },

            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgb(250,227,223)',
                    },
                    {
                        offset: 0.4,
                        color: 'rgb(254,240,240)',
                    },
                    {
                        offset: 1,
                        color: '#fff',
                    },
                ]),
            },
        },
    ],
};

export const initialSalesChartOption: EchartsOption = {
    tooltip: {
        type: 'item',
        trigger: 'axis',
        formatter: null,
        axisPointer: {
            snap: true,
            type: 'line',
            lineStyle: {
                color: '#f3745e',
                type: 'solid',
                width: 2,
            },
        },
    },
    xAxis: {
        show: true,
        type: 'category',
        data: null,
        boundaryGap: false,
        axisTick: {
            alignWithLabel: true,
            inside: true,
            length: 100,
            lineStyle: {
                color: 'rgba(85, 71, 100, 0.2)',
                type: 'dashed',
            },
        },
        axisLabel: {
            fontFamily: 'Ubuntu',
            color: '#9F99A3',
            fontSize: 10,
        },
    },
    yAxis: {
        show: false,
        type: 'value',
    },
    grid: {
        left: 0,
        right: 20,
        top: 4,
        bottom: 4,
        containLabel: true,
    },
    series: [
        {
            data: null,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
                color: '#F3745E',
            },

            itemStyle: {
                color: '#F3745E',
            },

            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgb(139, 60, 139)',
                    },
                    {
                        offset: 0.5,
                        color: 'rgba(243, 116, 94, 0.5)',
                    },
                    {
                        offset: 1,
                        color: '#fff',
                    },
                ]),
            },
        },
    ],
};
