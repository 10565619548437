import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit, Input } from '@angular/core';
import { StorefrontStateService, TextEditableEnum } from '../storefront-state.service';
import { StorefrontService } from '../storefront.service';

@Component({
    selector: 'app-recommended-products',
    templateUrl: './recommended-products.component.html',
    styleUrls: ['./recommended-products.component.less'],
})
export class RecommendedProductsComponent implements OnInit {
    @Input() shopDetail: ShopProfile;
    @Input() pageMode: 'view' | 'edit';
    @Input() siteView: 'desktop' | 'mobile';

    TextEditableEnum = TextEditableEnum;

    mobileRecommendedLayouts: any[] = ['one_product', 'swiper_product', 'four_product_grid'];

    layoutNameMap = {
        swiper_product: 'Waterfall',
        four_product_grid: 'Grid Style',
        one_product: 'Single Product',
    };

    get productList() {
        return this.storefrontStateService.productList;
    }

    get selectedProducts() {
        return this.storefrontStateService.currentSelectedProducts?.length
            ? this.storefrontStateService.currentSelectedProducts
            : this.storefrontService.defaultProducts;
    }

    get firstSelectedProduct() {
        return this.selectedProducts && this.selectedProducts[0];
    }

    get originalShopDetail() {
        return this.storefrontStateService.shopDetail;
    }

    get currentEditingTextSection() {
        return this.storefrontStateService.currentEditingTextSection;
    }
    get editableTextSectionDataMap() {
        return this.storefrontStateService.editableTextSectionDataMap;
    }
    get currentEditingTextSectionData() {
        return this.storefrontStateService.currentEditingTextSectionData;
    }

    constructor(private storefrontService: StorefrontService, public storefrontStateService: StorefrontStateService) {}

    ngOnInit(): void {}
}
