import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

export interface ITransactionsList {
    endRow: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    isFirstPage: boolean;
    isLastPage: boolean;
    list: TransactionItem[];
    navigateFirstPage: number;
    navigateLastPage: number;
    navigatePages: number;
    navigatepageNums: number[];
    nextPage: number;
    pageNum: number;
    pageSize: number;
    pages: number;
    prePage: number;
    size: number;
    startRow: number;
    total: number;
}

export interface TransactionItem {
    after_account_balance: number;
    after_pending_balance: number;
    amount: number;
    before_account_balance: number;
    before_pending_balance: number;
    id: number;
    influencer_id: string;
    meta_data: {
        campaign_id: string;
        paypal: string;
        brand_name: string;
        paymentMethod: any;
    };
    payMethod: string;
    bankNumber: string;
    items: string;
    type: string;
    status: 'DONE' | 'PENDING';
    transaction_time: string;
    transaction_type:
        | 'CAMPAIGN_PAY'
        | 'CASH_OUT'
        | 'CREDIT_CONVERT'
        | 'SHOP_COMMISSION'
        | 'SHOP_PAYMENT'
        | 'RAFFLE_CREDIT'
        | 'CREDIT'
        | 'AWARD_CREDIT'
        | 'RESTOCK'
        | 'Restock Payment'
        | 'AUTO_RESTOCK'
        | 'SHOP_EARNING'
        | 'SHIPPING_CREDIT'
        | 'Weekly Shipping Cost'
        | 'Monthly Shipping Cost'
        | 'Product Procurement Cost'
        | 'Payment for Customized Order'
        | 'Warehouse Operation Cost'
        | 'Subscription Payment - Advanced Plan';
}

@Injectable({
    providedIn: 'root',
})
export class BalanceHistoryService {
    constructor(private requestService: RequestService) {}

    getTransactionHistory() {
        return this.requestService.sendRequest<TransactionItem[]>({
            method: 'GET',
            url: '/influencer/transaction_history',
        });
    }
}
