<section class="subscription-box">
    <div style="max-width: 1670px; margin: 0 auto">
        <section class="header">
            <div class="logo-title">
                <img src="assets/imgs/project-logo.jpg" alt="" width="100" />
                <span><span class="strong">Lifo</span> Creator Hub</span>
            </div>
        </section>
        <section class="step-box">
            <section class="title">
                <h3>Flexible Plans For Your Business</h3>
                <p>
                    Pricing plans built for online merchants at every stage of their business. Pick a plan that fits your needs, and adjust
                    or cancel at any time.
                </p>
            </section>
            <section class="card-list clearfix">
                <app-subscribe-plan-cards [enableChoose]="false"></app-subscribe-plan-cards>
            </section>

            <app-plan-full-feature-table></app-plan-full-feature-table>
        </section>
        <section class="footer">v2.4 Ⓒ2022 by Lifo.ai</section>
    </div>
</section>
