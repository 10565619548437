import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from '@modules/home/home.service';
import { OrderLineItem, paymentStatusMap, OrderDetail, ProductTypeEnum } from '@shared/typings/order.typings';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.less'],
})
export class OrderDetailComponent implements OnInit {
    orderId: string;
    productList: OrderLineItem[];
    orderDetail: OrderDetail;
    paymentStatusMap = paymentStatusMap;
    loading = true;

    constructor(private router: Router, private homeService: HomeService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
        this.getOrderDetail();
    }

    getOrderDetail() {
        this.homeService.getOrderDetailById(this.orderId).then(orderDetail => {
            this.orderDetail = orderDetail;

            this.productList = orderDetail.line_items.filter(item => {
                const isProduct =
                    item.product_type === ProductTypeEnum.PRODUCT ||
                    item.product_type === ProductTypeEnum.CUSTOM_PRODUCT ||
                    item.product_type === ProductTypeEnum.DIY_PRODUCT ||
                    item.product_type === ProductTypeEnum.POD_PRODUCT;
                return isProduct;
            });
            this.loading = false;
        });
    }

    backToOrderList() {
        this.router.navigateByUrl('/home/order-history');
    }
}
