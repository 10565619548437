import { ConnectPaymentComponent } from '@shared/components/connect-payment/connect-payment.component';
import { FirebaseEventsService, CUSTOM_ANALYTICS_EVENT } from '@services/firebase-event.service';
import { GeneralPaymentComponent } from '@shared/components/general-payment/general-payment.component';
import { PaymentMethodEnum } from '@shared/components/shopping-cart/shopping-cart.service';
import { NewOrderPaymentModalComponent } from './new-order-payment-modal/new-order-payment-modal.component';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService, ReportItem } from '../home.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import dayjs from 'dayjs';
import { ProfileService } from '@services/profile.service';
import { PaypalService } from '@services/paypal.service';
import { MyEarningsService } from '@services/my-earnings.service';
import { AccountBalance } from '@typings/earnings.typings';
import { FormControl, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { echarts, EchartsOption } from '@shared/directives/echarts.directive';
import { ShopService } from '@services/shop.service';
import { PlaidService } from '@services/plaid.service';
import { AvailablePaymentMethods, IPaymentRecord } from '@typings/profile.typing';
import { NzModalService } from 'ng-zorro-antd/modal';
import { initialVisitsChartOption, initialSalesChartOption } from './home-dashboard.data';
import { Subscription } from 'rxjs';
import { SubscribePlanNameEnum } from '@src/app/shared/components/subscription/subscription.data';
import {
    fadeInUpOnEnterAnimation,
    rotateAnimation,
    collapseAnimation,
    expandOnEnterAnimation,
    collapseOnLeaveAnimation,
} from 'angular-animations';
import { ShowModalService } from '@services/show-modal.service';
import { MessageService } from '@services/message.service';

interface AnalyticsInfo {
    visitsToday: number;
    totalVisits: number;
    fromYesterday: number;
    weeklyTraffics: { date: string; visits: number }[];
}

@Component({
    selector: 'app-home-dashboard',
    templateUrl: './home-dashboard.component.html',
    styleUrls: ['./home-dashboard.component.less'],
    animations: [
        fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 800 }),
        rotateAnimation(),
        collapseAnimation(),
        expandOnEnterAnimation({ duration: 500 }),
        collapseOnLeaveAnimation(),
    ],
})
export class HomeDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    initialVisitsChartOption = initialVisitsChartOption;
    initialSalesChartOption = initialSalesChartOption;

    paymentRequests: IPaymentRecord[] = [];
    inCompleteCheckouts: IPaymentRecord[];
    paidRequests: IPaymentRecord[] = [];
    dialogState = { cashOutConfirmDialog: false };

    cashOutShowInput = false;
    isCashingOut = false;
    cashoutWarningMessageType: 'less_then_0' | 'more_than_balance' | 'error' | undefined;

    revenueReport: ReportItem[];
    costReport: ReportItem[];

    salesInfo: {
        daily_sales: {
            date: string;
            total_orders: number;
            total_sales: number;
        }[];
        since: string;
        total_item_sold: number;
        total_sales: number;
        total_orders: number;
    };

    paymentDetail;
    paypalTooltipVisible = false;
    amountTooltipVisible = false;
    amountReservedVisible = false;
    revenueReportHidden = true;
    costReportHidden = true;
    analyticsInfo: AnalyticsInfo;

    shownRevenueItem: ReportItem;

    loadingStatusMap = {
        costReport: true,
        revenueReport: true,
    };
    trafficChartFailedType: 'error' | 'noData';

    today = dayjs().format('YYYY-MM-DD');
    accountBalance: AccountBalance;

    connectedBankName: string;
    paypalAccount: string;

    AvailablePaymentMethods = AvailablePaymentMethods;
    paymentMethod: null | AvailablePaymentMethods = null;
    gettingPlaidInstitution = false;

    newAmountFC = new FormControl('', [Validators.required]);
    loadingBalance = false;
    mobilePopup = window.innerWidth > 800 ? false : true;

    visitsOptionToBeUpdate: EchartsOption;
    salesOptionToBeUpdate: EchartsOption;
    payByBalanceControl = new FormControl(false);
    payByPaymentMethodControl = new FormControl(false);

    paymentRequest = {
        id: 0,
        requestedAmount: 0,
        invoice_image_url: null,
        payment_type: null,
        calculations: {
            insufficientBalance: false,
            amountToCharge: 0,
            amountFromBalance: 0,
            rest: 0,
        },
    };
    salesChartFailed: boolean;
    connectingPaymentMethod = false;

    SubscribePlanNameEnum = SubscribePlanNameEnum;

    addPaymentMethodVisible = false;

    subscriptionVisible = false;
    subscriptions = new Subscription();

    isBindCard = true;

    notifications: any[];

    guidanceVisible = true;

    guidanceList = [
        {
            img: 'assets/imgs/guidance-1.png',
            title: 'Create Custom Products',
            content: 'Turn your ideas into tangible merchandise and create something unique for your audiences. ',
            btnText: 'Start Creating',
            type: 'create',
            visible: true,
        },
        {
            img: 'assets/imgs/guidance-2.png',
            title: 'Personalize Your Storefront',
            content: 'Customize the design of your online shop and sell your products effectively.',
            btnText: 'Edit My Store',
            type: 'onlineStore',
            visible: this.accessToStorefront,
        },
        {
            img: 'assets/imgs/guidance-3.png',
            title: 'See What Others Are Selling',
            content: 'Get inspiration from other creators’ storefronts.',
            btnText: 'Explore',
            type: 'allStar',
            visible: true,
        },
    ];

    get accessToStorefront() {
        if (this.shopProfile.subscription_plan !== SubscribePlanNameEnum.STARTER && !this.shopProfile.domain) {
            return false;
        }
        if (this.shopProfile.subscription_plan === SubscribePlanNameEnum.STARTER) {
            return false;
        }

        return true;
    }

    get transferrableBalance() {
        return this.accountBalance?.account_balance - this.accountBalance?.reserved_balance || 0;
    }

    get isRevenueReportHasNew() {
        return this.revenueReport?.some(data => this.isNewRecord(data, 'report_date'));
    }

    get isCostReportHasNew() {
        return this.paidRequests?.some(data => this.isNewRecord(data, 'created_at'));
    }

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    get freeTrialUnused() {
        return this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER && !this.shopProfile.subscription?.trial_end_date;
    }

    get freeTrialInUse() {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.ADVANCED &&
            this.shopProfile?.subscription?.trial_end_date &&
            new Date(this.shopProfile?.subscription?.trial_end_date).getTime() >= new Date().getTime()
        );
    }

    get subAdvanced() {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.ADVANCED &&
            (!this.shopProfile?.subscription?.trial_end_date ||
                new Date(this.shopProfile?.subscription?.trial_end_date).getTime() < new Date().getTime())
        );
    }

    get subPremium() {
        return this.shopProfile?.subscription_plan === SubscribePlanNameEnum.PREMIUM;
    }

    get freeTrialExpired() {
        return (
            this.shopProfile?.subscription_plan === SubscribePlanNameEnum.STARTER &&
            this.shopProfile?.subscription?.trial_end_date &&
            new Date(this.shopProfile?.subscription.trial_end_date).getTime() < new Date().getTime()
        );
    }

    get getShopClosedDate() {
        return this.shopProfile?.subscription?.trial_end_date
            ? dayjs(this.shopProfile?.subscription?.trial_end_date).add(1, 'month').valueOf()
            : null;
    }

    get isShopClosed() {
        return this.getShopClosedDate ? dayjs().valueOf() > this.getShopClosedDate : false;
    }

    constructor(
        private homeService: HomeService,
        private router: Router,
        private message: NzMessageService,
        private myEarningsService: MyEarningsService,
        private shopService: ShopService,
        private modalService: NzModalService,
        private messageService: MessageService,
        public profileService: ProfileService,
        public paypalService: PaypalService,
        public plaidService: PlaidService,
        public showModalService: ShowModalService,
        public firebaseEventService: FirebaseEventsService
    ) {
        this.subscriptions.add(this.showModalService.subscriptionModalVisible$.subscribe(res => (this.subscriptionVisible = res)));

        this.initialSalesChartOption.formatter = params => {
            const param = params[0];
            return `<div style="font-weight: 400; font-family: 'Ubuntu'; font-size: 12px; display: flex; width: 140px;
                align-items: flex-start; flex-direction: column; justify-content: space-between; ">
                    <section style="color: #1b222e; font-weight: 500;">${dayjs(
                        this.salesInfo.daily_sales[this.salesInfo.daily_sales.length - param.dataIndex - 1].date
                    ).format('YYYY-MM-DD')}</section>
                    <section style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                        <span style="color: #9F99A3;">Sales</span>
                        <span style="color: #d8b165; font-weight: 500;">$${param.value.toFixed(2)}</span>
                    </section>
                    <section style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                        <span style="color: #9F99A3;">Orders</span>
                        <span style="color: #1b222e;">${
                            this.salesInfo.daily_sales[this.salesInfo.daily_sales.length - param.dataIndex - 1].total_orders
                        }</span>
                    </section>
                </div>`;
        };
    }

    ngOnInit(): void {
        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_DASHBOARD);

        this.getRevenueReport();
        this.getCostReport();
        this.getAccountBalance();
        this.getInCompleteCheckout();
        this.getNotifications();
        this.guidanceVisible = !this.shopProfile.shop_detail.skip_user_guide;

        this.profileService.currentProfileSubject
            .pipe(filter(currentProfile => !!currentProfile))
            .subscribe(() => this.getSelectedBankAccount());

        this.homeService.getPaymentRecords().then(result => {
            this.paidRequests = result.filter(res => res.payment_status === 'paid');
            this.loadingStatusMap.costReport = false;
            this.processPayMethod(this.paidRequests);
            this.paymentRequests = result.filter(re => re.payment_status !== 'paid');
        });

        this.newAmountFC.valueChanges.subscribe(val => {
            if (this.cashoutWarningMessageType) {
                this.cashoutWarningMessageType = undefined;
            }
        });

        this.payByBalanceControl.valueChanges.subscribe(val => {
            this.payByPaymentMethodControl.enable({ emitEvent: false });
            this.calculatePayment();
            if (!this.paymentRequest.calculations.insufficientBalance) {
                this.payByPaymentMethodControl.patchValue(!val, { emitEvent: false });
            }
            this.calculatePayment();
        });
        this.payByPaymentMethodControl.valueChanges.subscribe(val => {
            this.payByPaymentMethodControl.enable({ emitEvent: false });
            this.calculatePayment();
            if (!this.paymentRequest.calculations.insufficientBalance) {
                this.payByBalanceControl.patchValue(!val, { emitEvent: false });
            }
            this.calculatePayment();
        });
    }

    getSelectedBankAccount() {
        this.gettingPlaidInstitution = true;

        return this.plaidService
            .getPaymentMethod()
            .then(data => {
                this.isBindCard = !!data.bank_account || !!data.credit_card;
                this.paypalAccount = data.paypal ?? null;
                this.connectedBankName = data.bank_account
                    ? `${data.bank_account.account.name} ******* ${data.bank_account.account.mask || ''}`
                    : null;

                if (this.profileService.currentProfile.selected_payment_method) {
                    this.paymentMethod = this.profileService.currentProfile.selected_payment_method;
                } else {
                    if (data.bank_account) {
                        this.paymentMethod = AvailablePaymentMethods.bank_account;
                    } else if (data.paypal) {
                        this.paymentMethod = AvailablePaymentMethods.paypal;
                    }
                }

                if (!this.paypalAccount && !this.connectedBankName) {
                    this.paypalTooltipVisible = true;
                    this.payByPaymentMethodControl.disable({ emitEvent: false });
                }
            })
            .finally(() => (this.gettingPlaidInstitution = false));
    }

    visitGuidance(type: string) {
        if (type === 'create') {
            this.router.navigate(['/', 'nidavellir', 'product-list']);
        }

        if (type === 'onlineStore') {
            this.router.navigate(['/', 'storefront']);
        }

        if (type === 'allStar') {
            window.open('/all-stars', '_blank');
        }
    }

    ngAfterViewInit() {
        this.getSalesInfo();
        this.getAnalyticsInfo();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    correctAmount() {
        this.newAmountFC.setValue((parseFloat(this.newAmountFC.value) || 0).toFixed(2));
    }

    getRevenueReport() {
        this.homeService
            .getRevenueReport()
            .then((revenueReport: ReportItem[]) => {
                this.loadingStatusMap.revenueReport = false;
                this.revenueReport = revenueReport.sort((a, b) => new Date(b.report_date).getTime() - new Date(a.report_date).getTime());
            })
            .catch(() => {
                this.loadingStatusMap.revenueReport = false;
                this.message.create('error', 'Get revenue report failed, please try again later');
            });
    }

    getInCompleteCheckout() {
        this.homeService.getInCompleteCheckout().then(res => {
            this.inCompleteCheckouts = res.map(item => ({
                payment_type: 'New Order Payment Request',
                ...item,
            }));
        });
    }

    getCostReport() {
        this.homeService
            .getCostReport()
            .then((costReport: ReportItem[]) => {
                this.loadingStatusMap.costReport = false;
                this.costReport = costReport;
            })
            .catch(() => {
                this.loadingStatusMap.costReport = false;
                this.message.create('error', 'Get cost report failed, please try again later');
            });
    }

    getSalesInfo() {
        const unixTimeStampTodayStart = dayjs(this.today).unix();
        this.homeService
            .getSalesInfoByDate(unixTimeStampTodayStart)
            .then((res: any) => {
                this.salesInfo = res;
                const dates = this.salesInfo.daily_sales.map(item => dayjs(item.date).format('MM/DD')).reverse();
                this.salesOptionToBeUpdate = {
                    xAxis: {
                        data: dates,
                    },
                    series: {
                        data: this.salesInfo.daily_sales.map(item => item.total_sales).reverse(),
                    },
                };
            })
            .catch(() => {
                this.salesInfo = {
                    daily_sales: null,
                    since: null,
                    total_item_sold: 0,
                    total_sales: 0,
                    total_orders: 0,
                };
                this.salesChartFailed = true;
                this.message.error('Get sales data failed, please try again.');
            });
    }

    getAccountBalance() {
        this.loadingBalance = true;
        return this.homeService
            .getAccountBalance()
            .then(res => {
                this.accountBalance = res;
                this.accountBalance.account_balance = Number(this.accountBalance.account_balance);
            })
            .catch(() => this.message.error('Get account balance failed, please try again later.'))
            .finally(() => (this.loadingBalance = false));
    }

    goOrderHistory() {
        this.router.navigateByUrl('/home/order-history');
    }

    transferFunds() {
        if (!this.accountBalance?.account_balance) {
            return;
        }

        if (!this.paymentMethod) {
            if (!this.paypalTooltipVisible) {
                this.paypalTooltipVisible = true;
            } else {
                // Alex: this trick is for cases when user clicked on tooltip's overlay
                // (this action leaves paypalTooltipVisible = true and tooltip will not shown again)
                this.paypalTooltipVisible = false;
                setTimeout(() => {
                    this.paypalTooltipVisible = true;
                }, 300);
            }
            return;
        }

        if (this.transferrableBalance) {
            this.newAmountFC.patchValue(this.transferrableBalance.toFixed(2));
            this.openDialog('cashOutConfirmDialog');
        }
    }

    showPaymentSuccess() {
        this.messageService.success('Success!');
    }

    cashOut() {
        if (!this.newAmountFC.valid) {
            return;
        }
        const newAmount = Number(this.newAmountFC.value);
        this.cashoutWarningMessageType = undefined;
        if (newAmount > this.transferrableBalance) {
            this.cashoutWarningMessageType = 'more_than_balance';
            return;
        }
        if (newAmount <= 0) {
            this.cashoutWarningMessageType = 'less_then_0';
            return;
        }
        this.isCashingOut = true;
        const requestData: {
            amount: number;
            payment_method: string;
        } = {
            amount: newAmount,
            payment_method: this.paymentMethod,
        };
        this.myEarningsService
            .cashOut(requestData)
            .then(async () => {
                this.cashOutShowInput = false;
                this.closeDialog('cashOutConfirmDialog');
                this.messageService.success('Transfer initiated! Your request will be processed in the next 1-3 business days.');

                await this.getAccountBalance();
                this.newAmountFC.patchValue((this.transferrableBalance || 0).toFixed(2));
            })
            .catch(() => (this.cashoutWarningMessageType = 'error'))
            .finally(() => (this.isCashingOut = false));
    }

    openDialog(key: string) {
        this.dialogState[key] = true;
    }

    closeDialog(key) {
        this.dialogState[key] = false;
    }

    getBalanceBeforeDot(amount: number): string {
        if (amount) {
            return String(amount.toFixed(2).split('.')[0]);
        }
        return '0';
    }

    getBalanceAfterDot(amount: number): string {
        if (amount) {
            return String(amount.toFixed(2)).split('.')[1];
        }
        return '00';
    }

    getAnalyticsInfo() {
        this.homeService
            .getAnalytics()
            .then((res: AnalyticsInfo) => {
                this.analyticsInfo = res;

                if (res.weeklyTraffics === null) {
                    this.trafficChartFailedType = 'noData';
                } else {
                    this.visitsOptionToBeUpdate = {
                        xAxis: {
                            data: this.analyticsInfo.weeklyTraffics?.map(item => item.date),
                        },
                        series: {
                            data: this.analyticsInfo.weeklyTraffics?.map(item => Number(item.visits)),
                        },
                    };
                }
            })
            .catch(() => {
                this.analyticsInfo = {
                    visitsToday: 0,
                    totalVisits: 0,
                    fromYesterday: 0,
                    weeklyTraffics: null,
                };
                this.trafficChartFailedType = 'error';
                this.message.error('Get visits data failed, please try again.');
            });
    }

    openPaymentRequestModal(payment: IPaymentRecord) {
        if (payment.payment_type === 'New Order Payment Request') {
            this.modalService.create({
                nzContent: NewOrderPaymentModalComponent,
                nzWidth: '1200px',
                nzFooter: null,
                nzWrapClassName: 'no-padding',
                nzComponentParams: { paymentItem: payment },
                nzOnOk: () => {
                    this.inCompleteCheckouts = this.inCompleteCheckouts.filter(item => item !== payment);
                    this.getAccountBalance();
                    this.showPaymentSuccess();
                },
            });
        } else {
            this.modalService.create({
                nzContent: GeneralPaymentComponent,
                nzComponentParams: {
                    data: {
                        itemList: [{ name: payment.payment_type, amount: payment.amount }],
                        paymentId: payment.id,
                    },
                },
                nzFooter: null,
                nzMaskClosable: false,
                nzOnOk: () => {
                    this.paymentRequests = this.paymentRequests.filter(item => item !== payment);
                    this.showPaymentSuccess();
                },
            });
        }
    }

    calculatePayment() {
        this.paymentRequest.calculations.amountFromBalance = 0;
        this.paymentRequest.calculations.amountToCharge = 0;
        this.paymentRequest.calculations.rest = 0;
        this.paymentRequest.calculations.insufficientBalance = false;

        if (this.payByBalanceControl.value) {
            if (this.paymentRequest.requestedAmount <= this.accountBalance.account_balance) {
                this.paymentRequest.calculations.amountFromBalance = this.paymentRequest.requestedAmount;
            } else {
                if (this.paymentMethod === 'bank_account') {
                    this.paymentRequest.calculations.insufficientBalance = true;
                    if (!this.payByPaymentMethodControl.value) {
                        this.payByPaymentMethodControl.patchValue(true, { emitEvent: false });
                    }
                    this.payByPaymentMethodControl.disable({ emitEvent: false });
                    this.paymentRequest.calculations.amountFromBalance = this.accountBalance.account_balance;
                    this.paymentRequest.calculations.amountToCharge =
                        this.paymentRequest.requestedAmount - this.accountBalance.account_balance;
                } else {
                    this.paymentRequest.calculations.insufficientBalance = true;
                    this.paymentRequest.calculations.rest = this.paymentRequest.requestedAmount - this.accountBalance.account_balance;
                }
            }
        } else {
            if (this.paymentMethod === 'bank_account') {
                this.paymentRequest.calculations.amountFromBalance = 0;
                this.paymentRequest.calculations.amountToCharge = this.paymentRequest.requestedAmount;
                if (this.paymentRequest.requestedAmount > this.accountBalance.account_balance || !this.payByPaymentMethodControl.value) {
                    this.paymentRequest.calculations.insufficientBalance = true;
                }
            } else {
                this.paymentRequest.calculations.insufficientBalance = true;
                this.paymentRequest.calculations.rest = this.paymentRequest.requestedAmount;
            }
            if (this.paymentRequest.calculations.insufficientBalance) {
                if (this.paymentMethod === 'bank_account') {
                    this.payByPaymentMethodControl.patchValue(true, { emitEvent: false });
                }
            }
            if (this.payByPaymentMethodControl.value) {
                this.payByPaymentMethodControl.disable({ emitEvent: false });
            }
        }
    }

    connectPayPal() {
        window.location.href = this.paypalService.getPaypalLink();
    }

    async connectPlaid() {
        try {
            this.connectingPaymentMethod = true;
            const linkToken = await this.plaidService.fetchLinkToken();
            const publicToken = await this.plaidService.getPublicToken(linkToken.link_token);
            if (publicToken) {
                this.connectingPaymentMethod = true;
                await this.plaidService.exchangePublicToken(publicToken);
                await this.profileService.getCurrentProfile().then(currentProfile => {
                    this.profileService.currentProfile = currentProfile;
                });
                this.connectingPaymentMethod = false;
                this.messageService.success('Success! Your payment account is connected.');
            } else {
                this.connectingPaymentMethod = false;
            }
        } catch (e) {
            this.connectingPaymentMethod = false;
            this.messageService.error('Connect payment account fail. Please try again.');
        }
    }

    processPayMethod(requests: IPaymentRecord[]) {
        requests.forEach(request => {
            const paymentMethods = request.payment_method || [];
            const isSplitPayment = paymentMethods.filter(paymentItem => paymentItem.amount > 0).length > 1;
            if (isSplitPayment) {
                request.pay_method = 'Split Payment';
            } else {
                request.pay_method = paymentMethods.find(paymentItem => paymentItem.amount > 0)?.method || 'Balance';
            }
        });
    }

    generatePaymentDetail(request: IPaymentRecord) {
        return {
            amount: request.amount,
            balance: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.BALANCE)?.amount,
            bank: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.BANK)?.amount,
            bankName: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.BANK)?.bankNumber,
            lifoCredit: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.LIFO_CREDIT)?.amount,
            creditCard: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.CREDIT_CARD)?.amount,
            installment: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.INSTALLMENT)?.amount,
            paypal: request.payment_method?.find(payment => payment.method === PaymentMethodEnum.PAYPAL)?.amount,
        };
    }

    openLink(url: string) {
        window.open(url, '_blank');
    }

    isNewRecord(data: ReportItem | IPaymentRecord, attribute: 'created_at' | 'report_date') {
        const oneWeekAgo = dayjs().add(-7, 'day');
        return dayjs(data[attribute]).isAfter(oneWeekAgo);
    }

    connectPaymentMethod() {
        this.modalService.create({
            nzContent: ConnectPaymentComponent,
            nzFooter: null,
        });
    }

    addPaymentMethod() {
        this.addPaymentMethodVisible = true;
    }

    showSubscriptionModal() {
        this.showModalService.showSubscriptionModal();
    }

    addPaymentMethodSuccess() {
        this.addPaymentMethodVisible = false;
        this.getSelectedBankAccount();
    }

    getNotifications() {
        this.homeService.getNotifications().then(data => (this.notifications = data));
    }

    goSampleDetail(notification: any) {
        this.homeService.markNotificationAsRead(notification.id);

        this.router.navigate(['/', 'my-purchase', 'orders'], {
            queryParams: {
                orderId: notification.meta_data.order_id,
            },
        });
    }

    skipGuidance() {
        this.guidanceVisible = false;
        this.homeService.skipGuidance().then(() => (this.shopService.shopProfile.shop_detail.skip_user_guide = true));
    }
}
