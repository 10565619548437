import { AccountBalance } from '@typings/earnings.typings';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MyEarningsService } from '@services/my-earnings.service';
import { NidavellirService, ProductInCartFromAPI, ProductInCart } from '@modules/nidavellir/nidavellir.service';

@Component({
    selector: 'app-credit-cart',
    templateUrl: './credit-cart.component.html',
    styleUrls: ['./credit-cart.component.less'],
})
export class CreditCartComponent implements OnInit, OnChanges {
    @Input() newAddedProducts: ProductInCart[];
    @Input() showMainBody = true;

    accountBalance: AccountBalance;

    productsInShoppingCart: ProductInCartFromAPI[];

    get quantity() {
        if (this.newAddedProducts) {
            return this.newAddedProducts[0].variants.reduce((sum, curr) => sum + curr.quantity, 0);
        }

        return 0;
    }

    constructor(private myEarnings: MyEarningsService, private nidavellirService: NidavellirService) {}

    ngOnInit(): void {
        this.myEarnings.getAccountBalance().then(res => (this.accountBalance = res));
        this.getProductsInShoppingCart();
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.newAddedProducts.currentValue) {
            this.getProductsInShoppingCart();
        }
    }

    getProductsInShoppingCart() {
        this.nidavellirService
            .getProductsInCart()
            .then(res => (this.productsInShoppingCart = res.filter(item => item.listing_status !== 'inactive')));
    }
}
