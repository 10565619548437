import { ShopProfile } from '@typings/profile.typing';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { MessageService } from '@services/message.service';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-sign-in-condition-contract',
    templateUrl: './sign-in-condition-contract.component.html',
    styleUrls: ['./sign-in-condition-contract.component.less'],
})
export class SignInConditionContractComponent implements OnInit {
    checkingStatus = false;
    submitting = false;

    get shopProfile() {
        return this.shopService.shopProfile;
    }

    constructor(
        private router: Router,
        private shopService: ShopService,
        private messageService: MessageService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.shopService
            .getShopDetail()
            .then(shopProfile => {
                if (shopProfile.setup_complete) {
                    this.router.navigateByUrl('/home');
                }
            })
            .catch(() => this.router.navigateByUrl('/home'));
    }

    confirm() {
        this.submitting = true;
        this.shopService
            .confirmShopAgreement()
            .then(() => {
                const newShopProfile: ShopProfile = {
                    ...this.shopProfile,
                    ...{ setup_complete: true },
                };

                this.shopService.shopProfile$.next(newShopProfile);

                this.router.navigateByUrl('/home');
            })
            .catch(() => this.messageService.error())
            .finally(() => (this.submitting = false));
    }

    goTermsCondition() {
        window.open('/terms-and-conditions', '_blank');
    }

    logout() {
        this.authService.signOut();
    }
}
