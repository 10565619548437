import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ShopService } from '@services/shop.service';
import { MessageService } from '@services/message.service';
import { SubscribePlan } from './../../subscription.data';

@Component({
    selector: 'app-contact-us',
    templateUrl: './subscription-contact-us.component.html',
})
export class SubscriptionContactUsComponent implements OnInit {
    @Input() selectedPlan: SubscribePlan;

    contacting = false;

    contactSuccess = false;

    constructor(public modalRef: NzModalRef, private shopService: ShopService, private messageService: MessageService) {}

    ngOnInit(): void {}

    confirm() {
        this.contacting = true;
        const data = { subscription_plan: this.selectedPlan.plan_text };
        this.shopService
            .selectSubscriptionPlan(data)
            .then(() => (this.contactSuccess = true))
            .catch(() => {
                this.contacting = false;
                this.messageService.error();
            });
    }
}
