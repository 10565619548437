import { Injectable } from '@angular/core';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';

export enum MessageEnum {
    ERROR = 'Oops! Something went wrong. Please try again.',
    LOADING = 'Loading',
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private message: NzMessageService) {}

    success(content?: string, options?: NzMessageDataOptions) {
        this.message.success(content, options);
    }

    error(content?: string, options?: NzMessageDataOptions) {
        const handledOptions = {
            ...{ nzDuration: 15000 },
            ...(options || {}),
        };
        this.message.error(content ?? MessageEnum.ERROR, handledOptions);
    }

    warning(content?: string, options?: NzMessageDataOptions) {
        this.message.warning(content, options);
    }

    info(content?: string, options?: NzMessageDataOptions) {
        this.message.info(content, options);
    }

    loading(content?: string, options?: NzMessageDataOptions) {
        this.message.loading(content ?? MessageEnum.LOADING, options);
    }
}
