import { SubscribePlanNameEnum, CARD_LIST } from './subscription.data';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SubscribePlanService {
    constructor() {}

    showWhichModal(currentPlan: SubscribePlanNameEnum, toPlan: SubscribePlanNameEnum): 'downgrade' | 'contact' | 'payment' {
        if (currentPlan === SubscribePlanNameEnum.PREMIUM || toPlan === SubscribePlanNameEnum.PREMIUM) {
            return 'contact';
        }

        if (this.isUpgradePlan(currentPlan, toPlan)) {
            return 'payment';
        }

        return 'downgrade';
    }

    isUpgradePlan(currentPlan: SubscribePlanNameEnum, toPlan: SubscribePlanNameEnum) {
        const currentPlanIndex = CARD_LIST.findIndex(item => item.plan_text === currentPlan);
        const toPlanIndex = CARD_LIST.findIndex(item => item.plan_text === toPlan);
        return toPlanIndex > currentPlanIndex;
    }
}
