<!-- Comments Modal -->
<section class="pt-30 pb-30">
    <div class="d-flex justify-content-between align-items-center pl-30 pr-30">
        <span class="fw-bold fs-20" style="color: #171724">Comments</span>
        <i nz-icon nzType="close" nzTheme="outline" style="font-size: 22px; cursor: pointer" (click)="closeCommentModal()"></i>
    </div>

    <div class="pl-30 pr-30"><nz-divider></nz-divider></div>

    <div class="comments-box pl-30 pr-30">
        <div *ngFor="let commentItem of commentList;let i = index;">
            <div class="d-flex justify-content-between align-items-start">
                <div>
                    <div class="name">{{commentItem.created_by}}</div>
                    <div class="time mb-10">{{commentItem.created_at | date:'medium'}}</div>
                </div>

                <div *ngIf="!commentEditingMap.has(commentItem)" class="edit-icons">
                    <i
                        *ngIf="commentItem.is_self"
                        class="active-mask mr-10"
                        title="Edit"
                        nz-icon
                        nzType="edit"
                        nzTheme="outline"
                        (click)="editComment(commentItem)"
                    ></i>
                    <i
                        *ngIf="commentItem.is_self"
                        class="active-mask mr-10"
                        title="Delete"
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        (click)="deleteComment(commentItem)"
                    ></i>
                    <i
                        class="active-mask"
                        title="Reply"
                        nz-icon
                        nzType="message"
                        nzTheme="outline"
                        (click)="expandComment(commentItem)"
                    ></i>
                </div>

                <div *ngIf="commentEditingMap.has(commentItem)" class="save-cancel-icons">
                    <button
                        nz-button
                        nzSize="small"
                        class="mr-10"
                        [disabled]="commentEditSubmittingSet.has(commentItem)"
                        (click)="cancelEditComment(commentItem)"
                    >
                        Cancel
                    </button>
                    <button
                        nz-button
                        nzSize="small"
                        nzType="primary"
                        [nzLoading]="commentEditSubmittingSet.has(commentItem)"
                        (click)="saveEditComment(commentItem)"
                    >
                        Save
                    </button>
                </div>
            </div>
            <div *ngIf="!commentEditingMap.has(commentItem)">{{commentItem.content}}</div>
            <textarea
                *ngIf="commentEditingMap.has(commentItem)"
                nz-input
                placeholder="Enter your comment here"
                class="mt-10"
                [(ngModel)]="commentEditingMap.get(commentItem).comments"
            ></textarea>

            <div class="reply-box mt-10" *ngIf="commentItem.replies?.length > 0 || shownAddCommentsMap.has(commentItem)" [@collapseLeave]>
                <div *ngFor="let replyItem of commentItem.replies;let i = index">
                    <div class="d-flex justify-content-between align-items-start mb-10">
                        <div>
                            <div class="name">{{replyItem.created_by}}</div>
                            <div class="time">{{replyItem.created_at|date:'medium'}}</div>
                        </div>

                        <i
                            *ngIf="replyItem.is_self"
                            class="active-mask mr-10 cursor-pointer"
                            title="Delete"
                            nz-icon
                            nzType="delete"
                            nzTheme="outline"
                            style="color: #9f99a3"
                            (click)="deleteComment(replyItem, commentItem)"
                        ></i>
                    </div>
                    <div>{{replyItem.content}}</div>
                    <nz-divider *ngIf="i !== commentItem.replies.length-1"></nz-divider>
                </div>
                <div [@collapse]="!shownAddCommentsMap.has(commentItem)" class="add-comment-box">
                    <nz-divider></nz-divider>
                    <ng-container *ngIf="shownAddCommentsMap.has(commentItem)">
                        <textarea
                            nz-input
                            placeholder="Enter your comment here"
                            [(ngModel)]="shownAddCommentsMap.get(commentItem).comments"
                        ></textarea>
                        <div style="text-align: right">
                            <button
                                nz-button
                                nzType="primary"
                                class="mt-30"
                                [nzLoading]="replySubmittingSet.has(commentItem)"
                                [disabled]="shouldCommentBtnDisable(shownAddCommentsMap.get(commentItem).comments)"
                                (click)="addReply(commentItem)"
                            >
                                Add Comments
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>

            <nz-divider></nz-divider>
        </div>

        <textarea nz-input placeholder="Enter your comment here" [(ngModel)]="comments"></textarea>
        <div style="text-align: right">
            <button
                nz-button
                nzType="primary"
                class="mt-30"
                [disabled]="shouldCommentBtnDisable(comments)"
                [nzLoading]="commentSubmitting"
                (click)="addComment() "
            >
                Add Comments
            </button>
        </div>
    </div>
</section>
