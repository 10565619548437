import { CUSTOM_ANALYTICS_EVENT } from './../../services/firebase-event.service';
import { FirebaseEventsService } from '@services/firebase-event.service';
import { MessageService } from '@services/message.service';
import { ProductManagementService } from '@modules/product-management/product-management.service';
import { ProductTypeEnum } from '@shared/typings/order.typings';
import { StorefrontStateService } from './storefront-state.service';
import { AboutComponent } from './about/about.component';
import { RecommendedProductsComponent } from './recommended-products/recommended-products.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewContainerRef,
    ElementRef,
    HostListener,
} from '@angular/core';
import { ShopService } from '@services/shop.service';
import { ShopProfile } from '@src/app/shared/typings/profile.typing';
import { cloneDeep, isEqual, merge, mergeWith } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter } from 'rxjs/operators';
import { StorefrontMediaEnum, StorefrontService } from './storefront.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GeneralDialogComponent } from '@shared/components/general-dialog/general-dialog.component';
import * as dayjs from 'dayjs';
import { environment } from '@src/environments/environment';
import { BannerComponent } from './banner/banner.component';
import { fromEvent, Subscription } from 'rxjs';
import firebase from 'firebase/app';

@Component({
    selector: 'app-storefront',
    templateUrl: './storefront.component.html',
    styleUrls: ['./storefront.component.less'],
})
export class StorefrontComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('bannerComp') bannerComp: BannerComponent;
    @ViewChild('recommendedProductsComp') recommendedProductsComp: RecommendedProductsComponent;
    @ViewChild('aboutComp') aboutComp: AboutComponent;
    @ViewChild('socialMediaComp') socialMediaComp: SocialMediaComponent;

    @ViewChild('rightOperateEle') rightOperateEle: ElementRef;
    @ViewChild('siteContainer') siteContainer: ElementRef;

    siteView: 'mobile' | 'desktop' = 'mobile';
    shopDetail: ShopProfile;
    originalShopDetail: ShopProfile;

    submitting = false;
    templateList = this.storefrontService.templateList;

    fontList = this.storefrontService.fontList;
    fontListMap = this.storefrontService.fontListMap;

    domainCheckingStatus: 'yes' | 'no' | 'checking';

    environment = environment;

    subscription = new Subscription();

    hasOngoingCampaign = false;

    toolbarVisibleSettled = false;

    height: any = 'auto';

    constructor(
        public storefrontService: StorefrontService,
        private shopService: ShopService,
        private message: NzMessageService,
        private modal: NzModalService,
        public vcr: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private productManagementService: ProductManagementService,
        private storefrontStateService: StorefrontStateService,
        private firebaseEventService: FirebaseEventsService
    ) {}

    get domain() {
        return this.storefrontStateService.domainControl.value;
    }

    get saveBtnDisabled() {
        const bannerImgUploading = this.bannerComp?.mobileImgUploading || this.bannerComp?.desktopImgUploading;
        const aboutImgUploading =
            this.aboutComp?.portraitImgUploading ||
            this.aboutComp?.mobileBackgroundImageUploading ||
            this.aboutComp?.desktopBackgroundImageUploading;
        const socialMediaImgUploading = this.socialMediaComp?.mobileImgUploading || this.socialMediaComp?.desktopImgUploading;

        return bannerImgUploading || aboutImgUploading || socialMediaImgUploading || false;
    }

    get isStoreReady() {
        return this.shopDetail?.theme_id;
    }

    get shouldUpdateDomain() {
        return this.shopDetail.shop_type === 'Shared' && this.domain?.trim() && this.domain !== this.getDomainByUrl();
    }

    get isDefaultLogo() {
        return this.storefrontService.isDefaultImage(StorefrontMediaEnum.LOGO, this.shopDetail?.theme_settings?.logoImage);
    }

    get scaleRatio() {
        return this.storefrontStateService.scaleRatio;
    }

    @HostListener('window:resize')
    calculate() {
        setTimeout(() => {
            const width = this.rightOperateEle?.nativeElement?.clientWidth;
            this.storefrontStateService.scaleRatio = width / 1440 - 0.05;
            this.height =
                (this.siteContainer?.nativeElement?.clientHeight + 100) *
                (this.storefrontStateService.scaleRatio > 1 ? 1 : this.storefrontStateService.scaleRatio);
        }, 10);
    }

    ngOnInit(): void {
        this.getShopDetail();

        this.getFavoriteProduct();
        this.getProductList();

        this.getCampaignList();

        window.addEventListener('beforeunload', this.beforeUnloadEvent, true);

        this.subscription.add(
            this.storefrontStateService.shopDetail$.pipe(filter(item => !!item)).subscribe(data => {
                this.shopDetail = this.storefrontService.handleAllEditableText(cloneDeep(data));
                this.originalShopDetail = cloneDeep(this.shopDetail);
                setTimeout(() => {
                    this.calculate();
                }, 1000);
            })
        );

        this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.VIEW_STOREFRONT);
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.beforeUnloadEvent, true);
        this.subscription.unsubscribe();
    }

    beforeUnloadEvent = (e: BeforeUnloadEvent) => {
        if (this.hasUnsavedData()) {
            e.preventDefault();
            e.returnValue = '';
            return '';
        }
    };

    hasUnsavedData() {
        const shopDetailChanged = !isEqual(this.shopDetail, this.originalShopDetail);
        const domainChanged = this.domain !== this.getDomainByUrl();
        const chosenProductsChanged = !isEqual(
            this.storefrontStateService.originalSelectedProducts,
            this.storefrontStateService.currentSelectedProducts
        );

        return !this.submitting && (shopDetailChanged || domainChanged || chosenProductsChanged);
    }

    async canDeactivate() {
        if (!this.hasUnsavedData()) {
            return true;
        }

        if (firebase.auth().currentUser) {
            return await new Promise((resolve, reject) => {
                const sub = this.modal.create({
                    nzContent: GeneralDialogComponent,
                    nzViewContainerRef: this.vcr,
                    nzFooter: null,
                    nzClassName: 'no-padding no-bg',
                    nzMaskClosable: false,
                    nzComponentParams: {
                        message: 'Would you like to keep the changes?',
                        confirmText: 'Save',
                        cancelText: 'Discard',
                    },
                    // Click Save
                    nzOnOk: async (comp: GeneralDialogComponent) => {
                        if (this.shouldUpdateDomain && this.storefrontStateService.domainControl.invalid) {
                            this.message.error('Domain is invalid!');
                            return false;
                        }

                        comp.submitting = true;

                        try {
                            if (this.shouldUpdateDomain) {
                                try {
                                    await this.updateDomain();
                                } catch {
                                    comp.submitting = false;
                                    this.message.error('Sorry, that domain name you chose is taken. Please try another');
                                    return false;
                                }
                            }

                            await this.storefrontService.updateShopTheme(
                                this.storefrontService.getHandledThemeSettings(
                                    this.storefrontService.setAllEditableText(this.shopDetail).theme_settings
                                )
                            );
                            await this.storefrontService.updateFavoriteProduct({
                                product_ids: Array.from(
                                    this.storefrontStateService.currentSelectedProducts.map(product => product.product_id)
                                ),
                            });

                            this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.SAVE_EDIT_STOREFRONT);

                            this.getShopDetail();

                            return true;
                        } catch (e) {
                            this.message.error('Oops, something went wrong. Please try again or contact us.', { nzDuration: 3000 });
                            comp.submitting = false;
                            return false;
                        }
                    },

                    // Click close icon
                    nzOnCancel: () => resolve(false),
                });

                // Click Discard
                sub.afterClose.subscribe(() => resolve(true));
            });
        }

        return true;
    }
    async getCampaignList() {
        const campaignList = await this.storefrontService.getCampaignList();
        const ongoingCampaignList = campaignList
            .filter(item => {
                if (item.ends_at) {
                    return dayjs().isAfter(item.starts_at) && dayjs().isBefore(item.ends_at);
                } else {
                    return dayjs().isAfter(item.starts_at);
                }
            })
            .filter(item => item.status !== 'terminated');

        if (ongoingCampaignList.length > 0) {
            this.hasOngoingCampaign = true;
        }
    }
    getShopDetail() {
        this.shopService.getShopDetail().then(res => {
            const data = res;
            if (data.shop_type === 'Shared') {
                this.storefrontService.defaultThemeSettings.banner.heroButtonLink = '/product/product-list';
            }

            data.theme_settings = merge({}, this.storefrontService.defaultThemeSettings, data.theme_settings || {});

            // If no social media channel add, add lifo instagram
            if (Object.values(data.theme_settings.socialMedia).every(item => !item)) {
                data.theme_settings.socialMedia.instagram = 'https://www.instagram.com/lifoinc/';
            }

            if (data.shop_type === 'Shared' && data.theme_settings?.banner?.heroButtonLink?.includes('collection/all')) {
                // Handle old data.
                // Different shop type has different all products page link
                data.theme_settings.banner.heroButtonLink = '/product/product-list';
            }

            this.storefrontStateService.shopDetail$.next(data);
            this.cdr.detectChanges();

            if (!this.toolbarVisibleSettled) {
                this.toolbarVisibleSettled = true;
                this.setupToolbarVisible();
            }
        });
    }

    getFavoriteProduct() {
        this.storefrontStateService.currentSelectedProducts = [];
        this.storefrontStateService.originalSelectedProducts = [];

        this.storefrontService
            .getFavoriteProduct()
            .then((res: any[]) => {
                if (res?.length) {
                    this.storefrontStateService.originalSelectedProducts = cloneDeep(res);
                    this.storefrontStateService.currentSelectedProducts = res;
                    this.storefrontStateService.currentSelectedProducts$.next(this.storefrontStateService.currentSelectedProducts);
                } else {
                    this.storefrontStateService.currentSelectedProducts$.next([]);
                }
            })
            .catch(() => {
                this.storefrontStateService.currentSelectedProducts = [];
                this.storefrontStateService.currentSelectedProducts$.next([]);
            });
    }

    getProductList() {
        this.productManagementService
            .getProductList()
            .then(res => {
                const productTypes = [
                    ProductTypeEnum.PRODUCT,
                    ProductTypeEnum.DIY_PRODUCT,
                    ProductTypeEnum.STANDARD_PRODUCT,
                    ProductTypeEnum.CUSTOM_PRODUCT,
                    ProductTypeEnum.POD_PRODUCT,
                ];

                this.storefrontStateService.productList = (res || [])
                    .filter(item => productTypes.includes(item.product_type))
                    .filter(ele => ele.active);
                this.storefrontStateService.productList$.next(this.storefrontStateService.productList);
            })
            .catch(() => this.messageService.error('Get product list failed, please refresh to try again', { nzDuration: 3000 }));
    }

    async saveContent() {
        if (this.shouldUpdateDomain && this.storefrontStateService.domainControl.invalid) {
            return this.message.error('Domain is invalid!');
        }

        this.submitting = true;

        try {
            if (this.aboutComp.aboutSectionMediaType === 'video') {
                this.shopDetail.theme_settings.aboutMe.portraitImage = null;
            } else {
                this.shopDetail.theme_settings.aboutMe.youtubeVideoUrl = null;
            }

            if (this.shouldUpdateDomain) {
                try {
                    await this.updateDomain();
                } catch {
                    this.submitting = false;
                    return this.message.error('Sorry, that domain name you chose is taken. Please try another');
                }
            }

            await this.storefrontService.updateShopTheme(
                this.storefrontService.getHandledThemeSettings(this.storefrontService.setAllEditableText(this.shopDetail).theme_settings)
            );
            await this.storefrontService
                .updateFavoriteProduct({
                    product_ids: Array.from(this.storefrontStateService.currentSelectedProducts.map(product => product.product_id)),
                })
                .then(
                    () =>
                        (this.storefrontStateService.originalSelectedProducts = cloneDeep(
                            this.storefrontStateService.currentSelectedProducts
                        ))
                );

            this.firebaseEventService.logEvent(CUSTOM_ANALYTICS_EVENT.SAVE_EDIT_STOREFRONT);

            await this.getShopDetail();

            this.message.success('Saved!');
        } catch (e) {
            this.message.error('Oops, something went wrong. Please try again or contact us.', { nzDuration: 3000 });
        }
        this.submitting = false;
    }

    updateDomain() {
        const handledDomain = this.domain.trim();
        const domain = `${environment.domainConfig.prefix}${handledDomain}${environment.domainConfig.suffix}`.toLowerCase();
        return this.storefrontService.updateDomain(domain);
    }

    getDomainByUrl() {
        return this.shopDetail.domain?.split('://')[1]?.split('.')[0] || '';
    }

    setupToolbarVisible() {
        this.subscription.add(
            fromEvent(this.rightOperateEle.nativeElement, 'scroll').subscribe(() => {
                // When scroll, hide toolbar
                if (this.storefrontStateService.currentEditingTextSectionData) {
                    document.body.click();
                }
            })
        );
    }
}
