<section class="home-dashboard">
    <section class="d-flex align-items-center sub-plan-status-box relative">
        <div class="status-content" (click)="showSubscriptionModal()">
            <div class="status sub-free-trial" *ngIf="freeTrialUnused" style="white-space: nowrap; background-color: #f3745e; color: #fff">
                Start 90-day Free Trial
                <i class="arrow-icon" nz-icon nzType="antd:sub-starter"></i>
            </div>
            <div class="status sub-free-trial" *ngIf="freeTrialInUse">
                Free Trial
                <i class="arrow-icon" nz-icon nzType="antd:sub-free-trial"></i>
            </div>
            <div class="status sub-advanced" *ngIf="subAdvanced">
                ADVANCED
                <i class="arrow-icon" nz-icon nzType="antd:sub-advanced"></i>
            </div>
            <div class="status sub-premium" *ngIf="subPremium">
                PREMIUM
                <i class="arrow-icon" nz-icon nzType="antd:sub-premium"></i>
            </div>
            <div class="status sub-expired" *ngIf="freeTrialExpired">Expired</div>
        </div>

        <div class="desc-content">
            <span *ngIf="freeTrialInUse && !isBindCard">
                Expires on {{shopProfile.subscription.trial_end_date| date: 'yyyy-MM-dd'}}. Set up subscription for the first month discount
                of $1.99.
            </span>
            <span *ngIf="freeTrialExpired && shopProfile?.shop_type === 'Shopify'">
                Your subscription plan has expired and your license has been closed. Please upgrade your plan.
            </span>
            <span *ngIf="freeTrialExpired && !isShopClosed && shopProfile?.shop_type !== 'Shopify'">
                Your subscription plan has expired. Upgrade your plan before {{getShopClosedDate | date: 'yyyy-MM-dd'}}, or your shop will
                be closed.
            </span>
        </div>
        <div class="btn-content">
            <span *ngIf="freeTrialInUse && !isBindCard" (click)="addPaymentMethod()">Add payment method</span>
            <span
                *ngIf="freeTrialExpired && (shopProfile?.shop_type === 'Shopify' || (!isShopClosed && shopProfile?.shop_type !== 'Shopify'))"
                (click)="showSubscriptionModal()"
            >
                Subscribe plan
            </span>
        </div>
    </section>

    <div *ngIf="notifications?.length" class="notification-content mt-20 mb-20">
        <div *ngFor="let notification of notifications" class="notification-item">
            <div class="left">
                <i nz-icon nzType="antd:notification-icon"></i>
                <span>You have new sample images that are ready for review.</span>
            </div>
            <span class="view-detail text-decoration-underline cursor-pointer" (click)="goSampleDetail(notification)"> View Details </span>
        </div>
    </div>

    <div class="relative">
        <i
            nz-icon
            nzType="antd:guidance"
            class="fs-24 cursor-pointer active-mask"
            style="position: absolute; right: 18px; top: -50px"
            (click)="guidanceVisible = !guidanceVisible"
        ></i>

        <div [style.display]="guidanceVisible ? 'block' : 'none'" [@collapse]="!guidanceVisible">
            <div class="guidance" style="margin-top: 13px">
                <div class="d-flex justify-content-between align-items-start mb-20">
                    <h2>Welcome to Lifo. Let’s Get Started</h2>
                    <span class="text-decoration-underline cursor-pointer active-mask" style="color: #554764" (click)="skipGuidance()">
                        Skip
                    </span>
                </div>

                <div class="d-flex justify-content-between align-items-stretch">
                    <ng-container *ngFor="let item of guidanceList">
                        <div *ngIf="item.visible" class="guidance-item p-20 flex-1">
                            <img [src]="item.img" />
                            <div class="mt-10 fs-14 fw-bold mb-10">{{item.title}}</div>
                            <div class="mb-30">{{item.content}}</div>

                            <button nz-button class="mb-10" (click)="visitGuidance(item.type)">{{item.btnText}}</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <section class="d-flex cards-box">
        <section class="balance-card-item" appLoading [loading]="loadingBalance">
            <section class="col col-1 mr-20">
                <span class="title">
                    <span>Total Balance</span>
                    <i
                        nz-icon
                        nzType="exclamation"
                        nzTheme="outline"
                        nz-tooltip
                        [nzTooltipTitle]="amountTooltip"
                        [nzTooltipOverlayClassName]="'common-tooltip'"
                        [nzTooltipTrigger]="'click'"
                        [nzTooltipVisible]="amountTooltipVisible"
                        [nzTooltipPlacement]="'bottomLeft'"
                        style="
                            margin-left: 10px;
                            color: #554764;
                            background-color: #ebeaed;
                            border-radius: 50%;
                            padding: 4px;
                            font-size: 14px;
                            cursor: pointer;
                        "
                    ></i>
                    <ng-template #amountTooltip>
                        <div class="amount-tooltip-content">
                            Your monthly earnings will be issued on the next payout date (the first day of every month).
                        </div>
                    </ng-template>
                </span>

                <section class="count">
                    <span style="font-size: 18px">$</span>
                    <span class="large-number">{{ getBalanceBeforeDot(accountBalance?.account_balance) }}</span>
                    <span class="small-number">.{{ getBalanceAfterDot(accountBalance?.account_balance) }}</span>
                </section>
            </section>

            <!-- Transferrable Balance -->
            <section class="col col-2 mr-20">
                <div class="row">
                    <div class="row-img-box">
                        <img src="assets/imgs/Send.png" alt="" />
                    </div>
                    <div class="row-title">Transferable Balance</div>
                </div>
                <div class="row justify-content-between">
                    <div class="row-count">${{ transferrableBalance| number: "1.2-2" | mask: 'separator':','}}</div>
                    <div
                        class="transfer-link"
                        [style.cursor]="!accountBalance?.account_balance?'not-allowed':'pointer'"
                        (click)="transferFunds()"
                    >
                        Transfer
                    </div>
                </div>
            </section>

            <!-- Amount Reserved -->
            <section class="col col-3 mr-20" *ngIf="subPremium">
                <div class="row">
                    <div class="row-img-box">
                        <img src="assets/imgs/Lock.png" alt="" />
                    </div>
                    <div class="row-title">Amount Reserved</div>
                </div>
                <div class="row">
                    <div class="row-count">${{(accountBalance?.reserved_balance || 0) | number: "1.2-2" | mask: 'separator':','}}</div>
                    <i
                        nz-icon
                        nzType="exclamation"
                        nzTheme="outline"
                        nz-tooltip
                        [nzTooltipTitle]="amountReserved"
                        [nzTooltipOverlayClassName]="'common-tooltip'"
                        [nzTooltipTrigger]="'click'"
                        [nzTooltipVisible]="amountReservedVisible"
                        [nzTooltipPlacement]="'bottomLeft'"
                        style="
                            margin-left: 10px;
                            color: #554764;
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 4px;
                            font-size: 14px;
                            cursor: pointer;
                        "
                    ></i>
                    <ng-template #amountReserved>
                        <div class="amount-tooltip-content">
                            25% of your monthly sales earnings will be reserved for the product procurement for next month's product
                            procurement.
                            <br />
                            <br />
                            You will be able to transfer the reserved money in your next payout date.
                        </div>
                    </ng-template>
                </div>
            </section>

            <!-- Payment Method -->
            <section class="col col-4">
                <div class="row">
                    <div class="row-img-box">
                        <i nz-icon nzType="credit-card" nzTheme="fill"></i>
                    </div>
                    <div class="row-title">Payment Method</div>
                </div>
                <div class="row justify-content-between" style="color: #573357">
                    <div
                        class="d-flex justify-content-between align-items-center"
                        *ngIf="(connectedBankName || paypalAccount);else connectBank"
                    >
                        <ng-container *ngIf="paymentMethod === AvailablePaymentMethods.bank_account">
                            <div>{{ connectedBankName}}</div>

                            <i
                                *ngIf="!connectingPaymentMethod"
                                nz-icon
                                nzType="edit"
                                nzTheme="outline"
                                style="color: #573357; cursor: pointer"
                                (click)="!connectingPaymentMethod ? connectPlaid() : null"
                            ></i>

                            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
                            <nz-spin *ngIf="connectingPaymentMethod" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
                        </ng-container>

                        <ng-container *ngIf="paymentMethod === AvailablePaymentMethods.paypal">
                            <div style="word-break: break-word; padding-right: 10px">{{ paypalAccount | emailAsterisk}}</div>

                            <i
                                *ngIf="!connectingPaymentMethod"
                                nz-icon
                                nzType="edit"
                                nzTheme="outline"
                                style="color: #573357; cursor: pointer"
                                (click)="!connectingPaymentMethod ? connectPayPal() : null"
                            ></i>

                            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
                            <nz-spin *ngIf="connectingPaymentMethod" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
                        </ng-container>
                    </div>

                    <ng-template #connectBank>
                        <button
                            *ngIf="profileService.currentProfile"
                            nz-button
                            nz-tooltip
                            [nzTooltipTitle]="connectPaypalTooltip"
                            [nzTooltipOverlayClassName]="'connect-paypal-tooltip'"
                            [nzTooltipTrigger]="null"
                            [nzTooltipVisible]="paypalTooltipVisible && !subscriptionVisible"
                            [nzTooltipPlacement]="'bottom'"
                            [nzLoading]="connectingPaymentMethod"
                            (click)="connectPaymentMethod()"
                            style="width: 100%; border-radius: 12px; color: #573357; background-color: #fff"
                        >
                            Connect
                        </button>

                        <ng-template #connectPaypalTooltip>
                            <div class="paypal-tooltip-content" (click)="paypalTooltipVisible = false;">
                                <label>
                                    <span>Please add your payment method for balance payout.</span>
                                    <br />
                                    <span *ngIf="!paymentMethod" style="font-size: 12px">
                                        *This will also be used for subscription payment.
                                    </span>
                                </label>
                                <a class="connect-now" (click)="!connectingPaymentMethod ? connectPaymentMethod(): null">Connect Now</a>
                                <a class="close" (click)="paypalTooltipVisible = false"><img src="assets/svg/close_icon_white.svg" /></a>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </section>
        </section>

        <section class="flex-1 payment-requests">
            <div class="block-title">
                <div class="title">Pending requests</div>
                <span class="requests-count">{{(paymentRequests?.length + inCompleteCheckouts?.length) || 0}}</span>
            </div>
            <div class="list">
                <ng-container *ngIf="paymentRequests?.length>0 || inCompleteCheckouts?.length>0">
                    <ng-container *ngTemplateOutlet="PaymentRequestItemTpl;context:{$implicit:inCompleteCheckouts}"></ng-container>
                    <ng-container *ngTemplateOutlet="PaymentRequestItemTpl;context:{$implicit:paymentRequests}"></ng-container>

                    <ng-template #PaymentRequestItemTpl let-requests>
                        <div
                            class="payment-request"
                            *ngFor="let request of requests;let i = index"
                            [@enter]="{value:'',params: { delay: i * 50} }"
                        >
                            <div class="payment-request-content">
                                <div class="title">{{request?.payment_type}}</div>
                                <div class="amount">${{request?.amount | number: "1.2-2"}}</div>
                                <a class="approve-request-button" (click)="openPaymentRequestModal(request)"> Approve </a>
                            </div>
                            <img *ngIf="request.invoice_image_url" src="assets/svg/approve-image.svg" />
                        </div>
                    </ng-template>
                </ng-container>

                <div class="no-active-image" *ngIf="!paymentRequests?.length && !inCompleteCheckouts?.length">
                    <img src="assets/svg/no-payment-requests.svg" />
                    <label>No new requests</label>
                </div>
            </div>
        </section>
    </section>

    <!-- Shop Sales -->
    <div class="shop-sales-box" appLoading [loading]="!salesInfo">
        <div class="sales-content">
            <section class="shop-sales">
                <section class="sales-row">
                    <span class="section-title">{{ today }}</span>
                    <span class="order-history" (click)="goOrderHistory()">
                        <span>Order History</span>
                        <i nz-icon nzType="right" nzTheme="outline"></i>
                    </span>
                </section>

                <section class="sales-row">
                    <section>
                        <section *ngIf="!salesInfo" class="count">--</section>
                        <section *ngIf="salesInfo" class="count">
                            <span style="font-size: 18px">$</span>
                            <span class="large-number">{{ getBalanceBeforeDot(salesInfo?.total_sales) }}</span>
                            <span class="small-number">.{{ getBalanceAfterDot(salesInfo?.total_sales) }}</span>
                        </section>
                        <span class="text">Sales Today</span>
                    </section>

                    <section>
                        <section *ngIf="!salesInfo" class="count">--</section>
                        <section *ngIf="salesInfo" class="count">{{ salesInfo?.total_orders }}</section>
                        <span class="text">Orders Today</span>
                    </section>
                </section>
            </section>

            <section class="weekly-sales">
                <h4 class="section-title">Weekly Sales</h4>
                <div *ngIf="salesChartFailed && salesInfo?.daily_sales === null" class="error-msg">
                    Oops! Something went wrong. Please try again.
                </div>
                <div
                    appEcharts
                    [initialOption]="initialSalesChartOption"
                    [optionToBeUpdate]="salesOptionToBeUpdate"
                    class="sales-chart"
                ></div>
            </section>
        </div>
    </div>

    <!-- Shop Visits -->
    <div *ngIf="subPremium" class="shop-visits-box" appLoading [loading]="!analyticsInfo">
        <div class="content">
            <section class="shop-visits">
                <h4 class="section-title">Shop Sessions</h4>
                <div class="nums">
                    <div class="num-item">
                        <div class="count-today">{{analyticsInfo?.visitsToday || 0}}</div>
                        <div class="num-desc">Sessions Today</div>
                    </div>
                    <div class="num-item">
                        <div class="count-from-yesterday">
                            <i *ngIf="analyticsInfo?.fromYesterday >= 0" nz-icon nzType="caret-up" nzTheme="outline"></i>
                            <i *ngIf="analyticsInfo?.fromYesterday < 0" nz-icon nzType="caret-down" nzTheme="outline"></i>
                            <span>{{analyticsInfo?.fromYesterday || 0 | percent}}</span>
                        </div>
                        <div class="num-desc">From Yesterday</div>
                    </div>
                    <div class="num-item">
                        <div class="count-total">{{analyticsInfo?.totalVisits || 0 | number:'1.0-0'}}</div>
                        <div class="num-desc">Total Sessions</div>
                    </div>
                </div>
            </section>
            <section class="weekly-traffic">
                <h4 class="section-title">Weekly Traffic</h4>
                <div *ngIf="analyticsInfo?.weeklyTraffics === null" class="error-msg">
                    <ng-container *ngIf="trafficChartFailedType === 'error'"> Oops! Something went wrong. Please try again. </ng-container>
                    <ng-container *ngIf="trafficChartFailedType === 'noData'"> No Data </ng-container>
                </div>
                <div
                    appEcharts
                    [initialOption]="initialVisitsChartOption"
                    [optionToBeUpdate]="visitsOptionToBeUpdate"
                    class="traffic-chart"
                ></div>
            </section>
        </div>
    </div>

    <section
        *ngIf="shopProfile.shop_detail.show_review_product && shopProfile.subscription_plan === SubscribePlanNameEnum.PREMIUM"
        style="margin: 0px -50px"
    >
        <app-recommend-product #recommend></app-recommend-product>
    </section>

    <!-- Revenue Report -->
    <section *ngIf="subPremium" class="revenue-report">
        <section class="section-title">
            <span (click)="revenueReportHidden = !revenueReportHidden">
                <i class="arrow-icon" nz-icon nzType="antd:arrow-down" [@rotate]="revenueReportHidden"></i>
                <span>Revenue Report</span>
            </span>

            <span *ngIf="isRevenueReportHasNew" class="new-icon">New</span>
        </section>

        <section [@collapse]="revenueReportHidden" class="table-content">
            <nz-table #basicTable [nzData]="revenueReport" [nzLoading]="loadingStatusMap.revenueReport" [nzNoResult]="noRevenueData">
                <thead>
                    <tr>
                        <th>Items</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td>
                            <div style="white-space: nowrap">
                                <span>{{ data.report_name | titlecase }}</span>
                                <span *ngIf="isNewRecord(data,'created_at')" class="new-icon">New</span>
                            </div>
                        </td>
                        <td>{{ data.created_at | localtime }}</td>
                        <td [ngClass]="{ add: data.amount >= 0, reduce: data.amount < 0 }">{{ data.amount | currency }}</td>
                        <td>
                            <span
                                class="show-detail"
                                nz-popover
                                nzPopoverPlacement="top"
                                nzPopoverTrigger="click"
                                nzPopoverOverlayClassName="no-padding no-bg"
                                [nzPopoverContent]="detailPopup"
                                (click)="shownRevenueItem = data"
                            >
                                <span> Show Details </span>
                                <i nz-icon nzType="caret-down" nzTheme="outline" style="margin-left: 5px"></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </section>

        <ng-template #detailPopup>
            <app-revenue-detail-popup [revenueDetail]="shownRevenueItem"></app-revenue-detail-popup>
        </ng-template>

        <ng-template #noRevenueData>
            <app-empty-table description="Your revenue report will be ready every month"></app-empty-table>
        </ng-template>
    </section>

    <!-- Cost Report -->
    <section *ngIf="shopProfile.subscription_plan !== 'starter'" class="cost-report">
        <div class="section-title">
            <span (click)="costReportHidden = !costReportHidden">
                <i class="arrow-icon" nz-icon nzType="antd:arrow-down" [@rotate]="costReportHidden"></i>
                <span>Cost Report</span>
                <span *ngIf="isCostReportHasNew" class="new-icon">New</span>
            </span>
        </div>

        <section [@collapse]="costReportHidden" class="table-content">
            <nz-table #costTable [nzData]="paidRequests" [nzLoading]="loadingStatusMap.costReport" [nzNoResult]="noCostData">
                <thead>
                    <tr>
                        <th>Items</th>
                        <th>Date</th>
                        <th>Payment Method</th>
                        <th>Amount</th>
                        <th>Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of costTable.data">
                        <td>
                            <div style="white-space: nowrap">
                                <span>{{ data.payment_type }}</span>
                                <span *ngIf="isNewRecord(data,'created_at')" class="new-icon">New</span>
                            </div>
                        </td>
                        <td>{{ data.created_at | localtime }}</td>
                        <td>
                            {{ data.pay_method }}
                            <span
                                class="arrow-down"
                                *ngIf="data.pay_method === 'Split Payment'"
                                nz-popover
                                nzPopoverPlacement="top"
                                nzPopoverTrigger="click"
                                nzPopoverOverlayClassName="no-padding no-bg"
                                [nzPopoverContent]="detailPopover"
                                (click)="paymentDetail = generatePaymentDetail(data)"
                            >
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                            </span>
                            <ng-template #detailPopover>
                                <app-amount-detail-popover
                                    [revenueDetail]="null"
                                    [paymentDetail]="paymentDetail"
                                ></app-amount-detail-popover>
                            </ng-template>
                            <div *ngIf="data.pay_method === 'Bank Account'" class="bank-name">{{ data.bank_number }}</div>
                        </td>
                        <td [ngClass]="{ add: data.amount >= 0, reduce: data.amount < 0 }">{{ data.amount | currency }}</td>
                        <td>
                            <div *ngIf="data.invoice_image_url">
                                View Invoice
                                <span class="arrow-down" (click)="openLink(data.invoice_image_url)">
                                    <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                                </span>
                            </div>
                            <div *ngIf="!data.invoice_image_url">————</div>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </section>

        <ng-template #noCostData>
            <app-empty-table description="No cost reports yet"></app-empty-table>
        </ng-template>
    </section>
</section>

<!-- Cashout confirm -->
<nz-modal
    [nzClassName]="'cash-out-modal'"
    [(nzVisible)]="this.dialogState.cashOutConfirmDialog"
    [nzFooter]="cashOutFooter"
    [nzClosable]="false"
>
    <section class="cash-out-modal-container">
        <div class="row title">
            <label>Transfer Funds</label>
            <div class="close-button" (click)="this.closeDialog('cashOutConfirmDialog')">
                <img src="assets/svg/close_icon.svg" />
            </div>
        </div>

        <div>
            <div class="row amount border">
                <label>Transferable Balance </label>
                <div class="amount curent-balance-amount" style="font-weight: bold">
                    $
                    <span>{{transferrableBalance | number: "1.2-2" | mask: 'separator':','}}</span>
                </div>
            </div>
            <div class="row amount border">
                <label>Amount</label>
                <div class="amount" style="font-weight: bold" [ngStyle]="{color:cashoutWarningMessageType?'#d72828':null}">
                    $
                    <span *ngIf="!cashOutShowInput">{{newAmountFC.value | number: "1.2-2" | mask: 'separator':','}}</span>
                    <input
                        *ngIf="cashOutShowInput"
                        type="text"
                        class="amount-input"
                        nz-input
                        placeholder="Enter amount"
                        [formControl]="newAmountFC"
                        [ngStyle]="{color:cashoutWarningMessageType?'#d72828':null}"
                        (blur)="correctAmount()"
                    />
                    <div class="edit-amount-icon" (click)="cashOutShowInput = !cashOutShowInput">
                        <img src="assets/svg/edit_icon.svg" />
                    </div>
                </div>
            </div>
            <div class="row border">
                <label>Transfer to</label>
                <div class="paypal-info">
                    <div class="paypal-logo">
                        <span *ngIf="!paymentMethod"> Payment Method </span>
                        <span class="payment-method-logo" *ngIf="paymentMethod === 'bank_account'"> Bank Account </span>
                        <span class="payment-method-logo" *ngIf="paymentMethod === 'paypal'"> Paypal </span>
                    </div>
                    <ng-container *ngIf="paymentMethod === 'paypal'">
                        <div class="paypal-account">{{ profileService.currentProfile?.paypal | emailAsterisk }}</div>
                    </ng-container>
                    <ng-container *ngIf="paymentMethod === 'bank_account'">
                        <div class="paypal-account">{{ connectedBankName }}</div>
                    </ng-container>
                </div>
            </div>

            <div class="warning-message" *ngIf="!!cashoutWarningMessageType">
                <img src="assets/svg/Info.svg" />
                <ng-container *ngIf="cashoutWarningMessageType === 'error'">Oops, something went wrong. Please try again.</ng-container>
                <ng-container *ngIf="cashoutWarningMessageType === 'less_then_0'">Please enter an amount larger than $0</ng-container>
                <ng-container *ngIf="cashoutWarningMessageType === 'more_than_balance'">
                    Please enter an amount less than or equal to ${{transferrableBalance | number: "1.2-2" | mask:'separator':','}}
                </ng-container>
            </div>
        </div>
    </section>

    <ng-template #cashOutFooter>
        <button nz-button nzType="default" [disabled]="isCashingOut" (click)="closeDialog('cashOutConfirmDialog')">Cancel</button>
        <button nz-button nzType="primary" [nzLoading]="isCashingOut" [disabled]="!newAmountFC.valid || isCashingOut" (click)="cashOut()">
            Transfer
        </button>
    </ng-template>
</nz-modal>

<nz-modal
    [nzClassName]="'add-payment-method-modal'"
    [(nzVisible)]="addPaymentMethodVisible"
    [nzFooter]="null"
    [nzClosable]="false"
    nzWidth="600px"
>
    <app-add-payment-method
        (closeModal)="addPaymentMethodVisible = false"
        (saveSuccess)="addPaymentMethodSuccess()"
    ></app-add-payment-method>
</nz-modal>

<nz-modal [nzFooter]="null" [nzClosable]="false" [nzStyle]="{ top: '100%' }" [(nzVisible)]="mobilePopup">
    <div class="mobile-info">
        <img class="mobile-down" (click)="mobilePopup = false" src="assets/imgs/mobile-down.png" />
        <img class="mobile-img" src="assets/imgs/mobile-img.png" />
        <div class="mobile-text">You’re welcome to visit via desktop for the best experience</div>
    </div>

    <button nz-button nzType="primary" style="width: 100%; margin-top: 40px" (click)="mobilePopup = false">Visit Desktop Version</button>
</nz-modal>
