<section class="table-list">
    <nz-table #middleTable [nzData]="productCreation" [nzPageSize]="50" [nzShowPagination]="false" nzbordered nzsize="small">
        <tbody>
            <tr *ngFor="let data of middleTable.data" [ngClass]="{'title': data.title}">
                <td [colSpan]="isTdShouldBeHide(data) ? 6 : 1" class="clearfix">
                    <span>
                        {{ data.name }}
                        <ul *ngIf="data.subName" style="margin-bottom: 0; padding-left: 24px">
                            <li *ngFor="let subN of data.subName">{{ subN }}</li>
                        </ul>
                    </span>
                    <span *ngIf="data.tooltips" class="tooltips">
                        <img
                            [nzTooltipOverlayClassName]="'common-tooltip'"
                            [nzTooltipPlacement]="'top'"
                            [nzTooltipTitle]="tooltipsTemplate"
                            nz-tooltip
                            src="/assets/imgs/question-icon.jpg"
                            width="20"
                        />
                        <ng-template #tooltipsTemplate>
                            <div [innerHTML]="data.tooltips"></div>
                        </ng-template>
                    </span>
                    <div *ngIf="data.describe" [innerHTML]="data.describe" class="sub-title"></div>
                </td>
                <td [hidden]="isTdShouldBeHide(data)">
                    <span *ngIf="data.title"> {{ data.advanced }} </span>
                    <span *ngIf="data.advanced && !data.title">
                        <img src="/assets/imgs/green-check.jpg" width="20" />
                    </span>
                    <span *ngIf="data.advancedDescribe" class="describe">
                        <span *ngIf="data.footer;else contentTemplate">
                            Up to
                            <span class="price">{{ data.advancedDescribe }}</span>
                        </span>
                        <ng-template #contentTemplate> {{ data.advancedDescribe }} </ng-template>
                    </span>
                </td>
                <td [hidden]="isTdShouldBeHide(data)">
                    <span *ngIf="data.title"> {{ data.premium }} </span>
                    <span *ngIf="data.premium && !data.title">
                        <img src="/assets/imgs/yellow-check.jpg" width="20" />
                    </span>
                    <span *ngIf="data.premiumDescribe" class="describe">
                        <span *ngIf="data.footer;else contentTemplate">
                            Up to
                            <span class="price premium">{{ data.premiumDescribe }}</span>
                        </span>
                        <ng-template #contentTemplate> {{ data.premiumDescribe }} </ng-template>
                    </span>
                </td>
            </tr>
        </tbody>
    </nz-table>
</section>
