import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { AngularFireAuth } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileService } from '@services/profile.service';
import { AuthService } from '@services/auth.service';
import { ImagePreviewFullscreenService } from '@services/image-preview-fullscreen.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private intercom: Intercom,
        private auth: AngularFireAuth,
        private profileService: ProfileService,
        private authService: AuthService,
        private imagePreviewService: ImagePreviewFullscreenService,
        private vcr: ViewContainerRef
    ) {}

    ngOnInit() {
        this.auth.idTokenResult.subscribe(idTokenResult => (this.authService.idTokenResult = idTokenResult));

        this.intercom.boot({
            app_id: 'etq9z8nj',
            // Supports all optional configuration.
            widget: { activator: '#intercom' },
        });

        this.auth.authState.pipe(takeUntil(this.unsubscribe)).subscribe(fireUser => {
            if (fireUser) {
                this.profileService.getCurrentProfile().then(profile => {
                    if (profile.email) {
                        this.profileService.currentProfile = profile;
                    }
                });
            }
        });

        this.imagePreviewService.setRootViewContainerRef(this.vcr);
    }

    ngOnDestroy() {
        this.unsubscribe.unsubscribe();
    }
}
